import styled, { keyframes } from 'styled-components';


/*
https://styled-components.com/docs


Over 200 classes were generated for component styled.div with the id of "sc-AxirZ".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:

const Component = styled.div.attrs({
    style: ({ background }) => {
        background,
    },
})`width: 100%;`

<Component />




calc(100% - 300px)


TM - Notes 

- PrimeScrollBox calc(100% - 120px) 120px equals the HeaderNav height + InfoBar height
- Use vh - viewport height - to set the stage height

   height:${props => props.height};


*/





const fadeIn = keyframes`

    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const fadeOut = keyframes`

    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
`;


export const AnimaShield = styled.div.attrs(props => ({

    basename:           props.basename || "",

    backgroundColor:    props.backgroundColor || "#141820",//#fcfcfc
    width:              props.width || "100%",
    height:             props.height || "100vh",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    zIndex:           props.zIndex || "550",
    top:              props.top || "0px", 
    left:             props.left || "0px",

    // percent:           props.percent || "99", 
    // decimal:           props.decimal || "0.99",

    percent:                props.percent || "0", 
    decimal:                props.decimal || "0.0",

    out:                    props.out || false,
    animationTime:          props.animationTime || '0.7s',
    transitionTime:         props.transitionTime || '0.7s',







    //-----------------
    //logo
    //-----------------

    logoBackgroundColor:    props.logoBackgroundColor || "transparent",

    //logoBackgroundImage:    props.logoBackgroundImage || props.basename + "/_resources/icons/tpc_profit_pie.svg",
    logoBackgroundImage:    props.logoBackgroundImage || "_resources/svgs/tpc_box_logo.svg",
 
    logoBackgroundRepeat:   props.logoBackgroundRepeat || "no-repeat",
    logoBackgroundPosition: props.logoBackgroundPosition || "bottom 0px left 0px",
    //logoBackgroundPosition: props.logoBackgroundPosition || "top 0px left 0px",
    logoBackgroundSize:     props.logoBackgroundSize || "300px 100px",




    //-----------------
    //pieLogo
    //-----------------

    pieLogoBackgroundColor:    props.pieLogoBackgroundColor || "transparent",

    //pieLogoBackgroundImage:    props.pieLogoBackgroundImage || props.basename + "/_resources/icons/tpc_profit_pie.svg",
    pieLogoBackgroundImage:    props.pieLogoBackgroundImage || "_resources/icons/tpc_profit_pie_black.svg",
 
    pieLogoBackgroundRepeat:   props.pieLogoBackgroundRepeat || "no-repeat",
    pieLogoBackgroundPosition: props.pieLogoBackgroundPosition || "bottom 0px left 0px",
    //pieLogoBackgroundPosition: props.pieLogoBackgroundPosition || "top 0px left 0px",
    pieLogoBackgroundSize:     props.pieLogoBackgroundSize || "200px 200px",


    //-----------------
    //textLogo
    //-----------------

    textLogoBackgroundColor:    props.textLogoBackgroundColor || "transparent",

    //textLogoBackgroundImage:    props.textLogoBackgroundImage || props.basename + "/_resources/icons/tpc_profit_pie.svg",
    textLogoBackgroundImage:    props.textLogoBackgroundImage || "_resources/icons/tpc_text_black.svg",
    
    textLogoBackgroundRepeat:   props.textLogoBackgroundRepeat || "no-repeat",
    textLogoBackgroundPosition: props.textLogoBackgroundPosition || "top 0px left 0px",
    //textLogoBackgroundPosition: props.textLogoBackgroundPosition || "top 0px left 0px",
    textLogoBackgroundSize:     props.textLogoBackgroundSize || "300px 30px",
    
    
    
    




    // TM: - for reference - transitions won't work on visibility - must be numeric value for keyframes timing
    //transition: visibility ${props => props.transitionTime} linear;


    // display: inline-block;
    // visibility: ${props => props.out ? 'hidden' : 'visible'};
    // animation: ${props => props.out ? fadeOut : fadeIn} ${props => props.animationTime} linear;





}))`
  



    background-color:transparent;


    width:${props => props.width};
    height:${props => props.height};

    padding :0 0 0 0; 
    margin  :0 0 0 0;

    position:absolute;   
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};



    .Alfa {

        background-color:${props => props.backgroundColor};

        width:${props => props.width};
        height:${props => props.height};

        padding :0 0 0 0; 
        margin  :0 0 0 0;

        position:relative;
        float:left;

        -ms-filter: "progid:pxImageTransform.Microsoft.Alpha(Opacity=${props => props.percent})";
        filter : alpha(opacity=${props => props.percent});
        -moz-opacity : ${props => props.decimal};
        -khtml-opacity : ${props => props.decimal};
        opacity : ${props => props.decimal};

        animation: ${props => props.out ? fadeOut : fadeIn} ${props => props.animationTime} linear;

    }



    .Box {

        background-color:transparent;
        width:300px;
        height:300px;
        padding:0 0 0 0;
        margin:100px auto 0 auto;

    }

    @media (max-height: 500px) {

     
        .Box {

            margin:25px auto 0 auto;
    
        }


    }





    .Logo {

        background-color:${props => props.logoBackgroundColor};
        background-image:url(${({
            basename,
            logoBackgroundImage
        }) => 

            (
                basename !== "" 
                && basename !== undefined  
                && basename + logoBackgroundImage
            ) 

            ||
            
            logoBackgroundImage
            
        });
        background-repeat:${props => props.logoBackgroundRepeat};
        background-position:${props => props.logoBackgroundPosition};
        background-size:${props => props.logoBackgroundSize};

        width:300px;
        height:100px;
        padding:0 0 0 0;
        margin:calc(50% - 0px) 0 0 calc(50% - 150px);

        position:relative;
        float:left;

    }






    .PieLogo {

        background-color:${props => props.pieLogoBackgroundColor};
        background-image:url(${({
            basename,
            pieLogoBackgroundImage
        }) => 

            (
                basename !== "" 
                && basename !== undefined  
                && basename + pieLogoBackgroundImage
            ) 

            ||
            
            pieLogoBackgroundImage
            
        });
        background-repeat:${props => props.pieLogoBackgroundRepeat};
        background-position:${props => props.pieLogoBackgroundPosition};
        background-size:${props => props.pieLogoBackgroundSize};


        width:200px;
        height:200px;
        padding:0 0 0 0;
        margin:calc(50% - 50px) 0 0 calc(50% - 100px);

        position:relative;
        float:left;

    }


    .TextLogo {

        background-color:${props => props.textLogoBackgroundColor};
        background-image:url(${({
            basename,
            textLogoBackgroundImage
        }) => 
    
            (
                basename !== "" 
                && basename !== undefined  
                && basename + textLogoBackgroundImage
            ) 
    
            ||
            
            textLogoBackgroundImage
            
        });
        background-repeat:${props => props.textLogoBackgroundRepeat};
        background-position:${props => props.textLogoBackgroundPosition};
        background-size:${props => props.textLogoBackgroundSize};
    
    
        width:300px;
        height:30px;
        padding:0 0 0 0;
        margin:calc(50% - 0px) 0 0 0;
    
        position:relative;
        float:left;
    
    }
    

    .Text {

        background-color:transparent;
        width:100%;
        height:auto;
        padding:0 0 0 0;
        margin:15px 0 0 0;

        position:relative;
        float:left;

        font-family:Arial, Helvetica, sans-serif;      
        font-size: 20px;
        font-weight:550;
        text-align: center;
        text-decoration:none;
        color:#fff;

    }



`;






export const Shield = styled.div.attrs(props => ({


    basename:           props.basename || "",

    backgroundColor:    props.backgroundColor || "#141820",//#fcfcfc
    width:              props.width || "100%",
    height:             props.height || "100vh",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    zIndex:             props.zIndex  || "550",




    //-----------------
    //logo
    //-----------------

    logoBackgroundColor:    props.logoBackgroundColor || "transparent",

    //logoBackgroundImage:    props.logoBackgroundImage || props.basename + "/_resources/icons/tpc_profit_pie.svg",
    logoBackgroundImage:    props.logoBackgroundImage || "_resources/svgs/tpc_box_logo.svg",
 
    logoBackgroundRepeat:   props.logoBackgroundRepeat || "no-repeat",
    logoBackgroundPosition: props.logoBackgroundPosition || "bottom 0px left 0px",
    //logoBackgroundPosition: props.logoBackgroundPosition || "top 0px left 0px",
    logoBackgroundSize:     props.logoBackgroundSize || "300px 100px",






    //-----------------
    //pieLogo
    //-----------------

    pieLogoBackgroundColor:    props.pieLogoBackgroundColor || "transparent",

    //pieLogoBackgroundImage:    props.pieLogoBackgroundImage || props.basename + "/_resources/icons/tpc_profit_pie.svg",
    pieLogoBackgroundImage:    props.pieLogoBackgroundImage || "_resources/icons/tpc_profit_pie_black.svg",
 
    pieLogoBackgroundRepeat:   props.pieLogoBackgroundRepeat || "no-repeat",
    pieLogoBackgroundPosition: props.pieLogoBackgroundPosition || "bottom 0px left 0px",
    //pieLogoBackgroundPosition: props.pieLogoBackgroundPosition || "top 0px left 0px",
    pieLogoBackgroundSize:     props.pieLogoBackgroundSize || "200px 200px",


    
    //-----------------
    //textLogo
    //-----------------

    textLogoBackgroundColor:    props.textLogoBackgroundColor || "transparent",

    //textLogoBackgroundImage:    props.textLogoBackgroundImage || props.basename + "/_resources/icons/tpc_profit_pie.svg",
    textLogoBackgroundImage:    props.textLogoBackgroundImage || "_resources/icons/tpc_text_black.svg",
    
    textLogoBackgroundRepeat:   props.textLogoBackgroundRepeat || "no-repeat",
    textLogoBackgroundPosition: props.textLogoBackgroundPosition || "top 0px left 0px",
    //textLogoBackgroundPosition: props.textLogoBackgroundPosition || "top 0px left 0px",
    textLogoBackgroundSize:     props.textLogoBackgroundSize || "300px 30px",
    
    



    // background-image:url(${({basename,backgroundImage}) => 

    //     (
    //         basename !== "" 
    //         && basename !== undefined  
    //         && basename + backgroundImage
    //     ) 

    //     ||
        
    //     backgroundImage
        
    // });




}))`

    background-color:${props => props.backgroundColor};
    
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:absolute;
    top:0px;
    left:0px;
    z-index:${props => props.zIndex};

    display:block;
    overflow:hidden;


    .Box {

        background-color:transparent;
        width:300px;
        height:300px;
        padding:0 0 0 0;
        margin:100px auto 0 auto;

    }

    @media (max-height: 500px) {

     
        .Box {

            margin:25px auto 0 auto;
    
        }


    }


    .Logo {

        background-color:${props => props.logoBackgroundColor};
        background-image:url(${({
            basename,
            logoBackgroundImage
        }) => 

            (
                basename !== "" 
                && basename !== undefined  
                && basename + logoBackgroundImage
            ) 

            ||
            
            logoBackgroundImage
            
        });
        background-repeat:${props => props.logoBackgroundRepeat};
        background-position:${props => props.logoBackgroundPosition};
        background-size:${props => props.logoBackgroundSize};

        width:300px;
        height:100px;
        padding:0 0 0 0;
        margin:calc(50% - 0px) 0 0 calc(50% - 150px);

        position:relative;
        float:left;

    }




    .PieLogo {

        background-color:${props => props.pieLogoBackgroundColor};
        background-image:url(${({
            basename,
            pieLogoBackgroundImage
        }) => 

            (
                basename !== "" 
                && basename !== undefined  
                && pieLogoBackgroundImage
            ) 

            ||
            
            pieLogoBackgroundImage
            
        });
        background-repeat:${props => props.pieLogoBackgroundRepeat};
        background-position:${props => props.pieLogoBackgroundPosition};
        background-size:${props => props.pieLogoBackgroundSize};

        width:200px;
        height:200px;
        padding:0 0 0 0;
        margin:calc(50% - 50px) 0 0 calc(50% - 100px);

        position:relative;
        float:left;

    }


    .TextLogo {

        background-color:${props => props.textLogoBackgroundColor};
        background-image:url(${({
            basename,
            textLogoBackgroundImage
        }) => 
    
            (
                basename !== "" 
                && basename !== undefined  
                && basename + textLogoBackgroundImage
            ) 
    
            ||
            
            textLogoBackgroundImage
            
        });
        background-repeat:${props => props.textLogoBackgroundRepeat};
        background-position:${props => props.textLogoBackgroundPosition};
        background-size:${props => props.textLogoBackgroundSize};
    
    
        width:300px;
        height:30px;
        padding:0 0 0 0;
        margin:calc(50% - 0px) 0 0 0;
    
        position:relative;
        float:left;
    
    }
    

    .Text {

        background-color:transparent;
        width:100%;
        height:auto;
        padding:0 0 0 0;
        margin:15px 0 0 0;

        position:relative;
        float:left;

        font-family:Arial, Helvetica, sans-serif;      
        font-size: 20px;
        font-weight:550;
        text-align: center;
        text-decoration:none;
        color:#fff;

    }




`;









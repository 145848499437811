const LibString =  {


	//CapitalizeWords('this IS THE wOrst string eVeR');
	
	CapitalizeWords : (s) => {
	
	
		return s.toLowerCase().replace( /\b./g, function(a){ return a.toUpperCase(); } );
	
		
    },
	

	TrimWhitespaces : function(dirty) {

		var clean = dirty.replace(/(^\s+|\s+$)/g, '');//replace kills whitespace
		return clean;

	},

	
    InputMaxlength: (v) => {

        var str = v.str.toString();
        var maxlength = parseInt(v.maxlength);
        var diff;
    

        // console.log("IN STR: "+str+"\nmaxlength: "+maxlength)

        if(str.length === parseInt(maxlength)-1)
        {
            if(!str.match(/\./gi)) maxlength = maxlength-2;
            diff = str.length - maxlength;
            str = str.substr(0,str.length-diff);
        }
        else
        if(str.length > maxlength 
        || str.length === maxlength) 
        {
            if(!str.match(/\./gi)) maxlength = maxlength-1;
            diff = str.length - maxlength;
            str = str.substr(0,str.length-diff);
        }

        // console.log("OUT STR: "+str+"\nmaxlength: "+maxlength)


        return str;

    },



	SplitAtIndex: (str, index, splitter) => {


		var ret;
		ret = str.substring(0, index) + splitter + str.substring(index);
		return ret;
		
		//console.log(split_at_index('3123124', 2));

	},

	
	SplitOnCapitalLetter: (str,delimiter) => {

		let ret = "";
		const result = str
		  .trim()
		  .split(/(?=[A-Z])/)
		  .map(element => element.trim());
		
		// ['One , 'Two', 'Three']
		console.log(result);

		ret = result.join(",");
		ret = ret.replace(/,/gi,delimiter);

		console.log(ret);

		return ret;
	

	},



	//#######################################################################
	//#######################################################################

	ReplaceSpecialChars : function(string,exceptions){


		var R = string;

			//=============================================
			//REPLACE all special chars except( - _  . ')
			//=============================================


			//WITH APOSTROPHE
			//var regex = /[!@#\$%^&\*,+="':;?()\<\>\[\]\{\}\/]+/gi;

			//WITHOUT APOSTROPHE
			//var regex = /[!@#\$%^&\*,+=":;?()\<\>\[\]\{\}\/]+/gi;



			// str.replace(/\W/g, '') //doesnt include underscores
			// str.replace(/[^0-9a-z]/gi, '') //removes underscores too
	


			//---------------------
			//React (Not Working)
			//---------------------

			//WITH APOSTROPHE
			//var regex = /[!@#\$%^&\*,+="':;?()\<\>\[\]\{\}\/]+/gi;


			//WITHOUT APOSTROPHE
			// var regex = /[!@#$%^&*,+=":;?()<>[]{}\/]+/gi;

			// R = R.replace(regex,'');




			//REFERENCE

			// /[^a-zA-Z\d\s:\u00C0-\u00FF]/g

			// ^ negates what follows

			// a-zA-Z matches upper and lower case letters

			// \d matches digits

			// \s matches white space (if you only want to match spaces, replace this with a space)

			// : matches a colon

			// \u00C0-\u00FF matches the Unicode range for accented latin characters.



			//---------------------
			//React (Working)
			//---------------------

			if(Object.prototype.toString.call(exceptions) === '[object Array]')
			{
				var exceptionsString = exceptions.join("-");
				//alert("exceptionsString: "+exceptionsString);


				//R = R.replace(/[^\w\s]/gi, '');// doesnt include spaces
				//R = R.replace(/[^\w,]/gi, '');// doesnt include commas
				//R = R.replace(/[^\w,.]/gi, '');// doesnt include commas and periods
				//R = R.replace(/[^\w,.?!]/gi, '');// doesnt include all punctuation




				//strict - no spacing dashes and underscores

				if(exceptionsString.match(/dash/gi) 
				&& exceptionsString.match(/underscore/gi)
				&& exceptionsString.match(/comma/gi)
				)
				{
					R = R.replace(/[^a-zA-Z\d\-_,\u00C0-\u00FF]/g, '');// doesnt include dashes and underscors
			
				}
				else
				if(exceptionsString.match(/dash/gi) 
				&& exceptionsString.match(/underscore/gi)
				)
				{
					R = R.replace(/[^a-zA-Z\d\-_\u00C0-\u00FF]/g, '');// doesnt include dashes and underscors
			
				}
		



				// separators = spaces dashes and underscores

				else
				if(exceptionsString.match(/separators/gi) && exceptionsString.match(/comma/gi)
				)
				{
					R = R.replace(/[^a-zA-Z\d\s\-_,\u00C0-\u00FF]/g, '');// doesnt include spaces punct and specials
				
				}
				else
				if(exceptionsString.match(/separators/gi) && exceptionsString.match(/punct_specials/gi)
				)
				{
					R = R.replace(/[^a-zA-Z\d\s\-_:;,."'?!@#$%\u00C0-\u00FF]/g, '');// doesnt include spaces punct and specials
				
				}
				else
				if(exceptionsString.match(/separators/gi) && exceptionsString.match(/punct_all/gi)
				)
				{
					R = R.replace(/[^\w\s\-_,.?!"':;]/gi, '');// doesnt include spaces and all punctuation
				
				}
				else
				if(exceptionsString.match(/separators/gi)
				)
				{
					R = R.replace(/[^a-zA-Z\d\s\-_\u00C0-\u00FF]/g, '');// doesnt include spaces dashes and underscores
				
				}
		


				// others


				else
				if(exceptionsString.match(/spaces/gi) && exceptionsString.match(/punct/gi)
				)
				{
					R = R.replace(/[^\w\s,.?!]/gi, '');// doesnt include spaces and basic punctuation
				
				}
				else
				if(exceptionsString.match(/spaces/gi) && exceptionsString.match(/commas/gi)
				)
				{
					R = R.replace(/[^\w\s,]/gi, '');// doesnt include spaces and commas

				}
				else
				if(exceptionsString.match(/spaces/gi)
				)
				{
					R = R.replace(/[^\w\s]/gi, '');// doesnt include spaces
				}
				else
				if(exceptionsString.match(/commas/gi)
				)
				{
					R = R.replace(/[^\w,]/gi, '');// doesnt include commas
					
				}





			}else{


				R = R.replace(/\W/g, '');			// doesnt include underscores
				//R = R.replace(/[^0-9a-z]/gi, ''); 	// removes underscores too			
			}



	


		return R;


	},



	//#######################################################################
	//#######################################################################

	
	LettersToNumbers : function(str) {


		var pos;
		var out = 0, len = str.length;

		for(pos = 0; pos < len; pos++) 
		{
			out += (str.charCodeAt(pos) - 64) * Math.pow(26, len - pos - 1);
		}

		return out;


	},





	//#######################################################################
	//#######################################################################

	CharacterLength : function(text,charlen,use_dots) {
					
		var R = text;

			R = R.substring(0,charlen);					
			if(use_dots)
			{
				if(R.length > charlen) R = R + "..";

			}//

		return R;

	},





	//#######################################################################
	//#######################################################################

	
	LimitString : function( string, charlen, dots ){


		var R = string;
		var use_dots;

			if(dots)
			{
				if(R.length > charlen)
				{ 
					use_dots = true;
				}else{
					use_dots = false;
				}//#

			}//#
										
			R = R.substring(0,charlen);
							
			if(use_dots) R = R + "..";


		return R;


	},

	






	//#######################################################################
	//#######################################################################

	

	SlashCount : function(str) {
	

		if(str && str !== '')
		{
			var arr = str.split('/');
			var count = arr.length-1;
			return count;

		}//#

	
	},





	//#######################################################################
	//#######################################################################
	
	HasInvalidChars : function(str){
	
		var r = false;


		//Non React
		//var regex = /[!@#\$%^&\*,+="':;?\<\>\[\]\{\}\/]+/i;


		// React Trys
		//var regex = /^[0-9a-z]+$/;
		//var regex = /[!@#$%^&*,+="':;?<>[]{}\/]+/gi;
		//var invalid_str = str.match(regex);



		// React Works
		var invalid_str = str.match(/\W/g) //doesnt include underscores
		//var invalid_str = str.match(/[^0-9a-z]/gi) //removes underscores too

		if(invalid_str)
		{
			r = '! @ # $ % ^ & * , "'; 
			r += " ' + = : ; ? [] {} < > ()";

		}//


		// alert(""
		// 	+"\nstring in: "+str
		// 	+"\ninvalid_str: "+invalid_str
		// );

	
		return r;

	},







	//#######################################################################
	//#######################################################################


	RemoveInvalidChars : function(str){
	
		//var regex = /[!@#\$%^&\*,+="':;?\<\>\[\]\{\}\/]+/i;
		// var regex = /[!@#$%^&*,+="':;?<>[]{}\/]+/i;
		// str = str.replace(regex,'');

		//---------------------
		//React (Working)
		//---------------------

		str = str.replace(/\W/g, '') 			// doesnt include underscores
		//str = str.replace(/[^0-9a-z]/gi, '') 	// removes underscores too


		return str;
	
	},






	//#######################################################################
	//#######################################################################

	EncodeDecode :  (props) => {


        var str = props.str;
        var call = props.call;
        var type = props.type;

	
		var amp_chars = { 
						"&": "&amp;",
						"<": "&lt;",
						">": "&gt;",
						'"': '&quot;',
						"'": '&#39;',
						"/": '&#x2F;',
						"?": '&#63'
						};
		
		
		var percent_chars = {
							"&": "%26",
							"<": "%26lt;",
							">": "%26gt;",
							'"': '%26quot;',
							"'": '%26#39;',
							"/": '%26#x2F;',
							"?": '%26#63',
                            " ": '%20'
							};
		
		
		switch(call)
		{
		case'decode':
	

			switch(type)
			{
			case'percent':
	
				str = str.replace(new RegExp(percent_chars['"'],'g'), '"');
				str = str.replace(new RegExp(percent_chars["'"],'g'), "'");
				str = str.replace(new RegExp(percent_chars["?"],'g'), "?");
				str = str.replace(new RegExp(percent_chars["&"],'g'), "&");
				str = str.replace(new RegExp(percent_chars[" "],'g'), " ");
	
				str = str.replace(new RegExp("%26nbsp;",'g'), "");


			break;
			case'amp':

				str = str.replace(new RegExp(amp_chars["?"],'g'), "?");	
				str = str.replace(new RegExp(amp_chars["&"],'g'), "&");
				str = str.replace(new RegExp(amp_chars['"'],'g'), '"');
				str = str.replace(new RegExp(amp_chars["'"],'g'), "'");

				str = str.replace(new RegExp("&nbsp;",'g'), "");
	
			break;
			default:


				str = str.replace(new RegExp(percent_chars["&"],'g'), "&");
				str = str.replace(new RegExp(percent_chars['"'],'g'), '"');
				str = str.replace(new RegExp(percent_chars["'"],'g'), "'");
				str = str.replace(new RegExp(percent_chars["?"],'g'), "?");
				str = str.replace(new RegExp(percent_chars[" "],'g'), " ");
		
		
				str = str.replace(new RegExp(amp_chars["&"],'g'), "&");
				str = str.replace(new RegExp(amp_chars['"'],'g'), '"');
				str = str.replace(new RegExp(amp_chars["'"],'g'), "'");
				str = str.replace(new RegExp(amp_chars["?"],'g'), "?");
		
		
				str = str.replace(new RegExp("%26nbsp;",'g'), "");
				str = str.replace(new RegExp("&nbsp;",'g'), "");
		

			break;
			}//switch
			//=======

	
		break;
		case'encode':


			switch(type)
			{
			case'percent':
	
				str = str.replace(new RegExp('"','g'), percent_chars['"']);
				str = str.replace(new RegExp("'",'g'), percent_chars["'"]);
				str = str.replace(new RegExp("&",'g'), percent_chars["&"]);
				str = str.replace(/\?/g, percent_chars["?"]);
	
			break;
			case'amp':

				str = str.replace(new RegExp("&",'g'), amp_chars["&"]);
				str = str.replace(new RegExp('"','g'), amp_chars['"']);
				str = str.replace(new RegExp("'",'g'), amp_chars["'"]);
				str = str.replace(/\?/g, amp_chars["?"]);
	
			break;
			default:


				str = str.replace(new RegExp("&",'g'), percent_chars["&"]);
				str = str.replace(new RegExp('"','g'), percent_chars['"']);
				str = str.replace(new RegExp("'",'g'), percent_chars["'"]);
				str = str.replace(/\?/g, percent_chars["?"]);
		
		
				str = str.replace(new RegExp("&",'g'), amp_chars["&"]);
				str = str.replace(new RegExp('"','g'), amp_chars['"']);
				str = str.replace(new RegExp("'",'g'), amp_chars["'"]);
				str = str.replace(/\?/g, amp_chars["?"]);


			break;
			}//switch
			//=======


		break;
		default:
		}//switch
		//#######

	
		return str;
	
	
	},







	
	//#######################################################################
	//#######################################################################

	Base64String : function(strung,call){



		var Base64 = {
		 
		
			// private property
		
			//_keyStr : "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
		 
			_keyStr : "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
		 
	
	
		
			// public method for encoding
		
			encode : function (input) {
		
				var output = "";
		
				var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
		
				var i = 0;
		 
		
				input = Base64._utf8_encode(input);
		 
		
				while (i < input.length) {
		 
		
					chr1 = input.charCodeAt(i++);
		
					chr2 = input.charCodeAt(i++);
		
					chr3 = input.charCodeAt(i++);
		 
		
					enc1 = chr1 >> 2;
		
					enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
		
					enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
		
					enc4 = chr3 & 63;
		 
		
					if (isNaN(chr2)) {
		
						enc3 = enc4 = 64;
		
					} else if (isNaN(chr3)) {
		
						enc4 = 64;
		
					}
		 
		
					output = output +
		
					this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) +
		
					this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);
		 
		
				}
		 
		
				return output;
		
			},
		 
		
			// public method for decoding
		
			decode : function (input) {
		
				var output = "";
		
				var chr1, chr2, chr3;
		
				var enc1, enc2, enc3, enc4;
		
				var i = 0;
		 
		
				input = input.replace(/[^A-Za-z0-9+/=]/g, "");
		 
		
				while (i < input.length) {
		 
		
					enc1 = this._keyStr.indexOf(input.charAt(i++));
		
					enc2 = this._keyStr.indexOf(input.charAt(i++));
		
					enc3 = this._keyStr.indexOf(input.charAt(i++));
		
					enc4 = this._keyStr.indexOf(input.charAt(i++));
		 
		
					chr1 = (enc1 << 2) | (enc2 >> 4);
		
					chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
		
					chr3 = ((enc3 & 3) << 6) | enc4;
		 
		
					output = output + String.fromCharCode(chr1);
		 
		
					if (enc3 !== 64) {
		
						output = output + String.fromCharCode(chr2);
		
					}
		
					if (enc4 !== 64) {
		
						output = output + String.fromCharCode(chr3);
		
					}
		 
		
				}
		 
		
				output = Base64._utf8_decode(output);
	
				return output;
		 
		
			},
		 
		
			// private method for UTF-8 encoding
		
			_utf8_encode : function (string) {
		
				string = string.replace(/\r\n/g,"\n");
		
				var utftext = "";
		 
		
				for (var n = 0; n < string.length; n++) {
		 
		
					var c = string.charCodeAt(n);
		 
		
					if (c < 128) {
		
						utftext += String.fromCharCode(c);
		
					}
		
					else if((c > 127) && (c < 2048)) {
		
						utftext += String.fromCharCode((c >> 6) | 192);
		
						utftext += String.fromCharCode((c & 63) | 128);
		
					}
		
					else {
		
						utftext += String.fromCharCode((c >> 12) | 224);
		
						utftext += String.fromCharCode(((c >> 6) & 63) | 128);
		
						utftext += String.fromCharCode((c & 63) | 128);
		
					}
		 
		
				}
		 
	
				return utftext;
		
			},
		 
		
			// private method for UTF-8 decoding
		
			_utf8_decode : function (utftext) {
		
				var string = "";
		
				var i = 0;
		
				var c;
				//var c1; 
				var c2;
				var c3;
				
				c = 0;
				//c1 = 0;
				c2 = 0;
		 
		
				while ( i < utftext.length ) {
		 
		
					c = utftext.charCodeAt(i);
		 
		
					if (c < 128) {
		
						string += String.fromCharCode(c);
		
						i++;
		
					}
		
					else if((c > 191) && (c < 224)) {
		
						c2 = utftext.charCodeAt(i+1);
		
						string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
		
						i += 2;
		
					}
		
					else {
		
						c2 = utftext.charCodeAt(i+1);
		
						c3 = utftext.charCodeAt(i+2);
		
						string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
		
						i += 3;
		
					}
		 
		
				}
		 
		
				return string;
		
			}
		 
		}//end var


		var R;

			if(call === 'encode') R = Base64.encode(strung);
			if(call === 'decode') R = Base64.decode(strung);

			if(call === '_utf8_encode') R = Base64._utf8_encode(strung);
			if(call === '_utf8_decode') R = Base64._utf8_decode(strung);

		return R;

		
		
	},








}




export default LibString;




/*



        
        //-----

        targetVal = targetVal.toString();
        if(targetVal.length === parseInt(maxlength)-1)
        {
            if(!targetVal.match(/\./gi)) maxlength = maxlength-2;
            diff = targetVal.length - maxlength;
            targetVal = targetVal.substr(0,targetVal.length-diff);
        }
        else
        if(targetVal.length > maxlength 
        || targetVal.length === maxlength) 
        {
            if(!targetVal.match(/\./gi)) maxlength = maxlength-1;
            diff = targetVal.length - maxlength;
            targetVal = targetVal.substr(0,targetVal.length-diff);
        }

        //------
        


*/
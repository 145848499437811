import React, { 

    //useState, 
    //useEffect,
    //useRef

} from 'react';



//import '../../StyleSheets/dynamic-form.css';
import 'app-style/dynamic-form/style.css';


export const FFlink = (props) => {


    //var _FUNC = props.data.funct['name'] + "()";
    //var _FUNC = props.data.funct['name'] + "('"+props.data.funct['object']+"')";
    //var _RUN = new Function(_FUNC);

    // const [buttonStyle, setButtonStyle] = useState({});

    // useEffect(() => {


    //     setButtonStyle({

    //         bgColor     :"transparent",
    //         txtColor    :"#07a2e3",
    //         //txtColor    :"blue",
    //         brColor     :"transparent"

    //     });
      

    //  },[])



    // const handleSetStyle = (v) => {

    //     //console.log(v);
    //     setButtonStyle({

    //         bgColor     :v.bgColor,
    //         txtColor    :v.txtColor,
    //         brColor     :v.brColor

    //     });

    // }


    return (

        <div 
        key={props.indx+"_fflink"} 
        className = "btn-link"
        // style={{

        //     backgroundColor     :buttonStyle.bgColor,
        //     width               :"80%",
        //     margin              :"0 0 0 8%",
        //     color               :buttonStyle.txtColor,
        //     border              :"1px solid "+buttonStyle.brColor

        // }}
        onClick={() => props.handle({

            call        :props.data.action,
            data        :props.data,
            funcname    :props.data.funct.name

        })}

        // onMouseEnter={() => handleSetStyle({

        //     call        :"over",
        //     bgColor     :"transparent",
        //     txtColor    :"orangered",
        //     brColor     :"transparent"

        // })}

        // onMouseOut={() => handleSetStyle({

        //     call        :"out",
        //     bgColor     :"transparent",
        //     //txtColor    :"#07a2e3",
        //     txtColor    :"blue",
        //     brColor     :"transparent"


        // })}

        >

            {props.data.text}
            
        </div>

    )

}









const SceneInfo = [



    //TM: Collage



    //##########################################################
    //##########################################################
    //##########################################################

    // Collage (Fixed) 



    {
        "styl": {
          "call": "indent",
          "class": "IndentRowB",
          "marginTop": "0px",
          "marginBottom": "0px"
        },
        "cover": {
          "alfa": {
            "backgroundColor": "black",
            "percent": "100",
            "decimal": "1.0",
            "zIndex": "7",
            "width": "70%",
            "height": "400px",
            "left": "15%",
            "top": "calc(50% - 200px)"
          },
          "base": {
            "backgroundColor": "yellow",
            "border": "1px solid red",
            "width": "70%",
            "height": "400px",
            "zIndex": "8",
            "left": "15%",
            "top": "calc(50% - 200px)"
          }
        },
        "call": "FGrid",
        "info": {
          "call": "collage",
          "gridType": "Fixed",
          "gridBgColor": "transparent",
          "gridCall": "grid_cell",
          "cellCall": "container",
          "modCall": "fill_div",
          "cells": {
            "call": "default",
            "total": "3",
            "across": "3"
          },
          "thumb": {
            "call": "default",
            "diff": "0",
            "bgColor": "black"
          },
          "cellBorder": {
            "boolean": "0",
            "thickness": "0",
            "color": "transparent"
          },
          "gutter": {
            "boolean": "0",
            "size": "0"
          },
          "thresh": {
            "refWidth": false,
            "across1": "501",
            "across2": "801",
            "across3": "1051"
          },
          "mask": {
            "boolean": "0",
            "message": {
              "boolean": "0",
              "backgroundColor": "transparent",
              "textColor": "black"
            }
          },
          "opacity": "1.0",
          "webkitFilter": "grayscale(0)",
          "filter": "grayscale(0)"
        },
        "items": [
          {
            "id": "20210407087118",
            "title": "sp-18000-ap1-040_01",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/sp-18000-ap1-040_01_sys20210407087118id_.jpg",
              "w": "1024",
              "h": "683"
            }
          },
          {
            "id": "20210407088066",
            "title": "sp-18000-ap1-040_02",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/sp-18000-ap1-040_02_sys20210407088066id_.jpg",
              "w": "1024",
              "h": "683"
            }
          },
          {
            "id": "20210407064008",
            "title": "spoon1",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/spoon1_sys20210407064008id_.jpg",
              "w": "1600",
              "h": "900"
            }
          },
          {
            "id": "20210407065570",
            "title": "spoon2",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/spoon2_sys20210407065570id_.jpg",
              "w": "1600",
              "h": "900"
            }
          },
          {
            "id": "20210407088621",
            "title": "SPOON_S2000_01",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_01_sys20210407088621id_.png",
              "w": "924",
              "h": "610"
            }
          },
          {
            "id": "20210407090476",
            "title": "SPOON_S2000_02",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_02_sys20210407090476id_.png",
              "w": "924",
              "h": "610"
            }
          },
          {
            "id": "20210407090657",
            "title": "SPOON_S2000_03",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_03_sys20210407090657id_.png",
              "w": "924",
              "h": "610"
            }
          },
          {
            "id": "20210407092262",
            "title": "SPOON_S2000_04",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_04_sys20210407092262id_.png",
              "w": "924",
              "h": "610"
            }
          },
          {
            "id": "20210407083560",
            "title": "SPOON_S2000_05",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_05_sys20210407083560id_.png",
              "w": "924",
              "h": "610"
            }
          },
          {
            "id": "20210407086178",
            "title": "SPOON_S2000_08",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_08_sys20210407086178id_.png",
              "w": "924",
              "h": "610"
            }
          },
          {
            "id": "20210407092552",
            "title": "SPOON_S2000_09",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_09_sys20210407092552id_.png",
              "w": "924",
              "h": "610"
            }
          },
          {
            "id": "20210407089764",
            "title": "SPOON_S2000_10",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_10_sys20210407089764id_.png",
              "w": "924",
              "h": "610"
            }
          },
          {
            "id": "20210407089986",
            "title": "SPOON_S2000_12",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_12_sys20210407089986id_.png",
              "w": "924",
              "h": "610"
            }
          },
          {
            "id": "20210407087179",
            "title": "SPOON_S2000_14",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_14_sys20210407087179id_.png",
              "w": "924",
              "h": "610"
            }
          },
          {
            "id": "20210407087505",
            "title": "SPOON_S2000_17",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_17_sys20210407087505id_.png",
              "w": "924",
              "h": "610"
            }
          }
        ],
        "recid": "20211027121543",
        "reckey": "rec-blogsegment-entry-20211027121543",
        "name": "Image--Collage_sys20211027121543id_.json"
    }
  




]

export default SceneInfo;
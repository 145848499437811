import styled from "styled-components";


/*
Over 200 classes were generated for component styled.div with the id of "sc-AxirZ".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:

const Component = styled.div.attrs({
    style: ({ background }) => {
        background,
    },
})`width: 100%;`

<Component />




    // if else

    height: ${({Size}) => 
        Size === 'Small' && '25px' ||
        Size === 'Large' && '100px' || 
        '50px'
    };

    width: ${({Size}) => 
        Size === 'Small' && '25px' || 
        Size === 'Large' && '100px' || 
        '50px'
    };


    height: ${props => props.Size === 'Small' ? '20px' : '40px'}
    width: ${props => props.Size === 'Small' ? '20px' : '40px'}



    const Box = styled.div`
    height:100px;
    width:100px;
    ${props => props.Size === 'Small' && `
         height:20px;
         width:20px;
    `}

    ${props => (props.indx === 4 || props.indx === 7 || props.indx === 9) 
    && `


        background-color:red;
        float:right;

    `}


    ${props => props.isWide === true 
    && `

        width:48%;

    `}

    ${props => (props.indx === 4 || props.indx === 7 ||  props.indx === 9)
    && ` 

        background-color:red;
        float:right;

    `}

    ${props => props.isWide === false
    && `

        background-color:blue;
        float:left;

    `}




    //---------------------
    //CUT CORNER
    //---------------------

    https://stackoverflow.com/questions/7324722/cut-corners-using-css

    div {
        height: 300px;
        background: red;
        position: relative;
    }
    
    div:before {
        content: '';
        position: absolute;
        top: 0; right: 0;
        border-top: 80px solid white;
        border-left: 80px solid red;
        width: 0;
    }



    // HTML VERSION - Working

    .CutCorner{

        background-color:royalblue;
        width:80%;
        height:50px;
        position:relative;
        float:left;
        margin:0 0 0 10%;


    }

    .CutCorner:before {
        content: '';
        position: absolute;
        top: 0; 
        right: 0;
        border-top: 15px solid darkslategray;
        border-left: 15px solid royalblue;
        width: 0;
    }




    width: ${({isWideReport,indx}) => 

        (isWideReport === true && indx === 3 && "calc(45% - 10px)") || 
        (isWideReport === true && indx === 4 && "calc(55% - 10px)") || 
        (isWideReport === true && indx === 5 && "calc(45% - 10px)") || 
        (isWideReport === true && indx === 7 && "calc(35% - 10px)") || 
        (isWideReport === true && indx === 8 && "calc(65% - 10px)") ||  
        '100%'
    };


    margin: ${({isWideReport,indx}) => 

        (isWideReport === true && indx === 5 && "15px 0 0 0") ||  
        '0 0 0 0'
    };


    float: ${({isWideReport,indx}) => 

        (isWideReport === true && indx === 4 && "right") || 
        (isWideReport === true && indx === 7 && 'right') || 
        'left'
    };

    border: ${({isWideReport,indx}) => 

        (isWideReport === true && indx === 3 && "1px solid #373d4c") || 
        (isWideReport === true && indx === 4 && "1px solid #373d4c") || 
        (isWideReport === true && indx === 5 && "1px solid #373d4c") || 
        (isWideReport === true && indx === 7 && "1px solid #373d4c") || 
        (isWideReport === true && indx === 8 && "1px solid #373d4c") ||  
        '0'
    };


*/







export const FixedBar = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    width:             props.width || "calc(100% - 10px)",
    //width:               "calc(100vw - 10px",
    maxWidth:           "100%",

    height:             props.height || "53px",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    top:                props.top  || "75px",// 0px or -200ox


    //TM-Note dynamic settings on a position:fixed div causes the error
    //"Over 200 classes were generated..."
    // My fix was to bypass dynamic settings


}))`


    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    max-width:${props => props.maxWidth};

    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    font-size: calc(10px + 2vmin);
    color: white;

    position:fixed;
    top:${props => props.top};
    left:0;
    z-index:100;


    overflow:hidden;

    inline-block;

`;



export const FixedBarBg = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#39306f",
    width:              props.width || "100%",
    height:             props.height || "100%",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    opacity:            props.opacity || "0.9"

  }))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    font-size: calc(10px + 2vmin);
    color: white;

    position:relative;
    float:left;

    transition: opacity 0.9s;
    opacity:${props => props.opacity};


`;



export const FixedBarMain = styled.div.attrs(props => ({


    offOpacity:         props.offOpacity || "0.4",


    arrowSize:          props.arrowSize || 14,
    arrowOpacity:       props.arrowOpacity || "0.9",
    arrowShowColor:     props.arrowShowColor || "#fff",
    arrowHideColor:     props.arrowHideColor || "#39306f",

    widthInt:           props.widthInt || false,

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "100%",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",





    titleFontFamily:         props.titleFontFamily || "Arial, Helvetica, sans-serif",
    titleFontSize:           props.titleFontSize || "28px",
    titleFontWeight:         props.titleFontWeight || 550,
    titleTextAlign:          props.titleTextAlign  || "center",
    titleTextDecoration:     props.titleTextDecoration || "none",
    titleTextColor:          props.titleTextColor || "#edeef1",

    titleTop:                props.titleTop || "0px",
    titleLeft:               props.titleLeft || "calc(50% - 150px)",
  

  }))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    font-size: calc(10px + 2vmin);
    color: white;

    position:absolute;
    z-index:2;
    top:0px;
    left:0px;


    @media (max-width: 1000000px) {

      width: ${({widthInt}) => 

          (widthInt && widthInt > 750 && widthInt+"px") || 
          '750px'
      };

      left: ${({widthInt}) => 

          (widthInt && widthInt > 750 && "calc(50% - "+(widthInt/2)+"px)") || 
          'calc(50% - 375px)'
      };

  }
    @media (max-width: 875px) {

        width:92%;
        left:4%;

    }





    .Bod {

        background-color:transparent;
        width:100%;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:relative;
        float:left;
    }

        .Bod .Title{

            background-color:transparent;

            width:calc(100% - 300px);
            height:auto;
            padding:16px 0 4px 0;
            margin:0 0 0 0;
            position:relative;
            float:left;
        
            font-family:Arial, Helvetica, sans-serif;      
            font-size: 18px;
            font-weight:550;
            text-align: left;
            text-decoration:none;
            color:#fff;
    
        }

        .Bod .AbsoluteTitle{

            background-color:transparent;

            width:300px;
            height:auto;
            padding:10px 0 4px 0;
            margin:0 0 0 0;
            position:absolute;
            z-index:3;
            top:${props => props.titleTop};
            left:${props => props.titleLeft};
        
            font-family:${props => props.titleFontFamily};      
            font-size: ${props => props.titleFontSize};
            font-weight:${props => props.titleFontWeight};
            text-align: ${props => props.titleTextAlign};
            text-decoration:${props => props.titleTextDecoration};
            color:${props => props.titleTextColor}
    
        }


        @media (max-width: 400px) {

            width:275px;
            left:calc(50% - 137.5px);

            font-size: 24px;
    
        }
    



        .Bod .BackButton {

            background-color:transparent;
            border-radius:7px;
            width:auto;
            height:auto;
            padding:5px 12px 5px 12px;
            margin:0 2.5px 0 2.5px;

            position:relative;
            float:left;

            font-family:"Verdana", sans-serif;      
            font-size: 16px;
            font-weight: normal;
            text-align: center;
            text-decoration:none;
            color:#fff;

            cursor:pointer;



        }

        .Bod.BackButton:hover{

            background-color:white;
            opacity:0.7;

            color:black;

        }




        .Bod .EffectButton {

            background-color:transparent;
            width:auto;
            height:auto;
            padding:0 0 0 0;
            margin:9px 0 0 0;
            position:relative;
            float:right;

        }

            .Bod .EffectButton .Text {

                background-color:transparent;
                border:1px solid #fff;
                border-radius:14px;

                width:auto;
                height:auto;
                padding:7px 14px 7px 14px;
                margin:0 2.5px 0 2.5px;

                position:relative;
                float:left;

                font-family:"Verdana", sans-serif;      
                font-size: 16px;
                font-weight: normal;
                text-align: center;
                text-decoration:none;
                color:#fff;
        
                cursor:pointer;


            }


            .Bod .EffectButton .Effect {

                background-color:transparent;
                border:1px solid #fff;
                border-radius:14px;

                width:auto;
                height:auto;
                padding:7px 14px 7px 14px;
                margin:0 2.5px 0 2.5px;

                position:absolute;
                z-index:2;
                top:0px;
                left:0px;

                font-family:"Verdana", sans-serif;      
                font-size: 16px;
                font-weight: normal;
                text-align: center;
                text-decoration:none;
                color:#fff;

                cursor:pointer;
        
            }

            .Bod .EffectButton .Effect:hover {

                background-color:black;
                opacity:0.2;

                color:#fff;

                // transition: color 0.9s;
                transition: background-color 0.4s;
            
                
            }






        .Bod .DirectionalButton {

            background-color:transparent; 
    
            min-width:50px;
            width:auto;
            height:auto;
    
            margin: 0 0 0 0;
            padding:0 0 0 0;
    
            position:relative;
            float:left;
    
            flex:1;

            cursor:pointer;
    
        }
    
    
    
    
    
    
            .Bod .DirectionalButton .DirectionalArrowsLeft {
    
                background:transparent;
                width: auto;
                height: auto;
                margin-top:${props => props.arrowSize/2 + 4}px;
                margin-left:2px;
                padding-left:${props => props.arrowSize/2}px;
                position: relative;
                float:left;

    
            }
                .Bod .DirectionalButton .DirectionalArrowsLeft .Arrow {
    
                    background:${props => props.arrowShowColor};
                    width: 5px;
                    height: ${props => props.arrowSize*2}px;
                    margin-left:${props => props.arrowSize/2}px;
                    position: relative;
                    float:left;
                }
                .Bod .DirectionalButton .DirectionalArrowsLeft .Arrow:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-right: ${props => props.arrowSize}px solid ${props => props.arrowHideColor};
                    border-top: ${props => props.arrowSize}px solid transparent;
                    border-bottom: ${props => props.arrowSize}px solid transparent;

                    opacity:${props => props.arrowOpacity};

                }
                .Bod .DirectionalButton .DirectionalArrowsLeft .Arrow:before {
                    content: "";
                    position: absolute;
                    left: -${props => props.arrowSize}px;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-right: ${props => props.arrowSize}px solid ${props => props.arrowShowColor};
                    border-top: ${props => props.arrowSize}px solid transparent;
                    border-bottom: ${props => props.arrowSize}px solid transparent;

                    opacity:${props => props.arrowOpacity};

                }
                
    

                .Bod .DirectionalButton .DirectionalArrowsLeft .ArrowOff {
    
                    background:${props => props.arrowShowColor};
                    width: 5px;
                    height: ${props => props.arrowSize*2}px;
                    margin-left:${props => props.arrowSize/2}px;
                    position: relative;
                    float:left;

                    opacity:${props => props.offOpacity};

                }
                .Bod .DirectionalButton .DirectionalArrowsLeft .ArrowOff:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-right: ${props => props.arrowSize}px solid ${props => props.arrowHideColor};
                    border-top: ${props => props.arrowSize}px solid transparent;
                    border-bottom: ${props => props.arrowSize}px solid transparent;

                    opacity:${props => props.arrowOpacity};

                }
                .Bod .DirectionalButton .DirectionalArrowsLeft .ArrowOff:before {
                    content: "";
                    position: absolute;
                    left: -${props => props.arrowSize}px;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-right: ${props => props.arrowSize}px solid ${props => props.arrowShowColor};
                    border-top: ${props => props.arrowSize}px solid transparent;
                    border-bottom: ${props => props.arrowSize}px solid transparent;

                    opacity:${props => props.arrowOpacity};

                }
    
    
    
    
    
    
            .Bod .DirectionalButton .DirectionalArrowsRight {
    
                background:transparent;
                width: auto;
                height: auto;
                margin-top:${props => props.arrowSize/2 + 4}px;
                margin-left:0px;
                padding-right:${props => props.arrowSize/2}px;
                position: relative;
                float:left;
            }




                .Bod .DirectionalButton .DirectionalArrowsRight .Arrow {
    
                    background:${props => props.arrowShowColor};
                    width: 5px;
                    height: ${props => props.arrowSize*2}px;
                    margin-right:${props => props.arrowSize/2}px;
                    position: relative;
                    float:right;

                    opacity:${props => props.arrowOpacity};
    
                }
                .Bod .DirectionalButton .DirectionalArrowsRight .Arrow:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-left: ${props => props.arrowSize}px solid ${props => props.arrowHideColor};
                    border-top: ${props => props.arrowSize}px solid transparent;
                    border-bottom: ${props => props.arrowSize}px solid transparent;

                    opacity:${props => props.arrowOpacity};

                }
                .Bod .DirectionalButton .DirectionalArrowsRight .Arrow:before {
                    content: "";
                    position: absolute;
                    right: -${props => props.arrowSize}px;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-left: ${props => props.arrowSize}px solid ${props => props.arrowShowColor};
                    border-top: ${props => props.arrowSize}px solid transparent;
                    border-bottom: ${props => props.arrowSize}px solid transparent;

                    opacity:${props => props.arrowOpacity};

                }
                
    

                .Bod .DirectionalButton .DirectionalArrowsRight .ArrowOff {
    
                    background:${props => props.arrowShowColor};
                    width: 5px;
                    height: ${props => props.arrowSize*2}px;
                    margin-right:${props => props.arrowSize/2}px;
                    position: relative;
                    float:right;

                    opacity:${props => props.offOpacity};

    
                }
                .Bod .DirectionalButton .DirectionalArrowsRight .ArrowOff:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-left: ${props => props.arrowSize}px solid ${props => props.arrowHideColor};
                    border-top: ${props => props.arrowSize}px solid transparent;
                    border-bottom: ${props => props.arrowSize}px solid transparent;

                    opacity:${props => props.arrowOpacity};

                }
                .Bod .DirectionalButton .DirectionalArrowsRight .ArrowOff:before {
                    content: "";
                    position: absolute;
                    right: -${props => props.arrowSize}px;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-left: ${props => props.arrowSize}px solid ${props => props.arrowShowColor};
                    border-top: ${props => props.arrowSize}px solid transparent;
                    border-bottom: ${props => props.arrowSize}px solid transparent;

                    opacity:${props => props.arrowOpacity};

                }
                
    









    
            .Bod .DirectionalButton .SingleButton {
    
                background:transparent;
    
                border-top:2px solid #454d5f;
                border-bottom:2px solid #454d5f;
                border-right:2px solid #454d5f;
    
                width:calc(100% - ${props => props.arrowSize}px);
                height: auto;
                margin:0 0 0 0;
                padding:12px 0 10px 0;
                position: relative;
                float:left;
    
                font-family:Arial, Helvetica, sans-serif;
                font-size: 16px;
                font-weight:500;
                text-align:center;
                color:#edeef1;
        
                cursor:pointer;
    
            }
    
    
    
    
    
            .Bod .DirectionalButton .DualButton {
    
                background:transparent;
    
                border-top:2px solid #454d5f;
                border-bottom:2px solid #454d5f;
            
                width:calc(100% - ${props => props.arrowSize*5}px);
                height: auto;
                margin:0 0 0 0;
                padding:12px 0 10px 0;
                position: relative;
                float:left;
    
                font-family:Arial, Helvetica, sans-serif;
                font-size: 16px;
                font-weight:500;
                text-align:center;
                color:#edeef1;
        
                cursor:pointer;
    
            }



            .Bod .DirectionalButton .Button {

                background:transparent;
    
                width:auto;
                height: auto;
                margin:0 0 0 0;
                padding:18px 10px 16px 10px;
                position: relative;
                float:left;
    
                font-family:Arial, Helvetica, sans-serif;
                font-size: 14px;
                font-weight:550;
                text-align:center;
                color:#edeef1;
        
                cursor:pointer;
    
            }

            .Bod .DirectionalButton .ButtonOff {

                background:transparent;
    
                width:auto;
                height: auto;
                margin:0 0 0 0;
                padding:18px 10px 16px 10px;
                position: relative;
                float:left;
    
                font-family:Arial, Helvetica, sans-serif;
                font-size: 14px;
                font-weight:550;
                text-align:center;
                color:${props => props.offShowColor};
        
                cursor:default;

                opacity:${props => props.offOpacity};
    
            }
    

    
    
        
    
    




  
  


`;



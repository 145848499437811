import styled from "styled-components";


//##################################################################
//##################################################################

export const Gauge = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "0",

    width:              props.width || "400px",
    height:             props.height || "400px",
    minHeight:          props.minHeight || "200px",

    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",

    top:                props.top || "25px",
    left:               props.left || "calc(50% - 155px)",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "20px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "black"


}))`


    background-color:${props => props.backgroundColor};
    border:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};
    min-height:${props => props.minHeight};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};



`;






const SceneInfo = [



    //##########################################################
    //##########################################################
    //##########################################################

    // Navigator


    {
        "styl": {
            "call": "full",
            "class": "FullRow",
            "marginTop": "0px",
            "marginBottom": "0px"
        },
        "call": "Navigator",
        "info": {
            "call": "default",//switchRoute, default
            "gridType": "Bars",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "default",
            "modCall": "fill_div",
            "thumb": {
                "call": "default",
                "diff": "0"
            },
            "cellBorder": {
                "boolean": true,
                "thickness": 1,
                "color": "gray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            }
        },
        "items": [



            {

                "to"    :"ErrorPage",     
                "name"  :"Error Page",
                "descr" :"Error Page - used to check static only react build"
    
            },


            {

                "to"    :"Templates",     
                "name"  :"Templates",
                "descr" :"Templates - The Profit Calculator Templates"
    
            },



            {

                "to"    :"SignUp",     
                "name"  :"Sign Up Walk",
                "descr" :"Sign Up Walk - The Profit Calculator"
    
            },


            {

                "to"    :"SignUpForm",     
                "name"  :"Sign Up Form",
                "descr" :"Sign Up Form - The Profit Calculator"
    
            },


            {

                "to"    :"TrialPage",     
                "name"  :"Trial Page",
                "descr" :"Uses this section to manually build/check Scenes"
    
            },

            {

                "to"    :"TrialPageFilmStrip",     
                "name"  :"Trial Page - FilmStrip",
                "descr" :"Builds form from data from Blog Segment"
    
            },


            {

                "to"    :"TrialPageHashPage",     
                "name"  :"Trial Page - Hash Page",
                "descr" :"Uses this section to manually build/check Scenes"
    
            },



            {

                "to"    :"TrialPageDynamicForm",     
                "name"  :"Trial Page - Dynamic Form",
                "descr" :"Builds form from data from Blog Segment"
    
            },



            {

                "to"    :"TrialPageLandingPage",     
                "name"  :"Trial Page - Landing Page",
                "descr" :"Uses this section to manually build/check Scenes"
    
            },




            {

                "to"    :"TrialPageBillboard",     
                "name"  :"Trial Page Billboard",
                "descr" :"Uses this section to manually build/check Scenes"
    
            },


            
            {

                "to"    :"TrialPageCollage",     
                "name"  :"Trial Page - Collage",
                "descr" :"Builds form from data from Blog Segment"
    
            },




            {

                "to"    :"TrialPageSVG",     
                "name"  :"Trial Page SVG",
                "descr" :"Uses this section to manually build/check Scenes"
    
            },
            

            {

                "to"    :"TrialPageGridSVG",     
                "name"  :"Trial Page Grid SVG",
                "descr" :"Uses this section to manually build/check Scenes"
    
            },


            {

                "to"    :"TrialPageSlideshow",     
                "name"  :"Trial Page Slideshow",
                "descr" :"Uses this section to manually build/check Scenes"
    
            },


            {

                "to"    :"TrialPageNav",     
                "name"  :"Trial Page Nav",
                "descr" :"Uses this section to manually build/check Scenes"
    
            },



            {
                "to"    :"TrialPageSocket",    
                "name"  :"Trial Page - Socket",
                "descr" :'Socket Page - used to check socket functions'
    
            },







            //===========================================
            //Auth


            {

                "to"    :"AuthPage",     
                "name"  :"Auth Page",
                "descr" :"Auth - Authentication protected page"
    
            },


            {

                "to"    :"Signup",     
                "name"  :"Sign Up",
                "descr" :"Auth - Sign Up"
    
            },

            {

                "to"    :"Login",     
                "name"  :"Login",
                "descr" :"Auth - Login"
            },

            {

                "to"    :"Logout",     
                "name"  :"Logout",
                "descr" :"Auth - Logout"
            },

            {

                "to"    :"ForgotPassword",     
                "name"  :"Forgot Password",
                "descr" :"Auth - Forgot Password"
            },











            //===========================================

            {

                "to"    :"FetchPage",     
                "name"  :"Fetch Page",
                "descr" :"Fetch Page - used to check static only react build"
    
            },

            {

                "to"    :"StaticPage",     
                "name"  :"Static Page",
                "descr" :"Static Page - used to check static only react build"
    
            },



            {
                "to"    :"FormSocketSSG",    
                "name"  :"SSG Socket Form",
                "descr" :'Use this form to run SSG using the socket'
    
            },


            {
                "to"    :"FormSSG",    
                "name"  :"SSG Form",
                "descr" :'Use this form to run SSG'
    
            },


            {

                "to"    :"SocketPage",     
                "name"  :"Socket Page",
                "descr" :"Testing sockets"
    
            },
    


    
            {
    
                "to"    :"Template01",     
                "name"  :"Template01",
                "descr" :"Uses this section to check validity of react utils and components"
    
            },
    
            {
    
                "to"    :"Playground",     
                "name"  :"Playground",
                "descr" :"This section includes react samples and tests"
    
            },
    
    
    
    
            {
    
                "to"    :"SinglePage",     
                "name"  :"Single Page",
                "descr" :"This is a single page resource"
    
            },
    
            {
    
                "to"    :"MultiPage",     
                "name"  :"Multi Page",
                "descr" :"This is a multi page resource"
    
            },
    
    
            {
                "to"    :"FormSample",    
                "name"  :"Sample Form",
                "descr" :'This is a form resource'
    
            },
    
    
            {
    
                "to"    :"AuthArea",     
                "name"  :"Auth Area",
                "descr" :"Template for authenticating every page within this section of the application"
    
            }
    

   
        ]

    }





];


export default SceneInfo;









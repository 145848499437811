import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
// import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";


//import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'
//import useAddScript from 'utils/myHooks/AddScript';
import { useFetch }  from 'utils/hooks/Fetch';
//import { useMatomo } from '@datapunt/matomo-tracker-react';


//import Controls from "./Controls";


import DefaultFetchResponse from 'utils/components/Pages/Default/FetchResponse';


import SIerrorPage from 'utils/components/Pages/SceneInfo/ErrorPage/SceneInfo';


import ApiResources from 'utils/components/Pages/ApiResources';



const Index = (props) => {



    //-------------------------------
    // add scripts
    //-------------------------------

    // -- add scripts -- //

    /*

    useAddScript({

        to:'body',
        async:false,
        url: 'https://apis.google.com/js/client.js?onload=googleApiClientReady'

    });
    */



    //-------------------------------
    // window dims
    //-------------------------------

    // const { 
    //     width, 
    //     height 
    // } = useWindowDimensions();

    // -- screen orientation -- //
    //const screenOrientation = useScreenOrientation()



    //-------------------------------
    // router
    //-------------------------------

    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();


    //console.log("Location: "+JSON.stringify(location,null,2));

    // location: {
    //     "pathname": "/Text1",
    //     "search": "",
    //     "hash": "#!",
    //     "key": "90mmxu"
    // }




    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);



    //-------------------------------
    // redux
    //-------------------------------

    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));

    let currentZone = pathname;
    //currentZone = currentZone.replace(/\//gi,"-");
    currentZone = currentZone.replace(/ /gi,"-");
    if(currentZone === '') currentZone = 'Home';

    let zoneKey = currentZone + "_fetch";
    
    // -- Fetch -- // 
    // let fetchd = Fetch({

    //     key      : currentZone+"_fetch",
    //     route    : pathname

    // }); 




    //-------------------------------
    // api
    //-------------------------------

    var apiDomain;
    var hostname;
    //var hostname = window.location.hostname;//returns the domain name of the web host
    var protocol = window.location.protocol;//returns the web protocol used (http: or https:)


    if(window.location.hostname.match(/192.168/gi))
    {
        hostname = ApiResources.network['3001'].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }
    else
    if(window.location.hostname.match(/localhost/gi))
    {
        hostname = ApiResources.local['3001'].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }else{
        hostname = ApiResources.aws['x'].vpc;
        apiDomain = "https://" + hostname + "/";
    }//=




    //-------------------------------
    // fetch
    //-------------------------------

    // hostname = ApiResources.aws['x'].vpc;
    // apiDomain = "https://" + hostname + "/";
    //var resrc = apiDomain + "fetch-siteplan";
    //var resrc = apiDomain + "fetch-blueprint";



    var resrc;
    resrc = apiDomain + "read-site-blueprint";
    if(localStorage.editMode)
        resrc = apiDomain + "fetch-site-blueprint";


    //console.log("RESRC: "+resrc);



    // var route = pathname;
    // if(pathname === '') route = 'home';


    //fetchdOptions-start
	const [fetchdOptions] = useState({
		method:"POST",
		body:{
			site:"theprofitcalculator.com",
			tree:"blueprint-site-SiteVersion0",
			route:pathname

		},
		query:{}
	});
	//fetchdOptions-end











    const fetchd = useFetch(

        //url
        //"http://localhost:3001/fetch-blueprint",
        resrc,

        //options
        {
            method:fetchdOptions.method,
            body:fetchdOptions.body,
            query:fetchdOptions.query

        },
        {

            zoneObj:zoneObj,
            zoneKey:zoneKey

        }

    );







    //------------------------------------------
    // state/effect
    //------------------------------------------

    // -- state -- //

    const [isLoaded, setIsLoaded] = useState(false);
    const [wasFetchd, setWasFetchd] = useState(false);


    // const [settings, setSettings] = useState(DefaultFetchResponse.settings);
    // const [sceneArr, setSceneArr] = useState(SceneInfo);

    const [settings, setSettings] = useState(false);
    const [sceneArr, setSceneArr] = useState([]);
    const [zoneExists, setZoneExists] = useState(false);
    const [isError, setIsError] = useState(false);


    useEffect(() => {


        //--------------------------
        // fetchd
        //--------------------------

        var si;
        var siError;
        var sett;
        // var resp;
        var isMulti;
        
        if(!isLoaded)
        {

            //alert(zoneKey + " isn't loaded")

            //======================================================
            //scene
            //======================================================

            if(zoneObj[zoneKey])
            {
                //alert('zoneExists '+zoneObj[zoneKey]);
                setIsLoaded(true);
                setZoneExists(true);

            }else{

                //console.log('no has scene -- '+currentZone)
                if(!fetchd.loading)
                {
                    if(fetchd.response 
                    && fetchd.response !== undefined
                    )
                    {
                        setIsLoaded(true);

                        //console.log(JSON.stringify(fetchd.response,null,2)); 
                        //alert("Prime Page Fetch - fetchd.response");

                        if(fetchd.response.scene)
                        {

                            //alert('Prime Page Fetch - fetchd.response.scene');

                            if(fetchd.response.scene.sceneInfo)
                            {

                                //alert('Prime Page Fetch - fetchd.response.scene.sceneInfo');

                                si = JSON.parse(JSON.stringify(fetchd.response.scene.sceneInfo));//avoids the react-redux mutation error

                                //alert(fetchd.response.scene.sceneName + " === " + pathname);
                                //if(fetchd.response.scene.name+"/"+fetchd.response.scene.sceneName === pathname)


                                siError = false;
                                isMulti = false;
                                if(fetchd.response.scene.currentScene)
                                {

                                    //alert('Prime Page Fetch - fetchd.response.scene.currentScene');


                                    var currentScene = fetchd.response.scene.currentScene;
                                    if(currentScene.routeName+"/"+currentScene.sceneCompName === pathname)
                                    {//Mixed

                                        isMulti = true;
                                    }
                                    else
                                    if(currentScene.compName+"/"+currentScene.sceneCompName === pathname)
                                    {//No Spaces

                                        isMulti = true;
                                    }
                                    else
                                    if(currentScene.routeName+"/"+currentScene.sceneName === pathname)
                                    {//Both Spaces
                                        
                                        isMulti = true;
                    
                                    }


                                    // alert("Prime Page Fetch - fetchd.response.scene.currentScene"

                                    //    +"\ncurrentScene: "+currentScene
                                    //    +"\nisMulti: "+isMulti

                                    //    +"\n"

                                    //    +"\ncurrentScene.routeName: "+currentScene.routeName
                                    //    +"\ncurrentScene.compName: "+currentScene.compName
                                    //    +"\ncurrentScene.sceneName: "+currentScene.sceneName
                                    //    +"\ncurrentScene.sceneCompName: "+currentScene.sceneCompName

                                    //    +"\n"

                                    //    +"\nrouteName/sceneCompName: "+currentScene.routeName+"/"+currentScene.sceneCompName
                                    //    +"\ncompName/sceneCompName: "+currentScene.compName+"/"+currentScene.sceneCompName
                                    //    +"\nrouteName/sceneName: "+currentScene.routeName+"/"+currentScene.sceneName

                                    // );




                                    if(isMulti
                                    )
                                    {//msz

                                        //alert('Prime Page Fetch - Multi');

                                        if(currentScene.routeName.match(/KnowledgeBase/gi))
                                        {

                                            //console.log("si: "+JSON.stringify(si,null,2));
                                            //alert('Prime Page Fetch - isKnowledgeBase');
                                            setSceneArr(si);

    
                                        }
                                        else
                                        {

                                            //alert('msz')
                                            if(si.length > 0)
                                            { 
                                                setSceneArr(si);
                                            }else{

                                                //console.log("si: "+JSON.stringify(si,null,2));
                                                //alert("Prime Fetchd - Missing Multi SceneInfo");

                                                siError = JSON.parse(JSON.stringify(SIerrorPage));//avoids the react-redux mutation error
                                                siError[0].items[0].pgraph = "PAGE: "+currentScene.routeName + "<br/>No SceneInfo (Multi)";
                                                //setSceneArr([siError]);
                                                setIsError(true);
                                        
                                            }

                                        }



                
                                    }else{


                                        //alert('Prime Page Fetch - Single');

                
                                        if("Home" === fetchd.response.scene.sceneName 
                                        || pathname === fetchd.response.scene.sceneName
                                        || pathname === ''
                                        || pathname === 'SinglePage'
                                        || pathname === fetchd.response.scene.currentScene.routeName
                                        || pathname === fetchd.response.scene.currentScene.compName
                                        )
                                        {

                                            //alert('Prime Page Fetch - Single okay');
            
                                            if(si.length > 0)
                                            { 
                                                // console.log("si: "+JSON.stringify(si,null,2));
                                                // alert("Prime Fetchd - Check Single SceneInfo")
                                                setSceneArr(si);

                                            }else{
                                                //console.log("si: "+JSON.stringify(si,null,2));
                                                //alert("Prime Fetchd - Missing Single SceneInfo");

                                                siError = JSON.parse(JSON.stringify(SIerrorPage));//avoids the react-redux mutation error
                                                siError[0].items[0].pgraph = "PAGE: "+currentScene.routeName + "<br/>No SceneInfo (Single)";
                                                //setSceneArr([siError]);
                                                setIsError(true);
                                               
                                            }
                                        }else{

                                            //alert("Prime Fetchd - No Scene");

                                            siError = JSON.parse(JSON.stringify(SIerrorPage));//avoids the react-redux mutation error
                                            siError[0].items[0].pgraph = "PAGE: "+currentScene.routeName + "<br/>MESSAGE: No Matching Scene Available";
                                            //setSceneArr([siError]);
                                            setIsError(true);
                                    
                                        }
                        
                                    }


                                    if(siError)
                                    {    
                                        //alert(siError[0].items);
                                        fetchd.response.scene.sceneInfo = siError;
                                    }
                                    
                                    //console.log("Prime Fetchd - scene: "+JSON.stringify(fetchd.response.scene,null,2));
                                    //alert("Prime Fetchd - Check dispatch Scene");

                                    props.handle({

                                        call:'dispatch',
                                        data:{

                                            action      :'UPDATE_ZONE',
                                            key         :'fetch',
                                            value       :fetchd.response.scene

                                        },
                                        usePrefix:true // will add currentZone to the zoneObj

                                    });






                                    // dispatch(ModifyZoneStore({

                                    //     type:"UPDATE_ZONE",
                                    //     item:{
                                    //         //key:'fetch',                            
                                    //         key:currentZone+"_fetch",
                                    //         value:fetchd.response.scene
                                    //     }
                    
                                    // })); 



                                }else{
                                    setIsError(true);
                                }




                            }else{


                                //si = [];
                                //setSceneArr(si);

                                props.handle({

                                    call:'dispatch',
                                    data:{

                                        action      :'UPDATE_ZONE',
                                        key         :'fetch',
                                        value       :{}

                                    },
                                    usePrefix:true // will add currentZone to the zoneObj

                                });

                                // dispatch(ModifyZoneStore({

                                //     type:"UPDATE_ZONE",
                                //     item:{
                                //         //key:'fetch',                            
                                //         key:currentZone+"_fetch",
                                //         value:{}
                                //     }
                
                                // })); 


                            }


                        }else{


                            // alert('Fetchd - Failed SceneInfo');

                            //si = [];
                            //setSceneArr(si);

                            props.handle({

                                call:'dispatch',
                                data:{

                                    action      :'UPDATE_ZONE',
                                    key         :'fetch',
                                    value       :{}

                                },
                                usePrefix:true // will add currentZone to the zoneObj

                            });


                            // dispatch(ModifyZoneStore({

                            //     type:"UPDATE_ZONE",
                            //     item:{
                            //         //key:'fetch',                            
                            //         key:currentZone+"_fetch",
                            //         value:{}
                            //     }
            
                            // })); 


                        }


                    }

                }

            }


   

            //=========================================================
            //settings
            //=========================================================

            if(zoneObj["settings"])
            {
            }else{


                //console.log('no has settings');

                if(!fetchd.loading)
                {
                    if(fetchd.response 
                    && fetchd.response !== undefined
                    )
                    {
                        setIsLoaded(true);

                        //console.log(JSON.stringify(fetchd.response,null,2)); 
                        //alert("utils/Pages/Page - fetchd.response");

                        if(fetchd.response.settings)
                        {
              
                            sett = JSON.parse(JSON.stringify(fetchd.response.settings));//avoids the react-redux mutation error
                            setSettings(sett);
                      
                            
                            props.handle({

                                call:'dispatch',
                                data:{

                                    action      :'UPDATE_ZONE',
                                    key         :'settings',
                                    value       :fetchd.response.settings,
                                    
                                },
                                usePrefix:false // will add currentZone to the zoneObj

                            });


                            // dispatch(ModifyZoneStore({

                            //     type:"UPDATE_ZONE",
                            //     item:{
                            //         key:'settings',                            
                            //         //key:currentZone+"_settings",
                            //         value:fetchd.response.settings
                            //     }
            
                            // })); 



                        }else{



                            //alert("Fetchd - Failed Settings");


                            setSettings(false);

                            // sett = DefaultFetchResponse.settings;
                            // sett = JSON.parse(JSON.stringify(sett));//avoids the react-redux mutation error
                              
                            // props.handle({
                
                            //     call:'dispatch',
                            //     data:{
                
                            //         action      :'UPDATE_ZONE',
                            //         key         :'settings',
                            //         value       :sett
                                    
                            //     },
                            //     usePrefix:false // will add currentZone to the zoneObj
                
                            // });


                            // //si = [];
                            // //setSceneArr(si);
                            // props.handle({

                            //     call:'dispatch',
                            //     data:{

                            //         action      :'UPDATE_ZONE',
                            //         key         :'settings',
                            //         value       :{},
                         
                            //     },
                            //     usePrefix:false // will add currentZone to the zoneObj

                            // });

                            // dispatch(ModifyZoneStore({

                            //     type:"UPDATE_ZONE",
                            //     item:{
                            //         key:'settings',                            
                            //         //key:currentZone+"_settings",
                            //         value:{}
                            //     }
            
                            // })); 



                        }


                    } 

                }

            }


        }else{


            if(!wasFetchd)
            {

                setWasFetchd(true);

                if(currentZone === 'ErrorPage' || currentZone === 'X')
                {
                    props.handle({

                        call:'Fetch',
                        data:currentZone

                    });

                }else{

                    var call = "Fetch";

                    //alert(zoneExists);
                    if(!zoneExists)
                    {
                        //var msg;
                        if(sceneArr !== undefined)
                        {
                            if(sceneArr.length === 0 && !settings)
                            {
                                call = "ErrorPage";
                                //msg = "ERROR: No Connection";
                            }
                            else
                            if(sceneArr.length === 0 && settings)
                            {
                                call = "X"
                                //msg = "X: Try to display missing page";
                            }
        
                        }

                    }


                    //alert("isError: "+isError);
                    if(isError)
                    {
                        //var msg;
                        // if(sceneArr !== undefined)
                        // {
                        //     if(sceneArr.length === 0)
                        //     {
                                call = "ErrorPage";
                                //msg = "ERROR: No Connection";
                        //     }
                        // }

                    }



                    //alert("Fetchd Call: "+call);

                    switch(call)
                    {
                    case'ErrorPage':
                    case'X':


                        si = SIerrorPage;
                        si = JSON.parse(JSON.stringify(si));//avoids the react-redux mutation error

                        //si.items[0].pgraph = msg;

                        props.handle({
            
                            call:'dispatch',
                            data:{
            
                                action      :'UPDATE_ZONE',
                                key         :'fetch',
                                value       :si
                                
                            },
                            usePrefix:true // will add currentZone to the zoneObj
            
                        });



                        sett = DefaultFetchResponse.settings;
                        sett = JSON.parse(JSON.stringify(sett));//avoids the react-redux mutation error


                        console.log("sett: "+JSON.stringify(sett,null,2));

                        props.handle({
            
                            call:'dispatch',
                            data:{
            
                                action      :'UPDATE_ZONE',
                                key         :'settings',
                                value       :sett
                                
                            },
                            usePrefix:false // will add currentZone to the zoneObj
            
                        });


                        props.handle({

                            call:'Redirect',
                            data:fetchdOptions

                        });


                    break;
                    default:

                        props.handle({

                            call:'Fetch'

                        });

                    }



                }



            }
    
        }//isLoaded


    },
    [

        props,

        location,
        
        //dispatch,
        zoneObj,
        zoneKey,

        pathname,
        currentZone,        
        fetchd,
        fetchdOptions, 
        
        isLoaded,
        wasFetchd,
      
        settings,
        sceneArr,
        zoneExists,

        isError

    ])




    return(<div></div>);


}

export default Index;

export const EYE_CLOSED = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};

	let iconColor = props.iconColor || "rgb(0,0,0)";


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

			<path 
				fill={iconColor}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M46.163,250.155c4.012-19.274,25.89-58.401,65.746-90.071 L62.13,110.305l24.021-24.023l327.695,327.695L389.823,438l-63.193-63.195c-22.547,8.227-48.085,13.266-76.63,13.266 C121.214,388.07,53.536,285.576,46.163,250.155L46.163,250.155z M164.233,212.408c-5.115,11.559-7.959,24.331-7.959,37.747 c0,51.622,42.103,93.726,93.726,93.726c13.415,0,26.188-2.846,37.745-7.961l-30.365-30.365c-2.416,0.32-4.879,0.488-7.38,0.488 c-30.782,0-55.888-25.105-55.888-55.888c0-2.501,0.166-4.964,0.486-7.381L164.233,212.408L164.233,212.408z M173.405,125.492 c22.541-8.215,48.065-13.252,76.595-13.252c128.786,0,196.464,102.495,203.837,137.916c-4.012,19.27-25.877,58.385-65.713,90.055 l-52.349-52.348c5.106-11.549,7.95-24.307,7.95-37.708c0-51.622-42.104-93.726-93.726-93.726c-13.402,0-26.159,2.844-37.708,7.949 L173.405,125.492L173.405,125.492z M250,194.268c30.782,0,55.888,25.105,55.888,55.888c0,2.485-0.167,4.933-0.484,7.335 l-62.739-62.738C245.065,194.435,247.515,194.268,250,194.268L250,194.268z"
			/>


			
		</svg>
		</div>

	);//return







}

const Exceptions = {


    mobileInputMode : {

        labelfields : [

            //schedule
            {
                id:"work_weeks",
                inputMode:"numeric",
            },
            {
                id:"work_days",
                inputMode:"numeric",
            },
            {
                id:"work_hours",
                inputMode:"numeric",
            },


            //ownr
            {
                id:"ownr_hours_weekly",
                inputMode:"numeric",
            },
            {
                id:"ownr_vacation_weeks",
                inputMode:"numeric",
            },



            //empl
            {
                id:"empl_hours_weekly",
                inputMode:"numeric",
            },
            {
                id:"empl_vacation_weeks",
                inputMode:"numeric",
            },



        ], 
        fields : {},
        editfields : {}


    },



    captured : {

        labelfields : [

            //schedule
            {
                id:"work_weeks",
                restrict:"numberOnly",
            },
            {
                id:"work_days",
                restrict:"numberOnly",
            },
            {
                id:"work_hours",
                restrict:"numberOnly",
            },


            //ownr
            {
                id:"ownr_hours_weekly",
                restrict:"numberOnly",
            },
            {
                id:"ownr_vacation_weeks",
                restrict:"numberOnly"

            },


            //empl
            {
                id:"empl_hours_weekly",
                restrict:"numberOnly",
            },
            {
                id:"empl_vacation_weeks",
                restrict:"numberOnly",
            },



        ], 
        fields : {},
        editfields : {}


    }



};

export default Exceptions;
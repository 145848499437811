


const SceneInfo = [




    //TM: FilmStrip



    //##########################################################
    //##########################################################
    //##########################################################

    // FilmStrip (Fixed) 

    {
        "styl": {
          "call": "full",
          "class": "FullRow",
          "marginTop": "0px",
          "marginBottom": "0px"
        },
        "call": "FGrid",
        "info": {
          "call": "filmstrip",
          "gridType": "ImageOnly",
          "gridBgColor": "transparent",
          "gridCall": "filmstrip",
          "modCall": "fitTo",
          "thumb": {
            "call": "flex_height",
            "diff": "0"
          },
          "cellBorder": {
            "boolean": false,
            "thickness": 0,
            "color": "lightgray"
          },
          "gutter": {
            "boolean": "0",
            "size": "0"
          },
          "thresh": {
            "refWidth": false,
            "across1": "501",
            "across2": "801",
            "across3": "1051"
          },
          "mask": {
            "boolean": false,
            "message": {
              "boolean": true,
              "backgroundColor": "#4CAF50",
              "textColor": "white"
            }
          }
        },
        "items": [
          {
            "id": "20211104131237",
            "title": "Williams-Canon-FW11B-1987-1600x550",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/FW11-B/Williams-Canon-FW11B-1987-1600x550_sys20211104131237id_.jpg",
              "w": "1600",
              "h": "550"
            }
          }
        ],
        "recid": "20211103077349",
        "reckey": "rec-blogsegment-entry-20211103077349",
        "name": "Image--Collage-----FW11B-Header_sys20211103077349id_.json"
      }




]

export default SceneInfo;
import {

    LibNumber,
    LibXss

} from 'library';

const Cleaner = (props) => {


    let rtn;

    switch(props.call)
    {
    case'email':

        //alert('xss clean - email')

        rtn = LibXss.RemoveHtmlElements(props.value);
        rtn = LibXss.ReplaceSpecialChars(rtn,['email']);
    break;
    case'password':
    case'pword':

        //alert('xss clean - password');

        rtn = LibXss.RemoveHtmlElements(props.value);
        rtn = LibXss.ReplaceSpecialChars(rtn,['password']);
    break;
    case'price':
 
        rtn = LibNumber.RealMoney(props.value);
        rtn = parseFloat(rtn);
        rtn = rtn.toFixed(2);
        rtn = LibXss.RemoveHtmlElements(rtn);
        rtn = LibXss.ReplaceSpecialChars(rtn,['price']);
    break;
    case'percent':

        rtn = LibNumber.RealPercent(props.value);
        rtn = parseFloat(rtn);
        rtn = rtn.toFixed(1);
        rtn = LibXss.RemoveHtmlElements(rtn);
        rtn = LibXss.ReplaceSpecialChars(rtn,['percent']);
    break;
    case'strict':

        rtn = LibXss.RemoveHtmlElements(props.value);
        rtn = LibXss.ReplaceSpecialChars(rtn,['strict']);
    break;
    case'lenient':

        rtn = LibXss.RemoveHtmlElements(props.value);
        rtn = LibXss.ReplaceSpecialChars(rtn,['lenient']);
    break;
    default:

        rtn = LibXss.RemoveHtmlElements(props.value);
        rtn = LibXss.ReplaceSpecialChars(rtn,['lenient']);
        // rtn = LibXss.ReplaceSpecialChars(rtn,[
        //     // 'comma',
        //     // 'dash',
        //     // 'underscore',    
        //     // // //'punct', 
        //     // // //'punct_specials',
        //     // 'punct_all',
        //     //'separators',
        //     // 'commas',
        //     //'spaces',                      
 
        // ]);



    }

    return rtn;

}

export default Cleaner;



/*

<div> <script>alert`1`</script> </div>



*/
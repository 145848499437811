


const SceneInfo = [






    //##########################################################
    //##########################################################
    //##########################################################

    // WideScreen TM:
    
    {
      "styl": {
          "call": "indent",
          "class": "IndentRowCompact",
          "marginTop": "0px",
          "marginBottom": "0px",
          "paddingTop": "0px",
          "paddingBottom": "0px",
          "backgroundColor":"#141820",
          "textColor":"white"
      },
      "call": "WideScreen",
      "info": {
          "call": "filmstrip",
          "gridType": "ImageOnly",
          "gridBgColor": "transparent",
          "gridCall": "filmstrip",
          "modCall": "fitTo",
          "thumb": {
              "call": "flex_height",
              "diff": "0"
          },
          "cellBorder": {
              "boolean": true,
              "thickness": 1,
              "color": "gray"
          },
          "gutter": {
              "boolean": "0",
              "size": "0"
          },
          "thresh": {
              "refWidth": false,
              "across1": "501",
              "across2": "801",
              "across3": "1051"
          }
      },
      "items": [

          {
              "image": {
                  "w"             : "1600",
                  "h"             : "550",
                  "src"           : "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/FW11-B/Williams-Canon-FW11B-1987-1600x550_sys20211104131237id_.jpg",
                  "id"            : "20211104131237",
                  "call"          : "wide"
              }
          },

          {
              "image": {
                  "w"             : "1262",
                  "h"             : "887",
                  "src"           : "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Camel/33jjv40_sys20211026100402id_.jpg",
                  "id"            : "20211026100402",
                  "call"          : "mobi"
              }
          }

      ]
  },





  //##########################################################
  //##########################################################
  //##########################################################

  // Headline TM:
  
  {
      "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "0px",
      "marginBottom": "0px",
      "paddingTop": "75px",
      "paddingBottom": "100px",
      "backgroundColor":"darkslategray",
      "textColor":"orange"
      },
      "call": "TxtComponent",
      "info": {
          "call": "flex",
          "gridType": "HeadlineText",
          "gridBgColor": "transparent",
          "gridCall": "grid_cell",
          "cellCall": "default",
          "modCall": "fill_div",
          "thumb": {
              "call": "default",
              "diff": "0"
          },
          "cellBorder": {
              "boolean": true,
              "thickness": 1,
              "color": "gray"
          },
          "gutter": {
              "boolean": "0",
              "size": "0"
          },
          "thresh": {
              "refWidth": false,
              "across1": "501",
              "across2": "801",
              "across3": "1051"
          }
      },

      "items": [
          {  
              "title"         : "Is WideScreen.",
              "subTitle"      : "Wide to Mobi.",
              "pgraph"        : "Do your best work with a powerful macOS app. Customize your workflow with plugins and extensions. Then take things further with flexible collaboration tools.",
              "note"          : "This is note text",

              "styl":{

                  "title":{

                      className:'Large',
                      textAlign:'center',
                      textColor:'black'

                  },

                  "subTitle":{

                      className:'Large',
                      textAlign:'center',
                      textColor:'black'

                      
                  },

                  "pgraph":{

                      className:'Pgraph',
                      textAlign:'center',
                      textColor:'black'

                  },

                  "note":{

                      className:'Note',
                      textAlign:'center',
                      textColor:'black'

                  }


              }
          }
      ]

  },












    //##########################################################
    //##########################################################
    //##########################################################

    // WideScreen TM:
    
    {
      "styl": {
          "call": "full",
          "class": "FullRow",
          "marginTop": "0px",
          "marginBottom": "0px",
          "paddingTop": "0px",
          "paddingBottom": "0px",
          "backgroundColor":"transparent",
          "textColor":"black"
      },
      "call": "WideScreen",
      "info": {
          "call": "filmstrip",
          "gridType": "ImageOnly",
          "gridBgColor": "transparent",
          "gridCall": "filmstrip",
          "modCall": "fitTo",
          "thumb": {
              "call": "flex_height",
              "diff": "0"
          },
          "cellBorder": {
              "boolean": true,
              "thickness": 1,
              "color": "gray"
          },
          "gutter": {
              "boolean": "0",
              "size": "0"
          },
          "thresh": {
              "refWidth": false,
              "across1": "501",
              "across2": "801",
              "across3": "1051"
          }
      },
      "items": [

          {
              "image": {
                  "w"             : "1600",
                  "h"             : "550",
                  "src"           : "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/FW11-B/Williams-Canon-FW11B-1987-1600x550_sys20211104131237id_.jpg",
                  "id"            : "20211104131237",
                  "call"          : "wide"
              }
          },

          {
              "image": {
                  "w"             : "1262",
                  "h"             : "887",
                  "src"           : "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Camel/33jjv40_sys20211026100402id_.jpg",
                  "id"            : "20211026100402",
                  "call"          : "mobi"
              }
          }

      ]
  },





  //##########################################################
  //##########################################################
  //##########################################################

  // Headline TM:
  
  {
      "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "0px",
      "marginBottom": "0px",
      "paddingTop": "75px",
      "paddingBottom": "100px",
      "backgroundColor":"darkslategray",
      "textColor":"orange"
      },
      "call": "TxtComponent",
      "info": {
          "call": "flex",
          "gridType": "HeadlineText",
          "gridBgColor": "transparent",
          "gridCall": "grid_cell",
          "cellCall": "default",
          "modCall": "fill_div",
          "thumb": {
              "call": "default",
              "diff": "0"
          },
          "cellBorder": {
              "boolean": true,
              "thickness": 1,
              "color": "gray"
          },
          "gutter": {
              "boolean": "0",
              "size": "0"
          },
          "thresh": {
              "refWidth": false,
              "across1": "501",
              "across2": "801",
              "across3": "1051"
          }
      },

      "items": [
          {  
              "title"         : "Is WideScreen.",
              "subTitle"      : "Wide to Mobi.",
              "pgraph"        : "Do your best work with a powerful macOS app. Customize your workflow with plugins and extensions. Then take things further with flexible collaboration tools.",
              "note"          : "This is note text",

              "styl":{

                  "title":{

                      className:'Large',
                      textAlign:'center',
                      textColor:'black'

                  },

                  "subTitle":{

                      className:'Large',
                      textAlign:'center',
                      textColor:'black'

                      
                  },

                  "pgraph":{

                      className:'Pgraph',
                      textAlign:'center',
                      textColor:'black'

                  },

                  "note":{

                      className:'Note',
                      textAlign:'center',
                      textColor:'black'

                  }


              }
          }
      ]

  },





    //##########################################################
    //##########################################################
    //##########################################################

    // Band (Fixed) TM:



    {
      "styl": {
        "call": "full",
        "class": "FullRow",
        "marginTop": "0px",
        "marginBottom": "0px",
        "paddingTop": "0px",
        "paddingBottom": "0px",
        "backgroundColor":"transparent",
        "textColor":"black"
      },
      "cover": {
        "alfa": {
          "backgroundColor": "black",
          "percent": "100",
          "decimal": "1.0",
          "zIndex": "7",
          "width": "70%",
          "height": "400px",
          "left": "15%",
          "top": "calc(50% - 200px)"
        },
        "base": {
          "backgroundColor": "yellow",
          "border": "1px solid red",
          "width": "70%",
          "height": "400px",
          "zIndex": "8",
          "left": "15%",
          "top": "calc(50% - 200px)"
        }
      },
      "call": "Band",
      "info": {
        "call": "CollageGrid",
        "gridType": "Fixed",
        "gridBgColor": "transparent",
        "gridCall": "grid_cell",
        "cellCall": "container",
        "modCall": "fill_div",
        "cells": {
          "call": "band",
          "total": "4",
          "across": "4"
        },
        "thumb": {
          "call": "default",
          "diff": "0",
          "bgColor": "lightgray"
        },
        "cellBorder": {
          "boolean": "0",
          "thickness": "0",
          "color": "transparent"
        },
        "gutter": {
          "boolean": "0",
          "size": "0"
        },
        "thresh": {
          "refWidth": false,
          "across1": "901",//"701",//"501",
          "across2": "1151",//"951",//"801",
          "across3": "10000",//"1151",//"1051"
        },
        "mask": {
          "boolean": "0",
          "message": {
            "boolean": "0",
            "backgroundColor": "transparent",
            "textColor": "black"
          }
        },
        "opacity": "1.0",
        "webkitFilter": "grayscale(0)",
        "filter": "grayscale(0)"
      },
      "items": [
          {
            "id": "20210407087118",
            "title": "sp-18000-ap1-040_01",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/sp-18000-ap1-040_01_sys20210407087118id_.jpg",
              "w": "1024",
              "h": "683"
            }
          },
          {
            "id": "20210407088066",
            "title": "sp-18000-ap1-040_02",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/sp-18000-ap1-040_02_sys20210407088066id_.jpg",
              "w": "1024",
              "h": "683"
            }
          },
          {
            "id": "20210407064008",
            "title": "spoon1",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/spoon1_sys20210407064008id_.jpg",
              "w": "1600",
              "h": "900"
            }
          },
          {
            "id": "20210407065570",
            "title": "spoon2",
            "text": "",
            "image": {
              "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/spoon2_sys20210407065570id_.jpg",
              "w": "1600",
              "h": "900"
            }
          },
      ],
      "recid": "20211027121543",
      "reckey": "rec-blogsegment-entry-20211027121543",
      "name": "Image--Collage_sys20211027121543id_.json"
  },







    //##########################################################
    //##########################################################
    //##########################################################

    // NameVals TM:
    
    {
      "styl": {
        "call": "indent",
        "class": "IndentRowCenter",
        "marginTop": "0px",
        "marginBottom": "0px",
        "paddingTop": "50px",
        "paddingBottom": "75px",
        "backgroundColor":"#191e28",
        "textColor":"#00cc66"
      },
      "call": "TxtComponent",
      "info": {
        "call": "flex",
        "gridType": "AllText",
        "gridBgColor": "transparent",
        "gridCall": "grid_cell",
        "cellCall": "default",
        "modCall": "fill_div",
        "thumb": {
          "call": "default",
          "diff": "0"
        },
        "cellBorder": {
          "boolean": true,
          "thickness": 1,
          "color": "gray"
        },
        "gutter": {
          "boolean": "0",
          "size": "0"
        },
        "thresh": {
          "refWidth": false,
          "across1": "501",
          "across2": "801",
          "across3": "1051"
        },
        "compStyl": {
          "heading": {
            "fontFamily": "Arial, Helvetica, sans-serif",
            "fontSize": "32px",
            "fontWeight": "550",
            "textAlign": "center",
            "textDecoration": "none",
            "color": "#454d5f"
          },
          "pgraph": {
            "fontFamily": "Arial, Helvetica, sans-serif",
            "fontSize": "16px",
            "fontWeight": "500",
            "textAlign": "center",
            "textDecoration": "none",
            "color": "black"
          },
          "button": {
            "mainWidth": "100%",
            "position": "center",
            "backgroundColorOver": "#fc0",
            "backgroundColorOut": "#07a2e3",
            "borderRadius": "20px",
            "borderColorOver": "black",
            "borderColorOut": "white",
            "textDecoration": "none",
            "textColorOver": "black",
            "textColorOut": "white",
            "fontSize": "16px",
            "fontWeight": "550"
          },
          "link": {
            "mainWidth": "100%",
            "position": "center",
            "textDecoration": "none",
            "textColorOver": "orangered",
            "textColorOut": "#07a2e3",
            "fontSize": "16px",
            "fontWeight": "550"
          }
        }
      },
      "items": [

        {
          "call": "namevals",
          "data": [
            {
              "name": "Tuner",
              "value": "Spoon Sports"
            },
            {
              "name": "Model",
              "value": "S2000 Super N1 Taikyu Series ST-4"
            },
            {
              "name": "Engine",
              "value": "Honda F20C N1"
            },
            {
              "name": "Power",
              "value": "256hp"
            },
            {
              "name": "Rev. Limit",
              "value": "8900 rpm"
            },
            {
              "name": "Tires",
              "value": "Yokohama Advan A038"
            },
            {
              "name": "Front",
              "value": "205/55/16"
            },
            {
              "name": "Rear",
              "value": "215/55/16"
            },
            {
              "name": "Suspension ",
              "value": "Showa"
            },
            {
              "name": "Weight",
              "value": "2,293 lbs "
            }
          ]
        }
      ]
  },







    //##########################################################
    //##########################################################
    //##########################################################

    // FAQ TM:
    
    {
      "styl": {
        "call": "indent",
        "class": "IndentRowCenter",
        "marginTop": "40px",
        "marginBottom": "50px",
        "paddingTop": "0px",
        "paddingBottom": "0px",
        "backgroundColor":"transparent",
        "textColor":"black"
      },
      "call": "FAQ",
      "info": {
        "call": "flex",
        "gridType": "FAQ",
        "gridBgColor": "transparent",
        "gridCall": "grid_cell",
        "cellCall": "default",
        "modCall": "fill_div",
        "thumb": {
          "call": "default",
          "diff": "0"
        },
        "cellBorder": {
          "boolean": true,
          "thickness": 1,
          "color": "gray"
        },
        "gutter": {
          "boolean": "0",
          "size": "0"
        },
        "thresh": {
          "refWidth": false,
          "across1": "501",
          "across2": "801",
          "across3": "1051"
        },
        "compStyl":false
      },


      // "items": [

      //     {
      //       "question": "Why are we doing this?",
      //       "answer": "Because we are retarded."
      //     },

      //     {
      //       "question": "What are we doing here?",
      //       "answer": "I don't know."
      //     },

      //     {
      //       "question": "Who are we?",
      //       "answer": "You ain't nobody."
      //     },

      //     {
      //       "question": "Where are we?",
      //       "answer": "Lost in space."
      //     }
  
      // ]



      "items": [
        {
          "call": "FAQ",
          "data": {
            "box": {
              "title": "Frequently Asked Questions",
              "titleAlign":"left",
              "accentColor": "#00cc66"
            },
            "lines": [
              {
                "question": "Why are we here?",
                "answer": "We don't know but the more I live I realize I shouldn't be."
              },
              {
                "question": "Who are we?",
                "answer": "You ain't nobody"
              },
              {
                "question": "How are you doing?",
                "answer": "Pretty Good."
              },
              {
                "question": "Where are we?",
                "answer": "We are lost in space."
              },
              {
                "question": "What are we?",
                "answer": "We are a carbon based life form."
              },
              {
                "question": "When will it end?",
                "answer": "Anytime now."
              }
            ]
          }
        }
      ],




  },













  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // Collage (Fixed) 



  //   {
  //       "styl": {
  //         "call": "indent",
  //         "class": "IndentRowB",
  //         "marginTop": "0px",
  //         "marginBottom": "0px"
  //       },
  //       "cover": {
  //         "alfa": {
  //           "backgroundColor": "black",
  //           "percent": "100",
  //           "decimal": "1.0",
  //           "zIndex": "7",
  //           "width": "70%",
  //           "height": "400px",
  //           "left": "15%",
  //           "top": "calc(50% - 200px)"
  //         },
  //         "base": {
  //           "backgroundColor": "yellow",
  //           "border": "1px solid red",
  //           "width": "70%",
  //           "height": "400px",
  //           "zIndex": "8",
  //           "left": "15%",
  //           "top": "calc(50% - 200px)"
  //         }
  //       },
  //       "call": "FGrid",
  //       "info": {
  //         "call": "collage",
  //         "gridType": "Fixed",
  //         "gridBgColor": "transparent",
  //         "gridCall": "grid_cell",
  //         "cellCall": "container",
  //         "modCall": "fill_div",
  //         "cells": {
  //           "call": "default",
  //           "total": "3",
  //           "across": "3"
  //         },
  //         "thumb": {
  //           "call": "default",
  //           "diff": "0",
  //           "bgColor": "black"
  //         },
  //         "cellBorder": {
  //           "boolean": "0",
  //           "thickness": "0",
  //           "color": "transparent"
  //         },
  //         "gutter": {
  //           "boolean": "0",
  //           "size": "0"
  //         },
  //         "thresh": {
  //           "refWidth": false,
  //           "across1": "501",
  //           "across2": "801",
  //           "across3": "1051"
  //         },
  //         "mask": {
  //           "boolean": "0",
  //           "message": {
  //             "boolean": "0",
  //             "backgroundColor": "transparent",
  //             "textColor": "black"
  //           }
  //         },
  //         "opacity": "1.0",
  //         "webkitFilter": "grayscale(0)",
  //         "filter": "grayscale(0)"
  //       },
  //       "items": [
  //         {
  //           "id": "20210407087118",
  //           "title": "sp-18000-ap1-040_01",
  //           "text": "",
  //           "image": {
  //             "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/sp-18000-ap1-040_01_sys20210407087118id_.jpg",
  //             "w": "1024",
  //             "h": "683"
  //           }
  //         },
  //         {
  //           "id": "20210407088066",
  //           "title": "sp-18000-ap1-040_02",
  //           "text": "",
  //           "image": {
  //             "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/sp-18000-ap1-040_02_sys20210407088066id_.jpg",
  //             "w": "1024",
  //             "h": "683"
  //           }
  //         },
  //         {
  //           "id": "20210407064008",
  //           "title": "spoon1",
  //           "text": "",
  //           "image": {
  //             "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/spoon1_sys20210407064008id_.jpg",
  //             "w": "1600",
  //             "h": "900"
  //           }
  //         },
  //         {
  //           "id": "20210407065570",
  //           "title": "spoon2",
  //           "text": "",
  //           "image": {
  //             "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/spoon2_sys20210407065570id_.jpg",
  //             "w": "1600",
  //             "h": "900"
  //           }
  //         },
  //         {
  //           "id": "20210407088621",
  //           "title": "SPOON_S2000_01",
  //           "text": "",
  //           "image": {
  //             "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_01_sys20210407088621id_.png",
  //             "w": "924",
  //             "h": "610"
  //           }
  //         },
  //         {
  //           "id": "20210407090476",
  //           "title": "SPOON_S2000_02",
  //           "text": "",
  //           "image": {
  //             "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_02_sys20210407090476id_.png",
  //             "w": "924",
  //             "h": "610"
  //           }
  //         },
  //         {
  //           "id": "20210407090657",
  //           "title": "SPOON_S2000_03",
  //           "text": "",
  //           "image": {
  //             "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_03_sys20210407090657id_.png",
  //             "w": "924",
  //             "h": "610"
  //           }
  //         },
  //         {
  //           "id": "20210407092262",
  //           "title": "SPOON_S2000_04",
  //           "text": "",
  //           "image": {
  //             "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_04_sys20210407092262id_.png",
  //             "w": "924",
  //             "h": "610"
  //           }
  //         },
  //         {
  //           "id": "20210407083560",
  //           "title": "SPOON_S2000_05",
  //           "text": "",
  //           "image": {
  //             "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_05_sys20210407083560id_.png",
  //             "w": "924",
  //             "h": "610"
  //           }
  //         },
  //         {
  //           "id": "20210407086178",
  //           "title": "SPOON_S2000_08",
  //           "text": "",
  //           "image": {
  //             "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_08_sys20210407086178id_.png",
  //             "w": "924",
  //             "h": "610"
  //           }
  //         },
  //         {
  //           "id": "20210407092552",
  //           "title": "SPOON_S2000_09",
  //           "text": "",
  //           "image": {
  //             "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_09_sys20210407092552id_.png",
  //             "w": "924",
  //             "h": "610"
  //           }
  //         },
  //         {
  //           "id": "20210407089764",
  //           "title": "SPOON_S2000_10",
  //           "text": "",
  //           "image": {
  //             "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_10_sys20210407089764id_.png",
  //             "w": "924",
  //             "h": "610"
  //           }
  //         },
  //         {
  //           "id": "20210407089986",
  //           "title": "SPOON_S2000_12",
  //           "text": "",
  //           "image": {
  //             "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_12_sys20210407089986id_.png",
  //             "w": "924",
  //             "h": "610"
  //           }
  //         },
  //         {
  //           "id": "20210407087179",
  //           "title": "SPOON_S2000_14",
  //           "text": "",
  //           "image": {
  //             "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_14_sys20210407087179id_.png",
  //             "w": "924",
  //             "h": "610"
  //           }
  //         },
  //         {
  //           "id": "20210407087505",
  //           "title": "SPOON_S2000_17",
  //           "text": "",
  //           "image": {
  //             "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/SPOON_S2000_17_sys20210407087505id_.png",
  //             "w": "924",
  //             "h": "610"
  //           }
  //         }
  //       ],
  //       "recid": "20211027121543",
  //       "reckey": "rec-blogsegment-entry-20211027121543",
  //       "name": "Image--Collage_sys20211027121543id_.json"
  //   },
  





  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // Image Gallery (Fixed) 



  //   // {
  //   //     "styl": {
  //   //       "call": "indent",
  //   //       "class": "IndentRow",
  //   //       "marginTop": "10px",
  //   //       "marginBottom": "10px"
  //   //     },
  //   //     "call": "FGrid",
  //   //     "info": {
  //   //       "call": "fixed",
  //   //       "gridType": "ImageOnly",
  //   //       "gridBgColor": "transparent",
  //   //       "gridCall": "grid_cell",
  //   //       "cellCall": "container",
  //   //       "modCall": "fill_div",
  //   //       "cells": {
  //   //         "call": "default",
  //   //         "total": 16,
  //   //         "across": 4
  //   //       },
  //   //       "thumb": {
  //   //         "call": "default",
  //   //         "diff": "0",
  //   //         "bgColor": "black"
  //   //       },
  //   //       "cellBorder": {
  //   //         "boolean": false,
  //   //         "thickness": 0,
  //   //         "color": "lightgray"
  //   //       },
  //   //       "gutter": {
  //   //         "boolean": "0",
  //   //         "size": "0"
  //   //       },
  //   //       "thresh": {
  //   //         "refWidth": false,
  //   //         "across1": "501",
  //   //         "across2": "801",
  //   //         "across3": "1051"
  //   //       },
  //   //       "mask": {
  //   //         "boolean": true,
  //   //         "message": {
  //   //           "boolean": true,
  //   //           "backgroundColor": "#4CAF50",
  //   //           "textColor": "white"
  //   //         }
  //   //       }
  //   //     },
  //   //     "items": [
  //   //       {
  //   //         "id": "20211103077566",
  //   //         "title": "Williams-Canon-FW11-1600x620",
  //   //         "text": "",
  //   //         "image": {
  //   //           "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/FW11-B/Williams-Canon-FW11-1600x620_sys20211103077566id_.jpg",
  //   //           "w": "1600",
  //   //           "h": "620"
  //   //         }
  //   //       },
  //   //       {
  //   //         "id": "20211103076793",
  //   //         "title": "Williams-FW11B-1987-1200x900",
  //   //         "text": "",
  //   //         "image": {
  //   //           "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/FW11-B/Williams-FW11B-1987-1200x900_sys20211103076793id_.jpg",
  //   //           "w": "1200",
  //   //           "h": "900"
  //   //         }
  //   //       },
  //   //       {
  //   //         "id": "20211103074481",
  //   //         "title": "Williams-Canon-FW11-1986-1600x900",
  //   //         "text": "",
  //   //         "image": {
  //   //           "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/FW11-B/Williams-Canon-FW11-1986-1600x900_sys20211103074481id_.jpg",
  //   //           "w": "1600",
  //   //           "h": "900"
  //   //         }
  //   //       }
  //   //     ],
  //   //     "recid": "20211103073000",
  //   //     "reckey": "rec-blogsegment-entry-20211103073000",
  //   //     "name": "Image--Collage-----FW11B_sys20211103073000id_.json"
  //   // },
  






  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // Image Gallery (Fixed) 


  //   {
  //       "styl": {
  //         "call": "full",
  //         "class": "FullRow",
  //         "marginTop": "0px",
  //         "marginBottom": "0px"
  //       },
  //       "call": "FGrid",
  //       "info": {
  //         "call": "fixed",
  //         "gridType": "ImageOnly",
  //         "gridBgColor": "transparent",
  //         "gridCall": "grid_cell",
  //         "cellCall": "container",
  //         "modCall": "fill_div",
  //         "thumb": {
  //           "call": "default",
  //           "diff": "0"
  //         },
  //         "cellBorder": {
  //           "boolean": false,
  //           "thickness": 0,
  //           "color": "lightgray"
  //         },
  //         "gutter": {
  //           "boolean": "0",
  //           "size": "0"
  //         },
  //         "thresh": {
  //           "refWidth": false,
  //           "across1": "501",
  //           "across2": "801",
  //           "across3": "1051"
  //         },
  //         "mask": {
  //           "boolean": true,
  //           "message": {
  //             "boolean": true,
  //             "backgroundColor": "#4CAF50",
  //             "textColor": "white"
  //           }
  //         }
  //       },
  //       "items": [
  //         {
  //           "id": "20200406240087",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //             "h": "426",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/6433addfile_sys20200406240087id_.jpg",
  //             "w": "640"
  //           }
  //         },
  //         {
  //           "id": "20200406234970",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //             "h": "426",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/8169addfile_sys20200406234970id_.jpg",
  //             "w": "640"
  //           }
  //         },
  //         {
  //           "id": "20200407011445",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //             "h": "2160",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/ferrari-monza_sys20200407011445id_.jpg",
  //             "w": "3840"
  //           }
  //         },
  //         {
  //           "id": "20200427148217",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //             "h": "1109",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/180957-car-monza-sp1-1_sys20200427148217id_.jpg",
  //             "w": "1582"
  //           }
  //         },
  //         {
  //           "id": "20200406242021",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //             "h": "640",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/2495addfile_sys20200406242021id_.jpg",
  //             "w": "426"
  //           }
  //         },
  //         {
  //           "id": "20200427151306",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //             "h": "850",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Front_3.4_edit_93741fd4-6b93-4c41-a419-ddb66797622c_2000x850_crop_center_sys20200427151306id_.jpg",
  //             "w": "2000"
  //           }
  //         },
  //         {
  //           "id": "20200427151838",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //             "h": "2677",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Rear_3.4_611ef627-0fbe-488c-ac45-1b2d57c0fe95_4000x2677_crop_center_sys20200427151838id_.jpg",
  //             "w": "4000"
  //           }
  //         },
  //         {
  //           "id": "20200427150116",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //             "h": "2667",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Right_Side_f43ee0b8-5243-4bf6-b855-5955347d8250_4000x2677_crop_center_sys20200427150116id_.jpg",
  //             "w": "4000"
  //           }
  //         },
  //         {
  //           "id": "20200427147996",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //             "h": "720",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/maxresdefault_sys20200427147996id_.jpg",
  //             "w": "1280"
  //           }
  //         }
  //       ]
  //   },





  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // Headline 
    
  //   {
  //       "styl": {
  //       "call": "indent",
  //       "class": "IndentRowCompact",
  //       "marginTop": "25px",
  //       "marginBottom": "100px"
  //       },
  //       "call": "TxtComponent",
  //       "info": {
  //           "call": "flex",
  //           "gridType": "HeadlineText",
  //           "gridBgColor": "transparent",
  //           "gridCall": "grid_cell",
  //           "cellCall": "default",
  //           "modCall": "fill_div",
  //           "thumb": {
  //               "call": "default",
  //               "diff": "0"
  //           },
  //           "cellBorder": {
  //               "boolean": true,
  //               "thickness": 1,
  //               "color": "gray"
  //           },
  //           "gutter": {
  //               "boolean": "0",
  //               "size": "0"
  //           },
  //           "thresh": {
  //               "refWidth": false,
  //               "across1": "501",
  //               "across2": "801",
  //               "across3": "1051"
  //           }
  //       },
 
  //       "items": [
  //           {  
  //               "title"         : "Band.",
  //               "subTitle"      : "Band Of Images.",
  //               "pgraph"        : "Do your best work with a powerful macOS app. Customize your workflow with plugins and extensions. Then take things further with flexible collaboration tools.",
  //               "note"          : "This is note text",

  //               "styl":{

  //                   "title":{

  //                       className:'Large',
  //                       textAlign:'center',
  //                       textColor:'black'

  //                   },

  //                   "subTitle":{

  //                       className:'Large',
  //                       textAlign:'center',
  //                       textColor:'black'

                        
  //                   },

  //                   "pgraph":{

  //                       className:'Pgraph',
  //                       textAlign:'center',
  //                       textColor:'black'

  //                   },

  //                   "note":{

  //                       className:'Note',
  //                       textAlign:'center',
  //                       textColor:'black'

  //                   }


  //               }
  //           }
  //       ]

  //   },







  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // FilmStrip (Fixed) 

  //   {
  //       "styl": {
  //         "call": "full",
  //         "class": "FullRow",
  //         "marginTop": "0px",
  //         "marginBottom": "0px"
  //       },
  //       "call": "FGrid",
  //       "info": {
  //         "call": "filmstrip",
  //         "gridType": "ImageOnly",
  //         "gridBgColor": "transparent",
  //         "gridCall": "filmstrip",
  //         "modCall": "fitTo",
  //         "thumb": {
  //           "call": "flex_height",
  //           "diff": "0"
  //         },
  //         "cellBorder": {
  //           "boolean": false,
  //           "thickness": 0,
  //           "color": "lightgray"
  //         },
  //         "gutter": {
  //           "boolean": "0",
  //           "size": "0"
  //         },
  //         "thresh": {
  //           "refWidth": false,
  //           "across1": "501",
  //           "across2": "801",
  //           "across3": "1051"
  //         },
  //         "mask": {
  //           "boolean": false,
  //           "message": {
  //             "boolean": true,
  //             "backgroundColor": "#4CAF50",
  //             "textColor": "white"
  //           }
  //         }
  //       },
  //       "items": [
  //         {
  //           "id": "20211104131237",
  //           "title": "Williams-Canon-FW11B-1987-1600x550",
  //           "text": "",
  //           "image": {
  //             "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/FW11-B/Williams-Canon-FW11B-1987-1600x550_sys20211104131237id_.jpg",
  //             "w": "1600",
  //             "h": "550"
  //           }
  //         }
  //       ],
  //       "recid": "20211103077349",
  //       "reckey": "rec-blogsegment-entry-20211103077349",
  //       "name": "Image--Collage-----FW11B-Header_sys20211103077349id_.json"
  //     },









  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // Headline 
    
  //   {
  //       "styl": {
  //       "call": "indent",
  //       "class": "IndentRowB",
  //       "marginTop": "20px",
  //       "marginBottom": "20px"
  //       },
  //       "call": "TxtComponent",
  //       "info": {
  //           "call": "flex",
  //           "gridType": "HeadlineText",
  //           "gridBgColor": "transparent",
  //           "gridCall": "grid_cell",
  //           "cellCall": "default",
  //           "modCall": "fill_div",
  //           "thumb": {
  //               "call": "default",
  //               "diff": "0"
  //           },
  //           "cellBorder": {
  //               "boolean": true,
  //               "thickness": 1,
  //               "color": "gray"
  //           },
  //           "gutter": {
  //               "boolean": "0",
  //               "size": "0"
  //           },
  //           "thresh": {
  //               "refWidth": false,
  //               "across1": "501",
  //               "across2": "801",
  //               "across3": "1051"
  //           }
  //       },
 
  //       "items": [
  //           {  
  //               "title"         : "Above. Is FilmStrip.",
  //               "subTitle"      : "And built for business.",
  //               "pgraph"        : "Do your best work with a powerful macOS app. Customize your workflow with plugins and extensions. Then take things further with flexible collaboration tools.",
  //               "note"          : "This is note text",

  //               "styl":{

  //                   "title":{

  //                       className:'Large',
  //                       textAlign:'center',
  //                       textColor:'black'

  //                   },

  //                   "subTitle":{

  //                       className:'Large',
  //                       textAlign:'center',
  //                       textColor:'black'

                        
  //                   },

  //                   "pgraph":{

  //                       className:'Pgraph',
  //                       textAlign:'center',
  //                       textColor:'black'

  //                   },

  //                   "note":{

  //                       className:'Note',
  //                       textAlign:'center',
  //                       textColor:'black'

  //                   }


  //               }
  //           }
  //       ]

  //   },














    //##########################################################
    //##########################################################
    //##########################################################

    // ImageTextBox w/Button TM:
    
    {
        "styl": {
        "call": "full",
        "class": "FullRow",
        "marginTop": "25px",
        "marginBottom": "25px",
        "paddingTop": "50px",
        "paddingBottom": "75px",
        "backgroundColor":"#191e28",
        "textColor":"#00cc66"

        },
        "call": "TxtComponent",
        "info": {
        "call": "flex",
        "gridType": "ImageTextBox",
        "gridBgColor": "transparent",
        "gridCall": "grid_cell",
        "cellCall": "default",
        "modCall": "fill_div",
        "thumb": {
            "call": "default",
            "diff": "0"
        },
        "cellBorder": {
            "boolean": true,
            "thickness": 1,
            "color": "gray"
        },
        "gutter": {
            "boolean": "0",
            "size": "0"
        },
        "thresh": {
            "refWidth": false,
            "across1": "501",
            "across2": "801",
            "across3": "1051"
        },


        "compStyl": {
          "heading": {
            //"fontFamily": "Arial, Helvetica, sans-serif",
            "fontFamily":"Arial-Black",
            "fontSize": "32px",
            "fontWeight": "550",
            "textAlign": "left",
            "textDecoration": "none",
            "color": "#fff"
          },
          "pgraph": {
            //"fontFamily": "Arial, Helvetica, sans-serif",
            "fontFamily":"Arial-Regular",
            "fontSize": "18px",
            "fontWeight": "500",
            "textAlign": "left",
            "textDecoration": "none",
            "color": "aqua"
          },
          "button": {
            "mainWidth": "100%",
            "position": "center",
            "backgroundColorOver": "#fc0",
            "backgroundColorOut": "#07a2e3",
            "borderRadius": "20px",
            "borderColorOver": "black",
            "borderColorOut": "white",
            "textDecoration": "none",
            "textColorOver": "black",
            "textColorOut": "white",
            "fontSize": "16px",
            "fontWeight": "550"
          },
          "link": {
            "mainWidth": "100%",
            "position": "center",
            "textDecoration": "none",
            "textColorOver": "orangered",
            "textColorOut": "#07a2e3",
            "fontSize": "16px",
            "fontWeight": "550"
          }
        }



        },
        "items": [


            {
                "image": {
                "h": "608",
                "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/f1-1_sys20210407067841id_.jpg",
                "w": "1080",
                "id": "20210407067841"
                },
                "heading": "External Link",
                "pgraph": "<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
                "button": {
                    "name": "READ MORE",
                    "value": "http://www.google.com",
                    "style": {

                        "buttonOrLink": "link",

                        // "mainWidth": "100%",           
                        // "position": "left",
     
                        // "backgroundColorOver": "#fc0",
                        // "borderOver": "1px solid black",
                        // "textColorOver": "black",

                        // "backgroundColorOut": "#0291cd",
                        // "borderOut": "1px solid #0291cd",
                        // "textColorOut": "white"

                        
                        "mainWidth": "98%",           
                        "position": "center",

                        "backgroundColorOver": "transparent",
                        "borderOver": "0",
                        "textColorOver": "orange",

                        "backgroundColorOut": "transparent",
                        "borderOut": "0",
                        "textColorOut": "purple"


                      }

                }
            },



            {
                "image": {
                "h": "608",
                "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/f1-1_sys20210407067841id_.jpg",
                "w": "1080",
                "id": "20210407067841"
                },
                "heading": "External Link",
                "pgraph": "<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                "button": {
                    "name": "MORE",
                    "value": "http://www.google.com",
                    "style": {

                        "buttonOrLink": "link",

                        // "mainWidth": "100%",           
                        // "position": "left",
     
                        // "backgroundColorOver": "#fc0",
                        // "borderOver": "1px solid black",
                        // "textColorOver": "black",

                        // "backgroundColorOut": "#0291cd",
                        // "borderOut": "1px solid #0291cd",
                        // "textColorOut": "white"


                        "mainWidth": "98%",           
                        "position": "right",

                        "backgroundColorOver": "transparent",
                        "borderOver": "0",
                        "textColorOver": "orange",

                        "backgroundColorOut": "transparent",
                        "borderOut": "0",
                        "textColorOut": "black"


                      }

                }
            },




            {
                "image": {
                "h": "608",
                "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/f1-1_sys20210407067841id_.jpg",
                "w": "1080",
                "id": "20210407067841"
                },
                "heading": "Internal Link",
                "pgraph": "<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
                "button": {
                    "name": "View",
                    "value": "Billboard",
                    "style": {
                        
                        "buttonOrLink": "button",


                        "mainWidth": "100%",           
                        "position": "left",
     
                        "backgroundColorOver": "#fc0",
                        "borderOver": "1px solid black",
                        "textColorOver": "black",

                        "backgroundColorOut": "#0291cd",
                        "borderOut": "1px solid #0291cd",
                        "textColorOut": "white"



                        // "mainWidth": "90%",           
                        // "position": "right",

                        // "backgroundColorOver": "transparent",
                        // "borderOver": "0",
                        // "textColorOver": "orange",

                        // "backgroundColorOut": "transparent",
                        // "borderOut": "0",
                        // "textColorOut": "purple"



                    }


                }
            }

        ]
    },





    //##########################################################
    //##########################################################
    //##########################################################

    // AllText 


    // {
    //     "styl": {
    //         "call": "indent",
    //         "class": "IndentRow",
    //         "marginTop": "25px",
    //         "marginBottom": "50px"
    //     },
    //     "call": "TxtComponent",
    //     "info": {
    //             "call": "flex",
    //             "gridType": "AllText",
    //             "gridBgColor": "transparent",
    //             "gridCall": "grid_cell",
    //             "cellCall": "default",
    //             "modCall": "fill_div",
    //             "thumb": {
    //             "call": "default",
    //             "diff": "0"
    //         },
    //         "cellBorder": {
    //             "boolean": true,
    //             "thickness": 1,
    //             "color": "gray"
    //         },
    //         "gutter": {
    //             "boolean": "0",
    //             "size": "0"
    //         },
    //         "thresh": {
    //             "refWidth": false,
    //             "across1": "501",
    //             "across2": "801",
    //             "across3": "1051"
    //         }
    //     },
    //     "items": [
    //         {
    //             "call": "cke",
    //             "data": {
    //                 "heading": "All Text",
    //                 "pgraph": "<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    //             }
    //         },
    //         {
    //             "call": "namevals",
    //             "data": [
    //                 {
    //                 "name": "MyFakeName-4000",
    //                 "value": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque"
    //                 },
    //                 {
    //                 "name": "MyFakeName-fakeName-is-on",
    //                 "value": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque"
    //                 },
    //                 {
    //                 "name": "MyFakeName",
    //                 "value": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque"
    //                 }
    //             ]
    //         },
    //         {
    //             "call": "links",
    //             "data": [
    //                 {
    //                 "name": "Landing Page",
    //                 "value": "http://site.site/LandingPage",
    //                 "position": "left",
    //                 "mainWidth":"100%"
    //                 },
    //                 {
    //                 "name": "Billboard",
    //                 "value": "http://site.site/Billboard",
    //                 "position": "center",
    //                 "mainWidth":"100%"
    //                 },
    //                 {
    //                 "name": "PDM",
    //                 "value": "http://powerdigitalmedia.net",
    //                 "position": "right",
    //                 "mainWidth":"98%"
    //                 }
    //             ]
    //         },
    //         {
    //             "call": "buttons",
    //             "data": [
    //                 {
    //                 "name": "PDM",
    //                 "value": "http://powerdigitalmedia.net",
    //                 "position": "left",
    //                 "mainWidth":"100%"
    //                 },
    //                 {
    //                 "name": "Billboards",
    //                 "value": "http://site.site/Billboard",
    //                 "position": "center",
    //                 "mainWidth":"100%"
    //                 },
    //                 {
    //                 "name": "PDM",
    //                 "value": "http://powerdigitalmedia.net",
    //                 "position": "right",
    //                 "mainWidth":"98%"
    //                 }
    //             ]
    //         },

    //         {
    //             "call": "style",
    //             "data": {
    //               "button": {
    //                 "backgroundColorOver": "#fc0",
    //                 "backgroundColorOut": "#0291cd",
    //                 "borderOver": "1px solid black",
    //                 "borderOut": "1px solid #0291cd",
    //                 "textColorOver":"black",
    //                 "textColorOut": "white"
    //               },
    //               "link": {
    //                 "textColorOver": "orange",
    //                 "textColorOut": "#0291cd"
    //               }
    //             }
    //           }


    //     ]
    // },



    //##########################################################
    //##########################################################
    //##########################################################

    // AllText 

    {
        "styl": {
            "call": "indent",
            "class": "IndentRow",
            "marginTop": "25px",
            "marginBottom": "50px"
        },
        "call": "TxtComponent",
        "info": {
            "call": "flex",
            "gridType": "AllText",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "default",
            "modCall": "fill_div",
            "thumb": {
            "call": "default",
            "diff": "0"
            },
            "cellBorder": {
            "boolean": true,
            "thickness": 1,
            "color": "gray"
            },
            "gutter": {
            "boolean": "0",
            "size": "0"
            },
            "thresh": {
            "refWidth": false,
            "across1": "501",
            "across2": "801",
            "across3": "1051"
            }
        },
        "items": [
            {
            "call": "cke",
            "data": [
                {
                "call": "heading",
                "data": "All Text Segment"
                },
                {
                "call": "pgraph",
                "data": "<strong>Direct vs Indirect</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                },
                {
                "call": "bullet",
                "data": [
                    "Bullet Number One",
                    "Bullet Number Two",
                    "Bullet Number Three"
                ]
                },
                {
                "call": "pgraph",
                "data": " <div style=\"background-color:#00cc66; width:100%; height:30px;\"> </div> "
                },
                {
                "call": "numbrd",
                "data": [
                    "First List Item",
                    "Second List Item",
                    "Third List Item"
                ]
                }
            ]
            },
            {
            "call": "cke",
            "data": [
                {
                "call": "heading",
                "data": "All Text Segment 2"
                },
                {
                "call": "pgraph",
                "data": "<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                },
                {
                "call": "pgraph",
                "data": " <hr style=\"background-color:#00cc66; height:2px;\"> "
                },
                {
                "call": "pgraph",
                "data": "<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                }
            ]
            },
            {
            "call": "namevals",
            "data": [
                {
                "name": "Title 1",
                "value": "Something to say 1"
                },
                {
                "name": "Title 2",
                "value": "Something to say 2"
                },
                {
                "name": "Title 3",
                "value": "Something to say 3"
                }
            ]
            },
            {
            "call": "cke",
            "data": [
                {
                "call": "pgraph",
                "data": " <hr style=\"background-color:#00cc66; height:2px;\"> "
                },
                {
                "call": "pgraph",
                "data": "<span style=\"color:blue\">Blue</span>"
                },
                {
                "call": "pgraph",
                "data": " <div style=\"background-color:#00cc66; width:100%; height:30px;\"> </div> "
                }
            ]
            },


            {
                "call": "namevals",
                "data": [
                    {
                    "name": "MyFakeName-4000",
                    "value": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque"
                    },
                    {
                    "name": "MyFakeName-fakeName-is-on",
                    "value": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque"
                    },
                    {
                    "name": "MyFakeName",
                    "value": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque"
                    }
                ]
            },
            {
                "call": "links",
                "data": [
                    {
                    "name": "Landing Page",
                    "value": "http://site.site/LandingPage",
                    "position": "left",
                    "mainWidth":"100%"
                    },
                    {
                    "name": "Billboard",
                    "value": "http://site.site/Billboard",
                    "position": "center",
                    "mainWidth":"100%"
                    },
                    {
                    "name": "PDM",
                    "value": "http://powerdigitalmedia.net",
                    "position": "right",
                    "mainWidth":"98%"
                    }
                ]
            },
            {
                "call": "buttons",
                "data": [
                    {
                    "name": "PDM",
                    "value": "http://powerdigitalmedia.net",
                    "position": "left",
                    "mainWidth":"100%"
                    },
                    {
                    "name": "Billboards",
                    "value": "http://site.site/Billboard",
                    "position": "center",
                    "mainWidth":"100%"
                    },
                    {
                    "name": "PDM",
                    "value": "http://powerdigitalmedia.net",
                    "position": "right",
                    "mainWidth":"98%"
                    }
                ]
            },


        ],
        "recid": "20210903116220",
        "reckey": "rec-blogsegment-entry-20210903116220",
        "name": "AllText--Segment_sys20210903116220id_.json"
    },
    
    
    
    
    






  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // ImageTextBox w/Button 
    
  //   {
  //       "styl": {
  //       "call": "full",
  //       "class": "FullRow",
  //       "marginTop": "50px",
  //       "marginBottom": "50px"
  //       },
  //       "call": "TxtComponent",
  //       "info": {
  //       "call": "flex",
  //       "gridType": "ImageTextBox",
  //       "gridBgColor": "transparent",
  //       "gridCall": "grid_cell",
  //       "cellCall": "default",
  //       "modCall": "fill_div",
  //       "thumb": {
  //           "call": "default",
  //           "diff": "0"
  //       },
  //       "cellBorder": {
  //           "boolean": true,
  //           "thickness": 1,
  //           "color": "gray"
  //       },
  //       "gutter": {
  //           "boolean": "0",
  //           "size": "0"
  //       },
  //       "thresh": {
  //           "refWidth": false,
  //           "across1": "501",
  //           "across2": "801",
  //           "across3": "1051"
  //       }
  //       },
  //       "items": [


  //           {
  //               "image": {
  //               "h": "608",
  //               "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/f1-1_sys20210407067841id_.jpg",
  //               "w": "1080",
  //               "id": "20210407067841"
  //               },
  //               "heading": "Internal Link",
  //               "pgraph": "<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //               "button": {
  //                   "name": "CHECK IT OUT",
  //                   "value": "Billboard",
  //                   "style": {
                        
  //                       "buttonOrLink": "button",


  //                       "mainWidth": "100%",           
  //                       "position": "center",
     
  //                       "backgroundColorOver": "lightgreen",
  //                       "borderOver": "1px solid black",
  //                       "textColorOver": "black",

  //                       "backgroundColorOut": "#fc0",
  //                       "borderOut": "1px solid black",
  //                       "textColorOut": "black"



  //                       // "mainWidth": "90%",           
  //                       // "position": "right",

  //                       // "backgroundColorOver": "transparent",
  //                       // "borderOver": "0",
  //                       // "textColorOver": "orange",

  //                       // "backgroundColorOut": "transparent",
  //                       // "borderOut": "0",
  //                       // "textColorOut": "purple"



  //                   }


  //               }
  //           }

  //       ]
  //   },






  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // Headline 
    
  //   {
  //       "styl": {
  //       "call": "indent",
  //       "class": "IndentRowB",
  //       "marginTop": "20px",
  //       "marginBottom": "20px"
  //       },
  //       "call": "TxtComponent",
  //       "info": {
  //           "call": "flex",
  //           "gridType": "HeadlineText",
  //           "gridBgColor": "transparent",
  //           "gridCall": "grid_cell",
  //           "cellCall": "default",
  //           "modCall": "fill_div",
  //           "thumb": {
  //               "call": "default",
  //               "diff": "0"
  //           },
  //           "cellBorder": {
  //               "boolean": true,
  //               "thickness": 1,
  //               "color": "gray"
  //           },
  //           "gutter": {
  //               "boolean": "0",
  //               "size": "0"
  //           },
  //           "thresh": {
  //               "refWidth": false,
  //               "across1": "501",
  //               "across2": "801",
  //               "across3": "1051"
  //           }
  //       },
 
  //       "items": [
  //           {  
  //               "title"         : "Powerful. Yet Simple.",
  //               "subTitle"      : "And built for business.",
  //               "pgraph"        : "Do your best work with a powerful macOS app. Customize your workflow with plugins and extensions. Then take things further with flexible collaboration tools.",
  //               "note"          : "This is note text",

  //               "styl":{

  //                   "title":{

  //                       className:'Large',
  //                       textAlign:'center',
  //                       textColor:'black'

  //                   },

  //                   "subTitle":{

  //                       className:'Large',
  //                       textAlign:'center',
  //                       textColor:'black'

                        
  //                   },

  //                   "pgraph":{

  //                       className:'Pgraph',
  //                       textAlign:'center',
  //                       textColor:'black'

  //                   },

  //                   "note":{

  //                       className:'Note',
  //                       textAlign:'center',
  //                       textColor:'black'

  //                   }


  //               }
  //           }
  //       ]

  //   },








    


    //##########################################################
    //##########################################################
    //##########################################################

    // ImageTextColumn TM:



    {
        "styl": {
        "call": "indent",
        "class": "IndentRow",
        "marginTop": "40px",
        "marginBottom": "40px",
        "paddingTop": "50px",
        "paddingBottom": "75px",
        "backgroundColor":"darkslategray",
        "textColor":"#00cc66"


        },
        "call": "TxtComponent",
        "info": {
            "call": "flex",
            "gridType": "ImageTextColumn",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "default",
            "modCall": "fill_div",
            "thumb": {
                "call": "default",
                "diff": "0"
            },
            "cellBorder": {
                "boolean": true,
                "thickness": 1,
                "color": "gray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            },

            "imageAlign":"left",
            "imageWidth":"60%",
            "textPosition":"top",
      

            "compStyl": {
              "textPosition": "top",
              "imageAlign": "right",
              "imageWidth": "40%",
              "heading": {
                "indent": "matchBullet",
                "width": "calc(100% - 50px)",
                "padding": "0 0 0 50px",
                "margin": "0 0 15px 0",
                "fontFamily": "Arial, Helvetica, sans-serif",
                "fontSize": "32px",
                "fontWeight": "550",
                "textAlign": "left",
                "textDecoration": "none",
                "color": "#edeef1"
              },
              "pgraph": {
                "indent": "matchBullet",
                "width": "calc(100% - 50px)",
                "padding": "0 0 0 50px",
                "margin": "15px 0 25px 0",
                "fontFamily": "Arial, Helvetica, sans-serif",
                "fontSize": "20px",
                "fontWeight": "500",
                "textAlign": "left",
                "textDecoration": "none",
                "color": "#edeef1"
              },
              "bullet": {
                "indent": "35px",
                "size": "16",
                "color": "aqua",
                "border": "4px solid black",
                "spacing": "10px",
                "width": "calc(100% - 35px)",
                "padding": "0 0 0 35px",
                "margin": "0 0 0 0",
                "fontFamily": "Arial, Helvetica, sans-serif",
                "fontSize": "20px",
                "fontWeight": "500",
                "textAlign": "left",
                "textDecoration": "none",
                "textColor": "black"
              },
              "button": {
                "indent":"50px",
                "backgroundColorOver": "#fc0",
                "backgroundColorOut": "#07a2e3",
                "borderRadius": "20px",
                "borderColorOver": "black",
                "borderColorOut": "white",
                "textDecoration": "none",
                "textColorOver": "black",
                "textColorOut": "white",
                "fontSize": "16px",
                "fontWeight": "550"
              },
              "link": {
                "indent":"50px",
                "textDecoration": "none",
                "textColorOver": "orangered",
                "textColorOut": "#07a2e3",
                "fontSize": "16px",
                "fontWeight": "550"
              }
            }
          
        },
 
        "items": [


              {


                "image": {

                    "h": "360",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/8773addfile_sys20200406242958id_.jpg",
                    "w": "640"

                    // "h": "640",
                    // "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/2139addfile_sys20200406239837id_.jpg",
                    // "w": "427"
            
                },


                "text":[

                    {
                        "heading": "Default Scene Info",
                        "pgraph": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore!",

                        "buttons": [
                          {
                            "name": "VIEW EXAMPLE",
                            "value": "http://theprofitcalculator.com/app/PriceReport/?template=template-calculator-20220628170429",
                            "position": "left",
                            "mainWidth":"98%"
                          },
                          {
                            "name": "VIEW EXAMPLE",
                            "value": "http://theprofitcalculator.com/app/PriceReport/?template=template-calculator-20220628170429",
                            "position": "center",
                            "mainWidth":"98%"
                          },
                          {
                            "name": "VIEW EXAMPLE",
                            "value": "http://theprofitcalculator.com/app/PriceReport/?template=template-calculator-20220628170429",
                            "position": "right",
                            "mainWidth":"98%"
                          }
  
                        ]


                    },


                    {
                      "bullet": [
                        "Rent/Mortgage",
                        "Utilities",
                        "Vehicles",
                        "Indirect Labor"
                      ]
                    },

                    {
                      "buttons": [
                        {
                          "name": "VIEW EXAMPLE",
                          "value": "http://theprofitcalculator.com/app/PriceReport/?template=template-calculator-20220628170429"
                        },
                        {
                          "name": "VIEW EXAMPLE",
                          "value": "http://theprofitcalculator.com/app/PriceReport/?template=template-calculator-20220628170429"
                        },
                        {
                          "name": "VIEW EXAMPLE",
                          "value": "http://theprofitcalculator.com/app/PriceReport/?template=template-calculator-20220628170429"
                        }

                      ]
                    },




    
                    {
                        "heading": "Default Scene Info",
                        "pgraph": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. ",

                    },


                    {
                      "bullet": [
                        "Rent/Mortgage",
                        "Utilities",
                        "Vehicles",
                        "Indirect Labor"
                      ]
                    },


                    {
                      "buttons": [
                        {
                          "name": "VIEW EXAMPLE",
                          "value": "http://theprofitcalculator.com/app/PriceReport/?template=template-calculator-20220628170429"
                        },
                        {
                          "name": "VIEW EXAMPLE",
                          "value": "http://theprofitcalculator.com/app/PriceReport/?template=template-calculator-20220628170429"
                        },
                        {
                          "name": "VIEW EXAMPLE",
                          "value": "http://theprofitcalculator.com/app/PriceReport/?template=template-calculator-20220628170429"
                        }

                      ]
                    },







                ]

            }
        ]

    },








  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // ImageTextColumn 
    
  //   {
  //       "styl": {
  //       "call": "indent",
  //       "class": "IndentRow",
  //       "marginTop": "40px",
  //       "marginBottom": "40px"
  //       },
  //       "call": "TxtComponent",
  //       "info": {
  //           "call": "flex",
  //           "gridType": "ImageTextColumn",
  //           "gridBgColor": "transparent",
  //           "gridCall": "grid_cell",
  //           "cellCall": "default",
  //           "modCall": "fill_div",
  //           "thumb": {
  //               "call": "default",
  //               "diff": "0"
  //           },
  //           "cellBorder": {
  //               "boolean": true,
  //               "thickness": 1,
  //               "color": "gray"
  //           },
  //           "gutter": {
  //               "boolean": "0",
  //               "size": "0"
  //           },
  //           "thresh": {
  //               "refWidth": false,
  //               "across1": "501",
  //               "across2": "801",
  //               "across3": "1051"
  //           },

  //           "imageAlign":"right",
  //           "imageWidth":"50%",
  //           "textPosition":"top",
 
  //           "compStyl": {
  //               "textPosition": "top",
  //               "imageAlign": "right",
  //               "imageWidth": "40%",
  //               "heading": {
  //                   "fontFamily": "Arial, Helvetica, sans-serif",
  //                   "fontSize": "50px",
  //                   "fontWeight": "550",
  //                   "textAlign": "left",
  //                   "textDecoration": "none",
  //                   "color": "black"
  //               },
  //               "pgraph": {
  //                   "fontFamily": "Arial, Helvetica, sans-serif",
  //                   "fontSize": "16px",
  //                   "fontWeight": "500",
  //                   "textAlign": "left",
  //                   "textDecoration": "none",
  //                   "color": "black"
  //               }
  //           }



  //       },
 
  //       "items": [
  //           {  

  //               "image": {

  //                   // "h": "360",
  //                   // "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/8773addfile_sys20200406242958id_.jpg",
  //                   // "w": "640"

  //                   "h": "640",
  //                   "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/2139addfile_sys20200406239837id_.jpg",
  //                   "w": "427"
            
  //               },
  //               "text":[

  //                   {
  //                   "heading": "Default Scene Info",
  //                   "pgraph": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
  //                   },

  //                   {
  //                   "heading": "Default Scene Info",
  //                   "pgraph": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. "
  //                   },


  //                   {
  //                   "heading": "Default Scene Info",
  //                   "pgraph": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. "
  //                   }

  //               ]
                
  //           }
  //       ]

  //   },






  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // ImageTextColumn 
    
  //   {
  //       "styl": {
  //       "call": "indent",
  //       "class": "IndentRow",
  //       "marginTop": "40px",
  //       "marginBottom": "40px"
  //       },
  //       "call": "TxtComponent",
  //       "info": {
  //           "call": "flex",
  //           "gridType": "ImageTextColumn",
  //           "gridBgColor": "transparent",
  //           "gridCall": "grid_cell",
  //           "cellCall": "default",
  //           "modCall": "fill_div",
  //           "thumb": {
  //               "call": "default",
  //               "diff": "0"
  //           },
  //           "cellBorder": {
  //               "boolean": true,
  //               "thickness": 1,
  //               "color": "gray"
  //           },
  //           "gutter": {
  //               "boolean": "0",
  //               "size": "0"
  //           },
  //           "thresh": {
  //               "refWidth": false,
  //               "across1": "501",
  //               "across2": "801",
  //               "across3": "1051"
  //           },

  //           "imageAlign":"right",
  //           "imageWidth":"40%",
  //           "textPosition":"center",

 
  //           "compStyl": {
  //               "textPosition": "top",
  //               "imageAlign": "right",
  //               "imageWidth": "40%",
  //               "heading": {
  //                   "fontFamily": "Arial, Helvetica, sans-serif",
  //                   "fontSize": "50px",
  //                   "fontWeight": "550",
  //                   "textAlign": "left",
  //                   "textDecoration": "none",
  //                   "color": "black"
  //               },
  //               "pgraph": {
  //                   "fontFamily": "Arial, Helvetica, sans-serif",
  //                   "fontSize": "16px",
  //                   "fontWeight": "500",
  //                   "textAlign": "left",
  //                   "textDecoration": "none",
  //                   "color": "black"
  //               }
  //           }



  //       },
 
  //       "items": [
  //           {  

  //               "image": {

  //                   // "h": "360",
  //                   // "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/8773addfile_sys20200406242958id_.jpg",
  //                   // "w": "640"

  //                   "h": "640",
  //                   "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/2139addfile_sys20200406239837id_.jpg",
  //                   "w": "427"
            
  //               },
  //               "text":[

  //                   {
  //                   "heading": "Default Scene Info",
  //                   "pgraph": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
  //                   },

  //                   {
  //                   "heading": "Default Scene Info",
  //                   "pgraph": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. "
  //                   }

  //               ]
                
  //           }
  //       ]

  //   },






  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // ImageTextColumn 
    
  //   {
  //       "styl": {
  //       "call": "indent",
  //       "class": "IndentRow",
  //       "marginTop": "40px",
  //       "marginBottom": "40px"
  //       },
  //       "call": "TxtComponent",
  //       "info": {
  //           "call": "flex",
  //           "gridType": "ImageTextColumn",
  //           "gridBgColor": "transparent",
  //           "gridCall": "grid_cell",
  //           "cellCall": "default",
  //           "modCall": "fill_div",
  //           "thumb": {
  //               "call": "default",
  //               "diff": "0"
  //           },
  //           "cellBorder": {
  //               "boolean": true,
  //               "thickness": 1,
  //               "color": "gray"
  //           },
  //           "gutter": {
  //               "boolean": "0",
  //               "size": "0"
  //           },
  //           "thresh": {
  //               "refWidth": false,
  //               "across1": "501",
  //               "across2": "801",
  //               "across3": "1051"
  //           },

  //           "imageAlign":"left",
  //           "imageWidth":"60%",
  //           "textPosition":"top",
 
  //           "compStyl": {
  //               "textPosition": "top",
  //               "imageAlign": "right",
  //               "imageWidth": "40%",
  //               "heading": {
  //                   "fontFamily": "Arial, Helvetica, sans-serif",
  //                   "fontSize": "50px",
  //                   "fontWeight": "550",
  //                   "textAlign": "left",
  //                   "textDecoration": "none",
  //                   "color": "black"
  //               },
  //               "pgraph": {
  //                   "fontFamily": "Arial, Helvetica, sans-serif",
  //                   "fontSize": "16px",
  //                   "fontWeight": "500",
  //                   "textAlign": "left",
  //                   "textDecoration": "none",
  //                   "color": "black"
  //               }
  //           }


  //       },
 
  //       "items": [
  //           {  

  //               "image": {

  //                   // "h": "360",
  //                   // "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/8773addfile_sys20200406242958id_.jpg",
  //                   // "w": "640"

  //                   "h": "640",
  //                   "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/2139addfile_sys20200406239837id_.jpg",
  //                   "w": "427"
            
  //               },
  //               "text":[

  //                   {
  //                   "heading": "Default Scene Info",
  //                   "pgraph": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
  //                   },

  //                   {
  //                   "heading": "Default Scene Info",
  //                   "pgraph": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. "
  //                   },


  //                   {
  //                   "heading": "Default Scene Info",
  //                   "pgraph": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. "
  //                   }

  //               ]
                
  //           }
  //       ]

  //   },








    //##########################################################
    //##########################################################
    //##########################################################

    // ImageWrapText TM:


    {
        "styl": {
            "call": "indent",
            "class": "IndentRow",
            "marginTop": "25px",
            "marginBottom": "25px",
            "paddingTop": "50px",
            "paddingBottom": "75px",
            "backgroundColor":"#191e28",
            "textColor":"#00cc66"

        },
        "call": "TxtComponent",
        "info": {
            "call": "flex",
            "gridType": "ImageWrapText",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "default",
            "modCall": "fill_div",
            "thumb": {
                "call": "default",
                "diff": "0"
            },
            "cellBorder": {
                "boolean": true,
                "thickness": 1,
                "color": "gray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            },

            "compStyl": {
              "heading": {
                //"fontFamily": "Arial, Helvetica, sans-serif",
                "fontFamily": "Arial-Black",
                "fontSize": "32px",
                "fontWeight": "550",
                "textAlign": "left",
                "textDecoration": "none",
                "color": "#00cc66"
              },
              "pgraph": {
                "fontFamily": "Arial-Narrow",
                "fontSize": "16px",
                "fontWeight": "500",
                "textAlign": "left",
                "textDecoration": "none",
                "color": "aqua"
              },
              "button": {
                "mainWidth": "100%",
                "position": "center",
                "backgroundColorOver": "#fc0",
                "backgroundColorOut": "#07a2e3",
                "borderRadius": "20px",
                "borderColorOver": "black",
                "borderColorOut": "white",
                "textDecoration": "none",
                "textColorOver": "black",
                "textColorOut": "white",
                "fontSize": "16px",
                "fontWeight": "550"
              },
              "link": {
                "mainWidth": "100%",
                "position": "center",
                "textDecoration": "none",
                "textColorOver": "orangered",
                "textColorOut": "#07a2e3",
                "fontSize": "16px",
                "fontWeight": "550"
              }
            }




        },
        "items": [
            {  
            "image": {

                "align":"left",
                "maxWidth":"400",
                "maxHeight":"400",
                "relief": "20",
         
                "h": "360",
                "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/8773addfile_sys20200406242958id_.jpg",
                "w": "640"
     
            }, 
            "heading": "Default Scene Info",
            "pgraph": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
            }
        ]

    },




  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // ImageWrapText 


  //   {
  //     "styl": {
  //         "call": "indent",
  //         "class": "IndentRow",
  //         "marginTop": "25px",
  //         "marginBottom": "25px"
  //     },
  //     "call": "TxtComponent",
  //     "info": {
  //         "call": "flex",
  //         "gridType": "ImageWrapText",
  //         "gridBgColor": "transparent",
  //         "gridCall": "grid_cell",
  //         "cellCall": "default",
  //         "modCall": "fill_div",
  //         "thumb": {
  //             "call": "default",
  //             "diff": "0"
  //         },
  //         "cellBorder": {
  //             "boolean": true,
  //             "thickness": 1,
  //             "color": "gray"
  //         },
  //         "gutter": {
  //             "boolean": "0",
  //             "size": "0"
  //         },
  //         "thresh": {
  //             "refWidth": false,
  //             "across1": "501",
  //             "across2": "801",
  //             "across3": "1051"
  //         }
  //     },
  //     "items": [
  //         {  
  //         "image": {

  //             "align":"right",
  //             "maxWidth":"400",
  //             "maxHeight":"400",
  //             "relief": "20",
       
  //             "h": "360",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/8773addfile_sys20200406242958id_.jpg",
  //             "w": "640"
   
  //         }, 
  //         "heading": "Default Scene Info",
  //         "pgraph": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
  //         }
  //     ]

  // },






  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // ImageWrapText 


  //   {
  //       "styl": {
  //           "call": "indent",
  //           "class": "IndentRow",
  //           "marginTop": "25px",
  //           "marginBottom": "25px"
  //       },
  //       "call": "TxtComponent",
  //       "info": {
  //           "call": "flex",
  //           "gridType": "ImageWrapText",
  //           "gridBgColor": "transparent",
  //           "gridCall": "grid_cell",
  //           "cellCall": "default",
  //           "modCall": "fill_div",
  //           "thumb": {
  //               "call": "default",
  //               "diff": "0"
  //           },
  //           "cellBorder": {
  //               "boolean": true,
  //               "thickness": 1,
  //               "color": "gray"
  //           },
  //           "gutter": {
  //               "boolean": "0",
  //               "size": "0"
  //           },
  //           "thresh": {
  //               "refWidth": false,
  //               "across1": "501",
  //               "across2": "801",
  //               "across3": "1051"
  //           }
  //       },
  //       "items": [
  //           {  
  //           "image": {

  //               "align":"right",
  //               "maxWidth":"full",
  //               "maxHeight":"full",
  //               "relief": "25",
         

  //               // "h": "360",
  //               // "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/8773addfile_sys20200406242958id_.jpg",
  //               // "w": "640"

  //               "h": "640",
  //               "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/2139addfile_sys20200406239837id_.jpg",
  //               "w": "427"
     
  //           }, 
  //           "heading": "Default Scene Info",
  //           "pgraph": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
  //           }
  //       ]

  //   },






  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // ImageWrapText 


  //   {
  //     "styl": {
  //         "call": "indent",
  //         "class": "IndentRow",
  //         "marginTop": "25px",
  //         "marginBottom": "25px"
  //     },
  //     "call": "TxtComponent",
  //     "info": {
  //         "call": "flex",
  //         "gridType": "ImageWrapText",
  //         "gridBgColor": "transparent",
  //         "gridCall": "grid_cell",
  //         "cellCall": "default",
  //         "modCall": "fill_div",
  //         "thumb": {
  //             "call": "default",
  //             "diff": "0"
  //         },
  //         "cellBorder": {
  //             "boolean": true,
  //             "thickness": 1,
  //             "color": "gray"
  //         },
  //         "gutter": {
  //             "boolean": "0",
  //             "size": "0"
  //         },
  //         "thresh": {
  //             "refWidth": false,
  //             "across1": "501",
  //             "across2": "801",
  //             "across3": "1051"
  //         }
  //     },
  //     "items": [
  //         { 
            
  //           "heading": "Default Scene Info",
  //           "pgraph": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",  

  //           "image": {

  //               "align":"left",
  //               "maxWidth":"full",
  //               "maxHeight":"full",
  //               "relief": "25",
        

  //               // "h": "360",
  //               // "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/8773addfile_sys20200406242958id_.jpg",
  //               // "w": "640"

  //               "h": "640",
  //               "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/2139addfile_sys20200406239837id_.jpg",
  //               "w": "427"
    
  //           }

  //         }
  //     ]

  // },










  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // Video Gallery 


  //   {
  //       "styl": {
  //           "call": "indent",
  //           "class": "IndentRow",
  //           "marginTop": "25px",
  //           "marginBottom": "25px"
  //       },
  //       "call": "VidGrid",
  //       "info": {
  //           "call": "vid",
  //           "gridType": "ImageOnly",
  //           "gridBgColor": "transparent",
  //           "gridCall": "grid_cell",
  //           "cellCall": "container",
  //           "modCall": "fill_div",
  //           "thumb": {
  //           "call": "default",
  //           "diff": "10"
  //           },
  //           "cellBorder": {
  //           "boolean": false,
  //           "thickness": 1,
  //           "color": "gray"
  //           },
  //           "gutter": {
  //           "boolean": "true",
  //           "size": "4"
  //           },
  //           "thresh": {
  //           "refWidth": false,
  //           "across1": "501",
  //           "across2": "801",
  //           "across3": "1051"
  //           },
  //           "mask": {
  //           "boolean": true,
  //           "message": {
  //               "boolean": true,
  //               "backgroundColor": "#4CAF50",
  //               "textColor": "white"
  //           }
  //           }
  //       },
  //       "items": [
  //           {
  //           "image": {
  //               "h": "90",
  //               "src": "https://img.youtube.com/vi/bcGebz7QGHU/0.jpg",
  //               "w": "120"
  //           },
  //           "video": {
  //               "site": "youtube",
  //               "id": "bcGebz7QGHU",
  //               "type": "external",
  //               "src": ""
  //           }
  //           },
  //           {
  //           "image": {
  //               "h": "90",
  //               "src": "https://img.youtube.com/vi/bcGebz7QGHU/0.jpg",
  //               "w": "120"
  //           },
  //           "video": {
  //               "site": "youtube",
  //               "id": "bcGebz7QGHU",
  //               "type": "external",
  //               "src": ""
  //           }
  //           },
  //           {
  //           "image": {
  //               "h": "90",
  //               "src": "https://img.youtube.com/vi/bcGebz7QGHU/0.jpg",
  //               "w": "120"
  //           },
  //           "video": {
  //               "site": "youtube",
  //               "id": "bcGebz7QGHU",
  //               "type": "external",
  //               "src": ""
  //           }
  //           }
  //       ]
  //   },






  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // Image Gallery (Fixed) 


  //   {
  //       "styl": {
  //         "call": "full",
  //         "class": "FullRow",
  //         "marginTop": "0px",
  //         "marginBottom": "50px"
  //       },
  //       "call": "FGrid",
  //       "info": {
  //         "call": "fixed",
  //         "gridType": "ImageOnly",
  //         "gridBgColor": "transparent",
  //         "gridCall": "grid_cell",
  //         "cellCall": "container",
  //         "modCall": "fill_div",
  //         "thumb": {
  //           "call": "default",
  //           "diff": "0"
  //         },
  //         "cellBorder": {
  //           "boolean": false,
  //           "thickness": 0,
  //           "color": "lightgray"
  //         },
  //         "gutter": {
  //           "boolean": "0",
  //           "size": "0"
  //         },
  //         "thresh": {
  //           "refWidth": false,
  //           "across1": "501",
  //           "across2": "801",
  //           "across3": "1051"
  //         },
  //         "mask": {
  //           "boolean": true,
  //           "message": {
  //             "boolean": true,
  //             "backgroundColor": "#4CAF50",
  //             "textColor": "white"
  //           }
  //         }
  //       },
  //       "items": [
  //         {
  //           "id": "20200406240087",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //             "h": "426",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/6433addfile_sys20200406240087id_.jpg",
  //             "w": "640"
  //           }
  //         },
  //         {
  //           "id": "20200406234970",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //             "h": "426",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/8169addfile_sys20200406234970id_.jpg",
  //             "w": "640"
  //           }
  //         },
  //         {
  //           "id": "20200407011445",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //             "h": "2160",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/ferrari-monza_sys20200407011445id_.jpg",
  //             "w": "3840"
  //           }
  //         },
  //         {
  //           "id": "20200427148217",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //             "h": "1109",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/180957-car-monza-sp1-1_sys20200427148217id_.jpg",
  //             "w": "1582"
  //           }
  //         },
  //         {
  //           "id": "20200406242021",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //             "h": "640",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/2495addfile_sys20200406242021id_.jpg",
  //             "w": "426"
  //           }
  //         },
  //         {
  //           "id": "20200427151306",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //             "h": "850",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Front_3.4_edit_93741fd4-6b93-4c41-a419-ddb66797622c_2000x850_crop_center_sys20200427151306id_.jpg",
  //             "w": "2000"
  //           }
  //         },
  //         {
  //           "id": "20200427151838",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //             "h": "2677",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Rear_3.4_611ef627-0fbe-488c-ac45-1b2d57c0fe95_4000x2677_crop_center_sys20200427151838id_.jpg",
  //             "w": "4000"
  //           }
  //         },
  //         {
  //           "id": "20200427150116",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //             "h": "2667",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Right_Side_f43ee0b8-5243-4bf6-b855-5955347d8250_4000x2677_crop_center_sys20200427150116id_.jpg",
  //             "w": "4000"
  //           }
  //         },
  //         {
  //           "id": "20200427147996",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //             "h": "720",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/maxresdefault_sys20200427147996id_.jpg",
  //             "w": "1280"
  //           }
  //         }
  //       ]
  //   },






  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // Image Gallery (Flex) 


  //   {
  //       "styl": {
  //       "call": "full",
  //       "class": "FullRow",
  //       "marginTop": "0px",
  //       "marginBottom": "50px"
  //       },
  //       "call": "FGrid",
  //       "info": {
  //       "call": "flex",
  //       "gridType": "ImageOnly",
  //       "gridBgColor": "transparent",
  //       "gridCall": "grid_column_cell",
  //       "cellCall": "container",
  //       "modCall": "fill_div",
  //       "thumb": {
  //           "call": "flex_height",
  //           "diff": "0"
  //       },
  //       "cellBorder": {
  //           "boolean": false,
  //           "thickness": 0,
  //           "color": "lightgray"
  //       },
  //       "gutter": {
  //           "boolean": "0",
  //           "size": "0"
  //       },
  //       "thresh": {
  //           "refWidth": false,
  //           "across1": "501",
  //           "across2": "801",
  //           "across3": "1051"
  //       },
  //       "mask": {
  //           "boolean": true,
  //           "message": {
  //           "boolean": true,
  //           "backgroundColor": "#4CAF50",
  //           "textColor": "white"
  //           }
  //       }
  //       },
  //       "items": [
  //       {
  //           "id": "20200406240087",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //           "h": "426",
  //           "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/6433addfile_sys20200406240087id_.jpg",
  //           "w": "640"
  //           }
  //       },
  //       {
  //           "id": "20200406234970",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //           "h": "426",
  //           "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/8169addfile_sys20200406234970id_.jpg",
  //           "w": "640"
  //           }
  //       },
  //       {
  //           "id": "20200407011445",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //           "h": "2160",
  //           "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/ferrari-monza_sys20200407011445id_.jpg",
  //           "w": "3840"
  //           }
  //       },
  //       {
  //           "id": "20200427148217",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //           "h": "1109",
  //           "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/180957-car-monza-sp1-1_sys20200427148217id_.jpg",
  //           "w": "1582"
  //           }
  //       },
  //       {
  //           "id": "20200406242021",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //           "h": "640",
  //           "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/2495addfile_sys20200406242021id_.jpg",
  //           "w": "426"
  //           }
  //       },
  //       {
  //           "id": "20200427151306",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //           "h": "850",
  //           "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Front_3.4_edit_93741fd4-6b93-4c41-a419-ddb66797622c_2000x850_crop_center_sys20200427151306id_.jpg",
  //           "w": "2000"
  //           }
  //       },
  //       {
  //           "id": "20200427151838",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //           "h": "2677",
  //           "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Rear_3.4_611ef627-0fbe-488c-ac45-1b2d57c0fe95_4000x2677_crop_center_sys20200427151838id_.jpg",
  //           "w": "4000"
  //           }
  //       },
  //       {
  //           "id": "20200427150116",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //           "h": "2667",
  //           "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Right_Side_f43ee0b8-5243-4bf6-b855-5955347d8250_4000x2677_crop_center_sys20200427150116id_.jpg",
  //           "w": "4000"
  //           }
  //       },
  //       {
  //           "id": "20200427147996",
  //           "title": "TITLE",
  //           "text": "Text",
  //           "image": {
  //           "h": "720",
  //           "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/maxresdefault_sys20200427147996id_.jpg",
  //           "w": "1280"
  //           }
  //       }
  //       ]
  //   },








  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // ImageText Gallery (Fixed) 


  //   {
  //       "styl": {
  //         "call": "full",
  //         "class": "FullRow",
  //         "marginTop": "0px",
  //         "marginBottom": "50px"
  //       },
  //       "call": "FGrid",
  //       "info": {
  //         "call": "fixed",
  //         "gridType": "ImageText",
  //         "gridBgColor": "transparent",
  //         "gridCall": "grid_cell",
  //         "cellCall": "container",
  //         "modCall": "fill_div",
  //         "thumb": {
  //           "call": "default",
  //           "diff": "10"
  //         },
  //         "cellBorder": {
  //           "boolean": false,
  //           "thickness": 0,
  //           "color": "lightgray"
  //         },
  //         "gutter": {
  //           "boolean": "1",
  //           "size": "20"
  //         },
  //         "thresh": {
  //           "refWidth": false,
  //           "across1": "501",
  //           "across2": "801",
  //           "across3": "1051"
  //         },
  //         "mask": {
  //           "boolean": true,
  //           "message": {
  //             "boolean": true,
  //             "backgroundColor": "#4CAF50",
  //             "textColor": "white"
  //           }
  //         }
  //       },
  //       "items": [
  //         {
  //           "id": "20200406240087",
  //           "title": "Generic Title",
  //           "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",
  //           "image": {
  //             "h": "426",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/6433addfile_sys20200406240087id_.jpg",
  //             "w": "640"
  //           }
  //         },
  //         {
  //           "id": "20200406234970",
  //           "title": "Generic Title",
  //           "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",
  //           "image": {
  //             "h": "426",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/8169addfile_sys20200406234970id_.jpg",
  //             "w": "640"
  //           }
  //         },
  //         {
  //           "id": "20200407011445",
  //           "title": "Generic Title",
  //           "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",
  //           "image": {
  //             "h": "2160",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/ferrari-monza_sys20200407011445id_.jpg",
  //             "w": "3840"
  //           }
  //         },
  //         {
  //           "id": "20200427148217",
  //           "title": "Generic Title",
  //           "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",
  //           "image": {
  //             "h": "1109",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/180957-car-monza-sp1-1_sys20200427148217id_.jpg",
  //             "w": "1582"
  //           }
  //         },
  //         {
  //           "id": "20200406242021",
  //           "title": "Generic Title",
  //           "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",
  //           "image": {
  //             "h": "640",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/2495addfile_sys20200406242021id_.jpg",
  //             "w": "426"
  //           }
  //         },
  //         {
  //           "id": "20200427151306",
  //           "title": "Generic Title",
  //           "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",
  //           "image": {
  //             "h": "850",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Front_3.4_edit_93741fd4-6b93-4c41-a419-ddb66797622c_2000x850_crop_center_sys20200427151306id_.jpg",
  //             "w": "2000"
  //           }
  //         },
  //         {
  //           "id": "20200427151838",
  //           "title": "Generic Title",
  //           "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",
  //           "image": {
  //             "h": "2677",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Rear_3.4_611ef627-0fbe-488c-ac45-1b2d57c0fe95_4000x2677_crop_center_sys20200427151838id_.jpg",
  //             "w": "4000"
  //           }
  //         },
  //         {
  //           "id": "20200427150116",
  //           "title": "Generic Title",
  //           "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",
  //           "image": {
  //             "h": "2667",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Right_Side_f43ee0b8-5243-4bf6-b855-5955347d8250_4000x2677_crop_center_sys20200427150116id_.jpg",
  //             "w": "4000"
  //           }
  //         },
  //         {
  //           "id": "20200427147996",
  //           "title": "Generic Title",
  //           "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",
  //           "image": {
  //             "h": "720",
  //             "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/maxresdefault_sys20200427147996id_.jpg",
  //             "w": "1280"
  //           }
  //         }
  //       ]
  //   },








  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // Image Gallery (Flex) 


  //   {
  //       "styl": {
  //       "call": "full",
  //       "class": "FullRow",
  //       "marginTop": "0px",
  //       "marginBottom": "50px"
  //       },
  //       "call": "FGrid",
  //       "info": {
  //       "call": "flex",
  //       "gridType": "ImageText",
  //       "gridBgColor": "transparent",
  //       "gridCall": "grid_column_cell",
  //       "cellCall": "container",
  //       "modCall": "fill_div",
  //       "thumb": {
  //           "call": "flex_height",
  //           "diff": "0"
  //       },
  //       "cellBorder": {
  //           "boolean": false,
  //           "thickness": 0,
  //           "color": "lightgray"
  //       },
  //       "gutter": {
  //           "boolean": "1",
  //           "size": "7"
  //       },
  //       "thresh": {
  //           "refWidth": false,
  //           "across1": "501",
  //           "across2": "801",
  //           "across3": "1051"
  //       },
  //       "mask": {
  //           "boolean": true,
  //           "message": {
  //           "boolean": true,
  //           "backgroundColor": "#4CAF50",
  //           "textColor": "white"
  //           }
  //       }
  //       },
  //       "items": [
  //           {
  //               "id": "20200406240087",
  //               "title": "Generic Title",
  //               "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",
  //               "image": {
  //                   "h": "426",
  //                   "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/6433addfile_sys20200406240087id_.jpg",
  //                   "w": "640"
  //               }
  //           },
  //           {
  //               "id": "20200406234970",
  //               "title": "Generic Title",
  //               "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",
  //               "image": {
  //                   "h": "426",
  //                   "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/8169addfile_sys20200406234970id_.jpg",
  //                   "w": "640"
  //               }
  //           },
  //           {
  //               "id": "20200407011445",
  //               "title": "Generic Title",
  //               "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",
  //               "image": {
  //                   "h": "2160",
  //                   "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/ferrari-monza_sys20200407011445id_.jpg",
  //                   "w": "3840"
  //               }
  //           },
  //           {
  //               "id": "20200427148217",
  //               "title": "Generic Title",
  //               "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",
  //               "image": {
  //                   "h": "1109",
  //                   "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/180957-car-monza-sp1-1_sys20200427148217id_.jpg",
  //                   "w": "1582"
  //               }
  //           },
  //           {
  //               "id": "20200406242021",
  //               "title": "Generic Title",
  //               "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",
  //               "image": {
  //                   "h": "640",
  //                   "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/2495addfile_sys20200406242021id_.jpg",
  //                   "w": "426"
  //               }
  //           },
  //           {
  //               "id": "20200427151306",
  //               "title": "Generic Title",
  //               "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",
  //               "image": {
  //                   "h": "850",
  //                   "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Front_3.4_edit_93741fd4-6b93-4c41-a419-ddb66797622c_2000x850_crop_center_sys20200427151306id_.jpg",
  //                   "w": "2000"
  //               }
  //           },
  //           {
  //               "id": "20200427151838",
  //               "title": "Generic Title",
  //               "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",
  //               "image": {
  //                   "h": "2677",
  //                   "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Rear_3.4_611ef627-0fbe-488c-ac45-1b2d57c0fe95_4000x2677_crop_center_sys20200427151838id_.jpg",
  //                   "w": "4000"
  //               }
  //           },
  //           {
  //               "id": "20200427150116",
  //               "title": "Generic Title",
  //               "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",
  //               "image": {
  //                   "h": "2667",
  //                   "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Right_Side_f43ee0b8-5243-4bf6-b855-5955347d8250_4000x2677_crop_center_sys20200427150116id_.jpg",
  //                   "w": "4000"
  //               }
  //           },
  //           {
  //               "id": "20200427147996",
  //               "title": "Generic Title",
  //               "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.",
  //               "image": {
  //                   "h": "720",
  //                   "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/maxresdefault_sys20200427147996id_.jpg",
  //                   "w": "1280"
  //               }
  //           }
  //       ]
  //   },










    //##########################################################
    //##########################################################
    //##########################################################

    // Newspaper TM:


    {
        "styl": {
            "call": "indent",
            "class": "IndentRow",
            "marginTop": "25px",
            "marginBottom": "25px",
            "paddingTop": "50px",
            "paddingBottom": "75px",
            "backgroundColor":"darkblue",
            "textColor":"#00cc66"


        },
        "call": "TxtComponent",
        "info": {
            "call": "flex",
            "gridType": "Newspaper",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "default",
            "modCall": "fill_div",
            "thumb": {
                "call": "default",
                "diff": "0"
            },
            "cellBorder": {
                "boolean": true,
                "thickness": 1,
                "color": "gray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            },


            "compStyl": {
              "heading": {
                "fontFamily": "Arial, Helvetica, sans-serif",
                "fontSize": "32px",
                "fontWeight": "550",
                "textAlign": "left",
                "textDecoration": "none",
                "color": "#00cc66"
              },
              "pgraph": {
                "fontFamily": "Arial, Helvetica, sans-serif",
                "fontSize": "16px",
                "fontWeight": "500",
                "textAlign": "left",
                "textDecoration": "none",
                "color": "aqua"
              },
              "button": {
                "mainWidth": "100%",
                "position": "center",
                "backgroundColorOver": "#fc0",
                "backgroundColorOut": "#07a2e3",
                "borderRadius": "20px",
                "borderColorOver": "black",
                "borderColorOut": "white",
                "textDecoration": "none",
                "textColorOver": "black",
                "textColorOut": "white",
                "fontSize": "16px",
                "fontWeight": "550"
              },
              "link": {
                "mainWidth": "100%",
                "position": "center",
                "textDecoration": "none",
                "textColorOver": "orangered",
                "textColorOut": "#07a2e3",
                "fontSize": "16px",
                "fontWeight": "550"
              }
            }





        },
        "items": [
            {
            "heading": "Default Scene Info",
            "pgraph": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
            }
        ]

    },



    
  //   //##########################################################
  //   //##########################################################
  //   //##########################################################

  //   // Navigator 


  //   {
  //       "styl": {
  //           "call": "full",
  //           "class": "FullRow",
  //           "marginTop": "0px",
  //           "marginBottom": "0px"
  //       },
  //       "call": "Navigator",
  //       "info": {
  //           "call": "default",//switchRoute, default
  //           "gridType": "Bars",
  //           "gridBgColor": "transparent",
  //           "gridCall": "grid_cell",
  //           "cellCall": "default",
  //           "modCall": "fill_div",
  //           "thumb": {
  //               "call": "default",
  //               "diff": "0"
  //           },
  //           "cellBorder": {
  //               "boolean": true,
  //               "thickness": 1,
  //               "color": "gray"
  //           },
  //           "gutter": {
  //               "boolean": "0",
  //               "size": "0"
  //           },
  //           "thresh": {
  //               "refWidth": false,
  //               "across1": "501",
  //               "across2": "801",
  //               "across3": "1051"
  //           }
  //       },
  //       "items": [


  //           {

  //               "to"    :"",     
  //               "name"  :"Home",
  //               "descr" :"Sample tile or bar link goes to Home"
    
  //           },
    
  //           {
    
  //               "to"    :"SinglePage",     
  //               "name"  :"Single Page",
  //               "descr" :"This is a single page resource"
    
  //           },
    
  //           {
    
  //               "to"    :"MultiPage",     
  //               "name"  :"Multi Page",
  //               "descr" :"This is a multi page resource"
    
  //           },
    
    
  //           {
  //               "to"    :"FormSample",    
  //               "name"  :"Sample Form",
  //               "descr" :'This is a form resource'
    
  //           },


  //           {

  //               "to"    :"AuthArea",     
  //               "name"  :"Auth Area",
  //               "descr" :"Template for authenticating every page within this section of the application"
    
  //           }
    

   
  //       ]

  //   }





];


export default SceneInfo;






/*

{
    "call": "collage",
    "gridType": "Fixed",
    "gridBgColor": "transparent",
    "gridCall": "grid_cell",
    "cellCall": "container",
    "modCall": "fill_div",
    "cells": {
      "call": "default",
      "total": 1,
      "across": 1
    },
    "thumb": {
      "call": "default",
      "diff": "0",
      "bgColor": "black"
    },
    "cellBorder": {
      "boolean": false,
      "thickness": 0,
      "color": "lightgray"
    },
    "gutter": {
      "boolean": "0",
      "size": "0"
    },
    "thresh": {
      "refWidth": false,
      "across1": "501",
      "across2": "801",
      "across3": "1051"
    },
    "mask": {
      "boolean": false,
      "message": {
        "boolean": false,
        "backgroundColor": "#4CAF50",
        "textColor": "white"
      }
    },
    "opacity": 1,
    "webkitFilter": "grayscale(0%)",
    "filter": "grayscale(0%)",
    "view": "fullScreen",
    "viewHeight": "613px",
    "thumbHInt": 723
}


*/


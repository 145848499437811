const LibXss =  {


	RemoveHtmlElements : (string) => {
	

        function isHtml(input) {
            return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input);
        }

        function hasGreaterLessThan(input) {
      
            if(input.match(/<|>/g))
            {
                return true;
            }else{
                return false;
            }
        
        }

        // For greater than and less than symbol.

        // var string = '<><>';
        // string = string.replace(/[\<\>]/g,'*');
        // alert(string);
        
        // For special characters
        
        // var string = '<><>';
        // string = string.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'_');
        // alert(string);


        if(isHtml(string))
        {
            return "XSS_ERROR";
        }else{

            if(hasGreaterLessThan(string))
            {
                return "XSS_ERROR";
            }else{
                return string;
            }

        }

        // var html,div; 
        // //html = "<p>Hello, <b>World</b>";
        // html = string;
        // div = document.createElement("div");
        // div.innerHTML = html;
        // //alert(div.innerText); // Hello, World
        // return div.innerText;
		
    },


 	RemoveEncodeElements : (string) => {
	
	
        return string;
    },
	
   

	ReplaceSpecialChars : function(string,exceptions){


		var R = string;

			//=============================================
			//REPLACE all special chars except( - _  . ')
			//=============================================


			//WITH APOSTROPHE
			//var regex = /[!@#\$%^&\*,+="':;?()\<\>\[\]\{\}\/]+/gi;

			//WITHOUT APOSTROPHE
			//var regex = /[!@#\$%^&\*,+=":;?()\<\>\[\]\{\}\/]+/gi;



			// str.replace(/\W/g, '') //doesnt include underscores
			// str.replace(/[^0-9a-z]/gi, '') //removes underscores too
	


			//---------------------
			//React (Not Working)
			//---------------------

			//WITH APOSTROPHE
			//var regex = /[!@#\$%^&\*,+="':;?()\<\>\[\]\{\}\/]+/gi;


			//WITHOUT APOSTROPHE
			// var regex = /[!@#$%^&*,+=":;?()<>[]{}\/]+/gi;

			// R = R.replace(regex,'');




			//REFERENCE

			// /[^a-zA-Z\d\s:\u00C0-\u00FF]/g

			// ^ negates what follows

			// a-zA-Z matches upper and lower case letters

			// \d matches digits

			// \s matches white space (if you only want to match spaces, replace this with a space)

			// : matches a colon

			// \u00C0-\u00FF matches the Unicode range for accented latin characters.



			//---------------------
			//React (Working)
			//---------------------

			let hasExceptions = false;
			if(Object.prototype.toString.call(exceptions) === '[object Array]')
			{
				if(exceptions.length > 0)
				{
					hasExceptions = true;
				}

			}
			if(hasExceptions)
			{
				var exceptionsString;
				if(exceptions.length > 1)
				{
					exceptionsString = exceptions.join("-");
				}else{
					exceptionsString = exceptions.toString();
				}

				//alert("exceptionsString: "+exceptionsString);

				//R = R.replace(/[^\w\s]/gi, '');// doesnt include spaces
				//R = R.replace(/[^\w,]/gi, '');// doesnt include commas
				//R = R.replace(/[^\w,.]/gi, '');// doesnt include commas and periods
				//R = R.replace(/[^\w,.?!]/gi, '');// doesnt include all punctuation


				switch(exceptionsString)
				{
				case'email':

					// var emailFilter = /^[^@]+@[^@.]+\.[^@]*\w\w$/ ;
					// //var illegalChars= /[\(\)\<\>\,\;\:\\\"\[\]]/ ;
					// var illegalChars= /[()<>,;:\\"[\]]/ ;
		
					R = R.replace(/[^a-zA-Z\d\-_@.\u00C0-\u00FF]/g, '');// doesnt include - _ . @
					//alert("EMAIL: "+R)
	
				break;
				case'password':

					R = R.replace(/[^a-zA-Z\d\s\-_:;,."'?!@#$%\u00C0-\u00FF]/g, '');// doesnt include spaces punct and specials
				break;
				case'label':

					R = R.replace(/[^a-zA-Z\d\s\-_\u00C0-\u00FF]/g, '');// doesnt include spaces - _ 
					//alert("EMAIL: "+R)
				break;
				case'price':

					R = R.replace(/[^a-zA-Z\d.\u00C0-\u00FF]/g, '');// doesnt include .

					// const str1 = 'hello 123 !@#$%^WORLD?.456_';
					// const str2 = '৳ $ 20,500.00';
				
					R = R.replace(/[^\d.]/g, ""); //123.456 or 20500.00
				break;
				case'percent':


					R = R.replace(/[^a-zA-Z\d.\u00C0-\u00FF]/g, '');// doesnt include .

					// const str1 = 'hello 123 !@#$%^WORLD?.456_';
					// const str2 = '৳ $ 20,500.00';
				
					R = R.replace(/[^\d.]/g, ""); //123.456 or 20500.00
		
				break;
				case'lenient':

					R = R.replace(/[^\w\s\-_,.?!"':;]/gi, '');// doesnt include spaces and all punctuation
				break;
				case'strict':

					R = R.replace(/[^a-zA-Z\d\s\-_,\u00C0-\u00FF]/g, '');// doesnt include spaces - _ ,
				break;
				default:


					//strict - no spacing dashes and underscores

					if(exceptionsString.match(/dash/gi) 
					&& exceptionsString.match(/underscore/gi)
					&& exceptionsString.match(/comma/gi)
					)
					{
						R = R.replace(/[^a-zA-Z\d\-_,\u00C0-\u00FF]/g, '');// doesnt include dashes underscores and comma
				
					}
					else
					if(exceptionsString.match(/dash/gi) 
					&& exceptionsString.match(/underscore/gi)
					)
					{
						R = R.replace(/[^a-zA-Z\d\-_\u00C0-\u00FF]/g, '');// doesnt include dashes and underscore
				
					}
			



					// separators = spaces dashes and underscores

					else
					if(exceptionsString.match(/separators/gi) && exceptionsString.match(/comma/gi)
					)
					{
						R = R.replace(/[^a-zA-Z\d\s\-_,\u00C0-\u00FF]/g, '');// doesnt include spaces punct and specials
					
					}
					else
					if(exceptionsString.match(/separators/gi) && exceptionsString.match(/punct_specials/gi)
					)
					{
						R = R.replace(/[^a-zA-Z\d\s\-_:;,."'?!@#$%\u00C0-\u00FF]/g, '');// doesnt include spaces punct and specials
					
					}
					else
					if(exceptionsString.match(/separators/gi) && exceptionsString.match(/punct_all/gi)
					)
					{
						R = R.replace(/[^\w\s\-_,.?!"':;]/gi, '');// doesnt include spaces and all punctuation
					
					}
					else
					if(exceptionsString.match(/separators/gi)
					)
					{
						R = R.replace(/[^a-zA-Z\d\s\-_\u00C0-\u00FF]/g, '');// doesnt include spaces dashes and underscores
					
					}
			


					// others


					else
					if(exceptionsString.match(/spaces/gi) && exceptionsString.match(/punct/gi)
					)
					{
						R = R.replace(/[^\w\s,.?!]/gi, '');// doesnt include spaces and basic punctuation
					
					}
					else
					if(exceptionsString.match(/spaces/gi) && exceptionsString.match(/commas/gi)
					)
					{
						R = R.replace(/[^\w\s,]/gi, '');// doesnt include spaces and commas

					}
					else
					if(exceptionsString.match(/spaces/gi)
					)
					{
						R = R.replace(/[^\w\s]/gi, '');// doesnt include spaces
					}
					else
					if(exceptionsString.match(/commas/gi)
					)
					{
						R = R.replace(/[^\w,]/gi, '');// doesnt include commas
						
					}



				}



			}else{


				R = R.replace(/\W/g, '');			// doesnt include underscores
				//R = R.replace(/[^0-9a-z]/gi, ''); 	// removes underscores too			
			}



	


		return R;


	},





	NotifySpecialChars : function(string,exceptions){


		var R = string;

			//=============================================
			//REPLACE all special chars except( - _  . ')
			//=============================================


			//WITH APOSTROPHE
			//var regex = /[!@#\$%^&\*,+="':;?()\<\>\[\]\{\}\/]+/gi;

			//WITHOUT APOSTROPHE
			//var regex = /[!@#\$%^&\*,+=":;?()\<\>\[\]\{\}\/]+/gi;



			// str.replace(/\W/g, '') //doesnt include underscores
			// str.replace(/[^0-9a-z]/gi, '') //removes underscores too
	


			//---------------------
			//React (Not Working)
			//---------------------

			//WITH APOSTROPHE
			//var regex = /[!@#\$%^&\*,+="':;?()\<\>\[\]\{\}\/]+/gi;


			//WITHOUT APOSTROPHE
			// var regex = /[!@#$%^&*,+=":;?()<>[]{}\/]+/gi;

			// R = R.replace(regex,'');




			//REFERENCE

			// /[^a-zA-Z\d\s:\u00C0-\u00FF]/g

			// ^ negates what follows

			// a-zA-Z matches upper and lower case letters

			// \d matches digits

			// \s matches white space (if you only want to match spaces, replace this with a space)

			// : matches a colon

			// \u00C0-\u00FF matches the Unicode range for accented latin characters.



			//---------------------
			//React (Working)
			//---------------------

			let hasExceptions = false;
			if(Object.prototype.toString.call(exceptions) === '[object Array]')
			{
				if(exceptions.length > 0)
				{
					hasExceptions = true;
				}

			}
			if(hasExceptions)
			{
				var exceptionsString;
				if(exceptions.length > 1)
				{
					exceptionsString = exceptions.join("-");
				}else{
					exceptionsString = exceptions.toString();
				}

				//alert("exceptionsString: "+exceptionsString);

				//R = R.replace(/[^\w\s]/gi, '');// doesnt include spaces
				//R = R.replace(/[^\w,]/gi, '');// doesnt include commas
				//R = R.replace(/[^\w,.]/gi, '');// doesnt include commas and periods
				//R = R.replace(/[^\w,.?!]/gi, '');// doesnt include all punctuation


				switch(exceptionsString)
				{
				case'email':


					R = R.replace(/[^a-zA-Z\d.\u00C0-\u00FF]/g, '');// doesnt include @ .
			
				break;
				case'password':
				

					R = R.replace(/[^a-zA-Z\d\s\-_:;,."'?!@#$%\u00C0-\u00FF]/g, '');// doesnt include spaces punct and specials
				
				break;
				case'price':


					R = R.replace(/[^a-zA-Z\d.\u00C0-\u00FF]/g, '');// doesnt include .
			
				break;
				case'percent':


					R = R.replace(/[^a-zA-Z\d.\u00C0-\u00FF]/g, '');// doesnt include .
					
				break;
				default:


					//strict - no spacing dashes and underscores

					if(exceptionsString.match(/dash/gi) 
					&& exceptionsString.match(/underscore/gi)
					&& exceptionsString.match(/comma/gi)
					)
					{
						R = R.replace(/[^a-zA-Z\d\-_,\u00C0-\u00FF]/g, '');// doesnt include dashes underscores and comma
				
					}
					else
					if(exceptionsString.match(/dash/gi) 
					&& exceptionsString.match(/underscore/gi)
					)
					{
						R = R.replace(/[^a-zA-Z\d\-_\u00C0-\u00FF]/g, '');// doesnt include dashes and underscore
				
					}
			



					// separators = spaces dashes and underscores

					else
					if(exceptionsString.match(/separators/gi) && exceptionsString.match(/comma/gi)
					)
					{
						R = R.replace(/[^a-zA-Z\d\s\-_,\u00C0-\u00FF]/g, '');// doesnt include spaces punct and specials
					
					}
					else
					if(exceptionsString.match(/separators/gi) && exceptionsString.match(/punct_specials/gi)
					)
					{
						R = R.replace(/[^a-zA-Z\d\s\-_:;,."'?!@#$%\u00C0-\u00FF]/g, '');// doesnt include spaces punct and specials
					
					}
					else
					if(exceptionsString.match(/separators/gi) && exceptionsString.match(/punct_all/gi)
					)
					{
						R = R.replace(/[^\w\s\-_,.?!"':;]/gi, '');// doesnt include spaces and all punctuation
					
					}
					else
					if(exceptionsString.match(/separators/gi)
					)
					{
						R = R.replace(/[^a-zA-Z\d\s\-_\u00C0-\u00FF]/g, '');// doesnt include spaces dashes and underscores
					
					}
			


					// others


					else
					if(exceptionsString.match(/spaces/gi) && exceptionsString.match(/punct/gi)
					)
					{
						R = R.replace(/[^\w\s,.?!]/gi, '');// doesnt include spaces and basic punctuation
					
					}
					else
					if(exceptionsString.match(/spaces/gi) && exceptionsString.match(/commas/gi)
					)
					{
						R = R.replace(/[^\w\s,]/gi, '');// doesnt include spaces and commas

					}
					else
					if(exceptionsString.match(/spaces/gi)
					)
					{
						R = R.replace(/[^\w\s]/gi, '');// doesnt include spaces
					}
					else
					if(exceptionsString.match(/commas/gi)
					)
					{
						R = R.replace(/[^\w,]/gi, '');// doesnt include commas
						
					}



				}












			}else{


				R = R.replace(/\W/g, '');			// doesnt include underscores
				//R = R.replace(/[^0-9a-z]/gi, ''); 	// removes underscores too			
			}



	


		return R;


	},










}




export default LibXss;




/*



        
        //-----

        targetVal = targetVal.toString();
        if(targetVal.length === parseInt(maxlength)-1)
        {
            if(!targetVal.match(/\./gi)) maxlength = maxlength-2;
            diff = targetVal.length - maxlength;
            targetVal = targetVal.substr(0,targetVal.length-diff);
        }
        else
        if(targetVal.length > maxlength 
        || targetVal.length === maxlength) 
        {
            if(!targetVal.match(/\./gi)) maxlength = maxlength-1;
            diff = targetVal.length - maxlength;
            targetVal = targetVal.substr(0,targetVal.length-diff);
        }

        //------
        


*/
import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';


//import '../../StyleSheets/dynamic-form.css';
import 'app-style/dynamic-form/style.css';


import SVGPencil from 'grfx/pencil.svg';
import SVGPencilOver from 'grfx/pencil_over.svg';
//import SVGPencilOff from 'grfx/pencil_off.svg';

//import {ClickInput} from './ClickInput';


import { LibString } from 'library';


export const FFclickeredit = (props) => {



    //=============================================
    // refs
    //=============================================

    // const [rowRefs] = useState([]);
    // const setRowRef = (ref) => {

    //     if(ref && ref !== null && ref !== undefined)
    //     {
    //         //console.log("ref::::"+ref.id);
    //         var found;
    //         found = false;
    //         for(var i=0; i < rowRefs.length; i++)
    //         {
    //             if(rowRefs[i].id === ref.id) found = true;
    //         }
    //         if(!found) rowRefs.push(ref);
    //         //console.log(boxRefs.length);

    //     }

    // };


    // USAGE
    // ref={setRowRef}  




    //=============================================
    // state/effect - field
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);

    const [inputIndex, setInputIndex] = useState(99);
    const [inputArr, setInputArr] = useState([]);

    const [fieldArr, setFieldArr] = useState([]);
    const [fieldStyl, setFieldStyl] = useState({});

    const [wasBlurred, setWasBlurred] = useState(false);
    
    useEffect(() => {



        if(!isLoaded)
        {
            //alert('isnt Loaded');
            setIsLoaded(true);
        }




        var call = props['data']['call'];
        //var clickername = props['data']['name'];
        var _array = props['data']['array'];
        var choice = props['data']['choice'];


        //var grid_id = clickername+"_grid";

        var inpArr = [];
        var clkArr = [];
        var found;
        var inner_color;


        var fontStyle = 'normal';
        var fontColor = "#000";



        for(var j=0; j < _array.length; j++)
        {
    
            var clickerval = _array[j];

            inpArr.push(clickerval);
    
            found = false;
    
            switch(call)
            {
            case'rad':

                //console.log(baselib.ObjectType(choice)+" === "+clickerval.toLowerCase());

                if(choice.length > 0)
                {
                    if(choice[0].toLowerCase() === clickerval.toLowerCase()
                    ) 
                    {
                        //console.log("::::::::::"+choice[0]+" "+clickerval);
                        found = true;
        
                    }//==

                }


            break;
            case'chk':
    
                for(var k=0; k < choice.length; k++)
                {

                    if(choice[k].toLowerCase() === clickerval.toLowerCase()
                    )
                    {
                        //console.log("::::::::::"+choice[k]+" "+clickerval);
                        found = true;
                    
                    }//==
    
                }//==
    
            break;
            default:
            break;
            }//===
    
            
            inner_color = 'white'
        
            if(found)
            {
                inner_color = 'orangered';

                //fontStyle = 'italic';
                //fontColor = '#777';
            }



            clkArr.push({
    
                "clickerVal"    :clickerval,
                "innerColor"    :inner_color,
                "myText"        :clickerval
    
            });

    
        }
    
        setInputArr(inpArr);
        setFieldArr(clkArr);
        setFieldStyl({

            "bgColor":"transparent",
            "fontStyle":fontStyle,
            "fontColor":fontColor

        });

        var problemArr = props.problemArr;
        for(var i=0; i < problemArr.length; i++)
        {
            if(props.indx === problemArr[i]['key'])
            {
                setFieldStyl({"bgColor":"lightyellow"});
                break;
            }
    
        }

        
   

     },[
         props, 
         isLoaded
    ])












    //=============================================
    // handle - click
    //=============================================

    function handleClick(indx) {



        /*

        console.log(JSON.stringify(props,null,2))


        {
        "indx": 8,
        "data": {

                "call": "rad",
                "required": true,
                "name": "typeofwork",
                "array": [
                    "Consulting",
                    "Full Stack",
                    "Frontend ",
                    "Backend",
                    "Not Sure"
                ],
                "choice": [
                    "Full Stack"
                ]
            }
        }

    
        alert("handleClick");

        */
   


        var newArr = [];
        var i;

        if(props.problemArr.length > 0)
        {
            props.handle({

                call        :'clear',
                fieldCall   :"clicker-edit",
                key         :props.indx,
                data        :props.data,
                newArray    :newArr,
                newValue    :false
            
            });

        }

        for(i=0; i < fieldArr.length; i++)
        {

            var object = fieldArr[i];


            switch(props.data.call)
            {
            case'chk':


      
                if(indx === i)
                {

                    var newColor = 'orangered';
                    if(object['innerColor'] === 'orangered') newColor = 'white';
                
                    newArr.push({


                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :newColor,
                        "myText"        :object['myText']
                    });

                }else{

                    newArr.push({

                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :object['innerColor'],
                        "myText"        :object['myText']

                    });

                }//==



            break;
            case'rad':


                if(indx === i)
                {
                
                    newArr.push({


                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :'orangered',
                        "myText"        :object['myText']


                    });

                }else{

                    newArr.push({

                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :"white",
                        "myText"        :object['myText']

                    });

                }//==



            break;
            default:
            break;
            }//==

    
        }//==
        
        setFieldArr(newArr);
        setInputIndex(99);

        props.handle({

            call        :'capture',
            action      :"click",
            fieldCall   :"clicker-edit",
            key         :props.indx,
            data        :props.data,
            newArray    :newArr,      
            newValue    :false

        })

    }



    //=============================================
    // handle - button click
    //=============================================

    function handleButtonClick(hndl) {


        // alert(JSON.stringify(hndl,null,2));

        //var row_id;
        //var inp_id;
   
        // var buttonName;
        // var btn;
        // var img;

        // buttonName = hndl.buttonName;
    
        //row_id = props.data.name+"_"+hndl.indx;
        //inp_id = row_id + "_inp";

        //controls = document.getElementById(hndl.id+"_controls");
        // btn = document.getElementById(row_id+"_controls_"+buttonName);
        // img = document.getElementById(row_id+"_controls_"+buttonName+"_img");


        //alert("inputIndex: "+inputIndex);



        if(wasBlurred && hndl.from === 'controls')
        {

            setWasBlurred(false);
            // alert(inputIndex+" "+hndl.indx);
            if(inputIndex === 99)
            {
                setInputIndex(hndl.indx); 
                //setInputIndex(99);
            }

        }else{

            setWasBlurred(false);

            var select = false;
            if(hndl.indx === inputIndex)
            { 
                setInputIndex(99);
            }else{
                setInputIndex(hndl.indx);
                select = true;
            }

            //alert(select+" "+hndl.from);
            if(select && hndl.from === 'txt')
            {
                setTimeout(function(){

                    var inp_id = props.data.name+"_"+hndl.indx+"_inp";
                    document.getElementById(inp_id).select();

                },20)
            }           
        }




    }








    //=============================================
    // handle - over out
    //=============================================

    const handleOverOut = (hndl) => {


        switch(hndl.from)
        {
        case'controls':


            //console.log("controls");

            var row_id;
            var buttonName;
            var btn;
            var img;


            row_id = props.data.name+"_"+hndl.indx;
            buttonName = hndl.buttonName;

            //controls = document.getElementById(hndl.id+"_controls");
            btn = document.getElementById(row_id+"_controls_"+buttonName);
            img = document.getElementById(row_id+"_controls_"+buttonName+"_img");

            switch(hndl.call)
            {
            case'mouseEnter':
            case'mouseOver':

                btn.style.backgroundColor = 'transparent';
                img.src = SVGPencilOver;
                
            break;
            case'mouseOut':

                btn.style.backgroundColor = 'transparent';
                img.src = SVGPencil;
                
            break;
            default:
            }

        

        break;
        case'input':


            //console.log(JSON.stringify(hndl,null,2));

            row_id = "row"+hndl.indx;
            buttonName = hndl.buttonName;

            btn = document.getElementById(row_id+"_"+buttonName);
            btn.style.backgroundColor = hndl.backgroundColor;
            btn.style.border = hndl.border;
            btn.style.color = hndl.fontColor;
            btn.style.cursor = 'pointer';


            /*
        
            switch(hndl.call)
            {
            case'mouseEnter':
            case'mouseOver':

                btn.style.backgroundColor = hndl.backgroundColor;
                btn.style.border = hndl.border;
                btn.style.fontColor = hndl.fontColor;
            break;
            case'mouseOut':

                btn.style.backgroundColor = hndl.backgroundColor;
                btn.style.border = hndl.border;
                btn.style.fontColor = hndl.fontColor;
            break;
            default:
            }

            */


        break;
        default:
        }



    }





    //=========================================
    //handle
    //=========================================

    function handleFocus(hndl) {

        if(wasBlurred) setWasBlurred(true);
        hndl.target.select();

    }

    function handleBlur(hndl) {


        setWasBlurred(true);

        //console.log("TARGET VALUE: ", e.target.value);
        //console.log("TARGET TYPE: ", e.target.type);
        //console.log("TARGET HINT: ", props.data.field.hint);

        //var newVal;
        //newVal = e.target.value;

        var inp_id = props.data.name+"_"+hndl.indx + "_inp";
        var inp = document.getElementById(inp_id);

        var newVal = inp.value;
        var newArr = [];
        var i;


        newVal = LibString.ReplaceSpecialChars(newVal);
        newVal = LibString.TrimWhitespaces(newVal);
        if(newVal === '' || newVal === ' ') newVal = 'Choice';

        if(props.problemArr.length > 0)
        {
            props.handle({

                call        :'clear',
                fieldCall   :"clicker-edit",
                key         :props.indx,
                data        :props.data,
                newArray    :newArr,
                newValue    :false
            
            });

        }

        for(i=0; i < fieldArr.length; i++)
        {

            var object = fieldArr[i];

            switch(props.data.call)
            {
            case'chk':


                if(hndl.indx === i)
                {

                    //var newColor = 'orangered';
                    //if(object['innerColor'] === 'orangered') newColor = 'white';
                
                    newArr.push({


                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :'orangered',
                        "myText"        :object['myText']
                    });

                }else{

                    newArr.push({

                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :object['innerColor'],
                        "myText"        :object['myText']

                    });

                }//==



            break;
            case'rad':


                if(hndl.indx === i)
                {
                
                    newArr.push({


                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :'orangered',
                        "myText"        :object['myText']


                    });

                }else{

                    newArr.push({

                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :"white",
                        "myText"        :object['myText']

                    });

                }//==



            break;
            default:
            break;
            }//==

    
        }//==
        
 
        setFieldArr(newArr);
        setInputIndex(99);

        //alert(JSON.stringify(newArr,null,2));
        // alert(newVal);

        // alert('handleBlur');

        props.handle({

            call        :'capture',
            action      :"blur",
            fieldCall   :"clicker-edit",
            key         :props.indx,
            data        :props.data,
            newArray    :newArr,
            newValue    :newVal,
            newIndex    :hndl.indx

        });



    }
    

    function handleChange(hndl) {


        if(wasBlurred) setWasBlurred(true);

        console.log("handleChange - TARGET VALUE: ", hndl.target.value);
        //console.log("TARGET TYPE: ", e.target.type);
        //console.log("TARGET HINT: ", props.data.field.hint);
        console.log("props.indx: "+props.indx+" \nindx: "+hndl.indx);


        //var call = props['data']['call'];
        //var clickername = props['data']['name'];
        //var _array = props['data']['array'];
        //var choice = props['data']['choice'];

        var inpArr = [];
        var newArr = [];
        var newVal = hndl.target.value;


        for(var i=0; i < fieldArr.length; i++)
        {

            var object = fieldArr[i];


            if(hndl.indx === i)
            {
                inpArr.push(newVal);
                //alert("object: "+JSON.stringify(object,null,2));
            }else{
                inpArr.push(object['clickerVal']);
            }

            switch(props.data.call)
            {
            case'chk':


                if(hndl.indx === i)
                {

                    //var newColor = 'orangered';
                    //if(object['innerColor'] === 'orangered') newColor = 'white';
                
                    newArr.push({

                        "clickerVal"    :newVal,
                        "innerColor"    :'orangered',
                        "myText"        :newVal
                    });

                }else{

                    newArr.push({

                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :object['innerColor'],
                        "myText"        :object['myText']

                    });

                }//==



            break;
            case'rad':


                if(hndl.indx === i)
                {
                
                    newArr.push({


                        "clickerVal"    :newVal,
                        "innerColor"    :'orangered',
                        "myText"        :newVal


                    });

                }else{

                    newArr.push({

                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :"white",
                        "myText"        :object['myText']

                    });

                }//==



            break;
            default:
            break;
            }//==

    
        }//==

        //console.log("newArr: "+JSON.stringify(newArr,null,2));
        setInputArr(inpArr);
        setFieldArr(newArr);

    }










    // -- display -- //

    return (

        <div
        key={props.indx+"_ffclicker"} 
        id={props.data.name+"_grid"}      
        className="grid"
        style={{

            backgroundColor: fieldStyl.bgColor

        }}

        >

            {

            fieldArr.map((clkData, j) => (


                <div 
                key={j}
                className="clicker"
                id={props.data.name+"_grid_clicker"+j}
                >

                    <div 
                    className={props.data.call}
                    onClick={() => handleClick(j)}
                    >

                        <div 
                        className={props.data.call+"-inner"}
                        id={props.data.name+"_"+j}
                        style={{

                            backgroundColor : clkData.innerColor 
  
                        }}
                        >
                        </div>

                    </div>



                    <input
                        type="hidden" 
                        id={props.data.name+"_"+j+"_color"} 
                        name={props.data.name+"_"+j+"_color"}        
                        value={clkData.innerColor}
                        //onChange={e => handleChange(idx, e)}
                    />

                    {/* <div 
                    className="click-txt"
                    onClick={() => handleClick(j)}
                    >
                        {clkData.myText}

                    </div> */}

                    <input
                        type="hidden"
                        id={props.data.name+"_"+j+"_txt"} 
                        name={props.data.name+"_"+j+"_txt"}        
                        value={clkData.myText}
                    />





                  


                    {
                    clkData.innerColor === 'white' 
                    ?
                    // Not Selected



                        <div>

                            <div 
                            className="click-txtoff"
                            onClick={() => handleClick(j)}
                            >
                                {clkData.myText}

                            </div>

                            {/* <div className="click-controls">

                                <div 
                                className="click-editbutton"
                                id={props.data.name+"_"+j+"_controls_pencilButton"}
                                style={{

                                    padding:"0 0 0 0"
                                }}
                                >
                                    <div 
                                    className="click-editbutton-mask"
                                    id={props.data.name+"_"+j+"_controls_pencilButton_mask"}

                                        onClick={() => handleClick(j)}
                                    >
                                    </div>

                                    <img
                                    id={props.data.name+"_"+j+"_controls_pencilButton_img"}
                                    className="click-editbutton-img"
                                    src={SVGPencilOff} 
                                    alt="edit" />

                                </div>

                            </div> */}

                        </div>



                    : 
                    // Selected


                       
                        inputIndex === j
                        ?

                            <div>

                                <div 
                                className="click-field"
                                >
                                    <div 
                                    className="field-wrap"
                                    >

                                        <input
                                            type="text"
                                            className="input-tag"
                                            style={{
                                                backgroundColor:fieldStyl.bgColor,
                                                fontStyle:fieldStyl.fontStyle,
                                                color:fieldStyl.fontColor
                                            }}
                            
                                            id={props.data.name+"_"+j+"_inp"}
                                            value={inputArr[j]}
                                            placeholder={"Choice"}

                        
                                            //onFocus={handleFocus}
                                            onFocus = {event => {
                                                // event properties must be copied to use async
                                                const target = event.target;
                                                setTimeout(() => handleFocus({

                                                    target:target,
                                                    indx:j
                                                    
                                                }), 0);
                                            }}

                                            
                                            onBlur={event => {

                                                handleBlur({

                                                    target:event.target,
                                                    indx:j
                                                    
                                                })

                                            }}  
                                            
                    
                                            onChange={event => {

                                                handleChange({

                                                    target:event.target,
                                                    indx:j
                                            
                                                })

                                            }}


                                        />
                                
                                    </div>

                                </div>




                                <div className="click-controls">

                                    <div 
                                    className="click-editbutton"
                                    id={props.data.name+"_"+j+"_controls_pencilButton"}
                                    style={{

                                        padding:"0 0 0 0"
                                    }}
                                    >

                                        <div 
                                        className="click-editbutton-mask"
                                        id={props.data.name+"_"+j+"_controls_pencilButton_mask"}

                                            onClick={() => handleButtonClick({

                                                indx        :j,    
                                                call        :'click',
                                                from        :'controls',
                                                buttonName  :"pencilButton"

                                            })}

                                            onMouseEnter={() => handleOverOut({

                                                indx        :j,    
                                                call        :'mouseEnter',
                                                from        :'controls',
                                                buttonName  :"pencilButton"

                                            })}
                                            onMouseOut={() => handleOverOut({

                                                indx        :j,       
                                                call        :'mouseOut',
                                                from        :'controls',
                                                buttonName  :'pencilButton'

                                            })}

                                        >
                                        </div>


                                        <img
                                        id={props.data.name+"_"+j+"_controls_pencilButton_img"}
                                        className="click-editbutton-img"
                                        src={SVGPencil} 
                                        alt="edit" />


                                    </div>

                                </div>


                            </div>



                        :


                            <div>
            
                                <div 
                                className="click-txt"
                                //onClick={() => handleClick(j)}
                                onClick={() => handleButtonClick({

                                    indx        :j,    
                                    call        :'click',
                                    from        :'txt',
                                    buttonName  :"pencilButton"

                                })}
                                >
                                    {clkData.myText}

                                </div>



                                <div className="click-controls">

                                    <div 
                                    className="click-editbutton"
                                    id={props.data.name+"_"+j+"_controls_pencilButton"}
                                    style={{

                                        padding:"0 0 0 0"
                                    }}
                                    >

                                        <div 
                                        className="click-editbutton-mask"
                                        id={props.data.name+"_"+j+"_controls_pencilButton_mask"}

                                            onClick={() => handleButtonClick({

                                                indx        :j,    
                                                call        :'click',
                                                from        :'controls',
                                                buttonName  :"pencilButton"

                                            })}

                                            onMouseEnter={() => handleOverOut({

                                                indx        :j,    
                                                call        :'mouseEnter',
                                                from        :'controls',
                                                buttonName  :"pencilButton"

                                            })}
                                            onMouseOut={() => handleOverOut({

                                                indx        :j,       
                                                call        :'mouseOut',
                                                from        :'controls',
                                                buttonName  :'pencilButton'

                                            })}

                                        >
                                        </div>


                                        <img
                                        id={props.data.name+"_"+j+"_controls_pencilButton_img"}
                                        className="click-editbutton-img"
                                        src={SVGPencil} 
                                        alt="edit" />


                                    </div>

                                </div>

                            </div>



                        //endif showInput

                    //endif clkcolor
                    }


                        
                
                </div>


            ))

            }

    
        </div>

    )

}






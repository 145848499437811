
import {

	LibArray

} from "library";

export const SVG_MENU_BULLET = (props) => {


	let svgWidth;
	let svgHeight;
	let svgStyle;
	let svgViewBox;
	let svgPreserveAspectRatio;

	svgWidth = props.svgWidth || "100%";
	svgHeight = props.svgHeight || "100%";
	svgViewBox = props.svgViewBox || "0 0 500 500";
	svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	svgStyle = props.svgStyle || {
		background:"transparent",
		margin:"0 0 0 0"
	};


	var pieceColor,pieColor;

	pieceColor = props.pieceColor || "rgb(0, 204, 99)";
	pieColor = props.pieColor || "rgb(255,255,255)";


	if(props.svgWidth)
	{
		svgWidth = props.svgWidth;
		if(!props.svgWidth.match(/px/)) svgWidth = props.svgWidth + "px";
	}
	if(props.svgHeight)
	{
		svgHeight = props.svgHeight;
		if(!props.svgHeight.match(/px/)) svgHeight = props.svgHeight + "px";
	}
	if(props.svgStyle)
	{

		var specials,spec;
		specials = props.svgStyle.specials;
		//alert(Object.prototype.toString.call(specials));
		//console.log(JSON.stringify(specials,null,2));


		let txtPieColor = "pieColor-";
		let txtPieceColor = "pieceColor-";

		let regxPieColor = new RegExp(txtPieColor,"g");
		let regxPieceColor = new RegExp(txtPieceColor,"g");


		let allowableColors = [

			"black","white","lightyellow","lightblue","lightgreen","pink",
			"yellow","aqua","blue","red","green","purple","magenta",
			"darkslategray",
			"darkslateblue"
		];


		for(spec of specials)
		{
			if(spec 
			&& spec !== '' 
			&& spec !== undefined
			)
			{

				//console.log("spec: "+spec);


				if(spec.match(regxPieColor))
				{
					pieColor = spec.split(txtPieColor)[1];
					pieColor = pieColor.split("_")[0];
					if(pieColor.match(/_/gi))
					{
						pieColor = pieColor.split("_")[0];
					}

					// if(!isNaN(pieColor))
					// {
					// 	//console.log("pieColor: "+pieColor);
					// 	if(!pieColor.match(/#/g))
					// 	{
					// 		pieColor = "#"+pieColor;
					// 		console.log("pieColor: "+pieColor);
					// 	}
					// }


					if(!LibArray.InArray(pieColor,allowableColors))
					{
						//console.log("pieColor: "+pieColor);
						if(!pieColor.match(/#/g))
						{
							pieColor = "#"+pieColor;
							console.log("pieColor: "+pieColor);
						}
					}



				}


				if(spec.match(regxPieceColor))
				{
					pieceColor = spec.split(txtPieceColor)[1];
					pieceColor = pieceColor.split("_")[0];
					if(pieceColor.match(/_/gi))
					{
						pieceColor = pieceColor.split("_")[0];
					}
					console.log("pieceColor: "+pieceColor);

					// if(!isNaN(pieceColor))
					// {
					// 	if(!pieceColor.match(/#/g))
					// 	{
					// 		pieceColor = "#"+pieceColor;
					// 		console.log("pieceColor: "+pieceColor);
					// 	}
					// }


					if(!LibArray.InArray(pieceColor,allowableColors))
					{
						if(!pieceColor.match(/#/g))
						{
							pieceColor = "#"+pieceColor;
							console.log("pieceColor: "+pieceColor);
						}
					}



				}


			}



		}//for






	}






	//console.log("profit_pie props: "+JSON.stringify(props,null,2));



	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>


			{
				//Piece fill="rgb(0,255,0)" 

				// hex #00cc66
				// rgb(0, 204, 99)
				// cmyk(100%, 0%, 51%, 20%)

			}
			<path 
				fill={pieceColor}
				d="M158.356,47.109C185.874,32.982,217.039,25,250,25	c32.968,0,64.13,7.979,91.65,22.109L250,226.604L158.356,47.109L158.356,47.109z"
			/>
		

			{
				//Pie
			}
			<path 
				fill={pieColor}
				d="M250,475c111.041,0,201.606-90.563,201.606-201.606 c0-78.074-44.775-146.026-109.963-179.494L250,273.394v0.393L158.353,93.899c-65.185,33.468-109.96,101.42-109.96,179.494 C48.393,384.438,138.959,475,250,475L250,475z"
			/>
   

		</svg>
		</div>

	);//return
}
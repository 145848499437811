
import styled from "styled-components";
//import px2vw from "utils/px2vw";

/*
https://styled-components.com/docs


https://stackoverflow.com/questions/55906668/converting-css-with-nested-classes-to-styled-components-problem


Over 200 classes were generated for component styled.div with the id of "sc-AxirZ".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:

const Component = styled.div.attrs({
    style: ({ background }) => {
        background,
    },
})`width: 100%;`

<Component />




calc(100% - 300px)


TM - Notes 

- ScrollBox calc(100% - 120px) 120px equals the HeaderNav height + InfoBar height
- Use vh - viewport height - to set the stage height




https://stackoverflow.com/questions/1216114/how-can-i-make-a-div-stick-to-the-top-of-the-screen-once-its-been-scrolled-to




*/


const Stage = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#fcfcfc",
    width:              props.width || "100%",
    height:             props.height || "100vh",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"


  }))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;
    float:left;

    display:block;
    overflow:hidden;

`;



const ScrollBox = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#fcfcfc",
    //backgroundColor:    props.backgroundColor || "#00cc66",
    width:              props.width || "100%",
    height:             props.height || "calc(100% - 127px)",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",
    overflow:           props.overflow || "scroll" // scroll or auto


  }))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;   
    float:left;

    overflow-x:hidden;
    overflow-y:${props => props.overflow};

`;



const Area = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    maxWidth:           props.maxWidth || "10000px",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "auto"


  }))`

    background-color:${props => props.backgroundColor};
    max-width:${props => props.maxWidth};
    width:${props => props.width};
    height:${props => props.height};
    min-height:500px;
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;  
    overflow:hidden;

`;



const AreaTitle = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#edeef1",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "8px 0 10px 0",
    margin:             props.margin  || "0 0 0 0"


  }))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;   
    float:left;

    display:block;
    overflow:hidden;

    font-family:"Verdana", sans-serif;      
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    text-decoration:none;
    color:#1A1926;

    border:1px solid lightgray;
`;




const PrintOut = styled.div.attrs(props => ({

  backgroundColor:    props.backgroundColor || "lightyellow",
  width:              props.width || "calc(90% - 30px)",
  height:             props.height || "auto",
  padding:            props.padding || "15px 15px 15px 15px",
  margin:             props.margin  || "0 0 100px calc(50% - 45%)"


}))`

  background-color:${props => props.backgroundColor};

  width:${props => props.width};
  height:${props => props.height};
  min-height:400px;

  padding:${props => props.padding};
  margin:${props => props.margin};


  position:relative;   
  float:left;

  display:block;
  overflow:hidden;

  font-family:"Verdana", sans-serif;      
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  text-decoration:none;
  color:#1A1926;

  border:1px solid lightgray;



  .Title{

    width:100%;
    height:auto;
    margin:0 0 0 0;

    font-family:Arial, Helvetica, sans-serif;      
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    text-decoration:none;
    color:#0291cd;

  }


  .Text{

    width:98%;
    height:auto;
    margin:10px 0 25px 1.5%;

    font-family:Arial, Helvetica, sans-serif;      
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    text-decoration:none;
    color:#1A1926;

  }




`;




const Group = styled.div.attrs(props => ({

  backgroundColor:    props.backgroundColor || "lightseagreen",
  width:              props.width || "100%",
  height:             props.height || "auto",
  padding:            props.padding || "0 0 0 0",
  margin:             props.margin  || "0 0 0 0"


}))`

  background-color:${props => props.backgroundColor};
  width:${props => props.width};
  height:${props => props.height};
  padding:${props => props.padding};
  margin:${props => props.margin};

  font-size: calc(10px + 2vmin);
  color: white;


  position:relative;
  float:left;

`;


const Member = styled.div.attrs(props => ({

  backgroundColor:    props.backgroundColor || "powderblue",
  width:              props.width || "100%",
  height:             props.height || "auto",
  padding:            props.padding || "0 0 0 0",
  margin:             props.margin  || "0 0 0 0"


}))`

  background-color:${props => props.backgroundColor};
  width:${props => props.width};
  height:${props => props.height};
  padding:${props => props.padding};
  margin:${props => props.margin};

  font-size: calc(10px + 2vmin);
  color: white;


  position:relative;
  float:left;

`;




const Tray = styled.div.attrs(props => ({

  backgroundColor:    props.backgroundColor || "lightgray",
  border:             props.border || "gray",
  borderRadius:       props.borderRadius || "12px",

  width:              props.width || "40px",
  height:             props.height || "auto",
  padding:            props.padding || "10px 10px 10px 10px",
  margin:             props.margin  || "0 0 0 0",

  zIndex:             props.zIndex || "99",
  bottom:             props.bottom || "25px",
  right:              props.right  || "15px"



  // writing-mode: vertical-lr;
  // text-orientation: upright;


}))`

  background-color:${props => props.backgroundColor};

  border-top:${props => props.border};
  border-bottom:${props => props.border};
  border-left:${props => props.border};

  border-top-left-radius:${props => props.borderRadius};
  border-bottom-left-radius:${props => props.borderRadius};


  width:${props => props.width};
  height:${props => props.height};
  padding:${props => props.padding};
  margin:${props => props.margin};

  position:absolute;  
  z-index:${props => props.zIndex};
  bottom:${props => props.bottom};
  right:${props => props.right};


  .Button{

    background-color:#0291cd;
    border-radius:2px;

    width:30px;
    height:auto;
    margin:0 5px 0 0;
    padding:7px 0 7px 10px;

    font-family:Arial, Helvetica, sans-serif;      
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    text-decoration:none;
    color:white;

    writing-mode: vertical-lr;
    text-orientation: sideways;


    cursor:pointer;

  }




`;








export {

    Stage,
    ScrollBox,  
    Area,
    AreaTitle,
    PrintOut,
    Group,
    Member,
    Tray

}


import styled from "styled-components";
//import px2vw from "utils/px2vw";

/*
https://styled-components.com/docs


https://stackoverflow.com/questions/55906668/converting-css-with-nested-classes-to-styled-components-problem


Over 200 classes were generated for component styled.div with the id of "sc-AxirZ".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:

const Component = styled.div.attrs({
    style: ({ background }) => {
        background,
    },
})`width: 100%;`

<Component />




calc(100% - 300px)


TM - Notes 

- ScrollBox calc(100% - 120px) 120px equals the HeaderNav height + InfoBar height
- Use vh - viewport height - to set the stage height




https://stackoverflow.com/questions/1216114/how-can-i-make-a-div-stick-to-the-top-of-the-screen-once-its-been-scrolled-to




*/


export const ConstantButton = styled.div.attrs(props => ({


  backgroundColor:    props.backgroundColor || "#00cc66",
  border:             props.border || "1px solid #fff",
  borderRadius:       props.borderRadius || "20px",

  width:              props.width || "200px",
  height:             props.height || "auto",
  padding:            props.padding || "15px 0 11px 0",
  margin:             props.margin  || "0 0 0 0",

  zIndex:             props.zIndex || "98",
  bottom:             props.bottom || "25px",
  right:              props.right  || "100px",



  // writing-mode: vertical-lr;
  // text-orientation: upright;


  opacity:            props.opacity || "1.0"


  // div {
  //     display: none;
  //     transition: opacity 1s ease-out;
  //     opacity: 0;
  // }
  // div.active {
  //     opacity: 1;
  //     display: block;
  // }




  // transition: opacity 0.9s;
  // opacity:${props => props.opacity};



}))`

  background-color:${props => props.backgroundColor};
  border:${props => props.border};
  border-radius:${props => props.borderRadius};

  width:${props => props.width};
  height:${props => props.height};
  padding:${props => props.padding};
  margin:${props => props.margin};

  position:absolute;  
  z-index:${props => props.zIndex};
  bottom:${props => props.bottom};
  right:${props => props.right};




  @media (max-width: 1000000px) {

    bottom:25px;
    right:100px;
}
  @media (max-width: 1050px) {

    bottom:20px;
    right:20px;

  }



  .Button{

    background-color:transparent;
    border-radius:2px;

    width:auto;
    height:auto;
    margin:0 0 0 0;
    padding:0 0 0 0;

    font-family:Arial, Helvetica, sans-serif;      
    font-size: 16px;
    font-weight:550;
    text-align: center;
    text-decoration:none;
    color:white;

    cursor:pointer;

  }




`;







export const ConstantLogoButton = styled.div.attrs(props => ({


  backgroundColor:    props.backgroundColor || "transparent",
  border:             props.border || "1px solid #fff",
  borderRadius:       props.borderRadius || "20px",

  width:              props.width || "200px",
  height:             props.height || "auto",
  padding:            props.padding || "0 0 0 0",
  margin:             props.margin  || "0 0 0 0",

  zIndex:             props.zIndex || "98",
  bottom:             props.bottom || "25px",
  right:              props.right  || "100px",



  // writing-mode: vertical-lr;
  // text-orientation: upright;


  opacity:            props.opacity || "1.0"


  // div {
  //     display: none;
  //     transition: opacity 1s ease-out;
  //     opacity: 0;
  // }
  // div.active {
  //     opacity: 1;
  //     display: block;
  // }




  // transition: opacity 0.9s;
  // opacity:${props => props.opacity};


  // background-color:${props => props.backgroundColor};
  // border:${props => props.border};
  // border-radius:${props => props.borderRadius};


}))`



  width:${props => props.width};
  height:${props => props.height};
  padding:${props => props.padding};
  margin:${props => props.margin};

  position:absolute;  
  z-index:${props => props.zIndex};
  bottom:${props => props.bottom};
  right:${props => props.right};




  @media (max-width: 1000000px) {

    bottom:25px;
    right:100px;
}
  @media (max-width: 1050px) {

    bottom:20px;
    right:20px;

  }




  .Crate {

    background-color:transparent;

    width:100%;
    height:60px;

    padding:0 0 0 0;  
    margin:0 0 0 0;

    position:relative;
    float:left;

  }


  .Logo {

    background-color:#141820;
    border:1px solid #edeef1;
    
    border-radius:50%;

    width:40px;
    height:40px;

    padding:8px 10px 12px 10px;  
    margin:0 0 0 0;

    position:absolute;
    z-index:2;
    top:0;
    left:0;


    font-family:Arial, Helvetica, sans-serif;      
    font-size: 16px;
    font-weight:550;
    text-align: center;
    text-decoration:none;
    color:white;

    cursor:pointer;

  }





  .Button {

    background-color:#141820;
    border:1px solid #edeef1;
    border-radius:20px;

    width:70%;
    height:auto;

    padding:15px 18px 15px 0;   
    margin:6px 0 0 20px;
 
    position:relative;
    float:left;

    font-family:Arial, Helvetica, sans-serif;      
    font-size: 14px;
    font-weight:550;
    text-align:right;
    text-decoration:none;
    color:white;

    cursor:pointer;

  }




`;



















const DefaultFetchResponse = {


    "scene":{
        
        "name": "Home",
        "path": "/Users/tm/Work/PROJEX/reaxBuilds/reax1/src/app-components/Zone/Home/",
        "sceneName": "home",
        "sceneInfo": [
            {

                styl:{

                    call:"indent",
                    class:"IndentRow",
        
                    marginTop:"50px",
                    marginBottom:"0"
        
        
                },
                call:'TxtComponent',
                info:{
        
                    call:'flex', //fixed, flex
                    gridType: "TextBox", //Newspaper
                    gridBgColor:'transparent',
                    gridCall: "grid_cell",  //grid_cell or grid_column_cell
                    cellCall: "default",  //container or default 
                    modCall: 'fill_div',
                    thumb: {
        
                        call    : "default", //flex_height or default
                        diff    : 0
        
                    },    
                    cellBorder: {
                        "boolean"       :true,
                        "thickness"     :1,//px
                        "color"         :"gray"  
                    },
                    gutter: {
                        "boolean"   :true,
                        "size"      :20
                    },
                    thresh: {
                        "refWidth"    :false,   //number or false = default screen width
                        "across1"     :501,
                        "across2"     :801,
                        "across3"     :1050
                    }
        
        
        
                },
        
                items:[{
        
                    heading : "Default Page",
                    pgraph  : "This is a default page"
                }]


            }
        
        
        ],
        "sceneSettings": {
            "call": "settings",
            "data": {
                "duration": 8000,
                "fadetime": 4000
            },
            "multi": false
        }

    },



//settings-start
"settings":{
  "routes": [
    {
      "name": "Home",
      "descr": "Home",
      "to": "",
      "category": "main"
    },
    {
      "name": "Plans and Pricing",
      "descr": "PlansNPricing",
      "to": "PlansNPricing",
      "category": "main"
    },
    {
      "name": "Road Map",
      "descr": "RoadMap",
      "to": "RoadMap",
      "category": "main"
    },
    {
      "name": "Quickbooks",
      "descr": "Quickbooks",
      "to": "Quickbooks",
      "category": "main"
    },
    {
      "name": "Templates",
      "descr": "Templates",
      "to": "Templates",
      "category": "main"
    },
    {
      "name": "Beta Testing Outline",
      "descr": "BetaTesting",
      "to": "/BetaTesting",
      "category": "Beta Testing"
    },
    {
      "name": "Beta Testing Review",
      "descr": "BetaTestingReview",
      "to": "/BetaTestingReview",
      "category": "Beta Testing"
    },
    {
      "name": "Stylr",
      "descr": "Stylr",
      "to": "Stylr",
      "category": "main"
    },
    {
      "name": "Sign Up",
      "descr": "Sign Up",
      "to": "SignUp",
      "category": "main"
    },
    {
      "name": "Login",
      "descr": "This is an Login",
      "to": "app/Login",
      "category": "main"
    },
    {
      "name": "Forgot Password",
      "descr": "Forgot Password",
      "to": "app/ForgotPassword",
      "category": "main"
    },
    {
      "name": "Logout",
      "descr": "Logout",
      "to": "app/Logout",
      "category": "main"
    },
    {
      "name": "Hash Page",
      "descr": "Hash Page",
      "to": "HashPage",
      "category": "main"
    },
    {
      "name": "FAQ",
      "descr": "FAQ",
      "to": "FAQ",
      "category": "main"
    },
    {
      "name": "Knowledge Base",
      "descr": "KnowledgeBase",
      "to": "KnowledgeBase",
      "category": "Knowledge Base"
    },
    {
      "name": "DynamicTestPage",
      "descr": "This will go to DynamicTestPage",
      "to": "DynamicTestPage",
      "category": "main"
    }
  ],
  "header": [
    {
      "name": "Home",
      "descr": "This link will go to Home",
      "to": "/",
      "category": "main"
    },
    {
      "name": "App",
      "descr": "To the app",
      "to": "/site.subfolder/app",
      "category": "Application"
    },
    {
      "name": "5 Steps To Profit",
      "descr": "ThePaintingCompany",
      "to": "/ThePaintingCompany",
      "category": "main"
    },
    {
      "name": "Beta Testing",
      "descr": "BetaTesting",
      "to": "/BetaTesting",
      "category": "Beta Testing"
    },
    {
      "name": "Sign Up",
      "descr": "SignUp",
      "to": "/SignUp",
      "category": "main"
    }
  ],
  "menu": [
    {
      "name": "Home",
      "descr": "This link will go to Home",
      "to": "",
      "category": "main"
    },
    {
      "name": "5 Steps To Profit",
      "descr": "This will go to ThePaintingCompany",
      "to": "ThePaintingCompany",
      "category": "main"
    },
    {
      "name": "Plans and Pricing",
      "descr": "This will go to PlansNPricing",
      "to": "PlansNPricing",
      "category": "main"
    },
    {
      "name": "Road Map",
      "descr": "This will go to RoadMap",
      "to": "RoadMap",
      "category": "main"
    },
    {
      "name": "Quickbooks",
      "descr": "This will go to Quickbooks",
      "to": "Quickbooks",
      "category": "main"
    },
    {
      "name": "Knowledge Base",
      "descr": "This will go to KnowledgeBase",
      "to": "KnowledgeBase",
      "category": "main"
    },
    {
      "name": "The Painting Company Example",
      "descr": "This will go to ThePaintingCompany",
      "to": "ThePaintingCompany",
      "category": "main"
    },
    {
      "name": "Contact",
      "descr": "This will go to Contact",
      "to": "Contact",
      "category": "main"
    },
    {
      "name": "Beta Testing Outline",
      "descr": "BetaTesting",
      "to": "/BetaTesting",
      "category": "Beta Testing"
    },
    {
      "name": "Beta Testing Review",
      "descr": "BetaTestingReview",
      "to": "/BetaTestingReview/",
      "category": "Beta Testing"
    },
    {
      "name": "SignUp",
      "descr": "To the app",
      "to": "/SignUp",
      "category": "Application"
    },
    {
      "name": "App",
      "descr": "To the app",
      "to": "/site.subfolder/app",
      "category": "Application"
    },
    {
      "name": "Login",
      "descr": "To the app",
      "to": "/site.subfolder/app/Login",
      "category": "Application"
    },
    {
      "name": "Calculator",
      "descr": "To the Calculator",
      "to": "/site.subfolder/app/Calculator",
      "category": "Application"
    }
  ],
  "footer": [
    {
      "name": "Home",
      "descr": "This link will go to Home",
      "to": "",
      "category": "main"
    },
    {
      "name": "5 Steps To Profit",
      "descr": "ThePaintingCompany",
      "to": "/ThePaintingCompany",
      "category": "main"
    },
    {
      "name": "Plans and Pricing",
      "descr": "PlansNPricing",
      "to": "/PlansNPricing",
      "category": "main"
    },
    {
      "name": "Road Map",
      "descr": "RoadMap",
      "to": "/RoadMap",
      "category": "main"
    },
    {
      "name": "Quickbooks",
      "descr": "Quickbooks",
      "to": "/Quickbooks",
      "category": "main"
    },
    {
      "name": "App",
      "descr": "To the app",
      "to": "/site.subfolder/app",
      "category": "Application"
    },
    {
      "name": "Knowledge Base",
      "descr": "KnowledgeBase",
      "to": "/KnowledgeBase",
      "category": "main"
    },
    {
      "name": "Beta Testing Outline",
      "descr": "BetaTesting",
      "to": "/BetaTesting",
      "category": "Beta Testing"
    },
    {
      "name": "Beta Testing Review",
      "descr": "BetaTesting",
      "to": "/BetaTestingReview/",
      "category": "Beta Testing"
    },
    {
      "name": "Contact",
      "descr": "Contact",
      "to": "/Contact",
      "category": "main"
    }
  ],
  "appearance": {
    "header": {
      "logoHeight": "40px",
      "headerOpacity": "0.9",
      "buttonTextOutColor": "white",
      "buttonBackgroundOverColor": "#00cc66",
      "navbarHeight": "35px",
      "headerMainHeight": "54px",
      "buttonBackgroundOutColor": "yellow",
      "logoColor": "black",
      "lineColor": "white",
      "buttonTextOverColor": "white",
      "logoMinWidth": "40px",
      "hamburgerColorOver": "red",
      "navbarColor": "#191e28",
      "logoWidth": "260px",
      "headerMainColor": "black",
      "buttonTextColorHold": "black",
      "lineHeight": "2px",
      "hamburgerColorOut": "#fcfcfc",
      "headerCall": "C",
      "buttonBackgroundColorHold": "orange"
    },
    "general": {
      "buttonTextOverColor": "black",
      "buttonTextOutColor": "white",
      "buttonBackgroundOverColor": "red",
      "linkOver": "orange",
      "buttonBorderOutColor": "blue",
      "buttonBackgroundOutColor": "blue",
      "linkOut": "#00cc66",
      "buttonBorderOverColor": "red"
    },
    "menu": {
      "menuButtonBackgroundOverColor": "#00cc66",
      "menuColor": "white",
      "menuButtonTextOverColor": "black",
      "menuTabTextColor": "white",
      "menuBorder": "2px solid #00cc66",
      "menuTabColor": "#00cc66",
      "menuButtonTextOutColor": "black",
      "menuButtonBackgroundOutColor": "transparent"
    },
    "footer": {
      "logoHeight": "40px",
      "buttonTextOutColor": "white",
      "buttonBackgroundOverColor": "#00cc66",
      "navbarHeight": "35px",
      "buttonBackgroundOutColor": "yellow",
      "logoColor": "black",
      "lineColor": "white",
      "footerMainColor": "#141820",
      "buttonTextOverColor": "white",
      "footerOpacity": "1.0",
      "logoMinWidth": "40px",
      "navbarColor": "#141820",
      "logoWidth": "260px",
      "buttonTextColorHold": "black",
      "lineHeight": "2px",
      "footerCall": "A",
      "buttonBackgroundColorHold": "orange",
      "footerMainHeight": "450px"
    },
    "headings": {
      "h1": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "50px",
        "fontWeight": "600",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },
      "h2": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "40px",
        "fontWeight": "600",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },
      "h3": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "32px",
        "fontWeight": "600",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },
      "h4": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "24px",
        "fontWeight": "600",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },
      "h5": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "20px",
        "fontWeight": "600",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      }
    },
    "pgraphs": {
      "p1": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "20px",
        "fontWeight": "500",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },
      "p2": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "18px",
        "fontWeight": "500",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },
      "p3": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "16px",
        "fontWeight": "500",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },
      "p4": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "14px",
        "fontWeight": "500",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },
      "p5": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "12px",
        "fontWeight": "500",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      }
    }
  },
  "graphic": {
    "icon": {
      "image": {
        "h": "500",
        "src": "admin/inventory/img/TPC_SVG_MENU_BULLET_width-40_height-40_style-_specials-_pieColor-141820_pieceColor-00cc66_sys20220329137263id_.svg",
        "w": "500"
      },
      "id": "20220329137263",
      "text": "",
      "title": "TPC_SVG_MENU_BULLET_width-40_height-40_style-_specials-_pieColor-141820_pieceColor-00cc66",
      "bgColor": "black"
    },
    "logo": {
      "image": {
        "h": "120",
        "src": "admin/inventory/img/TPC_SVG_MENU_LOGO_width-170_height-50_style-_backgroundColor-transparent_align-left_sys20220329101808id_.svg",
        "w": "400"
      },
      "id": "20220329101808",
      "text": "",
      "title": "TPC_SVG_MENU_LOGO_width-170_height-50_style-_backgroundColor-transparent_align-left",
      "bgColor": "black"
    },
    "header": {
      "image": {
        "h": "120",
        "src": "admin/inventory/img/TPC_SVG_HEADER_LOGO_sys20220329064089id_.svg",
        "w": "400"
      },
      "id": "20220329064089",
      "text": "",
      "title": "TPC_SVG_HEADER_LOGO",
      "bgColor": "black"
    },
    "footer": {
      "image": {
        "h": "240",
        "src": "admin/inventory/img/TPC_SVG_WIDESCREEN_LOGO_width-400_height-130_style-_backgroundColor-transparent_sys20220329069869id__sys20220329069869id_.svg",
        "w": "800"
      },
      "id": "20220329069869",
      "text": "",
      "title": "TPC_SVG_WIDESCREEN_LOGO_width-400_height-130_style-_backgroundColor-transparent_sys20220329069869id_",
      "bgColor": "black"
    }
  }
}
//settings-end














}

export default DefaultFetchResponse;





/*

admin/inventory/img/PowerDigitalMedia/


*/


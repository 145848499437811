import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import { 

    Gauge,
    // DonutOverlay,
    // DonutChart,
    // Parts

} from './Styled';



// import { 

//     GaugeColors,
//     GaugeColorsB,

//     ChartColorsA, 
//     ChartColorsB, 
//     ChartColorsC 

// } from 'utils/components/Charts/ChartColors';




export const TemperatureGauge = (props) => {


    // console.log("HrzBarChart")
    // console.log(JSON.stringify(props,null,2));


    // -- window dims -- //
    const { 
        width,
        //height 
    } = useWindowDimensions();


    //=============================================
    // refs
    //=============================================

    // -- ref -- //
    const refGauge = useRef(null);
    const refContent = useRef(null);

    

    //=============================================
    // state/effect - isLoaded
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [currentWidth, setCurrentWidth] = useState(false);  
    const [styl, setStyl] = useState({            
        
        backgroundColor:"transparent",
        width:250+"px",
        height:250+"px",
        margin:"0 0 0 calc(50% - "+(250/2)+"px)",

        svg:{

            defs:{},
            gaugeBackground:{

                stroke:"rgb(100,100,100)",
                strokeDasharray:"100",
                strokeWidth:"85"

            },
            gaugePercentage:{

                stroke:"rgb(255,0,0)",
                strokeDasharray:"60",//percentage
                strokeWidth:"75"

            },
            crosshairs:{},
            needleCenterCircle:{

                fill:"black"

            },
            needle:{

                size:"165",
                stroke:"rgb(0,0,0)",
                strokeLinecap:"round",
                strokeWidth:"14",
                rotate:"rotate(-110,250,290)"

            }


        }



    });
    // const [items, setItems] = useState([]);
    // const [showHoleText, setShowHoleText] = useState(false);
    // const [showChartKey, setShowChartKey] = useState(false);
    // const [chartLabel, setChartLabel] = useState('');
    const [gaugeData, setGaugeData] = useState(false);
    const [items, setItems] = useState([]);



    useEffect(() => {


        //var strokeColors = GaugeColors;




        // if(props.data.colors 
        // && Object.prototype.toString.call(props.data.colors) === '[object Array]')
        // {
        //     strokeColors = props.data.colors;
        // }
        // else
        // if(Object.prototype.toString.call(props.data.colors) === '[object String]')
        // {

        //     switch(props.data.colors)
        //     {                
        //     case'GaugeColors':

        //         strokeColors = GaugeColors;
        //     break;
        //     case'GaugeColorsB':

        //         strokeColors = GaugeColorsB;
        //     break;
        //     case'ChartColorsA':

        //         strokeColors = ChartColorsA;
        //     break;
        //     case'ChartColorsB':

        //         strokeColors = ChartColorsB;
        //     break;
        //     case'ChartColorsC':

        //         strokeColors = ChartColorsC;
        //     break;
        //     default:
        //     }

        // }



        if(!isLoaded)
        {
            setIsLoaded(true);


            // -- set styl -- //
            //setStyl(styl);
            

        }else{


        
            var gaugeSize = 125;
            if(props.data.gaugeSize) gaugeSize = props.data.gaugeSize;


            var containerBackgroundColor = props.data.backgroundColor;
            // var containerWidth = props.data.gaugeSize+"px";
            // var containerHeight = props.data.gaugeSize+"px";
            // var containerMargin = "0 0 0 calc(50% - "+(props.data.gaugeSize/2)+"px)";

            var containerWidth = gaugeSize+"px";
            var containerHeight = "auto";
            var containerMargin = "0 0 0 calc(50% - "+(gaugeSize/2)+"px)";


    
            var gaugeBackgroundColor = "#454d5f";
            var gaugeBackgroundWidth = 85;
    
            var gaugePercentageColor = "#c00";
            var gaugePercentageWidth = 75;
            var gaugePercent = props.data.gaugePercent;
    
            var needleCenterColor = "black";
            var needleColor = "black";
            var needleSize = "165";
            var needleRotation  = props.data.needleRotation;



            var dif;
            if(gaugePercent === 0)
            {
                needleRotation = -130;
            }
            else
            if(gaugePercent === 50)
            {
                needleRotation = 0;
            }
            else
            if(gaugePercent === 100)
            {
                needleRotation = 130;
            }
            else
            if(gaugePercent > 50)
            {
                //greater than
                dif = gaugePercent - 50;
                needleRotation = dif * 2.6;
        
            }else{

                //less than
                dif = 50 - gaugePercent;
                needleRotation = -(dif * 2.6);



            }




    
    
            if(props.data.gaugeBackgroundColor) gaugeBackgroundColor = props.data.gaugeBackgroundColor;
            if(props.data.gaugeBackgroundWidth) gaugeBackgroundWidth = props.data.gaugeBackgroundWidth;
    
            if(props.data.gaugePercentageColor) gaugePercentageColor = props.data.gaugePercentageColor;
            if(props.data.gaugePercentageWidth) gaugePercentageWidth = props.data.gaugePercentageWidth;
            if(props.data.gaugePercent)         gaugePercent = props.data.gaugePercent;
    
            if(props.data.needleCenterColor)    needleCenterColor = props.data.needleCenterColor;
            if(props.data.needleColor)          needleColor = props.data.needleColor;
            if(props.data.needleSize)           needleSize = props.data.needleSize;
    
    

            if(!currentWidth || width !== currentWidth)
            {
                setCurrentWidth(width);


                // -- reset styl on width change -- //

                setStyl({

                    backgroundColor:containerBackgroundColor,
                    width:containerWidth,
                    height:containerHeight,
                    margin:containerMargin,

                    svg:{

                        defs:{},
                        gaugeBackground:{
            
                            stroke          :gaugeBackgroundColor,
                            strokeDasharray :styl.svg.gaugeBackground.Dasharray,
                            strokeWidth     :gaugeBackgroundWidth
            
                        },
                        gaugePercentage:{
            
                            stroke          :gaugePercentageColor,
                            strokeDasharray :gaugePercent,//percentage
                            strokeWidth     :gaugePercentageWidth
            
                        },
                        crosshairs:{},
                        needleCenterCircle:{
            
                            fill:needleCenterColor
            
                        },
                        needle:{
            
                            size:needleSize,
                            stroke:needleColor,
                            strokeLinecap:"round",
                            strokeWidth:"14",
                            rotate:"rotate("+needleRotation+",250,290)"
            
                        }
            
        
                    }
     

                });




                //-------------------------------------------

                var array = [

                    {stroke:"rgb(120,0,0)", strokeDasharray:"100 100"},
                    {stroke:"rgb(145,0,0)", strokeDasharray:"95 100"},
                    {stroke:"rgb(170,0,0)", strokeDasharray:"90 100"},
                    {stroke:"rgb(200,0,0)", strokeDasharray:"85 100"},
                    {stroke:"rgb(220,0,0)", strokeDasharray:"80 100"},
                    {stroke:"rgb(240,65,35)", strokeDasharray:"75 100"},
                    {stroke:"rgb(240,115,35)", strokeDasharray:"70 100"},
                    {stroke:"rgb(250,165,25)", strokeDasharray:"65 100"},
                    {stroke:"rgb(255,200,5)", strokeDasharray:"60 100"},
                    {stroke:"rgb(255,255,0)", strokeDasharray:"55 100"},
                    {stroke:"rgb(225,255,0)", strokeDasharray:"50 100"},
                    {stroke:"rgb(205,245,0)", strokeDasharray:"45 100"},
                    {stroke:"rgb(185,235,0)", strokeDasharray:"40 100"},
                    {stroke:"rgb(165,225,0)", strokeDasharray:"35 100"},
                    {stroke:"rgb(145,215,0)", strokeDasharray:"30 100"},
                    {stroke:"rgb(125,205,0)", strokeDasharray:"25 100"},
                    {stroke:"rgb(105,195,0)", strokeDasharray:"20 100"},
                    {stroke:"rgb(85,185,0)", strokeDasharray:"15 100"},
                    {stroke:"rgb(45,165,0)", strokeDasharray:"10 100"},
                    {stroke:"rgb(5,145,0)", strokeDasharray:"5 100"},
      
                ];
                setItems(array);


            }


            setGaugeData({

                percent:gaugePercent,
                pathLength:100,
                rotate:"rotate("+needleRotation+",250,290)"

            });


            // console.log("styl: "+JSON.stringify(styl,null,2));
            // console.log("props: "+JSON.stringify(props,null,2));








        }


    },
    [
        props,
        width,currentWidth,
        isLoaded,
        refGauge,
        refContent,
        styl

    ])








    // const handleClick = (hndl) => {

    //     //alert(JSON.stringify(hndl,null,2));
    //     setChartLabel(hndl.label);

    // };



    // const handleOverOut = (hndl) => {

    //     //alert(JSON.stringify(hndl,null,2));
    //     setChartLabel(hndl.label);

    // };








    if(!isLoaded)
    {

        return(

            <div></div>
        )


    }else{


        return (


            <Gauge
            ref={refGauge}
            backgroundColor={"transparent"}
            width={styl.width}
            height={styl.height}
            margin={styl.margin}
            >
                

                <svg    
                width="100%" height="100%"   
                viewBox= "0 0 500 500"  
                style={{

                    backgroundColor: "transparent"

                }}
                preserveAspectRatio="none"
                >


                    <defs>
                        <marker id="arrowhead"
                            //stroke="red"
                            fill={styl.svg.needle.stroke}
                            markerWidth="10" 
                            markerHeight="7" 
                            refX="0" 
                            refY=".5" 
                            orient="auto">
                                <polygon 
                                    points="0 0, 2 .5, 0 1"
                                />
                        </marker>
                    </defs>
                    


                    
                    {
                    //Gauge Background
                    }
                    <path 
                        fill="none" 
                        pathLength="100"
                        stroke={styl.svg.gaugeBackground.stroke}  
                        strokeDasharray={styl.svg.gaugeBackground.strokeDasharray}  
                        strokeWidth={styl.svg.gaugeBackground.strokeWidth}  
                        d="M97 418.5 C67,384,50,339,50,290 C50,180,140,90,250,90 C360,90,450,180,450,290 C450,339,433,384,403,418.5"
                    />





                    {/* {
                    //Gauge Percentage
                    }
                    <path 
                        fill="none" 
                        pathLength={gaugeData.pathLength}                        
                        stroke={styl.svg.gaugePercentage.stroke}  
                        strokeDasharray={""+gaugeData.percent+" 100"} 
                        strokeWidth={styl.svg.gaugePercentage.strokeWidth}  
                        d="M97 418.5 C67,384,50,339,50,290 C50,180,140,90,250,90 C360,90,450,180,450,290 C450,339,433,384,403,418.5"
                    />
                    */}





                    {items.map((itm, i) => (

                        <g
                        key={i}
                        >
                            <path 
                                fill="none" 
                                pathLength="100"      
                                stroke={itm.stroke}  
                                strokeDasharray={itm.strokeDasharray} 
                                strokeWidth={styl.svg.gaugePercentage.strokeWidth} 
                                d="M97 418.5 C67,384,50,339,50,290 C50,180,140,90,250,90 C360,90,450,180,450,290 C450,339,433,384,403,418.5"
                            />

                        </g>

                    ))}



                
                    {
                    //Crosshairs
                    }      
                    {/* <line 
                        x1="200" y1="290" x2="300" y2="290" 
                        stroke="rgb(255,0,0)"
                        strokeWidth="2"
                    />
                    <line
                        x1="250" y1="340" x2="250" y2="240" 
                        stroke="rgb(255,0,0)"
                        strokeWidth="2"

                    /> */}



                    {
                    //Needle Center Circle
                    }
                    <circle 
                        cx="250" cy="290" r="20" 
                        fill={styl.svg.needleCenterCircle.fill}
                    />


                    {
                    //Needle
                    }
                    <line
                        x1="250" y1="340" x2="250" y2={styl.svg.needle.size} 
                        stroke={styl.svg.needle.stroke} 
                        strokeLinecap="round"
                        strokeWidth="14"
                        markerEnd="url(#arrowhead)"
                        transform={gaugeData.rotate}
                    />


                    
                </svg> 



            </Gauge>


        )    

    }





}





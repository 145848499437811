
// import {

// 	LibArray

// } from "library";

export const SVG_EMPLOYEE = (props) => {


	let svgWidth;
	let svgHeight;
	let svgStyle;
	let svgViewBox;
	let svgPreserveAspectRatio;

	svgWidth = props.svgWidth || "100%";
	svgHeight = props.svgHeight || "100%";
	svgViewBox = props.svgViewBox || "0 0 500 500";
	svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	svgStyle = props.svgStyle || {
		background:"transparent",
		margin:"0 0 0 0"
	};



	//console.log("profit_pie props: "+JSON.stringify(props,null,2));


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>


			{/* <!--Black Background--> */}
			<path 
				fill="rgb(0,0,0)" 
				d="M389.385,266.924h-15.637v-0.208H333.26c18.385-18.327,30.848-42.615,34.066-69.606h22.822v-56.901h-11.52v-0.029h-2.086	c-6.693-36.519-29.316-68.943-61.625-87.924c-1.975-7.13-6.967-13.326-14.232-16.731l-0.357-0.168l-0.367-0.158 C284.094,28.431,267.285,25,250.001,25c-17.283,0-34.093,3.431-49.961,10.197l-0.365,0.158l-0.361,0.168 c-7.263,3.405-12.258,9.602-14.229,16.731c-32.312,18.98-54.934,51.405-61.628,87.924h-2.083v0.029H109.85v56.901h22.825 c3.217,26.991,15.678,51.279,34.063,69.606h-40.487v0.208h-15.637C66.164,266.924,30,303.086,30,347.537V475h440V347.537 C470,303.086,433.838,266.924,389.385,266.924z"
			/>
			{/* <!--Face--> */}
			<path 
				fill="rgb(255,255,255)"
				d="M249.998,278.913c-52.148,0-94.772-42.081-95.686-94.02h191.378 C344.773,236.832,302.15,278.913,249.998,278.913z"
			/>
			{/* <!--Yellow--> */}
			<path 
				fill="rgb(255,242,0)"
				d="M351.301,289.162V458H148.698V289.162h45.105c15.842,11.414,35.254,18.152,56.197,18.152 c20.942,0,40.354-6.738,56.194-18.152H351.301z M389.385,289.368V458h58.172V347.537 C447.557,315.544,421.381,289.368,389.385,289.368z M52.446,347.537V458h58.169V289.368	C78.622,289.368,52.446,315.544,52.446,347.537z M222.777,104.762c1.329,3.685,4.333,9.268,16.431,9.268h21.583	c12.1,0,15.102-5.583,16.43-9.268l15.902-44.078c0.602-1.602,0.438-3.712-1.965-4.84c-12.68-5.404-26.584-8.398-41.157-8.398 c-14.575,0-28.481,2.994-41.158,8.398c-2.403,1.128-2.569,3.238-1.964,4.84L222.777,104.762z M302.691,71.109l-14.324,43.927 c-4.607,12.152-14.979,16.666-27.402,17.027h-21.929c-12.434-0.361-22.769-4.875-27.401-17.017L197.31,71.109	c-28.292,16.262-48.451,45.175-52.672,78.925h210.728C351.141,116.284,330.98,87.371,302.691,71.109z M367.705,162.653h-11.523 c0-0.009,0-0.019,0-0.029H143.816c0,0.011-0.001,0.021-0.001,0.029h-11.52v12.009c21.778,0,213.63,0,235.409,0V162.653z"
			/>
  

		</svg>
		</div>

	);//return
}
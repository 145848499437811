import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";


// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'
// import useAddScript from 'utils/myHooks/AddScript';
// import { useFetch }  from 'utils/hooks/Fetch';
// import { useMatomo } from '@datapunt/matomo-tracker-react';




import { Fingerprint } from 'utils/components/Fingerprint';
// let dust = Fingerprint();

// console.log("dust fingerprint: \n"+JSON.stringify(dust,null,2));
// console.log("" 
//     + "\ndust.user: "+dust.user 
//     + "\ndust.isMobile: "+dust.isMobile
//     + "\ndust.agent: "+dust.agent
// );




// -- library -- //

import { 
    //LibArray,
    LibDetect,
    LibNumber 
} from 'library';




export const Track = (props) => {



    let dust = Fingerprint();

    // console.log("dust fingerprint: \n"+JSON.stringify(dust,null,2));
    // console.log("" 
    //     + "\ndust.user: "+dust.user 
    //     + "\ndust.isMobile: "+dust.isMobile
    //     + "\ndust.agent: "+dust.agent
    // );




    //-------------------------------
    // add scripts
    //-------------------------------

    // -- add scripts -- //

    /*

    useAddScript({

        to:'body',
        async:false,
        url: 'https://apis.google.com/js/client.js?onload=googleApiClientReady'

    });
    */



    //-------------------------------
    // window dims
    //-------------------------------

    // const { 
    //     width, 
    //     height 
    // } = useWindowDimensions();




    // -- screen orientation -- //
    //const screenOrientation = useScreenOrientation()



    //-------------------------------
    // router
    //-------------------------------

    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();


    //console.log("Location: "+JSON.stringify(location,null,2));

    // location: {
    //     "pathname": "/Text1",
    //     "search": "",
    //     "hash": "#!",
    //     "key": "90mmxu"
    // }




    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);



    //-------------------------------
    // redux
    //-------------------------------

    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));

    // let currentZone = pathname;
    // currentZone = currentZone.replace(/\//gi,"-");
    // currentZone = currentZone.replace(/ /gi,"-");
    // if(currentZone === '') currentZone = 'Home';

    //let zoneKey = currentZone + "_fetch";
    
    // -- Fetch -- // 
    // let fetchd = Fetch({

    //     key      : currentZone+"_fetch",
    //     route    : pathname

    // }); 




    //-------------------------------
    // matomo
    //-------------------------------

    // https://developer.matomo.org/guides/spa-tracking

    const [wasTrackedMatomo, setWasTrackedMatomo] = useState(false);
    

    // Track page view
    useEffect(() => {
     
        //var documentTitle = pathname;

        // var href = window.location.href;                //returns the href (URL) of the current page
        // var host = window.location.host;                //returns the domain with port number
        // var hostname = window.location.hostname;        //returns the domain name of the web host
        // var pathname = window.location.pathname;        //returns the path and filename of the current page
        // var protocol = window.location.protocol;        //returns the web protocol used (http: or https:)
        // var assign = window.location.assign;            //loads a new document


        // console.log("settings: "+JSON.stringify(zoneObj,null,2));
        // alert('check settings');


        var bypass;
        bypass = zoneObj.track ? 
            zoneObj.track.isAdmin ? true 
            : zoneObj.track.isDeveloper ? true 
            : false 
        : false; 

        //bypass = false;
      
        // if(dust.user && dust.user.match(/@/gi))
        // {
        //     bypass = dust.user === 'tom.m@tpc1.betatest' ? true : false;

        // }
        // alert("Track bypass: "+bypass);

        if(bypass)
        {
            //alert('bypass');
            //console.log('bypass');
            if(wasTrackedMatomo !== pathname) setWasTrackedMatomo(pathname);

        }else{


            //alert("basename: "+props.basename);

            //alert('tracking')
            var trackingStuff = {};
            //trackingStuff['page_location_site'] = "//theprofitcalculator.com/";
            trackingStuff['page_title_prefix'] = 'Site';
            trackingStuff['event_prefix'] = 'site_';
        


            var matomo_site_id;
            var matomo_url;
            var hostname;


            //matomoAnalytics-start
			matomo_site_id = "2";
			matomo_url = "https://matomo.powerdigitalmedia.com/matomo/";
			hostname = "theprofitcalculator.com";
			//matomoAnalytics-end



            var page_location, page_title, page_title_prefix;
            var user_id;

            var event_prefix;


            //page_location = 'https://example.com/page1';
            //page_location = window.location.protocol + "//" + window.location.hostname + "/" + pathname;

            if(window.location.hostname.match(/localhost/gi))
            {
                if(pathname === '' || pathname === '/')
                {
                    page_location = window.location.protocol + "//" + hostname; // MAIN SITE
                }else{
                    page_location = window.location.protocol + "//" + hostname + "/" + pathname; // MAIN SITE
                }
                //page_location = window.location.protocol + "//theprofitcalculator.com/app/" + pathname; // APP
                //page_location = window.location.protocol + "//calc.theprofitcalculator.com/" + pathname; // calc subdomain
            }else{
                page_location = window.location.protocol + "//" + window.location.hostname + "/" + pathname;
            }



            page_title_prefix = trackingStuff.page_title_prefix;
            page_title = page_title_prefix + " - " + pathname;
            if(pathname === '' || pathname === '/') page_title = page_title_prefix + " - Home";

            user_id = dust.user;
            event_prefix = trackingStuff.event_prefix;
        

            //------------------------------------------

            var event_category, event_action;
            
            var _paq = window._paq = window._paq || [];

            /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        
            switch(props.data.call)
            {
            case'trackEvent':

                event_category = props.data.category ? event_prefix+props.data.category : event_prefix+"UserAction";
                event_action = 'click-event';
                // event_name = '';
                // event_value = '';

                //alert(event_category+"\n"+event_action+"\n"+JSON.stringify(props.data,null,2));
 
                _paq.push([
                    'trackEvent', 
                    event_category,
                    event_action 
                    // event_name, 
                    // event_value, 
                    // {dimension1: 'DimensionValue'}
                ]);

            break;
            default:
                _paq.push(['setCustomUrl', page_location]);
                _paq.push(['setDocumentTitle', page_title]);
                // _paq.push(['setCustomVariable',
                //     // Index, the number from 1 to 5 where this custom variable name is stored
                //     1,
                //     // Name, the name of the variable, for example: Gender, VisitorType
                //     "Gender",
                //     // Value, for example: "Male", "Female" or "new", "engaged", "customer"
                //     "Male",
                //     // Scope of the custom variable, "visit" means the custom variable applies to the current visit
                //     "visit"
                // ]);


                _paq.push(['trackPageView']);
            }



            _paq.push(['enableLinkTracking']);


            (function() {

                var u=matomo_url;
                _paq.push(['setTrackerUrl', u+'matomo.php']);
                _paq.push(['setSiteId', matomo_site_id]);
                _paq.push(['setUserId',user_id])
                var d=window.document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                g.async=true; 
                g.src=u+'matomo.js'; 
                s.parentNode.insertBefore(g,s);

            })();
   
            if(wasTrackedMatomo !== pathname) setWasTrackedMatomo(pathname);

        }//bypass


        
    },[

        props,
        dust,
        pathname,
        zoneObj,
        wasTrackedMatomo
    ])








    //-------------------------------
    // ga 4
    //-------------------------------

    const [wasTracked, setWasTracked] = useState(false);
    
    // Track page view
    useEffect(() => {
     
        //var documentTitle = pathname;

        // var href = window.location.href;                //returns the href (URL) of the current page
        // var host = window.location.host;                //returns the domain with port number
        // var hostname = window.location.hostname;        //returns the domain name of the web host
        // var pathname = window.location.pathname;        //returns the path and filename of the current page
        // var protocol = window.location.protocol;        //returns the web protocol used (http: or https:)
        // var assign = window.location.assign;            //loads a new 

        
        var bypass;
        bypass = zoneObj.track ? 
            zoneObj.track.isAdmin ? true 
            : zoneObj.track.isDeveloper ? true 
            : false 
        : false; 

        //bypass = false;
      
        // if(dust.user && dust.user.match(/@/gi))
        // {
        //     bypass = dust.user === 'tom.m@tpc1.betatest' ? true : false;

        // }

        //alert("Track bypass: "+bypass);

        if(bypass)
        {
            //alert('bypass');
            //console.log('bypass');
            if(wasTracked !== pathname) setWasTracked(pathname);

        }else{



            //alert('tracking')
            var trackingStuff = {};
            //trackingStuff['page_location_site'] = "//theprofitcalculator.com/";
            trackingStuff['page_title_prefix'] = 'Site';
            trackingStuff['event_prefix'] = 'site_';
        

            var google_analytics_id;
            var configObj;
            var eventObj;

                var colors = ['blue','red','yellow','green','orange','purple'];
                var randomIndex = LibNumber.Random({min:0,max:colors.length-1});


                var hostname;
                var client_id;
                var page_location, page_title, page_title_prefix;
                var user_id, user_properties;

                var event_prefix, event_user;
                //var event_email;


                // google_analytics_id = 'G-X06SG4TXPF';
                // hostname = 'theprofitcalculator.com';


                //googleAnalytics-start
				google_analytics_id = "G-X06SG4TXPF";
				hostname = "theprofitcalculator.com";
				//googleAnalytics-end




                client_id = LibDetect.Browser();


                //page_location = 'https://example.com/page1';
                //page_location = window.location.protocol + "//" + window.location.hostname + "/" + pathname;
                if(window.location.hostname.match(/localhost/gi))
                {
                    if(pathname === '' || pathname === '/')
                    {
                        page_location = window.location.protocol + "//" + hostname; // MAIN SITE
                    }else{
                        page_location = window.location.protocol + "//" + hostname + "/" + pathname; // MAIN SITE
                    }
                    //page_location = window.location.protocol + "//theprofitcalculator.com/app/" + pathname; // APP
                    //page_location = window.location.protocol + "//calc.theprofitcalculator.com/" + pathname; // calc subdomain
                }else{
                    page_location = window.location.protocol + "//" + window.location.hostname + "/" + pathname;
                }

                page_title_prefix = trackingStuff.page_title_prefix;
                page_title = page_title_prefix + " - " + pathname;
                if(pathname === '' || pathname === '/') page_title = page_title_prefix + " - Home";

                user_id = dust.user;

                user_properties = {};
                user_properties['favorite-color'] = colors[randomIndex];
                user_properties['email'] = dust.user.match(/@/gi) ? dust.user : "na";


                event_prefix = trackingStuff.event_prefix;
                event_user = dust.user;
                //event_email = "tom.m@tpc1.betatest",


            configObj = {

                'client_id': client_id,   

                'page_location': page_location,
                'page_title': page_title,

                'user_id': user_id,
                'user_properties': user_properties
                
            };
            // console.log(JSON.stringify(configObj,null,2));

            // eventObj = {
            //     'event_user' : event_user,
            //     //'event_agent' : dust.agent,   
            // };
            // console.log(JSON.stringify(eventObj,null,2));

            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', google_analytics_id, configObj);
            // gtag('event', 'tpc_user', eventObj);


            eventObj = {};
            switch(props.data.call)
            {
            case'trackEvent':

                eventObj['event_click'] = props.data.category; 
                //console.log(JSON.stringify(eventObj,null,2));
                gtag('event', event_prefix + 'click', eventObj);
            
            break;
            default:

                eventObj['event_user'] = event_user;
                //alert(JSON.stringify(eventObj,null,2));
                gtag('event', event_prefix + 'user', eventObj);


            }

            if(wasTracked !== pathname) setWasTracked(pathname);


        }//bypass



    },[

        props,
        pathname,
        zoneObj,
        dust,
        wasTracked
    ])


  


    //------------------------------------------
    // state/effect - view zoneObj
    //------------------------------------------

    // -- state -- //

    const [isLoaded, setIsLoaded] = useState(false);
  

    useEffect(() => {


        if(!isLoaded)
        {
            //alert(pathname);

            setIsLoaded(true);
            props.handle({

                call:"Track",
                data:pathname

            });

        }

        if(wasTracked) console.log(wasTracked);

    },
    [

        props,
        pathname,
        zoneObj,
        isLoaded,
        wasTracked
    ])




    return(<div></div>);
    


}



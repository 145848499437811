import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';


import { createPortal } from 'react-dom';
import usePortal from 'utils/hooks/Portal';
//import { useWindowDimensions } from 'utils/WindowDimensionsProvider';


//import 'app-style/alfabase.css';//alfa,base,tab,tab-x,flank

import { Alfa, Base } from 'app-styled/AlfaBase';
import { LoaderBox } from './Styled';

import './StyleSheets/loader.css';


import { LibElem } from 'library';


const Index = (props) => {


    const portalTarget = usePortal("stage");


    // -- window dims -- //
    //const { width, height } = useWindowDimensions();


    // -- ref -- //

    //const baseRef = useRef(null)
    //const tabRef = useRef(null)
    const flyrRef = useRef(null)    


    // -- state -- //
    const [highZ, setHighZ] = useState(0)

    useEffect(() => {
        
        //setFlyrHeight(flyrRef.current.offsetHeight);
        if(highZ === 0)
        {
            var z = LibElem.HighestZ();
            setHighZ(z)

        }

    }, [highZ])






    if(props.call === 'pageLoader')
    {

        return createPortal(<div>


            <LoaderBox 
            id="flyr"
            ref={flyrRef}
            backgroundColor={'transaprent'}
            border={"0"}
            width={"200px"}
            top={"calc(50% - 150px)"}
            left={"calc(50% - 100px)"}
            >


                {props.text
                ?
                    <div 
                    id="loaderText"
                    style={{

                        width       :"100%",
                        height      :"auto",
                        padding     :"0 0 5px 0",
                        margin      :"0 auto 20px auto",
                        textAlign   :"center",
                        color       :"black"

                    }}
                    >
                        {props.text}

                    </div>

                :null}

                <div 
                id="loader"
                className="sm-loader-circle" 
                style={{

                    width               : "6em",
                    height              : "6em",
                
                    borderTop           : "0.7em solid rgba(100,100,100,0.2)",
                    borderRight         : "0.7em solid rgba(100,100,100,0.2)",
                    borderBottom        : "0.7em solid rgba(100,100,100,0.2)",
                    borderLeft          : "0.7em solid #aaa"

                }}
                >
                </div>


            </LoaderBox>



        </div>, portalTarget);



    }else{



        return createPortal(<div>


            <Alfa
            backgroundColor={props.alfaColor ? props.alfaColor : null}
            zIndex={highZ+1}
            ></Alfa>

            <Base 
            //ref={baseRef}
            zIndex={highZ+2}
            >


                <LoaderBox 
                id="flyr"
                ref={flyrRef}
                >
                    <div 
                    id="loader"
                    className="sm-loader-circle" 
                    style={{

                        borderTop          : "0.5em solid rgba(250,250,250,0.2)",
                        borderRight        : "0.5em solid rgba(250,250,250,0.2)",
                        borderBottom       : "0.5em solid rgba(250,250,250,0.2)",
                        borderLeft         : "0.5em solid #FFF"

                    }}
                    >
                    </div>


                </LoaderBox>

            </Base>


        </div>, portalTarget);



    }



}



export default Index;






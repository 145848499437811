
const Restrictions = [


    //schedule
    {
        id:"work_weeks",
        restrict:"numberOnly",
    },
    {
        id:"work_days",
        restrict:"numberOnly",
    },
    {
        id:"work_hours",
        restrict:"numberOnly",
    },


    //ownr
    {
        id:"ownr_hours_weekly",
        restrict:"numberOnly",
    },
    {
        id:"ownr_vacation_weeks",
        restrict:"numberOnly"

    },


    //empl
    {
        id:"empl_hours_weekly",
        restrict:"numberOnly",
    },
    {
        id:"empl_vacation_weeks",
        restrict:"numberOnly",
    }



];

export default Restrictions;
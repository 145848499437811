export const SVG_MENU_LOGO = (props) => {


	let svgWidth;
	let svgHeight;
	let svgStyle;
	let svgViewBox;
	let svgPreserveAspectRatio;

	svgWidth = props.svgWidth || "220";
	svgHeight = props.svgHeight || "70";
	svgViewBox = props.svgViewBox || "0 0 400 120";
	svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	svgStyle = props.svgStyle || {
		background:"transparent",
		margin:"0 0 0 0"
	};



	// if(props.svgWidth)
	// {
	// 	svgWidth = props.svgWidth;
	// 	if(!props.svgWidth.match(/px/)) svgWidth = props.svgWidth + "px";
	// }
	// if(props.svgHeight)
	// {
	// 	svgHeight = props.svgHeight;
	// 	if(!props.svgHeight.match(/px/)) svgHeight = props.svgHeight + "px";
	// }








	// let backgroundColor = props.backgroundColor || "rgb(30,35,45)";
	// let buttonColor = props.buttonColor || "rgb(255,255,255)";
	// let pieceColor = props.pieceColor || "rgb(0,255,0)";
	// let pieColor = props.pieColor || "rgb(30,35,45)";


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"

			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>


		<rect 
			fill="transparent" 
			width={svgWidth} 
			height={svgHeight}
		/>




		<path 
			fillRule="evenodd" 
			clipRule="evenodd" 
			fill="#00cc66" 
			d="M81.75,23.592c4.953-2.455,10.727-4.092,16.5-4.092 c5.775,0,11.551,1.637,16.5,4.092L98.25,55.5L81.75,23.592L81.75,23.592z"
		/>

		<path 
			fillRule="evenodd" 
			clipRule="evenodd" 
			fill="#141820" 
			d="M98.25,100.5c19.774,0,36.25-16.507,36.25-36.316 c0-14.025-8.236-26.408-19.774-32.184L98.25,64.184L81.773,32C70.239,37.775,62,50.159,62,64.184 C62,83.993,78.478,100.5,98.25,100.5L98.25,100.5z M184.494,84.5h-12.311V50.486h12.311V84.5L184.494,84.5z M149,46.396V35.5 h58.679v10.896H149L149,46.396z M227.196,84.5h-12.312V52.157h12.312v6.087h26.689c5.516,0,6.431-1.976,6.431-5.438 c0-3.646,0,2.679,0-0.964c0-3.46-0.915-5.439-6.431-5.439h-39.001V35.5h40.745c12.585,0,17.935,1.961,17.935,16.341 c0,3.643,0-2.682,0,0.964c0,14.371-5.35,16.34-17.935,16.34h-28.434V84.5L227.196,84.5z M293.885,68.512 c0,3.455,0.915,5.426,6.43,5.426H339V84.5h-40.783c-12.591,0-17.963-1.639-17.963-15.988c0-4.129,0-12.922,0-17.049 c0-14.356,5.372-15.962,17.963-15.962H339v10.535h-38.686c-5.515,0-6.43,1.981-6.43,5.428 C293.885,55.589,293.885,64.383,293.885,68.512L293.885,68.512z M70.392,101c0.918,0.704,1.857,1.371,2.816,2H14V17h58.533 l-4.035,2H16v82H70.392z M386,17H123.963l4.031,2H384v82H126.108c-0.917,0.704-1.856,1.371-2.815,2H386V17z"
		/>


		</svg>
		</div>

	);//return
}
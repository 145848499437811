
import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    Link,

    //useRouteMatch,
    //useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


//-----------------
// hooks
//-----------------

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
// import useScreenOrientation from 'utils/hooks/ScreenOrientation';
// import { useFetch }  from 'utils/hooks/Fetch';
// import { useMatomo } from '@datapunt/matomo-tracker-react';







import { 
    SubstituteSVG
} from 'utils/components/Specials/SubstituteSVG';



import { Footer } from './_Styled';


const Index = (props) => {


    const primeHandle = props.databind.handle;


    //-------------------------------
    // window dims
    //-------------------------------

    const { 
        width, 
        //height 
    } = useWindowDimensions();


    //-------------------------------
    // redux
    //-------------------------------

    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    //------------------------------------------
    // state/effect - view zoneObj
    //------------------------------------------

    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState(false);
    const [navigation, setNavigation] = useState(false);

    useEffect(() => {


        //alert(animateOut);
        //console.log(boardNumber+" "+props.data.boardNumber);

        if(!isLoaded)
        {
            setIsLoaded(true);
        } 

        if(zoneObj)
        {
            // console.log("zoneObj: "+JSON.stringify(Object.keys(zoneObj)));
            if(zoneObj.settings)
            {
                if(zoneObj.settings.footer)
                {
                    //console.log("zoneObj.settings.footer: "+JSON.stringify(Object.keys(zoneObj.settings.footer)));
                    setData(zoneObj.settings.footer);

                    let nav;
                    let object,array,count,keyname,groupCount;
                    let url;
     
                    if(width <  900)
                    {
                        nav = zoneObj.settings.footer;

                    }else{

                        nav = {};
                        count = 0;
                        keyname = 0;
                        groupCount = 7;


                        array = JSON.parse(JSON.stringify(zoneObj.settings.footer));
                        for(object of array)
                        {

                            if(object.to !== "/" 
                            && object.to !== "" 
                            && !object.to.match(/site./)
                            )
                            {
                                url = object.to;

                                    if(url.charAt(0) === '/') 
                                        url = url.substring(1,url.length);
            
                                    if(url.charAt(url.length-1) === '/') 
                                        url = url.substring(0,url.length-1);

                                url = "/" + url + "/";
                                object.to = url;

                            }


                            if(count === 0)
                            {
                                keyname = count;
                                nav[keyname] = [];
                            }
                            if(count === groupCount)
                            {
                                keyname = count;
                                nav[keyname] = [];
                            }
                            if(count === groupCount*2)
                            {
                                keyname = count;
                                nav[keyname] = [];
                            }
                            if(count === groupCount*3)
                            {
                                keyname = count;
                                nav[keyname] = [];
                            }
                            if(count === groupCount*4)
                            {
                                keyname = count;
                                nav[keyname] = [];
                            }
                            nav[keyname].push(object);
                            count++;

                        }

                    }

                    setNavigation(nav);




                }else{
                    //console.log("footer - defaultSettings : "+JSON.stringify(Object.keys(props.databind.defaultSettings.appearance.footer)));
                    setData(props.databind.defaultSettings.appearance.footer);
                }



                // setNavigation([

                //     {
                //     name:"Home"
                //     },
                //     {
                //     name:"FAQ"
                //     },

                //     {
                //     name:"App"
                //     },
                //     {
                //     name:"Some Link Four"
                //     },
                //     {
                //     name:"Some Link Five"
                //     },

                //     {
                //     name:"Privacy Policy"
                //     },
                //     {
                //     name:"Contact"
                //     }


                // ])



            }


        }
 
    },
    [
        props,
        width,
        zoneObj,
        isLoaded,
        data

    ])






    if(!data || !isLoaded)
    {

        return (

            <Footer
            id='footer'
            // backgroundColor={styl ? styl.backgroundColor ? styl.backgroundColor : null : null}
            // nav={styl ? styl.nav ? styl.nav : null : null}
            >

            </Footer>

        );


    }else{

        return (

            <Footer
            id='footer'
            backgroundColor={data.footerMainColor}
            height={data.footerMainHeight}
            opacity={data.footerOpacity}
            >
                <div className={"Container"}>

                    <div className={"Logo"}>

                        <SubstituteSVG 

                            imgSrc={"TPC_SVG_FOOTER_LOGO.svg"}
                            // svgWidth={"400px"}
                            // svgHeight={"120px"}
                            // svgStyle={{
                            //     backgroundColor:"green",
                            //     margin:"0 0 0 200px"
                            // }}

                        />

                    </div>

       

                    <div className={"Nav"}>

                        {
                        navigation&& navigation !== undefined
                        ?

                            Object.prototype.toString.call(navigation) === '[object Object]'
                            ?
                                Object.keys(navigation).map(keyName => (
                                    //<option value={key}>{tifs[key]}</option>
                                    <div 
                                    key={keyName}
                                    className={"Grid"}
                                    >
                                        {
                                        navigation[keyName].map((itm, i) => (


                                            itm.to.match(/site.site/gi) || itm.to.match(/site.subfolder/gi)
                                            ?

                                                <div 
                                                key={i}
                                                className="Cell"
                                                onClick={() => primeHandle({
                
                                                    call:"url",
                                                    data:{

                                                        action:'href',
                                                        url:itm.to
                                
                                                    } 
                
                                                })}
                                                >
                                                    {itm.name}
                                            
                                                </div>
                                    

                                            :
                                                <Link 
                                                key={i}
                                                to={itm.to}
                                                >
                                                    <div className="Cell">
                                                        
                                                        {itm.name}
                                                      
                                                    </div>
                                                    
                                                </Link>



                                        ))
                                        }
                                    </div>
    
                                ))
    

                            :

                                <div
                                className={"Grid"}
                                >
                                    {
                                    navigation.map((itm, i) => (

                                        // <div
                                        // key={i}
                                        // className={"Cell"}
                                        // >

                                        //     {itm.name}

                                        // </div>



                                        itm.to.match(/site.site/gi) || itm.to.match(/site.subfolder/gi)
                                        ?

                                            <div 
                                            key={i}
                                            className="Cell"
                                            onClick={() => primeHandle({
            
                                                call:"url",
                                                data:{

                                                    action:'href',
                                                    url:itm.to
                            
                                                } 
            
                                            })}
                                            >
                                                {itm.name}
                                        
                                            </div>
                                

                                        :
                                            <Link 
                                            key={i}
                                            to={itm.to}
                                            >
                                                <div className="Cell">
                                                    
                                                    {itm.name}
                                                  
                                                </div>
                                                
                                            </Link>




                                    ))
                                    }

                                </div>

                        :null
                        }
                    
                    </div>

                    <div className={"Date"}>Copyright 2022</div>

                </div>

            </Footer>

        );

    }



}

export default Index




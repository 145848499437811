


const SceneInfo = [



    //TM: Billboard 1


    //##########################################################
    //##########################################################
    //##########################################################

    // Billboard 
    

    {
        "styl": {
            "call": "full",
            "class": "FullRow",
            "marginTop": "0px",
            "marginBottom": "0px"
        },

        "call": "Billboard",

        "info": {
            "call": "collage",
            "gridType": "Fixed",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "container",
            "modCall": "fill_div",
            "cells": {
                "call": "default",
                "total": 12,
                "across": 3
            },
            "thumb": {
                "call": "default",
                "diff": "0",
                "bgColor": "black"
            },
            "cellBorder": {
                "boolean": false,
                "thickness": 0,
                "color": "lightgray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            },
            "mask": {
                "boolean": false,
                "message": {
                    "boolean": false,
                    "backgroundColor": "#4CAF50",
                    "textColor": "white"
                }
            },

            "view": "fullScreen",
            "opacity": 0.7,
            "webkitFilter": "grayscale(0%)",
            "filter": "grayscale(0%)"

        },



        "items": [
            {
              "overlay": {
                "category": "Category One",
                "title": "This is &quot;Quoted Text&quot; and &#39;Single Quote&#39;",
                "button": {
                  "name": "Knowledge Base",
                  "url": "/KnowledgeBase/",
                  "style": {
                    "backgroundColorOver": "#fcfcfc",
                    "backgroundColorOut": "#00cc66",
                    "borderOver": "1px solid purple",
                    "borderOut": "1px solid #0291cd",
                    "textColorOver": "#00cc66",
                    "textColorOut": "white"
                  }
                },
                "controls": {
                  "styl": {
                    "backgroundColor": "transparent",
                    "borderTop": "0",
                    "borderBottom": "0",
                    "borderLeft": "0",
                    "borderRight": "0",
                    "borderTopLeftRadius": "0px",
                    "borderTopRightRadius": "0px",
                    "borderBottomLeftRadius": "0px",
                    "borderBottomRightRadius": "0px",
                    "width": "80%",
                    "height": "auto",
                    "margin": "0 0 0 0",
                    "padding": "7px",
                    "zIndex": "12",
                    "top": "calc(100% - 100px)",
                    "left": "10%",
                    "fontFamily": "Verdana",
                    "fontSize": "24px",
                    "fontWeight": "normal",
                    "textAlign": "center",
                    "textDecoration": "none",
                    "color": "#fff"
                  },
                  "button": {
                    "name": "Knowledge Base",
                    "url": "/KnowledgeBase/",
                    "call": "arrows",
                    "text": "My Button",
                    "styl": {
                      "backgroundColorOver": "#fcfcfc",
                      "backgroundColorOut": "#00cc66",
                      "borderOver": "1px solid purple",
                      "borderOut": "1px solid #0291cd",
                      "textColorOver": "#00cc66",
                      "textColorOut": "white",
                      "backgroundColor": "transparent",
                      "border": "1px solid gray",
                      "borderRadius": "7px",
                      "width": "50px",
                      "height": "50px",
                      "margin": "0 1px 0 1px",
                      "padding": "0 0 0 0",
                      "position": "relative",
                      "float": "left",
                      "fontFamily": "0px",
                      "fontSize": "18px",
                      "fontWeight": "normal",
                      "textAlign": "center",
                      "textDecoration": "none",
                      "color": "#fff"
                    }
                  },
                  "circle": {
                    "backgroundColorOver": "#00cc66",
                    "backgroundColorOut": "#fcfcfc",
                    "borderOver": "2px solid #00cc66",
                    "borderOut": "2px solid #fcfcfc"
                  }
                },
                "styl": {
                  "category": {
                    "backgroundColor": "transparent",
                    "border": "0",
                    "width": "80%",
                    "height": "auto",
                    "zIndex": "10",
                    "top": "calc(53% - 1px)",
                    "topB": "calc(53% - 1px)",
                    "topC": "calc(53% - 1px)",
                    "topD": "calc(53% - 1px)",
                    "topE": "calc(53% - 1px)",
                    "left": "10%",
                    "fontFamily": "Verdana",
                    "fontSize": "24px",
                    "fontSizeB": "22px",
                    "fontSizeC": "18px",
                    "fontSizeD": "16px",
                    "fontSizeE": "14px",
                    "fontWeight": "normal",
                    "textAlign": "center",
                    "textDecoration": "none",
                    "color": "#fff"
                  },
                  "title": {
                    "backgroundColor": "transparent",
                    "border": "0",
                    "width": "80%",
                    "height": "auto",
                    "margin": "0 0 0 0",
                    "padding": "5px 0 5px 0",
                    "zIndex": "10",
                    "top": "calc(50% - 130px)",
                    "topB": "calc(50% - 110px)",
                    "topC": "calc(50% - 90px)",
                    "topD": "calc(50% - 70px)x",
                    "topE": "calc(50% - 55px)",
                    "left": "10%",
                    "fontFamily": "Verdana",
                    "fontSize": "120px",
                    "fontSizeB": "100px",
                    "fontSizeC": "80px",
                    "fontSizeD": "60px",
                    "fontSizeE": "40px",
                    "fontWeight": "normal",
                    "textAlign": "center",
                    "textDecoration": "none",
                    "color": "#fff"
                  }
                },
                "boolean": "1"
              },
              "alfa": {
                "backgroundColor": "transparent",
                "percent": "0",
                "decimal": "0.0",
                "width": "100%",
                "height": "100%",
                "top": "0px",
                "left": "0px"
              },
              "base": {
                "backgroundColor": "transparent",
                "border": "0",
                "zIndex": "11",
                "width": "100%",
                "height": "100%",
                "top": "0px",
                "left": "0px"
              },
              "info": {
                "call": "collage",
                "gridType": "Fixed",
                "gridBgColor": "transparent",
                "gridCall": "grid_cell",
                "cellCall": "container",
                "modCall": "fill_div",
                "cells": {
                  "call": "default",
                  "total": "1",
                  "across": "1"
                },
                "thumb": {
                  "diff": "0"
                },
                "cellBorder": {
                  "boolean": false,
                  "thickness": 0,
                  "color": "lightgray"
                },
                "gutter": {
                  "boolean": "0",
                  "size": "0"
                },
                "thresh": {
                  "across1": "501",
                  "across2": "801",
                  "across3": "1051"
                },
                "mask": {
                  "boolean": false,
                  "message": {
                    "boolean": false,
                    "backgroundColor": "#4CAF50",
                    "textColor": "white"
                  }
                },
                "view": "fullScreen",
                "opacity": "1.0",
                "webkitFilter": "grayscale(0%)",
                "filter": "grayscale(0%)"
              },
              "images": [
                {
                  "id": "20220622056237",
                  "title": "Slideshow-1600x700",
                  "text": "",
                  "image": {
                    "src": "https://theprofitcalculator-com.s3.amazonaws.com/admin/inventory/img/Slideshow-1600x700_sys20220622056237id_.jpg",
                    "w": "1600",
                    "h": "700"
                  }
                }
              ]
            },
            {
              "overlay": {
                "category": "Category 2",
                "title": "This is a really long long title to test the Billboard TItle Threshold",
                "button": {
                  "name": "Road Map",
                  "url": "/RoadMap/",
                  "style": {
                    "backgroundColorOver": "#fc0",
                    "backgroundColorOut": "#fcfcfc",
                    "borderOver": "1px solid purple",
                    "borderOut": "1px solid #0291cd",
                    "textColorOver": "black",
                    "textColorOut": "magenta"
                  }
                },
                "controls": {
                  "styl": {
                    "backgroundColor": "transparent",
                    "borderTop": "0",
                    "borderBottom": "0",
                    "borderLeft": "0",
                    "borderRight": "0",
                    "borderTopLeftRadius": "0px",
                    "borderTopRightRadius": "0px",
                    "borderBottomLeftRadius": "0px",
                    "borderBottomRightRadius": "0px",
                    "width": "80%",
                    "height": "auto",
                    "margin": "0 0 0 0",
                    "padding": "7px",
                    "zIndex": "12",
                    "top": "calc(100% - 100px)",
                    "left": "10%",
                    "fontFamily": "Verdana",
                    "fontSize": "24px",
                    "fontWeight": "normal",
                    "textAlign": "center",
                    "textDecoration": "none",
                    "color": "#fff"
                  },
                  "button": {
                    "name": "Road Map",
                    "url": "/RoadMap/",
                    "call": "arrows",
                    "text": "My Button",
                    "styl": {
                      "backgroundColorOver": "#fc0",
                      "backgroundColorOut": "#fcfcfc",
                      "borderOver": "1px solid purple",
                      "borderOut": "1px solid #0291cd",
                      "textColorOver": "black",
                      "textColorOut": "magenta",
                      "backgroundColor": "transparent",
                      "border": "1px solid gray",
                      "borderRadius": "7px",
                      "width": "50px",
                      "height": "50px",
                      "margin": "0 1px 0 1px",
                      "padding": "0 0 0 0",
                      "position": "relative",
                      "float": "left",
                      "fontFamily": "0px",
                      "fontSize": "18px",
                      "fontWeight": "normal",
                      "textAlign": "center",
                      "textDecoration": "none",
                      "color": "black"
                    }
                  },
                  "circle": {
                    "backgroundColorOver": "magenta",
                    "backgroundColorOut": "#fcfcfc",
                    "borderOver": "2px solid magenta",
                    "borderOut": "2px solid magenta"
                  }
                },
                "styl": {
                  "category": {
                    "backgroundColor": "transparent",
                    "border": "0",
                    "width": "80%",
                    "height": "auto",
                    "zIndex": "10",
                    "top": "calc(53% - 1px)",
                    "topB": "calc(53% - 1px)",
                    "topC": "calc(53% - 1px)",
                    "topD": "calc(53% - 1px)",
                    "topE": "calc(53% - 1px)",
                    "left": "10%",
                    "fontFamily": "Verdana",
                    "fontSize": "24px",
                    "fontSizeB": "22px",
                    "fontSizeC": "18px",
                    "fontSizeD": "16px",
                    "fontSizeE": "14px",
                    "fontWeight": "normal",
                    "textAlign": "center",
                    "textDecoration": "none",
                    "color": "#fff"
                  },
                  "title": {
                    "backgroundColor": "transparent",
                    "border": "0",
                    "width": "80%",
                    "height": "auto",
                    "margin": "0 0 0 0",
                    "padding": "5px 0 5px 0",
                    "zIndex": "10",
                    "top": "calc(50% - 130px)",
                    "topB": "calc(50% - 110px)",
                    "topC": "calc(50% - 90px)",
                    "topD": "calc(50% - 70px)x",
                    "topE": "calc(50% - 55px)",
                    "left": "10%",
                    "fontFamily": "Verdana",
                    "fontSize": "120px",
                    "fontSizeB": "100px",
                    "fontSizeC": "80px",
                    "fontSizeD": "60px",
                    "fontSizeE": "40px",
                    "fontWeight": "normal",
                    "textAlign": "center",
                    "textDecoration": "none",
                    "color": "#fff"
                  }
                },
                "boolean": "1"
              },
              "alfa": {
                "backgroundColor": "transparent",
                "percent": "0",
                "decimal": "0.0",
                "width": "100%",
                "height": "100%",
                "top": "opx",
                "left": "0px"
              },
              "base": {
                "backgroundColor": "transparent",
                "border": "0",
                "zIndex": "11",
                "width": "100%",
                "height": "100%",
                "top": "0px",
                "left": "0px"
              },
              "info": {
                "call": "collage",
                "gridType": "Fixed",
                "gridBgColor": "transparent",
                "gridCall": "grid_cell",
                "cellCall": "container",
                "modCall": "fill_div",
                "cells": {
                  "call": "default",
                  "total": "4",
                  "across": "4"
                },
                "thumb": {
                  "diff": "0"
                },
                "cellBorder": {
                  "boolean": false,
                  "thickness": 0,
                  "color": "lightgray"
                },
                "gutter": {
                  "boolean": "0",
                  "size": "0"
                },
                "thresh": {
                  "across1": "501",
                  "across2": "801",
                  "across3": "1051"
                },
                "mask": {
                  "boolean": false,
                  "message": {
                    "boolean": false,
                    "backgroundColor": "#4CAF50",
                    "textColor": "white"
                  }
                },
                "view": "fullScreen",
                "opacity": "0.7",
                "webkitFilter": "grayscale(0%)",
                "filter": "grayscale(0%)"
              },
              "images": [
                {
                  "id": "20210830123266",
                  "title": "ferrari-towards",
                  "text": "",
                  "image": {
                    "src": "https://theprofitcalculator-com.s3.amazonaws.com/admin/inventory/img/Ferrari/ferrari-towards_sys20210830123266id_.jpg",
                    "w": "640",
                    "h": "426"
                  }
                },
                {
                  "id": "20210830116601",
                  "title": "ferrari-fender-logo",
                  "text": "",
                  "image": {
                    "src": "https://theprofitcalculator-com.s3.amazonaws.com/admin/inventory/img/Ferrari/ferrari-fender-logo_sys20210830116601id_.jpg",
                    "w": "640",
                    "h": "424"
                  }
                },
                {
                  "id": "20210830121951",
                  "title": "ferrari-frontlight",
                  "text": "",
                  "image": {
                    "src": "https://theprofitcalculator-com.s3.amazonaws.com/admin/inventory/img/Ferrari/ferrari-frontlight_sys20210830121951id_.jpg",
                    "w": "640",
                    "h": "426"
                  }
                },
                {
                  "id": "20210830120730",
                  "title": "raceaway",
                  "text": "",
                  "image": {
                    "src": "https://theprofitcalculator-com.s3.amazonaws.com/admin/inventory/img/Ferrari/raceaway_sys20210830120730id_.jpg",
                    "w": "640",
                    "h": "426"
                  }
                },
                {
                  "id": "20210830116091",
                  "title": "formula1",
                  "text": "",
                  "image": {
                    "src": "https://theprofitcalculator-com.s3.amazonaws.com/admin/inventory/img/Ferrari/formula1_sys20210830116091id_.jpg",
                    "w": "640",
                    "h": "360"
                  }
                }
              ]
            }
        ],
        "recid": "20220621145936",
        "reckey": "rec-blogsegment-entry-20220621145936",
        "name": "TPC--Billboard_sys20220621145936id_.json"
    }





]

export default SceneInfo;
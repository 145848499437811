const SceneInfo = [


  {
    "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "30px",
      "marginBottom": "0px",
      "paddingTop": "0px",
      "paddingBottom": "0px",
      "backgroundColor": "transparent",
      "textColor": "black"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "AllText",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compStyl": {
        "heading": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "50px",
          "fontWeight": "550",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        },
        "pgraph": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "16px",
          "fontWeight": "500",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "heading",
            "data": "Samples Page"
          },
          {
            "call": "pgraph",
            "data": "This page shows various sample sections. I will continue to add to this for reference"
          }
        ]
      }
    ],
    "recid": "20210917176060",
    "reckey": "rec-blogsegment-entry-20210917176060",
    "name": "Sample--Page--HeadingPgraph_sys20210917176060id_.json"
  },










  //TM:

  {
    "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "0px",
      "marginBottom": "0px",
      "paddingTop": "0px",
      "paddingBottom": "0px",
      "backgroundColor": "transparent",
      "textColor": "black"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "HashList",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compData": {
        "id": "HashList"
      },
      "compStyl": {
        "box": {
          "backgroundColor": "#fcfcfc",
          "border": "1px solid #0291cd",
          "borderRadius": "7px",
          "width": "calc(100% - 50px)",
          "height": "#fcfcfc",
          "padding": "30px 15px 30px 30px",
          "margin": "0 0 0 0",
          "float": "left"
        },
        "title": {
          "textColor": "black",
          "fontSize": "24px"
        },
        "link": {
          "overColor": "darkslateblue",
          "outColor": "#0291cd",
          "fontSize": "18px"
        },
        "bullet": {
          "type": "square",
          "width": "16px",
          "height": "16px"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "HashList",
            "data": {
              "type": "",
              "title": "On This Page",
              "list": [
                {
                  "name": "Bullet List",
                  "value": "#HashSection1"
                },
                {
                  "name": "Numbered List",
                  "value": "#HashSection2"
                },
                {
                  "name": "Notice",
                  "value": "#HashSection3"
                },
                {
                  "name": "Checklist",
                  "value": "#HashSection4"
                },
                {
                  "name": "Breaker",
                  "value": "#HashSection5"
                },
                {
                  "name": "DynamicText",
                  "value": "#HashSection6"
                },
                {
                  "name": "MathLedger",
                  "value": "#HashSection7"
                },
                {
                  "name": "This is a section 8",
                  "value": "#HashSection8"
                }
              ]
            }
          }
        ]
      }
    ],
    "recid": "20210917177446",
    "reckey": "rec-blogsegment-entry-20210917177446",
    "name": "HashList_sys20210917177446id_.json"
  },








  //TM:

  {
    "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "50px",
      "marginBottom": "0px"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "HashSection",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compData": {
        "id": "HashSection1",
        "title": "Bullet List",
        "href": "#HashList"
      },
      "compStyl": {
        "bar": {
          "backgroundColor": "#edeef1",
          "border": "1px solid lightgray",
          "borderRadius": "7px",
          "width": "calc(100% - 14px)",
          "height": "auto",
          "padding": "10px 0 10px 15px",
          "margin": "0 0 0 0",
          "float": "left"
        },
        "btn": {
          "top": "5px",
          "right": "10px"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "HashSection",
            "data": {}
          }
        ]
      }
    ],
    "recid": "20210917180214",
    "reckey": "rec-blogsegment-entry-20210917180214",
    "name": "HashSection_sys20210917180214id_.json"
  },
  {
    "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "0px",
      "marginBottom": "0px",
      "paddingTop": "0px",
      "paddingBottom": "0px",
      "backgroundColor": "transparent",
      "textColor": "black"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "AllText",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compStyl": {
        "heading": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "50px",
          "fontWeight": "550",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        },
        "pgraph": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "16px",
          "fontWeight": "500",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "pgraph",
            "data": " <div style=\"font-family:Arial, Helvetica, sans-serif; font-size:24px; font-weight:bold; text-align:left; line-height:1.0; margin:15px 0 10px 0; padding:0 0 0 0px; width:calc(100%-0px); height:auto; background-color:transparent;\">Bullets:</div> "
          },
          {
            "call": "bullet",
            "data": [
              "Expense to Sales Ratio Calculation",
              "Owner / Operator Salary Calculation",
              "Owner/ Operator Production Hourly Rate Calculation",
              "Facility Costs",
              "Business Costs",
              "Payroll Costs",
              "Healthcare Costs",
              "Vehicle Costs",
              "Equipment Costs",
              "Indirect Labor Employee Salary Calculation"
            ]
          }
        ]
      }
    ],
    "recid": "20210917176883",
    "reckey": "rec-blogsegment-entry-20210917176883",
    "name": "Sample--Page--Bullets_sys20210917176883id_.json"
  },





  // TM:

  {
    "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "50px",
      "marginBottom": "0px"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "HashSection",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compData": {
        "id": "HashSection2",
        "title": "Numbered List",
        "href": "#HashList"
      },
      "compStyl": {
        "bar": {
          "backgroundColor": "#edeef1",
          "border": "1px solid lightgray",
          "borderRadius": "7px",
          "width": "calc(100% - 14px)",
          "height": "auto",
          "padding": "10px 0 10px 15px",
          "margin": "0 0 0 0",
          "float": "left"
        },
        "btn": {
          "top": "5px",
          "right": "10px"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "HashSection",
            "data": {}
          }
        ]
      }
    ],
    "recid": "20210917180214",
    "reckey": "rec-blogsegment-entry-20210917180214",
    "name": "HashSection_sys20210917180214id_.json"
  },
  {
    "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "0px",
      "marginBottom": "0px",
      "paddingTop": "0px",
      "paddingBottom": "0px",
      "backgroundColor": "transparent",
      "textColor": "black"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "AllText",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compStyl": {
        "heading": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "50px",
          "fontWeight": "550",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        },
        "pgraph": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "16px",
          "fontWeight": "500",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "pgraph",
            "data": " <div style=\"font-family:Arial, Helvetica, sans-serif; font-size:24px; font-weight:bold; text-align:left; line-height:1.0; margin:15px 0 10px 0; padding:0 0 0 0px; width:calc(100%-0px); height:auto; background-color:transparent;\">Numbers:</div> "
          },
          {
            "call": "numbrd",
            "data": [
              "Expense to Sales Ratio Calculation",
              "Owner / Operator Salary Calculation",
              "Owner/ Operator Production Hourly Rate Calculation",
              "Facility Costs",
              "Business Costs",
              "Payroll Costs",
              "Healthcare Costs",
              "Vehicle Costs",
              "Equipment Costs",
              "Indirect Labor Employee Salary Calculation"
            ]
          }
        ]
      }
    ],
    "recid": "20210917178535",
    "reckey": "rec-blogsegment-entry-20210917178535",
    "name": "Sample--Page--Numbers_sys20210917178535id_.json"
  },










  
  //TM: 


  {
    "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "50px",
      "marginBottom": "0px"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "HashSection",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compData": {
        "id": "HashSection3",
        "title": "Notice",
        "href": "#HashList"
      },
      "compStyl": {
        "bar": {
          "backgroundColor": "#edeef1",
          "border": "1px solid lightgray",
          "borderRadius": "7px",
          "width": "calc(100% - 14px)",
          "height": "auto",
          "padding": "10px 0 10px 15px",
          "margin": "0 0 0 0",
          "float": "left"
        },
        "btn": {
          "top": "5px",
          "right": "10px"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "HashSection",
            "data": {}
          }
        ]
      }
    ],
    "recid": "20210917180214",
    "reckey": "rec-blogsegment-entry-20210917180214",
    "name": "HashSection_sys20210917180214id_.json"
  },
  {
    "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "0px",
      "marginBottom": "0px",
      "paddingTop": "0px",
      "paddingBottom": "0px",
      "backgroundColor": "transparent",
      "textColor": "black"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "AllText",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compStyl": {
        "heading": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "50px",
          "fontWeight": "550",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        },
        "pgraph": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "16px",
          "fontWeight": "500",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "Notice",
            "data": {
              "box": {
                "background-color": "lightyellow",
                "border": "2px solid black",
                "width": "calc(100%-30px)",
                "height": "auto",
                "padding": "30px 15px 30px 30px",
                "margin": "0 0 0 0",
                "float": "left"
              },
              "lines": [
                {
                  "type": "text",
                  "background-color": "lightyellow",
                  "border": "2px solid black",
                  "width": "calc(100%-30px)",
                  "height": "auto",
                  "padding": "30px 15px 30px 30px",
                  "margin": "0 0 0 0",
                  "float": "left",
                  "text": "A company's financial reports are like the blood pressure reading for its owners, showing whether or not a company is in good financial health. One simple calculation provides a tremendous amount of insight. This is the expense to sales ratio."
                },
                {
                  "type": "text",
                  "background-color": "lightyellow",
                  "border": "2px solid black",
                  "width": "calc(100%-30px)",
                  "height": "auto",
                  "padding": "30px 15px 30px 30px",
                  "margin": "0 0 0 0",
                  "float": "left",
                  "text": "\"The expense ratio helps define the organization's financial health and its ability to scale operations up or down.\""
                },
                {
                  "type": "raw",
                  "background-color": "transparent",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 auto 0 0",
                  "font-family": "\"Times New Roman\", Times, serif",
                  "font-size": "18px",
                  "font-weight": "normal",
                  "font-style": "italic",
                  "text-align": "left",
                  "line-height": "1.4",
                  "text": "<a class=\"Anchor\" href=\"http://theprofitcalculator.com\" target=\"_blank\">theprofitcalculator.com</a><br /><a class=\"Anchor\" href=\"http://smallbusiness.chron.com\" target=\"_blank\">smallbusiness.chron.com</a>"
                }
              ]
            }
          }
        ]
      }
    ],
    "recid": "20210917172558",
    "reckey": "rec-blogsegment-entry-20210917172558",
    "name": "Sample--Page--Notice_sys20210917172558id_.json"
  },
  {
    "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "50px",
      "marginBottom": "0px"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "HashSection",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compData": {
        "id": "HashSection4",
        "title": "Checklist",
        "href": "#HashList"
      },
      "compStyl": {
        "bar": {
          "backgroundColor": "#edeef1",
          "border": "1px solid lightgray",
          "borderRadius": "7px",
          "width": "calc(100% - 14px)",
          "height": "auto",
          "padding": "10px 0 10px 15px",
          "margin": "0 0 0 0",
          "float": "left"
        },
        "btn": {
          "top": "5px",
          "right": "10px"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "HashSection",
            "data": {}
          }
        ]
      }
    ],
    "recid": "20210917180214",
    "reckey": "rec-blogsegment-entry-20210917180214",
    "name": "HashSection_sys20210917180214id_.json"
  },
  {
    "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "0px",
      "marginBottom": "0px",
      "paddingTop": "0px",
      "paddingBottom": "0px",
      "backgroundColor": "transparent",
      "textColor": "black"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "AllText",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compStyl": {
        "heading": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "50px",
          "fontWeight": "550",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        },
        "pgraph": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "16px",
          "fontWeight": "500",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "Checklist",
            "data": {
              "type": "bullet",
              "title": "My Checklist",
              "box": {
                "background-color": "lightyellow;",
                "border": "2px solid black;",
                "width": "calc(100%-30px);",
                "height": "auto;",
                "padding": "30px 15px 30px 30px;",
                "margin": "0 0 0 0;",
                "float": "left;"
              },
              "list": [
                "This is Bullet #1",
                "This is Bullet #2",
                "This is Bullet #3",
                "This is Bullet #4",
                "This is Bullet #5",
                "This is Bullet #6"
              ]
            }
          }
        ]
      }
    ],
    "recid": "20210917181694",
    "reckey": "rec-blogsegment-entry-20210917181694",
    "name": "Sample--Page--Checklist_sys20210917181694id_.json"
  },
  {
    "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "50px",
      "marginBottom": "0px"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "HashSection",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compData": {
        "id": "HashSection5",
        "title": "Breaker",
        "href": "#HashList"
      },
      "compStyl": {
        "bar": {
          "backgroundColor": "#edeef1",
          "border": "1px solid lightgray",
          "borderRadius": "7px",
          "width": "calc(100% - 14px)",
          "height": "auto",
          "padding": "10px 0 10px 15px",
          "margin": "0 0 0 0",
          "float": "left"
        },
        "btn": {
          "top": "5px",
          "right": "10px"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "HashSection",
            "data": {}
          }
        ]
      }
    ],
    "recid": "20210917180214",
    "reckey": "rec-blogsegment-entry-20210917180214",
    "name": "HashSection_sys20210917180214id_.json"
  },
  {
    "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "0px",
      "marginBottom": "0px",
      "paddingTop": "0px",
      "paddingBottom": "0px",
      "backgroundColor": "transparent",
      "textColor": "black"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "AllText",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compStyl": {
        "heading": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "50px",
          "fontWeight": "550",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        },
        "pgraph": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "16px",
          "fontWeight": "500",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "Breaker",
            "data": {
              "component": "Breaker",
              "width": "calc(100%-69%)",
              "height": "5px",
              "margin": "30px auto 25px auto",
              "padding": "0 0 0 0",
              "background-color": "#0291cd",
              "border": "1px solid #0291cd",
              "border-radius": "10px"
            }
          }
        ]
      }
    ],
    "recid": "20210917173999",
    "reckey": "rec-blogsegment-entry-20210917173999",
    "name": "Sample--Page--Breaker_sys20210917173999id_.json"
  },
  {
    "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "0px",
      "marginBottom": "0px"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "HashSection",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compData": {
        "id": "HashSection6",
        "title": "DynamicText",
        "href": "#HashList"
      },
      "compStyl": {
        "bar": {
          "backgroundColor": "#edeef1",
          "border": "1px solid lightgray",
          "borderRadius": "7px",
          "width": "calc(100% - 14px)",
          "height": "auto",
          "padding": "10px 0 10px 15px",
          "margin": "0 0 0 0",
          "float": "left"
        },
        "btn": {
          "top": "5px",
          "right": "10px"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "HashSection",
            "data": {}
          }
        ]
      }
    ],
    "recid": "20210917180214",
    "reckey": "rec-blogsegment-entry-20210917180214",
    "name": "HashSection_sys20210917180214id_.json"
  },
  {
    "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "0px",
      "marginBottom": "0px",
      "paddingTop": "0px",
      "paddingBottom": "0px",
      "backgroundColor": "transparent",
      "textColor": "black"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "AllText",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compStyl": {
        "heading": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "50px",
          "fontWeight": "550",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        },
        "pgraph": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "16px",
          "fontWeight": "500",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "DynamicText",
            "data": {
              "box": {
                "background-color": "transparent",
                "border": "0",
                "border-top": "3px solid purple",
                "border-right": "3px solid green",
                "border-bottom": "3px solid blue",
                "border-left": "3px solid red",
                "border-radius": "0px",
                "width": "calc(100%-36px)",
                "height": "auto",
                "padding": "30px 15px 30px 30px",
                "margin": "0 0 50px 0",
                "float": "left"
              },
              "lines": [
                {
                  "type": "text",
                  "background-color": "transparent",
                  "border": "0px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 auto 15px 0",
                  "float": "left",
                  "font-family": "Arial",
                  "font-size": "32px",
                  "font-weight": "550",
                  "font-style": "normal",
                  "text-align": "left",
                  "text-decoration": "none",
                  "color": "black",
                  "line-height": "1.4",
                  "text": "Dynamic Text Example"
                },
                {
                  "type": "text",
                  "background-color": "transparent",
                  "border": "0px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 auto 15px 0",
                  "float": "left",
                  "font-family": "Times",
                  "font-size": "18px",
                  "font-weight": "normal",
                  "font-style": "normal",
                  "text-align": "left",
                  "text-decoration": "none",
                  "color": "black",
                  "line-height": "1.4",
                  "text": "A company's financial reports are like the blood pressure reading for its owners, showing whether or not a company is in good financial health. One simple calculation provides a tremendous amount of insight. This is the expense to sales ratio."
                },
                {
                  "type": "text",
                  "background-color": "transparent",
                  "border": "0px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 auto 15px 0",
                  "float": "left",
                  "font-family": "Times",
                  "font-size": "18px",
                  "font-weight": "normal",
                  "font-style": "italic",
                  "text-align": "left",
                  "text-decoration": "none",
                  "color": "lightgray",
                  "line-height": "1.4",
                  "text": "A 2 company's financial reports are like the blood pressure reading for its owners, showing whether or not a company is in good financial health. One simple calculation provides a tremendous amount of insight. This is the expense to sales ratio."
                },
                {
                  "type": "raw",
                  "background-color": "transparent",
                  "border": "0px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 auto 0 0",
                  "font-family": "Times",
                  "font-size": "18px",
                  "font-weight": "normal",
                  "font-style": "italic",
                  "text-align": "left",
                  "text-decoration": "none",
                  "​color": "black",
                  "line-height": "1.4",
                  "text": "<a class=\"Anchor\" href=\"http://theprofitcalculator.com\" target=\"_blank\">theprofitcalculator.com</a><br /><a class=\"Anchor\" href=\"http://smallbusiness.chron.com\" target=\"_blank\">smallbusiness.chron.com</a>"
                }
              ]
            }
          }
        ]
      }
    ],
    "recid": "20210918035282",
    "reckey": "rec-blogsegment-entry-20210918035282",
    "name": "Sample--Page--DynamicText_sys20210918035282id_.json"
  },
  {
    "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "0px",
      "marginBottom": "0px",
      "paddingTop": "0px",
      "paddingBottom": "0px",
      "backgroundColor": "transparent",
      "textColor": "black"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "AllText",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compStyl": {
        "heading": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "50px",
          "fontWeight": "550",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        },
        "pgraph": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "16px",
          "fontWeight": "500",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "DynamicText",
            "data": {
              "box": {
                "background-color": "transparent",
                "border": "0",
                "border-top": "3px solid purple",
                "border-right": "3px solid green",
                "border-bottom": "3px solid blue",
                "border-left": "3px solid red",
                "border-radius": "15px",
                "width": "calc(100%-36px)",
                "height": "auto",
                "padding": "30px 15px 30px 30px",
                "margin": "0 0 50px 0",
                "float": "left"
              },
              "lines": [
                {
                  "type": "text",
                  "background-color": "transparent",
                  "border": "0px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 auto 15px 0",
                  "float": "left",
                  "font-family": "Arial, Helvetica, sans-serif",
                  "font-size": "32px",
                  "font-weight": "550",
                  "font-style": "normal",
                  "text-align": "left",
                  "text-decoration": "none",
                  "color": "black",
                  "line-height": "1.4",
                  "text": "Dynamic Text 2"
                },
                {
                  "type": "text",
                  "background-color": "transparent",
                  "border": "0px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 auto 15px 0",
                  "float": "left",
                  "font-family": "\"Times New Roman\", Times, serif",
                  "font-size": "18px",
                  "font-weight": "normal",
                  "font-style": "normal",
                  "text-align": "left",
                  "text-decoration": "none",
                  "color": "black",
                  "line-height": "1.4",
                  "text": "A company's financial reports are like the blood pressure reading for its owners, showing whether or not a company is in good financial health. One simple calculation provides a tremendous amount of insight. This is the expense to sales ratio."
                },
                {
                  "type": "text",
                  "background-color": "transparent",
                  "border": "0px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 auto 15px 0",
                  "float": "left",
                  "font-family": "\"Times New Roman\", Times, serif",
                  "font-size": "18px",
                  "font-weight": "normal",
                  "font-style": "italic",
                  "text-align": "left",
                  "text-decoration": "none",
                  "color": "lightgray",
                  "line-height": "1.4",
                  "text": "A 2 company's financial reports are like the blood pressure reading for its owners, showing whether or not a company is in good financial health. One simple calculation provides a tremendous amount of insight. This is the expense to sales ratio."
                },
                {
                  "type": "raw",
                  "background-color": "transparent",
                  "border": "0px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 auto 0 0",
                  "font-family": "\"Times New Roman\", Times, serif",
                  "font-size": "18px",
                  "font-weight": "normal",
                  "font-style": "italic",
                  "text-align": "left",
                  "text-decoration": "none",
                  "​color": "black",
                  "line-height": "1.4",
                  "text": "<a class=\"Anchor\" href=\"http://theprofitcalculator.com\" target=\"_blank\">theprofitcalculator.com</a><br /><a class=\"Anchor\" href=\"http://smallbusiness.chron.com\" target=\"_blank\">smallbusiness.chron.com</a>"
                }
              ]
            }
          }
        ]
      }
    ],
    "recid": "20210920174483",
    "reckey": "rec-blogsegment-entry-20210920174483",
    "name": "Sample--Page--DynamicText2_sys20210920174483id_.json"
  },
  {
    "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "0px",
      "marginBottom": "0px",
      "paddingTop": "0px",
      "paddingBottom": "0px",
      "backgroundColor": "transparent",
      "textColor": "black"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "AllText",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compStyl": {
        "heading": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "50px",
          "fontWeight": "550",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        },
        "pgraph": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "16px",
          "fontWeight": "500",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "DynamicText",
            "data": {
              "box": {
                "background-color": "transparent",
                "border": "0",
                "border-top": "5px solid purple",
                "border-right": "0px solid black",
                "border-bottom": "5px solid blue",
                "border-left": "0px solid black",
                "border-radius": "0px",
                "width": "calc(100%-30px)",
                "height": "auto",
                "padding": "30px 15px 30px 30px",
                "margin": "0 0 50px 0",
                "float": "left"
              },
              "lines": [
                {
                  "type": "text",
                  "background-color": "transparent",
                  "border": "0px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 auto 15px 0",
                  "float": "left",
                  "font-family": "Arial, Helvetica, sans-serif",
                  "font-size": "32px",
                  "font-weight": "550",
                  "font-style": "normal",
                  "text-align": "left",
                  "text-decoration": "none",
                  "color": "black",
                  "line-height": "1.4",
                  "text": "Dynamic Text 3"
                },
                {
                  "type": "text",
                  "background-color": "transparent",
                  "border": "0px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 auto 15px 0",
                  "float": "left",
                  "font-family": "Times",
                  "font-size": "18px",
                  "font-weight": "normal",
                  "font-style": "normal",
                  "text-align": "left",
                  "text-decoration": "none",
                  "color": "black",
                  "line-height": "1.4",
                  "text": "A company's financial reports are like the blood pressure reading for its owners, showing whether or not a company is in good financial health. One simple calculation provides a tremendous amount of insight. This is the expense to sales ratio."
                },
                {
                  "type": "text",
                  "background-color": "transparent",
                  "border": "0px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 auto 15px 0",
                  "float": "left",
                  "font-family": "\"Times New Roman\", Times, serif",
                  "font-size": "18px",
                  "font-weight": "normal",
                  "font-style": "italic",
                  "text-align": "left",
                  "text-decoration": "none",
                  "color": "lightgray",
                  "line-height": "1.4",
                  "text": "A 2 company's financial reports are like the blood pressure reading for its owners, showing whether or not a company is in good financial health. One simple calculation provides a tremendous amount of insight. This is the expense to sales ratio."
                },
                {
                  "type": "raw",
                  "background-color": "transparent",
                  "border": "0px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 auto 0 0",
                  "font-family": "\"Times New Roman\", Times, serif",
                  "font-size": "18px",
                  "font-weight": "normal",
                  "font-style": "italic",
                  "text-align": "left",
                  "text-decoration": "none",
                  "​color": "black",
                  "line-height": "1.4",
                  "text": "<a class=\"Anchor\" href=\"http://theprofitcalculator.com\" target=\"_blank\">theprofitcalculator.com</a><br /><a class=\"Anchor\" href=\"http://smallbusiness.chron.com\" target=\"_blank\">smallbusiness.chron.com</a>"
                }
              ]
            }
          }
        ]
      }
    ],
    "recid": "20210920174372",
    "reckey": "rec-blogsegment-entry-20210920174372",
    "name": "Sample--Page--DynamicText3_sys20210920174372id_.json"
  },
  {
    "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "0px",
      "marginBottom": "0px",
      "paddingTop": "0px",
      "paddingBottom": "0px",
      "backgroundColor": "transparent",
      "textColor": "black"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "AllText",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compStyl": {
        "heading": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "50px",
          "fontWeight": "550",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        },
        "pgraph": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "16px",
          "fontWeight": "500",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "DynamicText",
            "data": {
              "box": {
                "background-color": "transparent",
                "border": "0",
                "border-top": "0px solid purple",
                "border-right": "5px solid green",
                "border-bottom": "0px solid blue",
                "border-left": "5px solid red",
                "border-radius": "0px",
                "width": "calc(100%-30px)",
                "height": "auto",
                "padding": "30px 15px 30px 30px",
                "margin": "0 0 50px 0",
                "float": "left"
              },
              "lines": [
                {
                  "type": "text",
                  "background-color": "transparent",
                  "border": "0px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 auto 15px 0",
                  "float": "left",
                  "font-family": "Arial",
                  "font-size": "32px",
                  "font-weight": "550",
                  "font-style": "normal",
                  "text-align": "left",
                  "text-decoration": "none",
                  "color": "black",
                  "line-height": "1.4",
                  "text": "Dynamic Text 4"
                },
                {
                  "type": "text",
                  "background-color": "transparent",
                  "border": "0px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 auto 15px 0",
                  "float": "left",
                  "font-family": "Times",
                  "font-size": "18px",
                  "font-weight": "normal",
                  "font-style": "normal",
                  "text-align": "left",
                  "text-decoration": "none",
                  "color": "black",
                  "line-height": "1.4",
                  "text": "A company's financial reports are like the blood pressure reading for its owners, showing whether or not a company is in good financial health. One simple calculation provides a tremendous amount of insight. This is the expense to sales ratio."
                },
                {
                  "type": "text",
                  "background-color": "transparent",
                  "border": "0px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 auto 15px 0",
                  "float": "left",
                  "font-family": "Times",
                  "font-size": "18px",
                  "font-weight": "normal",
                  "font-style": "italic",
                  "text-align": "left",
                  "text-decoration": "none",
                  "color": "lightgray",
                  "line-height": "1.4",
                  "text": "A 2 company's financial reports are like the blood pressure reading for its owners, showing whether or not a company is in good financial health. One simple calculation provides a tremendous amount of insight. This is the expense to sales ratio."
                },
                {
                  "type": "raw",
                  "background-color": "transparent",
                  "border": "0px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 auto 0 0",
                  "font-family": "Times",
                  "font-size": "18px",
                  "font-weight": "normal",
                  "font-style": "italic",
                  "text-align": "left",
                  "text-decoration": "none",
                  "​color": "black",
                  "line-height": "1.4",
                  "text": "<a class=\"Anchor\" href=\"http://theprofitcalculator.com\" target=\"_blank\">theprofitcalculator.com</a><br /><a class=\"Anchor\" href=\"http://smallbusiness.chron.com\" target=\"_blank\">smallbusiness.chron.com</a>"
                }
              ]
            }
          }
        ]
      }
    ],
    "recid": "20210920173294",
    "reckey": "rec-blogsegment-entry-20210920173294",
    "name": "Sample--Page--DynamicText4_sys20210920173294id_.json"
  },
  {
    "styl": {
      "call": "indent",
      "class": "IndentRowCompact",
      "marginTop": "50px",
      "marginBottom": "0px"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "HashSection",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compData": {
        "id": "HashSection7",
        "title": "MathLedger",
        "href": "#HashList"
      },
      "compStyl": {
        "bar": {
          "backgroundColor": "#edeef1",
          "border": "1px solid lightgray",
          "borderRadius": "7px",
          "width": "calc(100% - 14px)",
          "height": "auto",
          "padding": "10px 0 10px 15px",
          "margin": "0 0 0 0",
          "float": "left"
        },
        "btn": {
          "top": "5px",
          "right": "10px"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "HashSection",
            "data": {}
          }
        ]
      }
    ],
    "recid": "20210917180214",
    "reckey": "rec-blogsegment-entry-20210917180214",
    "name": "HashSection_sys20210917180214id_.json"
  },
  {
    "styl": {
      "call": "indnent",
      "class": "IndentRowCompact",
      "marginTop": "0px",
      "marginBottom": "0px",
      "paddingTop": "0px",
      "paddingBottom": "0px",
      "backgroundColor": "transparent",
      "textColor": "black"
    },
    "call": "TxtComponent",
    "info": {
      "call": "flex",
      "gridType": "AllText",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "default",
      "modCall": "fill_div",
      "thumb": {
        "call": "default",
        "diff": "0"
      },
      "cellBorder": {
        "boolean": true,
        "thickness": 1,
        "color": "gray"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "compStyl": {
        "heading": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "50px",
          "fontWeight": "550",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        },
        "pgraph": {
          "fontFamily": "Arial, Helvetica, sans-serif",
          "fontSize": "16px",
          "fontWeight": "500",
          "textAlign": "left",
          "textDecoration": "none",
          "color": "black"
        }
      }
    },
    "items": [
      {
        "call": "cke",
        "data": [
          {
            "call": "MathLedger",
            "data": {
              "box": {
                "background-color": "transparent",
                "border": "0px solid black",
                "border-top": "false",
                "border-right": "false",
                "border-bottom": "false",
                "border-left": "false",
                "border-radius": "0px",
                "width": "450px",
                "height": "auto",
                "padding": "0 0 0 0",
                "margin": "75px 0 100px calc(50% - 225px)",
                "float": "left"
              },
              "lines": [
                {
                  "type": "text",
                  "background-color": "transparent",
                  "border": "0",
                  "border-top": "false",
                  "border-right": "false",
                  "border-bottom": "false",
                  "border-left": "false",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 0 10px 0",
                  "float": "left",
                  "font-family": "Arial, Helvetica, sans-serif",
                  "font-size": "32px",
                  "font-weight": "550",
                  "font-style": "normal",
                  "text-align": "left",
                  "text-decoration": "none",
                  "color": "black",
                  "line-height": "1.4",
                  "text": "Math Ledger"
                },
                {
                  "type": "ledgerline",
                  "background-color": "transparent",
                  "border": "0",
                  "border-top": "1px solid black",
                  "border-right": "1px solid black",
                  "border-bottom": "0",
                  "border-left": "1px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 0 0 0",
                  "float": "left",
                  "font-family": "Arial",
                  "font-size": "18px",
                  "font-weight": "550",
                  "font-style": "normal",
                  "text-align": "left",
                  "text-decoration": "none",
                  "color": "black",
                  "line-height": "1.4",
                  "text": {
                    "name": "Price",
                    "value": "$2000.00"
                  }
                },
                {
                  "type": "ledgerline",
                  "background-color": "transparent",
                  "border": "0",
                  "border-top": "1px solid black",
                  "border-right": "1px solid black",
                  "border-bottom": "0",
                  "border-left": "1px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 0 0 0",
                  "float": "left",
                  "font-family": "Arial, Helvetica, sans-serif",
                  "font-size": "18px",
                  "font-weight": "550",
                  "font-style": "normal",
                  "text-align": "left",
                  "text-decoration": "none",
                  "color": "black",
                  "line-height": "1.4",
                  "text": {
                    "name": "Cost Of Goods",
                    "value": "$1000.00"
                  }
                },
                {
                  "type": "ledgerline",
                  "background-color": "transparent",
                  "border": "0",
                  "border-top": "1px solid black",
                  "border-right": "1px solid black",
                  "border-bottom": "false",
                  "border-left": "1px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 0 0 0",
                  "float": "left",
                  "font-family": "Arial, Helvetica, sans-serif",
                  "font-size": "18px",
                  "font-weight": "550",
                  "font-style": "normal",
                  "text-align": "left",
                  "text-decoration": "none",
                  "color": "black",
                  "line-height": "1.4",
                  "text": {
                    "name": "Overhead",
                    "value": "$100.00"
                  }
                },
                {
                  "type": "ledgerline",
                  "background-color": "transparent",
                  "border": "0",
                  "border-top": "1px solid black",
                  "border-right": "1px solid black",
                  "border-bottom": "false",
                  "border-left": "1px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 0 0 0",
                  "float": "left",
                  "font-family": "Arial, Helvetica, sans-serif",
                  "font-size": "18px",
                  "font-weight": "550",
                  "font-style": "normal",
                  "text-align": "left",
                  "text-decoration": "none",
                  "color": "black",
                  "line-height": "1.4",
                  "text": {
                    "name": "Sales Commission",
                    "value": "$3.00"
                  }
                },
                {
                  "type": "ledgerline",
                  "background-color": "transparent",
                  "border": "0",
                  "border-top": "1px solid black",
                  "border-right": "1px solid black",
                  "border-bottom": "1px solid black",
                  "border-left": "1px solid black",
                  "border-radius": "0px",
                  "width": "100%",
                  "height": "auto",
                  "padding": "0 0 0 0",
                  "margin": "0 0 0 0",
                  "float": "left",
                  "font-family": "Arial, Helvetica, sans-serif",
                  "font-size": "18px",
                  "font-weight": "550",
                  "font-style": "normal",
                  "text-align": "left",
                  "text-decoration": "none",
                  "color": "black",
                  "line-height": "1.4",
                  "text": {
                    "name": "Net Profit",
                    "value": "$55.00"
                  }
                }
              ]
            }
          }
        ]
      }
    ],
    "recid": "20210920178011",
    "reckey": "rec-blogsegment-entry-20210920178011",
    "name": "Sample--Page--MathLedger_sys20210920178011id_.json"
  }
];


export default SceneInfo;



import styled from "styled-components";
//import px2vw from "utils/px2vw";



export const Alfa = styled.div.attrs(props => ({


    backgroundColor:    props.backgroundColor || "#1a1926",//#fcfcfc
    border:             props.border || "0",
    borderRadius:       props.borderRadius || "0",

    percent:           props.percent || "60", 
    decimal:           props.decimal || "0.6",


    width:            props.width || "100%", 
    height:           props.height || "100%",

    zIndex:           props.zIndex || "1",
    top:              props.top || "0px", 
    left:             props.left || "0px"


}))`
  

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};

    width:${props => props.width};
    height:${props => props.height};

    padding :0 0 0 0; 
    margin  :0 0 0 0;

    position:absolute;   
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};

    -ms-filter: "progid:pxImageTransform.Microsoft.Alpha(Opacity=${props => props.percent})";
    filter : alpha(opacity=${props => props.percent});
    -moz-opacity : ${props => props.decimal};
    -khtml-opacity : ${props => props.decimal};
    opacity : ${props => props.decimal};


`;


export const Base = styled.div.attrs(props => ({


    backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
    border:             props.border || "0",
    borderRadius:       props.borderRadius || "0",

    width:            props.width || "100%", 
    height:           props.height || "100%",

    zIndex:           props.zIndex || "2", 
    top:              props.top || "0", 
    left:             props.left || "0"


}))`
  

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};


    width:${props => props.width};
    height:${props => props.height};

    padding :0 0 0 0; 
    margin  :0 0 0 0;


    position:absolute;   
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};


    overflow-x:hidden;
    overflow-y:auto;


`;









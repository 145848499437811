import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';



//import '../../StyleSheets/dynamic-form.css';
import 'app-style/dynamic-form/style.css';


import {
    LibString
} from 'library';

const FFedittitle = (props) => {

    const refLabelInput = useRef(null);

    const [labelVal, setLabelVal] = useState('');   
    const [labelValShort, setLabelValShort] = useState('');
    const [labelValLong, setLabelValLong] = useState('');

    useEffect(() => {

        let label = props.data.text;
        setLabelVal(label);
        var shortLabel = LibString.InputMaxlength({
            str       :label,
            maxlength :29
        });
        if(label.length > 29) shortLabel = shortLabel+"...";
        setLabelValShort(shortLabel);
        setLabelValLong(label);


     },[props])
 

    //<input name="firstName" onChange={e => setFirstName(e.target.value)} />
     


    //=========================================
    //state / effect
    //=========================================

    const [labelInputView, setLabelInputView] = useState(false);
    const [labelInputSelect, setLabelInputSelect] = useState(false);

    useEffect(() => {
 
        if(labelInputView && !labelInputSelect)
        {
            setLabelInputSelect(true);
            refLabelInput.current.select();
        }

        setLabelVal(props.data.text);

     },[
         props,
         labelInputView,
         labelInputSelect,
         refLabelInput

    ])
 


    //=========================================
    //showLabelInput
    //=========================================

    function showLabelInput(hndl) {

        console.log(JSON.stringify(hndl,null,2));
        setLabelInputView(true);

    }

    function handleChangeLabel(e) {

        //console.log("ChangeLabel");

        if(e.target.id.match(/labelInput/gi)) setLabelVal(e.target.value);
     
        var shortVal = '';
        var longVal = '';
        var targetVal;
        targetVal = e.target.value;
        if(targetVal === undefined
        || targetVal === '' 
        // || isNaN(targetVal)
        )
        {
            targetVal = ''; 
        }


        if(e.target.id.match(/labelInput/gi)) 
        {
            longVal = targetVal;
            shortVal = LibString.InputMaxlength({
                str       :targetVal,
                maxlength :29
            });
            if(longVal.length > 29) shortVal = shortVal+"...";
            //console.log(shortVal);
            setLabelValShort(shortVal+".."); 
            setLabelValLong(longVal);
            setLabelVal(longVal);
        }

    }


    function handleFocusLabel(target) {

        target.select();
    }

    function handleBlurLabel(e) {

        var targetVal;
        targetVal = e.target.value;
        if(targetVal === undefined
        || targetVal === '' 
        // || isNaN(targetVal)
        )
        {
            targetVal = 'New Title'; 
        }

        // console.log(targetVal);
        // console.log(labelValShort);
        // console.log(labelValLong);
        // console.log(labelVal);

        setLabelInputSelect(false);   
        setLabelInputView(false);
        setLabelVal(labelValShort);

        props.handle({

            call        :'edit',
            fieldCall   :"edittitle",
            key         :props.indx,
            id          :e.target.id,
            data        :props.data,
            value       :labelValLong
        
        });

 
    }








    // -- display -- //

    return (

        <div 
        key={props.indx+"_ffedittitle"} 
        className = "edittitle"
        >

            {
            labelInputView
            ?

                <div 
                className="label-wrap"
                >

                    <input
                        ref={refLabelInput}
                        type="text"
                        className="input-tag"
                        style={{
                            //backgroundColor:fieldStyl.bgColor,
                            //fontStyle:fieldStyl.fontStyle,
                            //color:fieldStyl.fontColor
                        }}

                        id={"labelInput-"+props.ij}
                        value={labelVal}
                        placeholder={props.data.text}
                        onChange={handleChangeLabel}
                        onFocus = {event => {
                            // event properties must be copied to use async
                            const target = event.target;
                            setTimeout(() => handleFocusLabel(target), 0);//set with a timeout to fix safari browser
                        }}
                        onBlur={handleBlurLabel} 

            
                    />

                </div>


            :

                <div 
                className="label"
                onClick={(v) => showLabelInput()}
                >
                    {labelValShort}

                </div>
            
            }
            
        </div>

    )

}



export {
  
    FFedittitle
}


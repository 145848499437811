
import styled from "styled-components";
//import px2vw from "utils/px2vw";

/*
https://styled-components.com/docs


Over 200 classes were generated for component styled.div with the id of "sc-AxirZ".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:

const Component = styled.div.attrs({
    style: ({ background }) => {
        background,
    },
})`width: 100%;`

<Component />


*/



export const GroupFixed = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    width:             props.width || "calc(100% - 10px)",
    //width:               "calc(100vw - 10px",
    maxWidth:           "100%",

    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    top:                props.top  || "-250px",// 0px or -200ox


    //TM-Note dynamic settings on a position:fixed div causes the error
    //"Over 200 classes were generated..."
    // My fix was to bypass dynamic settings


}))`


    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    max-width:${props => props.maxWidth};

    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    font-size: calc(10px + 2vmin);
    color: white;

    position:fixed;
    top:${props => props.top};
    left:0;
    z-index:100;

    overflow:hidden;


`;







export const GroupAbsolute = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    top:                props.top  || "-100px",


  }))`


    background-color:${props => props.backgroundColor};
    width:${props => props.width};

    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    font-size: calc(10px + 2vmin);
    color: white;

    position:absolute;
    top:${props => props.top};
    left:0;
    z-index:100;


    overflow:hidden;


`;





export const GroupRelative = styled.div.attrs(props => ({

  backgroundColor:    props.backgroundColor || "transparent",
  width:              props.width || "100%",
  height:             props.height || "auto",
  padding:            props.padding || "0 0 0 0",
  margin:             props.margin  || "0 0 0 0",

  top:                props.top  || "-100px",



}))`

  background-color:${props => props.backgroundColor};
  width:${props => props.width};
  height:${props => props.height};
  padding:${props => props.padding};
  margin:${props => props.margin};

  font-size: calc(10px + 2vmin);
  color: white;


  position:relative;
  float:left;

  overflow:hidden;

`;






export const Member = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"


  }))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    font-size: calc(10px + 2vmin);
    color: white;


    position:relative;
    float:left;

`;






import React, { 

    useState, 
    useEffect, 
    //useRef,
    //useMemo

} from 'react';

// import { 
//     useSelector, 
//     useDispatch 
// } 
// from 'react-redux';
// import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';




import {TemperatureGauge} from 'utils/components/Charts/Gauge';

export const SVG_OVERHEAD = (props) => {


	const [isLoaded, setIsLoaded] = useState(false);
    const [gaugeSize, setGaugeSize] = useState(false);
  
    useEffect(() => {


		if(!isLoaded)
		{
			setIsLoaded(true);
		}
		
		if(props.svgWidth && !gaugeSize)
		{
			//alert(props.svgWidth);
			setGaugeSize(props.svgHeight);
			//setGaugeSize(205)
		}


    },
    [
  
        props,
        //zoneObj,
        isLoaded,
		gaugeSize
  
    ])




	if(!gaugeSize)
	{

		return(<div>


			<TemperatureGauge
																		
				refid={"gauge"}
				id={"overhead_gauge"}
				lessThanThresh={false}    
				data={{
	
					backgroundColor:"transparent",
					gaugeSize:100,
					margin:"0 0 0 0",

					gaugeBackgroundColor : false,
					gaugeBackgroundWidth : false, //85,
			
					gaugePercentageColor : "#c00",
					gaugePercentageWidth : false, //75,
					gaugePercent : 45.0,
			
					needleCenterColor : "#ecedef", //"black",
					needleColor : "#ecedef", //"black",
					needleSize : false, //"165",
					needleRotation  : -100,
			
					colors: "GaugeColorsB"

				}}
	
				
	
			/>
	
	
		</div>);//return


	}else{


		return(<div>


			<TemperatureGauge
																		
				refid={"gauge"}
				id={"overhead_gauge"}
				lessThanThresh={false}    
				data={{

					backgroundColor:"transparent",
					gaugeSize:gaugeSize,
					margin:"0 0 0 0",

					gaugeBackgroundColor : false,
					gaugeBackgroundWidth : false, //85,
			
					gaugePercentageColor : "#c00",
					gaugePercentageWidth : false, //75,
					gaugePercent : 45.0,
			
					needleCenterColor : "#ecedef", //"black",
					needleColor : "#ecedef", //"black",
					needleSize : false, //"165",
					needleRotation  : -100,
			
					colors: "GaugeColorsB"
	
				}}
			
			/>
	
	
		</div>);//return

	}


}
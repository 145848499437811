
import styled from "styled-components";
//import px2vw from "utils/px2vw";

/*
https://styled-components.com/docs


https://stackoverflow.com/questions/55906668/converting-css-with-nested-classes-to-styled-components-problem


Over 200 classes were generated for component styled.div with the id of "sc-AxirZ".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:

const Component = styled.div.attrs({
    style: ({ background }) => {
        background,
    },
})`width: 100%;`

<Component />




calc(100% - 300px)


TM - Notes 

- ScrollBox calc(100% - 120px) 120px equals the HeaderNav height + InfoBar height
- Use vh - viewport height - to set the stage height




https://stackoverflow.com/questions/1216114/how-can-i-make-a-div-stick-to-the-top-of-the-screen-once-its-been-scrolled-to




*/


export const ScrollUpButton = styled.div.attrs(props => ({


  arrowSize:          props.arrowSize || 14,
  arrowOpacity:       props.arrowOpacity || "1.0",
  arrowShowColor:     props.arrowShowColor || "#edeef1",
  arrowHideColor:     props.arrowHideColor || "#141820",

  widthInt:           props.widthInt || false,



  backgroundColor:    props.backgroundColor || "#141820",
  border:             props.border || "1px solid #fff",
  borderRadius:       props.borderRadius || "50%",

  width:              props.width || "40px",
  height:             props.height || "40px",
  padding:            props.padding || "10px 10px 10px 10px",
  margin:             props.margin  || "0 0 0 0",

  zIndex:             props.zIndex || "98",
  bottom:             props.bottom || "25px",
  right:              props.right  || "40px",



  // writing-mode: vertical-lr;
  // text-orientation: upright;


  opacity:            props.opacity || "1.0"


  // div {
  //     display: none;
  //     transition: opacity 1s ease-out;
  //     opacity: 0;
  // }
  // div.active {
  //     opacity: 1;
  //     display: block;
  // }




  // transition: opacity 0.9s;
  // opacity:${props => props.opacity};



}))`

  background-color:${props => props.backgroundColor};
  border:${props => props.border};
  border-radius:${props => props.borderRadius};

  width:${props => props.width};
  height:${props => props.height};
  padding:${props => props.padding};
  margin:${props => props.margin};

  position:absolute;  
  z-index:${props => props.zIndex};
  bottom:${props => props.bottom};
  right:${props => props.right};




  @media (max-width: 1000000px) {

    bottom:25px;
    right:40px;
}
  @media (max-width: 1050px) {

    bottom:20px;
    right:10px;

  }





  .Button{

    background-color:#0291cd;
    border-radius:2px;

    width:auto;
    height:auto;
    margin:0 0 0 0;
    padding:7px 0 7px 0;

    font-family:Arial, Helvetica, sans-serif;      
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    text-decoration:none;
    color:white;

    cursor:pointer;

  }






  .ArrowHolder {

    background-color:transparent; 
    width:auto;
    height:auto;
    margin: 0 0 0 0;
    padding:0 0 0 0;
    position:relative;
    float:left;

}


    .ArrowHolder .arrow {
      border: solid yellow;
      border-width: 0 15px 4px 0;
      display: inline-block;
      padding: 3px;
    }
    
    .ArrowHolder .right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    
    .ArrowHolder .left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
    
    .ArrowHolder .up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
    
    .ArrowHolder .down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }









  .DirectionalButton{

    background-color:transparent; 

    min-width:40px;
    width:auto;
    height:auto;

    margin: 0 0 0 0;
    padding:0 0 0 0;

    position:relative;
    float:left;

    cursor:pointer;

}




    .DirectionalButton .DirectionalArrowsUp {

      background:transparent;
      width: auto;
      height: auto;
      margin-left:${props => props.arrowSize/2 + 4}px;
      margin-top:5px;
      padding-bottom:${props => props.arrowSize/2}px;
      position: relative;
      float:left;


      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);


    }

      .DirectionalButton .DirectionalArrowsUp .Arrow {

          background:${props => props.arrowShowColor};
          width: 6px;
          height: ${props => props.arrowSize*2}px;
          margin-right:${props => props.arrowSize/2}px;
          position: relative;
          float:right;

      }
      .DirectionalButton .DirectionalArrowsUp .Arrow:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 0;
          border-left: ${props => props.arrowSize}px solid ${props => props.arrowHideColor};
          border-top: ${props => props.arrowSize}px solid transparent;
          border-bottom: ${props => props.arrowSize}px solid transparent;

          opacity:${props => props.arrowOpacity};

      }
      .DirectionalButton .DirectionalArrowsUp .Arrow:before {
          content: "";
          position: absolute;
          right: -${props => props.arrowSize}px;
          bottom: 0;
          width: 0;
          height: 0;
          border-left: ${props => props.arrowSize}px solid ${props => props.arrowShowColor};
          border-top: ${props => props.arrowSize}px solid transparent;
          border-bottom: ${props => props.arrowSize}px solid transparent;

          opacity:${props => props.arrowOpacity};

      }






    .DirectionalButton .DirectionalArrowsLeft {

        background:transparent;
        width: auto;
        height: auto;
        margin-top:${props => props.arrowSize/2 + 4}px;
        margin-left:2px;
        padding-left:${props => props.arrowSize/2}px;
        position: relative;
        float:left;


    }
        .DirectionalButton .DirectionalArrowsLeft .Arrow {

            background:${props => props.arrowShowColor};
            width: 5px;
            height: ${props => props.arrowSize*2}px;
            margin-left:${props => props.arrowSize/2}px;
            position: relative;
            float:left;

        }
        .DirectionalButton .DirectionalArrowsLeft .Arrow:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            width: 0;
            height: 0;
            border-right: ${props => props.arrowSize}px solid ${props => props.arrowHideColor};
            border-top: ${props => props.arrowSize}px solid transparent;
            border-bottom: ${props => props.arrowSize}px solid transparent;

            opacity:${props => props.arrowOpacity};

        }
        .DirectionalButton .DirectionalArrowsLeft .Arrow:before {
            content: "";
            position: absolute;
            left: -${props => props.arrowSize}px;
            bottom: 0;
            width: 0;
            height: 0;
            border-right: ${props => props.arrowSize}px solid ${props => props.arrowShowColor};
            border-top: ${props => props.arrowSize}px solid transparent;
            border-bottom: ${props => props.arrowSize}px solid transparent;


            opacity:${props => props.arrowOpacity};

        }
        







    .DirectionalButton .DirectionalArrowsRight {

        background:transparent;
        width: auto;
        height: auto;
        margin-top:${props => props.arrowSize/2 + 4}px;
        margin-left:0px;
        padding-right:${props => props.arrowSize/2}px;
        position: relative;
        float:left;
    }
        .DirectionalButton .DirectionalArrowsRight .Arrow {

            background:${props => props.arrowShowColor};
            width: 5px;
            height: ${props => props.arrowSize*2}px;
            margin-right:${props => props.arrowSize/2}px;
            position: relative;
            float:right;

        }
        .DirectionalButton .DirectionalArrowsRight .Arrow:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 0;
            border-left: ${props => props.arrowSize}px solid ${props => props.arrowHideColor};
            border-top: ${props => props.arrowSize}px solid transparent;
            border-bottom: ${props => props.arrowSize}px solid transparent;

            opacity:${props => props.arrowOpacity};

        }
        .DirectionalButton .DirectionalArrowsRight .Arrow:before {
            content: "";
            position: absolute;
            right: -${props => props.arrowSize}px;
            bottom: 0;
            width: 0;
            height: 0;
            border-left: ${props => props.arrowSize}px solid ${props => props.arrowShowColor};
            border-top: ${props => props.arrowSize}px solid transparent;
            border-bottom: ${props => props.arrowSize}px solid transparent;

            opacity:${props => props.arrowOpacity};

        }
        









    .DirectionalButton .SingleButton {

        background:transparent;

        border-top:2px solid #454d5f;
        border-bottom:2px solid #454d5f;
        border-right:2px solid #454d5f;

        width:calc(100% - ${props => props.arrowSize}px);
        height: auto;
        margin:0 0 0 0;
        padding:12px 0 10px 0;
        position: relative;
        float:left;

        font-family:Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight:500;
        text-align:center;
        color:#edeef1;

        cursor:pointer;

    }





    .DirectionalButton .DualButton {

        background:transparent;

        border-top:2px solid #454d5f;
        border-bottom:2px solid #454d5f;
    
        width:calc(100% - ${props => props.arrowSize*5}px);
        height: auto;
        margin:0 0 0 0;
        padding:12px 0 10px 0;
        position: relative;
        float:left;

        font-family:Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight:500;
        text-align:center;
        color:#edeef1;

        cursor:pointer;

    }











`;






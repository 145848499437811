import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';

import * as serviceWorker from './serviceWorker';

import store from './app-store/store';

import { 
    Provider, 
    //ReactReduxContext 
} from 'react-redux';





// //---------------------------------
// // matomo
// //---------------------------------

// import { 
//     MatomoProvider, 
//     createInstance 
// } from '@datapunt/matomo-tracker-react'


// //---------------------------------
// // Fingerprint
// //---------------------------------

// // Must be last in the chain but before instance
// // dust is an object returned by Fingerprint

// import { Fingerprint } from 'utils/components/Fingerprint';
// let dust = Fingerprint();

// // alert("dust fingerprint: \n"+JSON.stringify(dust,null,2));
// // alert("" 
// //     + "\ndust.user: "+dust.user 
// //     + "\ndust.isMobile: "+dust.isMobile
// //     + "\ndust.agent: "+dust.agent
// // );


// //---------------------------------
// // matomo instance
// //---------------------------------

// //matomo-start
// const instance = createInstance({
//     urlBase: 'http://theprofitcalculator.com',
//     siteId: 2,
//     //userId: 'tpc_user', // optional, default value: `undefined`.
//     userId: dust.user, // optional, default value: `undefined`.
//     trackerUrl: 'https://matomo.powerdigitalmedia.com/matomo/matomo.php', // optional, default value: `${urlBase}matomo.php`
//     srcUrl: 'https://matomo.powerdigitalmedia.com/matomo/matomo.js', // optional, default value: `${urlBase}matomo.js`
//     disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
//     // heartBeat: { // optional, enabled by default
//     //     active: true, // optional, default value: true
//     //     seconds: 10 // optional, default value: `15
//     // },
//     // linkTracking: false, // optional, default value: true
//     // configurations: { // optional, default value: {}
//     //     // any valid matomo configuration, all below are optional
//     //     disableCookies: true,
//     //     setSecureCookie: true,
//     //     setRequestMethod: 'POST'
//     // }
// });
// //matomo-end





ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// ReactDOM.render(
//     <React.StrictMode>
//         <MatomoProvider value={instance}>
//             <Provider store={store}>
//                 <App />
//             </Provider>
//         </MatomoProvider>
//     </React.StrictMode>,
//     document.getElementById('root')
// );


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import styled from "styled-components";



export const Footer = styled.div.attrs(props => ({


    basename:           props.basename || "/source/",

    backgroundColor:    props.backgroundColor || "#1e232d",
    border:             props.border || "0",

    width:              props.width || "calc(100% - 100px)",
    height:             props.height || "auto",
    padding:            props.padding || "75px 50px 0 50px",
    margin:             props.margin  || "0 0 0 0",

    // percent:            props.percent || "40", 
    // decimal:            props.decimal || "0.4",

    // -ms-filter: "progid:pxImageTransform.Midateosoft.Alpha(Opacity=${props => props.percent})";
    // filter : alpha(opacity=${props => props.percent});
    // -moz-opacity : ${props => props.decimal};
    // -khtml-opacity : ${props => props.decimal};
    // opacity : ${props => props.decimal};



    // font-family:Verdana, Arial, Helvetica, sans-serif;
    // font-size:13px;
    // font-weight:normal;
    // color:#CCC;
    // text-align: center; /* IMPORTANT this can cause a shift in divs if set to the right or left*/



    //dateFontFamily:         props.dateFontFamily || '"Verdana", sans-serif',
    dateFontFamily:         props.dateFontFamily || 'Arial, Helvetica, sans-serif',
    dateFontSize:           props.dateFontSize || "16px",
    dateFontWeight:         props.dateFontWeight || "normal",
    dateTextAlign:          props.dateTextAlign || "center",
    dateTextDecoration:     props.dateTextDecoration || "none",
    dateTextColor:          props.dateTextColor || "#fff",


    //navFontFamily:         props.navFontFamily || '"Verdana", sans-serif',
    navFontFamily:         props.navFontFamily || 'Arial, Helvetica, sans-serif',
    navFontSize:           props.navFontSize || "18px",
    navFontWeight:         props.navFontWeight || "normal",
    navTextAlign:          props.navTextAlign || "left",
    navTextDecoration:     props.navTextDecoration || "none",
    navTextColor:          props.navTextColor || "#edeef1",





    //-----------------
    //pieLogo
    //-----------------

    pieLogoBackgroundColor:    props.pieLogoBackgroundColor || "transparent",

    //pieLogoBackgroundImage:    props.pieLogoBackgroundImage || props.basename + "/_resources/icons/tpc_profit_pie.svg",
    pieLogoBackgroundImage:    props.pieLogoBackgroundImage || "_resources/icons/tpc_profit_pie_black.svg",
 
    pieLogoBackgroundRepeat:   props.pieLogoBackgroundRepeat || "no-repeat",
    pieLogoBackgroundPosition: props.pieLogoBackgroundPosition || "bottom 0px left 0px",
    //pieLogoBackgroundPosition: props.pieLogoBackgroundPosition || "top 0px left 0px",
    pieLogoBackgroundSize:     props.pieLogoBackgroundSize || "200px 200px",




    //-----------------
    //textLogo
    //-----------------

    textLogoBackgroundColor:    props.textLogoBackgroundColor || "transparent",

    //textLogoBackgroundImage:    props.textLogoBackgroundImage || props.basename + "/_resources/icons/tpc_profit_pie.svg",
    textLogoBackgroundImage:    props.textLogoBackgroundImage || "_resources/svgs/tpc_logo_text_white.svg",
    
    textLogoBackgroundRepeat:   props.textLogoBackgroundRepeat || "no-repeat",
    textLogoBackgroundPosition: props.textLogoBackgroundPosition || "top 0px left 0px",
    //textLogoBackgroundPosition: props.textLogoBackgroundPosition || "top 0px left 0px",
    textLogoBackgroundSize:     props.textLogoBackgroundSize || "400px 30px",
    
    




  }))`

    background-color:${props => props.backgroundColor};
    border-top:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:left;

    display:block;
    overflow:hidden;



    .Container{

      background-color:transparent;

      width:100%;
      height:400px;

      margin:0 auto 0 auto;
      padding:0 0 0 0;

      position:relative;

  
    }



    .Logo{

      background-color:transparent;
      width:260px;
      height:100%;

      margin:0 0 0 0;
      padding:0 0 0 0;

      position:relative;
      float:left;

    }



    .Date{

      background-color:transparent;
      width:200px;
      height:calc(100% - 15px);

      margin:0 0 0 0;
      padding:15px 0 0 0;

      position:relative;
      float:right;

      font-family:${props => props.dateFontFamily};     
      font-size:${props => props.dateFontSize};
      font-weight: ${props => props.dateFontWeight};
      text-align: ${props => props.dateTextAlign};
      text-decoration:${props => props.dateTextDecoration};
      color:${props => props.dateTextColor};

    }



    .Nav{

      background-color:transparent;
      width:calc(100% - 510px);
      height:auto;

      margin:7px 0 50px 50px;
      padding:0 0 0 0;

      position:relative;
      float:left;


    }




      .Nav .Grid{

        background-color:transparent;
        width:275px;
        height:auto;

        margin:0 0 0 0;
        padding:0 0 0 0;

        position:relative;
        float:left;

      }

        .Nav .Grid .Cell{

          background-color:transparent;
          width:225px;
          height:auto;

          margin:0 0 0 0;
          padding:7px 0 5px 0;

          position:relative;
          float:left;

          cursor:pointer;

          font-family:${props => props.navFontFamily};     
          font-size: ${props => props.navFontSize};
          font-weight: ${props => props.navFontWeight};
          text-align: ${props => props.navTextAlign};
          text-decoration:${props => props.navTextDecoration};
          color:${props => props.navTextColor};


          &:hover{

            color:#00cc66;
            text-decoration:none;

        }




        }






    @media (max-width: 900px) {


      width:calc(100% - 40px);
      padding:40px 20px 50px 20px;


      .Logo{

        background-color:transparent;
        width:100%;
        height:auto;
        margin:0 0 0 0;
        padding:0 0 0 0;

      }

      .Date{

        background-color:transparent;
        width:100%;
        height:auto;
        margin:75px 0 0 0;
        padding:10px 0 10px 0;

        float:left;

      }

      .Nav{

        background-color:transparent;
        width:calc(100% - 40px);
        height:auto;

        margin:25px 0 0 40px;
  
      }


    }









    .PieLogo {

        background-color:${props => props.pieLogoBackgroundColor};
        background-image:url(${({
            basename,
            pieLogoBackgroundImage
        }) => 

            (
                basename !== "" 
                && basename !== undefined  
                && basename + pieLogoBackgroundImage
            ) 

            ||
            
            pieLogoBackgroundImage
            
        });
        background-repeat:${props => props.pieLogoBackgroundRepeat};
        background-position:${props => props.pieLogoBackgroundPosition};
        background-size:${props => props.pieLogoBackgroundSize};


        width:200px;
        height:200px;
        padding:0 0 0 0;
        margin:calc(50% - 50px) 0 0 calc(50% - 100px);

        position:relative;
        float:left;

    }




    .TextLogo {

      background-color:${props => props.textLogoBackgroundColor};
      background-image:url(${({
          basename,
          textLogoBackgroundImage
      }) => 
  
          (
              basename !== "" 
              && basename !== undefined  
              && basename + textLogoBackgroundImage
          ) 
  
          ||
          
          textLogoBackgroundImage
          
      });
      background-repeat:${props => props.textLogoBackgroundRepeat};
      background-position:${props => props.textLogoBackgroundPosition};
      background-size:${props => props.textLogoBackgroundSize};
  
  
      width:400px;
      height:30px;
      padding:0 0 0 0;
      margin:0 0 0 0;
  
      position:absolute;
      top:120px;
      left:calc(50% - 200px);
      z-index:5;
    
    }
  



    .Text{

      background-color:darkslategray;

      width:300px;
      height:30px;

      margin:0 0 0 0;
      padding:0 0 0 0;

      position:absolute;
      top:120px;
      left:calc(50% - 150px);
      z-index:5;

      font-family:${props => props.fontFamily};     
      font-size: ${props => props.fontSize};
      font-weight: ${props => props.fontWeight};
      text-align: ${props => props.textAlign};
      text-decoration:${props => props.textDecoration};
      color:${props => props.color};

    }








`;










import styled from "styled-components";
//import px2vw from "utils/px2vw";

/*
https://styled-components.com/docs


https://stackoverflow.com/questions/55906668/converting-css-with-nested-classes-to-styled-components-problem


Over 200 classes were generated for component styled.div with the id of "sc-AxirZ".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:

const Component = styled.div.attrs({
    style: ({ background }) => {
        background,
    },
})`width: 100%;`

<Component />




calc(100% - 300px)


TM - Notes 

- ScrollBox calc(100% - 120px) 120px equals the HeaderNav height + InfoBar height
- Use vh - viewport height - to set the stage height




https://stackoverflow.com/questions/1216114/how-can-i-make-a-div-stick-to-the-top-of-the-screen-once-its-been-scrolled-to




*/


export const Tray = styled.div.attrs(props => ({

  backgroundColor:    props.backgroundColor || "lightgray",
  border:             props.border || "gray",
  borderRadius:       props.borderRadius || "12px",

  width:              props.width || "40px",
  height:             props.height || "auto",
  padding:            props.padding || "10px 10px 10px 10px",
  margin:             props.margin  || "0 0 0 0",

  zIndex:             props.zIndex || "99",
  bottom:             props.bottom || "130px",
  right:              props.right  || "15px"



  // writing-mode: vertical-lr;
  // text-orientation: upright;


}))`

  background-color:${props => props.backgroundColor};

  border-top:${props => props.border};
  border-bottom:${props => props.border};
  border-left:${props => props.border};

  border-top-left-radius:${props => props.borderRadius};
  border-bottom-left-radius:${props => props.borderRadius};


  width:${props => props.width};
  height:${props => props.height};
  padding:${props => props.padding};
  margin:${props => props.margin};

  position:absolute;  
  z-index:${props => props.zIndex};
  bottom:${props => props.bottom};
  right:${props => props.right};


  .Button{

    background-color:#0291cd;
    border-radius:2px;

    width:30px;
    height:auto;
    margin:0 5px 0 0;
    padding:7px 0 7px 10px;

    font-family:Arial, Helvetica, sans-serif;      
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    text-decoration:none;
    color:white;

    writing-mode: vertical-lr;
    text-orientation: sideways;


    cursor:pointer;

  }




`;






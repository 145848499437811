import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';



//---------------------------
//utils/hooks
//---------------------------

import useDeviceDetect from 'utils/hooks/DeviceDetect';


//---------------------------
//utils/components
//---------------------------

import {
    Cleaner as XssCleaner
} from 'utils/components/Xss';




//import '../../StyleSheets/dynamic-form.css';
import 'app-style/dynamic-form/style.css';



import SVGTrash from 'grfx/trash.svg';
import SVGTrashOver from 'grfx/trash_over.svg';

import {
    LibString
} from 'library';


//https://stackoverflow.com/questions/21733847/react-jsx-selecting-selected-on-selected-select-option


export const FFeditfield = (props) => {


    // -- Device Detect -- //
    const DeviceDetection = useDeviceDetect();
    //console.log("FFcostfield - DeviceDetection " + JSON.stringify(DeviceDetection));
    let isMobile = DeviceDetection.isMobile;



    const refLabelInput = useRef(null);

    const [labelVal, setLabelVal] = useState('');   
    const [labelValShort, setLabelValShort] = useState('');
    const [labelValLong, setLabelValLong] = useState('');

    const [fieldVal, setFieldVal] = useState('');
    const [fieldStyl, setFieldStyl] = useState({});


    useEffect(() => {

        var fontStyle = 'normal';
        var fontColor = "#000";

        let data = props.data;

        for(var keyname in data)
        {
            switch(keyname)
            {
            case'label':

                var label = data[keyname].text;
                setLabelVal(label);
                var shortLabel = LibString.InputMaxlength({
                    str       :label,
                    maxlength :29
                });
                if(label.length > 29) shortLabel = shortLabel+"...";

                shortLabel = XssCleaner({call:"label",value:shortLabel});
                label = XssCleaner({call:"label",value:label});

                setLabelValShort(shortLabel);
                setLabelValLong(label);

            break;
            case'field':

                var value = '';

                switch(data[keyname].id)
                {
                case'email':
                case'password':
                case'pword':
        
                    value = XssCleaner({call:data[keyname].id,value:data[keyname].text});
                    //alert("Xss Safe Value: "+newVal);
                break;
                default:

                    value = XssCleaner({call:"lenient",value:data[keyname].text});
                    //alert("Xss Safe Value: "+newVal);
                }

                setFieldVal(value);
                // var fieldType = data[keyname].fieldtype;
                // var fieldVal = data[keyname].text;
                // switch(fieldType)
                // {
                // case'price':

                //     fieldVal = parseFloat(fieldVal);
                //     fieldVal = fieldVal.toFixed(2);
                // break;
                // case'percent':

                //     fieldVal = parseFloat(fieldVal);
                //     fieldVal = fieldVal.toFixed(1);
                // break;
                // default:
                // }
                // setFieldVal(fieldVal);


                if(data[keyname].fieldtype === 'select')
                {
                    if(data[keyname].hint === data[keyname].text 
                    || data[keyname].text === ''
                    )
                    {
                        fontStyle = 'italic';
                        fontColor = '#777';
                    }

                }//==

            break;
            default:
            break;
            }//==

        }


        setFieldStyl({
            "bgColor":"transparent",
            "fontStyle":fontStyle,
            "fontColor":fontColor
        });


        var problemArr = props.problemArr;
        for(var i=0; i < problemArr.length; i++)
        {
            if(props.ij === problemArr[i]['key'])
            {
                setFieldStyl({
                    "bgColor":"lightyellow",
                    "fontStyle":fontStyle,
                    "fontColor":fontColor
                });
                break;
            }
    
        }//== i
 
    
 
     },[props])
 
 
  

    //<input name="firstName" onChange={e => setFirstName(e.target.value)} />
     





    //=========================================
    //handle
    //=========================================

    function handleOverOut(hndl) {

        //console.log(hndl.index);

        var img;
        img = document.getElementById("svg-"+hndl.index);

        switch(hndl.call)
        {
        case'over':

            switch(hndl.svg)
            {
            case'trash':

                img.src = SVGTrashOver;
            break;
            default:
            }


        break;
        default:

            switch(hndl.svg)
            {
            case'trash':

                img.src = SVGTrash;
            break;
            default:
            }

        }

 
    }





    //=========================================
    //handle
    //=========================================

    function handleFocus(target) {

        target.select();
    }


    
    function handleBlur(e) {


        //console.log("TARGET VALUE: ", e.target.value);
        //console.log("TARGET TYPE: ", e.target.type);
        //console.log("TARGET HINT: ", props.data.field.hint);

        var newVal;
        newVal = fieldVal;
        if(e.target.value !== '')
        {
            newVal = e.target.value;
        }

        switch(e.fieldtype)
        {
        case'price':


            if(isNaN(newVal) || newVal === '') newVal = 0;
            newVal = parseFloat(newVal);
            newVal = newVal.toFixed(2);


            newVal = XssCleaner({call:"price",value:newVal});
            //alert("Xss Safe Value: "+newVal);

            setFieldVal(newVal);

            props.handle({

                call        :'capture',
                fieldCall   :"editfield",
                key         :props.ij,
                id          :props.data.id,
                data        :props.data,
                newValue    :newVal
            
            });

        break;
        case'percent':

            if(isNaN(newVal) || newVal === '') newVal = 0;
            newVal = parseFloat(newVal);
            newVal = newVal.toFixed(1);


            newVal = XssCleaner({call:"percent",value:newVal});
            //alert("Xss Safe Value: "+newVal);

            setFieldVal(newVal);


            props.handle({

                call        :'capture',
                fieldCall   :"editfield",
                key         :props.ij,
                id          :props.data.field.id,
                data        :props.data,
                newValue    :newVal
            
            });

        break;
        default:
        }
        

    }
    



    function handleChange(e) {

        console.log("TARGET VALUE: ", e.target.value);
        //console.log("TARGET TYPE: ", e.target.type);
        //console.log("TARGET HINT: ", props.data.field.hint);
        console.log("IJ: "+props.ij+" \nINDX: "+e.indx);



        var newStyl;
        var newVal;
        var newArr;
        var i;
    

        /*
        if(e.target.type === 'select')
        {

            newStyl = fieldStyl;

            if(e.target.value !== props.data.field.hint)
            {
                newStyl['fontStyle'] = 'normal';
                newStyl['fontColor'] = '#000';
            }else{
                newStyl['fontStyle'] = 'italic';
                newStyl['fontColor'] = '#777';
            }

            setFieldStyl(newStyl);

        }

        setFieldVal(e.target.value);

        */





        /*
        if(props.problemArr.length > 0)
        {
            props.handle({

                call        :'clear',
                fieldCall   :"editfield",
                key         :props.ij,
                id          :props.data.id,
                data        :props.data,
                newValue    :newVal
            
            });

        }
        */


        if(props.problemArr.length > 0)
        {
            if(props.ij !== undefined)
            {
                newArr = [];
                for(i=0; i < props.problemArr.length; i++)
                {

                    //console.log(props.problemArr[i]['key'] +" "+ e.indx);
                    if(props.problemArr[i]['key'] !== props.ij)
                    {
                        newArr.push(props.problemArr[i]);
                    }
                }

                console.log(JSON.stringify(newArr,null,2));
                //alert('new')
                props.handle({

                    call:"resetProblemArr",
                    errors:newArr

                });

            }
        }
        



        newVal = fieldVal;
        if(e.target.value !== '')
        {
            newVal = e.target.value;
        }
        
        switch(e.fieldtype)
        {
        case'select':

            newStyl = fieldStyl;

            if(e.target.value !== props.data.field.hint)
            {
                newStyl['fontStyle'] = 'normal';
                newStyl['fontColor'] = '#000';
            }else{
                newStyl['fontStyle'] = 'italic';
                newStyl['fontColor'] = '#777';
            }

            setFieldStyl(newStyl);

        break;
        case'price':

            if(isNaN(newVal) || newVal === '') newVal = 0;
        break;
        case'percent':

            if(isNaN(newVal) || newVal === '') newVal = 0;
        break;
        default:
        }


        newVal = XssCleaner({call:e.fieldtype,value:newVal});
        //alert("Xss Safe Value: "+newVal);


        setFieldVal(newVal);



        props.handle({

            call        :'capture',
            fieldCall   :"editfield",
            key         :props.ij,
            id          :props.data.field.id,
            data        :props.data,
            newValue    :newVal
        
        })


    }








    //=========================================
    //state / effect
    //=========================================

    const [labelInputView, setLabelInputView] = useState(false);
    const [labelInputSelect, setLabelInputSelect] = useState(false);

    useEffect(() => {
 
        if(labelInputView && !labelInputSelect)
        {
            setLabelInputSelect(true);
            refLabelInput.current.select();
        }

        setLabelVal(props.data.label.text);

     },[
         props,
         labelInputView,
         labelInputSelect,
         refLabelInput

    ])
 


    //=========================================
    //showLabelInput
    //=========================================

    function showLabelInput(hndl) {

        console.log(JSON.stringify(hndl,null,2));
        setLabelInputView(true);

    }

    function handleChangeLabel(e) {

        console.log("ChangeLabel");

        if(e.target.id.match(/labelInput/gi)) setLabelVal(e.target.value);
     
        var shortVal = '';
        var longVal = '';
        var targetVal;
        targetVal = e.target.value;
        if(targetVal === undefined
        || targetVal === '' 
        // || isNaN(targetVal)
        )
        {
            targetVal = ''; 
        }


        if(e.target.id.match(/labelInput/gi)) 
        {
            longVal = targetVal;
            shortVal = LibString.InputMaxlength({
                str       :targetVal,
                maxlength :29
            });
            if(longVal.length > 29) shortVal = shortVal+"...";
            //console.log(shortVal);

            shortVal = XssCleaner({call:"label",value:shortVal});
            longVal = XssCleaner({call:"label",value:longVal});


            setLabelValShort(shortVal+".."); 
            setLabelValLong(longVal);
            setLabelVal(longVal);


        }

    }


    function handleFocusLabel(target) {

        target.select();
    }

    function handleBlurLabel(e) {

        var targetVal;
        targetVal = e.target.value;
        if(targetVal === undefined
        || targetVal === '' 
        // || isNaN(targetVal)
        )
        {
            targetVal = 'Good'; 
        }

        // console.log(targetVal);
        // console.log(labelValShort);
        // console.log(labelValLong);
        // console.log(labelVal);

        setLabelInputSelect(false);   
        setLabelInputView(false);
        setLabelVal(labelValShort);

        props.handle({

            call        :'edit',
            fieldCall   :"editfield",
            key         :props.ij,
            id          :e.target.id,
            data        :props.data,
            value       :labelValLong
        
        });

 
    }









    // -- display -- //

    return (


        <div className="frm">

            {
            //------------------------------------
            //HIDDEN
            //------------------------------------

            props.data.field.fieldtype === "hidden" 
            ? 


                <div 
                key={props.ij}
                >

                    <input
                        type="hidden"
                        id={props.data.field.id}
                        value={fieldVal}          
                        placeholder={props.data.hint}
                    />

                </div>



            : null


            //-------------------------------------
            }




            {
            //------------------------------------
            //TEXT FIELD
            //------------------------------------

            props.data.field.fieldtype === "text" 
            ? 


                <div 
                key={props.ij}
                className="editfield"
                style={{
                    backgroundColor:'transparent'
                }}
                >

                    {/* <div 
                    className="label"
                    >

                        {props.data.label.text}

                    </div> */}



                    {
                    labelInputView
                    ?

                        <div 
                        className="label-wrap"
                        >

                            <input
                                ref={refLabelInput}
                                type="text"
                                className="input-tag"
                                style={{
                                    //backgroundColor:fieldStyl.bgColor,
                                    //fontStyle:fieldStyl.fontStyle,
                                    //color:fieldStyl.fontColor
                                }}

                                id={"labelInput-"+props.ij}
                                value={labelVal}
                                placeholder={props.data.label.text}
                                onChange={handleChangeLabel}
                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocusLabel(target), 0);//set with a timeout to fix safari browser
                                }}
                                onBlur={handleBlurLabel} 

                    
                            />

                        </div>


                    :

                        <div 
                        className="label"
                        onClick={(v) => showLabelInput()}
                        >
                            {labelValShort}

                        </div>
                    
                    }


                    {/* <div 
                    className="label"
                    onClick={(v) => showLabelInput({

                        call        :"edit",
                        data        :props.data,
                        fieldCall   :'editfield',
                        key         :props.ij
            
                    })}
                    >
                        {props.data.label.text}

                    </div> */}



                    <div 
                    className="controller"
                    >
                        <div 
                        id={"button-"+props.ij}                  
                        className="button"

                        onClick={(v) => props.handle({

                            call        :"delete",
                            data        :props.data,
                            fieldCall   :'editfield',
                            key         :props.ij
                
                        })}

                        onMouseOver={(v) => handleOverOut({

                            call:"over",
                            index:props.ij,
                            svg:"trash"

                        })}
                        onMouseOut={(v) => handleOverOut({

                            call:"out",
                            index:props.ij,
                            svg:"trash"

                        })}

                        >

                            <img
                            id={"svg-"+props.ij}
                            className="icon"
                            src={SVGTrash} 
                            alt="del" />

                            
                        </div>

                    </div>







                    <div 
                    className="field-wrap"
                    >

                        <input
                            type="text"
                            inputMode="text"
                            // type="number" pattern="[0-9]*"
                            // inputMode="numeric"

                            className="input-tag"
                            style={{
                                backgroundColor:fieldStyl.bgColor,
                                fontStyle:fieldStyl.fontStyle,
                                color:fieldStyl.fontColor
                            }}
            
                            id={props.data.field.id}
                            value={fieldVal}
                            placeholder={props.data.field.hint}
                            tabIndex={props.data.field.tabIndex}
        
                            //onFocus={handleFocus}

                            /*
                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => target.select(), 0);
                            }}
                            */

                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}

                            /*
                            onBlur={event => {

                                handleBlur({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype

                                })

                            }}  
                            */
    
                            onChange={event => {

                                handleChange({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype

                                })

                            }}

                            onKeyPress = {event => {
                                // event properties must be copied to use async
                                setTimeout(() => props.handleMovement({

                                    event       :event,
                                    section     :"editfields",
                                    index       :props.ij
                                
        
                                }), 0);
                            }}




                        />

                
                    </div>

                </div>



            : null


            //-------------------------------------
            }







            {
            //------------------------------------
            //TEXTAREA
            //------------------------------------

            props.data.field.fieldtype === "textarea" 
            ? 

                <div 
                key={props.ij}
                className="editfield"
                style={{
                    backgroundColor:'transparent'
                }}

                >



                    {/* <div 
                    className="label-ta"
                    >

                        {props.data.label.text}

                    </div> */}





                    {
                    labelInputView
                    ?

                        <div 
                        className="label-wrap"
                        >

                            <input
                                ref={refLabelInput}
                                type="text"
                                className="input-tag"
                                style={{
                                    //backgroundColor:fieldStyl.bgColor,
                                    //fontStyle:fieldStyl.fontStyle,
                                    //color:fieldStyl.fontColor
                                }}

                                id={"labelInput-"+props.ij}
                                value={labelVal}
                                placeholder={props.data.label.text}
                                onChange={handleChangeLabel}
                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocusLabel(target), 0);//set with a timeout to fix safari browser
                                }}
                                onBlur={handleBlurLabel} 

                    
                            />

                        </div>


                    :

                        <div 
                        className="label"
                        onClick={(v) => showLabelInput()}
                        >
                            {labelValShort}

                        </div>
                    
                    }


                    {/* <div 
                    className="label"
                    onClick={(v) => showLabelInput({

                        call        :"edit",
                        data        :props.data,
                        fieldCall   :'editfield',
                        key         :props.ij
            
                    })}
                    >
                        {props.data.label.text}

                    </div> */}



                    <div 
                    className="controller"
                    >
                        <div 
                        id={"button-"+props.ij}                  
                        className="button"

                        onClick={(v) => props.handle({

                            call        :"delete",
                            data        :props.data,
                            fieldCall   :'editfield',
                            key         :props.ij
                
                        })}

                        onMouseOver={(v) => handleOverOut({

                            call:"over",
                            index:props.ij,
                            svg:"trash"

                        })}
                        onMouseOut={(v) => handleOverOut({

                            call:"out",
                            index:props.ij,
                            svg:"trash"

                        })}

                        >

                            <img
                            id={"svg-"+props.ij}
                            className="icon"
                            src={SVGTrash} 
                            alt="del" />

                            
                        </div>

                    </div>








                    <div 
                    className="field-wrap"
                    >

                        <textarea
                        className="ta-tag"
                        style={{
                            backgroundColor:fieldStyl.bgColor,
                            fontStyle:fieldStyl.fontStyle,
                            color:fieldStyl.fontColor

                        }}

                        id={props.data.field.id}
                        placeholder={props.data.field.hint}
                        tabIndex={props.data.field.tabIndex}

                        value={fieldVal}


                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}

                            /*
                            onBlur={event => {

                                handleBlur({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype

                                })

                            }} 
                            */ 
    
                            onChange={event => {

                                handleChange({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype

                                })

                            }}

                            onKeyPress = {event => {
                                // event properties must be copied to use async
                                setTimeout(() => props.handleMovement({

                                    event       :event,
                                    section     :"editfields",
                                    index       :props.ij
                                
        
                                }), 0);
                            }}



                        >
                        </textarea>

                    </div>
                    
                </div>
            
            : null


            //-------------------------------------
            }







            {
            //------------------------------------
            //SELECT
            //------------------------------------

            props.data.field.fieldtype === "select" 
            ? 

                <div 
                key={props.ij}
                className="editfield"
                style={{
                    backgroundColor:'transparent'
                }}

                >

                    {/* <div 
                    className="label"
                    >

                        {props.data.label.text}

                    </div> */}



                    {
                    labelInputView
                    ?

                        <div 
                        className="label-wrap"
                        >

                            <input
                                ref={refLabelInput}
                                type="text"
                                className="input-tag"
                                style={{
                                    //backgroundColor:fieldStyl.bgColor,
                                    //fontStyle:fieldStyl.fontStyle,
                                    //color:fieldStyl.fontColor
                                }}

                                id={"labelInput-"+props.ij}
                                value={labelVal}
                                placeholder={props.data.label.text}
                                onChange={handleChangeLabel}
                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocusLabel(target), 0);//set with a timeout to fix safari browser
                                }}
                                onBlur={handleBlurLabel} 

                    
                            />

                        </div>


                    :

                        <div 
                        className="label"
                        onClick={(v) => showLabelInput()}
                        >
                            {labelValShort}

                        </div>
                    
                    }


                    {/* <div 
                    className="label"
                    onClick={(v) => showLabelInput({

                        call        :"edit",
                        data        :props.data,
                        fieldCall   :'editfield',
                        key         :props.ij
            
                    })}
                    >
                        {props.data.label.text}

                    </div> */}



                    <div 
                    className="controller"
                    >
                        <div 
                        id={"button-"+props.ij}                  
                        className="button"

                        onClick={(v) => props.handle({

                            call        :"delete",
                            data        :props.data,
                            fieldCall   :'editfield',
                            key         :props.ij
                
                        })}

                        onMouseOver={(v) => handleOverOut({

                            call:"over",
                            index:props.ij,
                            svg:"trash"

                        })}
                        onMouseOut={(v) => handleOverOut({

                            call:"out",
                            index:props.ij,
                            svg:"trash"

                        })}

                        >

                            <img
                            id={"svg-"+props.ij}
                            className="icon"
                            src={SVGTrash} 
                            alt="del" />

                            
                        </div>

                    </div>







                    <div 
                    className="field-wrap"
                    >

                        <select

                        className="sel-tag"
                        style={{
                            backgroundColor:fieldStyl.bgColor,
                            fontStyle:fieldStyl.fontStyle,
                            color:fieldStyl.fontColor
                        }}

                        id={props.data.field.id}
                        placeholder={props.data.field.hint}
                        tabIndex={props.data.field.tabIndex}
                        value={fieldVal}
                        onChange={event => {

                            handleChange({

                                target      :event.target,
                                fieldtype   :props.data.field.fieldtype

                            })

                        }}

                        onKeyPress = {event => {
                            // event properties must be copied to use async
                            setTimeout(() => props.handleMovement({

                                event       :event,
                                section     :"editfields",
                                index       :props.ij
                            
    
                            }), 0);
                        }}

                        
                
                        /*
                        onFocus = {event => {
                            // event properties must be copied to use async
                            const target = event.target;
                            setTimeout(() => handleFocus(target), 0);
                        }}
                        
                        onBlur={event => {

                            handleBlur({

                                target      :event.target,
                                fieldtype   :props.data.field.fieldtype

                            })

                        }}
                        */
                        
        
                        >

                                {
                                //====================================			
                                //first option
                                //====================================
                                }

                                <option 
                                className="opt"       
                                key={props.data.field.hint} 
                                value={props.data.field.hint}
                                >

                                    {props.data.field.hint}

                                </option>
                            

                
                                {
                                //====================================			
                                //options
                                //====================================


                                props.data.field.array.map((option) => (

                                    <option 
                                    className="opt"                     
                                    key={option} 
                                    value={option}

                                    >

                                        {option}

                                    </option>
                                ))

                                }


                        </select>


                    </div>

                </div>
            
            : null


            //-------------------------------------
            }






            {
            isMobile
            ?
                //------------------------------------
                //PRICE FIELD
                //------------------------------------

                props.data.field.fieldtype === "price" 
                ? 


                    <div 
                    key={props.ij}
                    className="editfield"
                    style={{
                        backgroundColor:'transparent'
                    }}
                    >


                        {/* <div 
                        className="label"
                        >

                            {props.data.label.text}

                        </div> */}






                        {
                        labelInputView
                        ?

                            <div 
                            className="label-wrap"
                            >

                                <input
                                    ref={refLabelInput}
                                    type="text"
                                    className="input-tag"
                                    style={{
                                        //backgroundColor:fieldStyl.bgColor,
                                        //fontStyle:fieldStyl.fontStyle,
                                        //color:fieldStyl.fontColor
                                    }}

                                    id={"labelInput-"+props.ij}
                                    value={labelVal}
                                    placeholder={props.data.label.text}
                                    onChange={handleChangeLabel}
                                    onFocus = {event => {
                                        // event properties must be copied to use async
                                        const target = event.target;
                                        setTimeout(() => handleFocusLabel(target), 0);//set with a timeout to fix safari browser
                                    }}
                                    onBlur={handleBlurLabel} 

                        
                                />

                            </div>


                        :

                            <div 
                            className="label"
                            onClick={(v) => showLabelInput()}
                            >
                                {labelValShort}

                            </div>
                        
                        }


                        {/* <div 
                        className="label"
                        onClick={(v) => showLabelInput({

                            call        :"edit",
                            data        :props.data,
                            fieldCall   :'editfield',
                            key         :props.ij
                
                        })}
                        >
                            {props.data.label.text}

                        </div> */}



                        <div 
                        className="controls"
                        >
                            <div 
                            id={"button-"+props.ij}                  
                            className="button"

                            onClick={(v) => props.handle({

                                call        :"delete",
                                data        :props.data,
                                fieldCall   :'editfield',
                                key         :props.ij
                    
                            })}

                            onMouseOver={(v) => handleOverOut({

                                call:"over",
                                index:props.ij,
                                svg:"trash"

                            })}
                            onMouseOut={(v) => handleOverOut({

                                call:"out",
                                index:props.ij,
                                svg:"trash"

                            })}

                            >

                                <img
                                id={"svg-"+props.ij}
                                className="icon"
                                src={SVGTrash} 
                                alt="del" />

                                
                            </div>

                        </div>






                        <div 
                        className="field-wrap"
                        >


                            <div
                            className="symbol"
                            >
                                $
                            </div>


                            <input

                                // type="text"
                                // inputMode="text"
                                type="number" pattern="[0-9]*"
                                inputMode="numeric"


                                className="symbol-input-tag"
                                style={{
                                    backgroundColor:fieldStyl.bgColor,
                                    fontStyle:fieldStyl.fontStyle,
                                    color:fieldStyl.fontColor
                                }}
                
                                id={props.data.field.id}
                                value={fieldVal}
                                placeholder={props.data.field.hint}
                                tabIndex={props.data.field.tabIndex}

            
                                //onFocus={handleFocus}

                                
                                // onFocus = {event => {
                                //     // event properties must be copied to use async
                                //     const target = event.target;
                                //     setTimeout(() => target.select(), 0);
                                // }}
                                

                                onFocus={event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocus(target), 0);
                                }}
                                onBlur={event => {

                                    handleBlur({

                                        target      :event.target,
                                        fieldtype   :props.data.field.fieldtype

                                    })

                                }}  
        
                                onChange={event => {

                                    handleChange({

                                        target      :event.target,
                                        fieldtype   :props.data.field.fieldtype

                                    })

                                }}

                                onKeyPress = {event => {
                                    // event properties must be copied to use async
                                    setTimeout(() => props.handleMovement({

                                        event       :event,
                                        section     :"editfields",
                                        index       :props.ij
                                    
            
                                    }), 0);
                                }}



                            />



                    
                        </div>





                    </div>



                : 



                //------------------------------------
                //PERCENT FIELD
                //------------------------------------

                props.data.field.fieldtype === "percent" 
                ? 


                    <div 
                    key={props.ij}
                    className="editfield"
                    style={{
                        backgroundColor:'transparent'
                    }}
                    >

                        {/* <div 
                        className="label"
                        >

                            {props.data.label.text}

                        </div> */}




                        {
                        labelInputView
                        ?

                            <div 
                            className="label-wrap"
                            >

                                <input
                                    ref={refLabelInput}
                                    type="text"
                                    className="input-tag"
                                    style={{
                                        //backgroundColor:fieldStyl.bgColor,
                                        //fontStyle:fieldStyl.fontStyle,
                                        //color:fieldStyl.fontColor
                                    }}

                                    id={"labelInput-"+props.ij}
                                    value={labelVal}
                                    placeholder={props.data.label.text}
                                    onChange={handleChangeLabel}
                                    onFocus = {event => {
                                        // event properties must be copied to use async
                                        const target = event.target;
                                        setTimeout(() => handleFocusLabel(target), 0);//set with a timeout to fix safari browser
                                    }}
                                    onBlur={handleBlurLabel} 

                        
                                />

                            </div>


                        :

                            <div 
                            className="label"
                            onClick={(v) => showLabelInput()}
                            >
                                {labelValShort}

                            </div>
                        
                        }


                        {/* <div 
                        className="label"
                        onClick={(v) => showLabelInput({

                            call        :"edit",
                            data        :props.data,
                            fieldCall   :'editfield',
                            key         :props.ij
                
                        })}
                        >
                            {props.data.label.text}

                        </div> */}



                        <div 
                        className="controller"
                        >
                            <div 
                            id={"button-"+props.ij}                  
                            className="button"

                            onClick={(v) => props.handle({

                                call        :"delete",
                                data        :props.data,
                                fieldCall   :'editfield',
                                key         :props.ij
                    
                            })}

                            onMouseOver={(v) => handleOverOut({

                                call:"over",
                                index:props.ij,
                                svg:"trash"

                            })}
                            onMouseOut={(v) => handleOverOut({

                                call:"out",
                                index:props.ij,
                                svg:"trash"

                            })}

                            >

                                <img
                                id={"svg-"+props.ij}
                                className="icon"
                                src={SVGTrash} 
                                alt="del" />

                                
                            </div>

                        </div>






                        <div 
                        className="field-wrap"
                        >


                            <div
                            className="symbol"
                            >
                                %
                            </div>




                            <input

                                // type="text"
                                // inputMode="text"
                                type="number" pattern="[0-9]*"
                                inputMode="numeric"

                                className="symbol-input-tag"
                                style={{
                                    backgroundColor:fieldStyl.bgColor,
                                    fontStyle:fieldStyl.fontStyle,
                                    color:fieldStyl.fontColor
                                }}
                
                                id={props.data.field.id}
                                value={fieldVal}
                                placeholder={props.data.field.hint}
                                tabIndex={props.data.field.tabIndex}
                                //onFocus={handleFocus}

                                /*
                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => target.select(), 0);
                                }}
                                */

                                onFocus={event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocus(target), 0);
                                }}
            
                                onBlur={event => {

                                    handleBlur({

                                        target      :event.target,
                                        fieldtype   :props.data.field.fieldtype

                                    })

                                }}

                                onChange={event => {

                                    handleChange({

                                        target      :event.target,
                                        fieldtype   :props.data.field.fieldtype

                                    })

                                }}

                                onKeyPress = {event => {
                                    // event properties must be copied to use async
                                    setTimeout(() => props.handleMovement({

                                        event       :event,
                                        section     :"editfields",
                                        index       :props.ij
                                    
            
                                    }), 0);
                                }}



                            />

                    
                        </div>

                    </div>



                : null
            
                



            :// NOT MOBILE





                //------------------------------------
                //PRICE FIELD
                //------------------------------------

                props.data.field.fieldtype === "price" 
                ? 


                    <div 
                    key={props.ij}
                    className="editfield"
                    style={{
                        backgroundColor:'transparent'
                    }}
                    >


                        {/* <div 
                        className="label"
                        >

                            {props.data.label.text}

                        </div> */}






                        {
                        labelInputView
                        ?

                            <div 
                            className="label-wrap"
                            >

                                <input
                                    ref={refLabelInput}
                                    type="text"
                                    className="input-tag"
                                    style={{
                                        //backgroundColor:fieldStyl.bgColor,
                                        //fontStyle:fieldStyl.fontStyle,
                                        //color:fieldStyl.fontColor
                                    }}

                                    id={"labelInput-"+props.ij}
                                    value={labelVal}
                                    placeholder={props.data.label.text}
                                    onChange={handleChangeLabel}
                                    onFocus = {event => {
                                        // event properties must be copied to use async
                                        const target = event.target;
                                        setTimeout(() => handleFocusLabel(target), 0);//set with a timeout to fix safari browser
                                    }}
                                    onBlur={handleBlurLabel} 

                        
                                />

                            </div>


                        :

                            <div 
                            className="label"
                            onClick={(v) => showLabelInput()}
                            >
                                {labelValShort}

                            </div>
                        
                        }


                        {/* <div 
                        className="label"
                        onClick={(v) => showLabelInput({

                            call        :"edit",
                            data        :props.data,
                            fieldCall   :'editfield',
                            key         :props.ij
                
                        })}
                        >
                            {props.data.label.text}

                        </div> */}



                        <div 
                        className="controls"
                        >
                            <div 
                            id={"button-"+props.ij}                  
                            className="button"

                            onClick={(v) => props.handle({

                                call        :"delete",
                                data        :props.data,
                                fieldCall   :'editfield',
                                key         :props.ij
                    
                            })}

                            onMouseOver={(v) => handleOverOut({

                                call:"over",
                                index:props.ij,
                                svg:"trash"

                            })}
                            onMouseOut={(v) => handleOverOut({

                                call:"out",
                                index:props.ij,
                                svg:"trash"

                            })}

                            >

                                <img
                                id={"svg-"+props.ij}
                                className="icon"
                                src={SVGTrash} 
                                alt="del" />

                                
                            </div>

                        </div>






                        <div 
                        className="field-wrap"
                        >


                            <div
                            className="symbol"
                            >
                                $
                            </div>


                            <input

                                type="text"
                                inputMode="text"
                                // type="number" pattern="[0-9]*"
                                // inputMode="numeric"


                                className="symbol-input-tag"
                                style={{
                                    backgroundColor:fieldStyl.bgColor,
                                    fontStyle:fieldStyl.fontStyle,
                                    color:fieldStyl.fontColor
                                }}
                
                                id={props.data.field.id}
                                value={fieldVal}
                                placeholder={props.data.field.hint}
                                tabIndex={props.data.field.tabIndex}

            
                                //onFocus={handleFocus}

                                
                                // onFocus = {event => {
                                //     // event properties must be copied to use async
                                //     const target = event.target;
                                //     setTimeout(() => target.select(), 0);
                                // }}
                                

                                onFocus={event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocus(target), 0);
                                }}
                                onBlur={event => {

                                    handleBlur({

                                        target      :event.target,
                                        fieldtype   :props.data.field.fieldtype

                                    })

                                }}  
        
                                onChange={event => {

                                    handleChange({

                                        target      :event.target,
                                        fieldtype   :props.data.field.fieldtype

                                    })

                                }}

                                onKeyPress = {event => {
                                    // event properties must be copied to use async
                                    setTimeout(() => props.handleMovement({

                                        event       :event,
                                        section     :"editfields",
                                        index       :props.ij
                                    
            
                                    }), 0);
                                }}



                            />



                    
                        </div>





                    </div>



                : 

                //------------------------------------
                //PERCENT FIELD
                //------------------------------------

                props.data.field.fieldtype === "percent" 
                ? 


                    <div 
                    key={props.ij}
                    className="editfield"
                    style={{
                        backgroundColor:'transparent'
                    }}
                    >

                        {/* <div 
                        className="label"
                        >

                            {props.data.label.text}

                        </div> */}




                        {
                        labelInputView
                        ?

                            <div 
                            className="label-wrap"
                            >

                                <input
                                    ref={refLabelInput}
                                    type="text"
                                    className="input-tag"
                                    style={{
                                        //backgroundColor:fieldStyl.bgColor,
                                        //fontStyle:fieldStyl.fontStyle,
                                        //color:fieldStyl.fontColor
                                    }}

                                    id={"labelInput-"+props.ij}
                                    value={labelVal}
                                    placeholder={props.data.label.text}
                                    onChange={handleChangeLabel}
                                    onFocus = {event => {
                                        // event properties must be copied to use async
                                        const target = event.target;
                                        setTimeout(() => handleFocusLabel(target), 0);//set with a timeout to fix safari browser
                                    }}
                                    onBlur={handleBlurLabel} 

                        
                                />

                            </div>


                        :

                            <div 
                            className="label"
                            onClick={(v) => showLabelInput()}
                            >
                                {labelValShort}

                            </div>
                        
                        }


                        {/* <div 
                        className="label"
                        onClick={(v) => showLabelInput({

                            call        :"edit",
                            data        :props.data,
                            fieldCall   :'editfield',
                            key         :props.ij
                
                        })}
                        >
                            {props.data.label.text}

                        </div> */}



                        <div 
                        className="controller"
                        >
                            <div 
                            id={"button-"+props.ij}                  
                            className="button"

                            onClick={(v) => props.handle({

                                call        :"delete",
                                data        :props.data,
                                fieldCall   :'editfield',
                                key         :props.ij
                    
                            })}

                            onMouseOver={(v) => handleOverOut({

                                call:"over",
                                index:props.ij,
                                svg:"trash"

                            })}
                            onMouseOut={(v) => handleOverOut({

                                call:"out",
                                index:props.ij,
                                svg:"trash"

                            })}

                            >

                                <img
                                id={"svg-"+props.ij}
                                className="icon"
                                src={SVGTrash} 
                                alt="del" />

                                
                            </div>

                        </div>






                        <div 
                        className="field-wrap"
                        >


                            <div
                            className="symbol"
                            >
                                %
                            </div>




                            <input

                                type="text"
                                inputMode="text"
                                // type="number" pattern="[0-9]*"
                                // inputMode="numeric"

                                className="symbol-input-tag"
                                style={{
                                    backgroundColor:fieldStyl.bgColor,
                                    fontStyle:fieldStyl.fontStyle,
                                    color:fieldStyl.fontColor
                                }}
                
                                id={props.data.field.id}
                                value={fieldVal}
                                placeholder={props.data.field.hint}
                                tabIndex={props.data.field.tabIndex}
                                //onFocus={handleFocus}

                                /*
                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => target.select(), 0);
                                }}
                                */

                                onFocus={event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocus(target), 0);
                                }}
            
                                onBlur={event => {

                                    handleBlur({

                                        target      :event.target,
                                        fieldtype   :props.data.field.fieldtype

                                    })

                                }}

                                onChange={event => {

                                    handleChange({

                                        target      :event.target,
                                        fieldtype   :props.data.field.fieldtype

                                    })

                                }}

                                onKeyPress = {event => {
                                    // event properties must be copied to use async
                                    setTimeout(() => props.handleMovement({

                                        event       :event,
                                        section     :"editfields",
                                        index       :props.ij
                                    
            
                                    }), 0);
                                }}



                            />

                    
                        </div>

                    </div>



                : null
                

            //isMobile
            }












            {
            //------------------------------------
            //STANDARD FIELD
            //------------------------------------

            props.data.field.fieldtype === "standard" 
            ? 


                <div 
                key={props.ij}
                className="editfield"
                style={{
                    backgroundColor:'transparent'
                }}
                >

                    {/* <div 
                    className="label"
                    >

                        {props.data.label.text}

                    </div> */}




                    {
                    labelInputView
                    ?

                        <div 
                        className="label-wrap"
                        >

                            <input
                                ref={refLabelInput}
                                type="text"
                                className="input-tag"
                                style={{
                                    //backgroundColor:fieldStyl.bgColor,
                                    //fontStyle:fieldStyl.fontStyle,
                                    //color:fieldStyl.fontColor
                                }}

                                id={"labelInput-"+props.ij}
                                value={labelVal}
                                placeholder={props.data.label.text}
                                onChange={handleChangeLabel}
                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocusLabel(target), 0);//set with a timeout to fix safari browser
                                }}
                                onBlur={handleBlurLabel} 

                    
                            />

                        </div>


                    :

                        <div 
                        className="label"
                        onClick={(v) => showLabelInput()}
                        >
                            {labelValShort}

                        </div>
                    
                    }


                    {/* <div 
                    className="label"
                    onClick={(v) => showLabelInput({

                        call        :"edit",
                        data        :props.data,
                        fieldCall   :'editfield',
                        key         :props.ij
            
                    })}
                    >
                        {props.data.label.text}

                    </div> */}



                    <div 
                    className="controller"
                    >
                        <div 
                        id={"button-"+props.ij}                  
                        className="button"

                        onClick={(v) => props.handle({

                            call        :"delete",
                            data        :props.data,
                            fieldCall   :'editfield',
                            key         :props.ij
                
                        })}

                        onMouseOver={(v) => handleOverOut({

                            call:"over",
                            index:props.ij,
                            svg:"trash"

                        })}
                        onMouseOut={(v) => handleOverOut({

                            call:"out",
                            index:props.ij,
                            svg:"trash"

                        })}

                        >

                            <img
                            id={"svg-"+props.ij}
                            className="icon"
                            src={SVGTrash} 
                            alt="del" />

                            
                        </div>

                    </div>






                    <div 
                    className="field-wrap"
                    >

                        <input
                            type="text"
                            inputMode="text"
                            // type="number" pattern="[0-9]*"
                            // inputMode="numeric"


                            className="symbol-input-tag"
                            style={{
                                backgroundColor:fieldStyl.bgColor,
                                fontStyle:fieldStyl.fontStyle,
                                color:fieldStyl.fontColor
                            }}
            
                            id={props.data.field.id}
                            value={fieldVal}
                            placeholder={props.data.field.hint}
                            tabIndex={props.data.field.tabIndex}

        
                            //onFocus={handleFocus}

                            /*
                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => target.select(), 0);
                            }}
                            */

                            onFocus={event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}
                            /*
                            onBlur={event => {

                                handleBlur({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype

                                })

                            }}  
                            */
                            onChange={event => {
            
                                handleChange({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype
    

                                });
                            }}

                            onKeyPress = {event => {
                                // event properties must be copied to use async
                                setTimeout(() => props.handleMovement({

                                    event       :event,
                                    section     :"editfields",
                                    index       :props.ij
                                
        
                                }), 0);
                            }}


                        />

                
                    </div>

                </div>



            : null


            //-------------------------------------
            }


        </div>



    )


}





const SceneInfo = [



    //TM: Billboard 1


    //##########################################################
    //##########################################################
    //##########################################################

    // Billboard 
    

    {
        "styl": {
            "call": "full",
            "class": "FullRow",
            "marginTop": "0px",
            "marginBottom": "0px",
            "paddingTop": "0px",
            "paddingBottom": "0px",
            "backgroundColor":"transparent",
            "textColor":"black"
        },

        "call": "Billboard",

        "info": {
            "call": "collage",
            "gridType": "Fixed",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "container",
            "modCall": "fill_div",
            "cells": {
                "call": "default",
                "total": 12,
                "across": 3
            },
            "thumb": {
                "call": "default",
                "diff": "0",
                "bgColor": "black"
            },
            "cellBorder": {
                "boolean": false,
                "thickness": 0,
                "color": "lightgray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            },
            "mask": {
                "boolean": false,
                "message": {
                    "boolean": false,
                    "backgroundColor": "#4CAF50",
                    "textColor": "white"
                }
            },

            "view": "fullScreen",
            "opacity": 0.7,
            "webkitFilter": "grayscale(0%)",
            "filter": "grayscale(0%)"

        },



        "items":[

            {

                "info": {
                    "call": "collage",
                    "gridType": "Fixed",
                    "gridBgColor": "transparent",
                    "gridCall": "grid_cell",
                    "cellCall": "container",
                    "modCall": "fill_div",
                    "cells": {
                        "call": "default",
                        "total": 1,
                        "across": 1
                    },
                    "thumb": {
                        "call": "default",
                        "diff": "0",
                        "bgColor": "black"
                    },
                    "cellBorder": {
                        "boolean": false,
                        "thickness": 0,
                        "color": "lightgray"
                    },
                    "gutter": {
                        "boolean": "0",
                        "size": "0"
                    },
                    "thresh": {
                        "refWidth": false,
                        "across1": "501",
                        "across2": "801",
                        "across3": "1051"
                    },
                    "mask": {
                        "boolean": false,
                        "message": {
                            "boolean": false,
                            "backgroundColor": "#4CAF50",
                            "textColor": "white"
                        }
                    },
        
                    "view": "fullScreen",
                    "opacity": 0.8,
                    "webkitFilter": "grayscale(0%)",
                    "filter": "grayscale(0%)"
        
                },

    
                "overlay": {

                    "category":"CATEGORY NAME",
                    "title":'Your are viewing default overlay data from the "#1 Billboard Scene Info"',
                    "button":{


                        "name":"Read More",
                        "url":"/",

                        "style":{

                            "backgroundColorOver":"#fc0",
                            "backgroundColorOut":"#0291cd",

                            "borderOver":"#fc0",
                            "borderOut":"#0291cd",

                            "textColorOver":"black",
                            "textColorOut":"white",

                        }

                    }

                },


                "alfa": {
                    "backgroundColor": "transparent",
                    "top": "0px",
                    "left": "0px",
                    "width": "100%",
                    "height": "100%",
                    "zIndex": "10",
                    "decimal": "0.0",
                    "percent": "0"
    
                },
                "base": {
                    "border": "0",
                    "backgroundColor": "transparent",
                    "top": "0px",
                    "left": "0px",
                    "width": "100%",
                    "height": "100%",           
                    "zIndex": "11"
                },
    


                "images": [
                    {

                        "image": {
                            "h": "2667",
                            "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Right_Side_f43ee0b8-5243-4bf6-b855-5955347d8250_4000x2677_crop_center_sys20200427150116id_.jpg",
                            "w": "4000"
                        },
                        "id": "20200427150116",
                        "title": "TITLE",
                        "text": "Text",
                        "bgColor": "black"
             
                    }
    
        
                ]


            },


            {

                "info": {
                    "call": "collage",
                    "gridType": "Fixed",
                    "gridBgColor": "transparent",
                    "gridCall": "grid_cell",
                    "cellCall": "container",
                    "modCall": "fill_div",
                    "cells": {
                        "call": "default",
                        "total": 1,
                        "across": 1
                    },
                    "thumb": {
                        "call": "default",
                        "diff": "0",
                        "bgColor": "black"
                    },
                    "cellBorder": {
                        "boolean": false,
                        "thickness": 0,
                        "color": "lightgray"
                    },
                    "gutter": {
                        "boolean": "0",
                        "size": "0"
                    },
                    "thresh": {
                        "refWidth": false,
                        "across1": "501",
                        "across2": "801",
                        "across3": "1051"
                    },
                    "mask": {
                        "boolean": false,
                        "message": {
                            "boolean": false,
                            "backgroundColor": "#4CAF50",
                            "textColor": "white"
                        }
                    },
        
                    "view": "fullScreen",
                    "opacity": 0.7,
                    "webkitFilter": "grayscale(0%)",
                    "filter": "grayscale(0%)"
        
        
                },


                "overlay": {

                    "category":"CATEGORY NAME",
                    "title":'Your are viewing default overlay data from the "#2 Billboard Scene Info"',
                    "button":{


                        "name":"Read More",
                        "url":"/",

                        "style":{

                            "backgroundColorOver":"#fc0",
                            "backgroundColorOut":"#0291cd",

                            "borderOver":"#fc0",
                            "borderOut":"#0291cd",

                            "textColorOver":"black",
                            "textColorOut":"white",

                        }

                    }

                },


                "alfa": {
                    "backgroundColor": "transparent",
                    "top": "0px",
                    "left": "0px",
                    "width": "100%",
                    "height": "100%",
                    "zIndex": "10",
                    "decimal": "0.0",
                    "percent": "0"
    
                },
                "base": {
                    "border": "0",
                    "backgroundColor": "transparent",
                    "top": "0px",
                    "left": "0px",
                    "width": "100%",
                    "height": "100%",           
                    "zIndex": "11"
                },
    
            

                "images": [

                    {

                        "image": {
                            "h": "2677",
                            "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Rear_3.4_611ef627-0fbe-488c-ac45-1b2d57c0fe95_4000x2677_crop_center_sys20200427151838id_.jpg",
                            "w": "4000"
                        },
                        "id": "20200427151838",
                        "title": "TITLE",
                        "text": "Text",
                        "bgColor": "black"
             
                    }
    
        
                ]


            },



            {


                "info": {
                    "call": "collage",
                    "gridType": "Fixed",
                    "gridBgColor": "transparent",
                    "gridCall": "grid_cell",
                    "cellCall": "container",
                    "modCall": "fill_div",
                    "cells": {
                        "call": "default",
                        "total": 12,
                        "across": 3
                    },
                    "thumb": {
                        "call": "default",
                        "diff": "0",
                        "bgColor": "black"
                    },
                    "cellBorder": {
                        "boolean": false,
                        "thickness": 0,
                        "color": "lightgray"
                    },
                    "gutter": {
                        "boolean": "0",
                        "size": "0"
                    },
                    "thresh": {
                        "refWidth": false,
                        "across1": "501",
                        "across2": "801",
                        "across3": "1051"
                    },
                    "mask": {
                        "boolean": false,
                        "message": {
                            "boolean": false,
                            "backgroundColor": "#4CAF50",
                            "textColor": "white"
                        }
                    },
        
                    "view": "fullScreen",
                    "opacity": 0.7,
                    "webkitFilter": "grayscale(0%)",
                    "filter": "grayscale(0%)"
        
        
                },


                "overlay": {

                    "category":"CATEGORY NAME",
                    "title":'Your are viewing default overlay data from the "#3 Billboard Scene Info"',
                    "button":{


                        "name":"Read More",
                        "url":"/TrialPage",

                        "style":{

                            "backgroundColorOver":"#fc0",
                            "backgroundColorOut":"#0291cd",

                            "borderOver":"#fc0",
                            "borderOut":"#0291cd",

                            "textColorOver":"black",
                            "textColorOut":"white",

                        }

                    }

                },



                "alfa": {
                    "backgroundColor": "transparent",
                    "top": "0px",
                    "left": "0px",
                    "width": "100%",
                    "height": "100%",
                    "zIndex": "10",
                    "decimal": "0.0",
                    "percent": "0"
    
                },
                "base": {
                    "border": "0",
                    "backgroundColor": "transparent",
                    "top": "0px",
                    "left": "0px",
                    "width": "100%",
                    "height": "100%",           
                    "zIndex": "11"
                },
    


                "images": [

                    {

                        "image": {
                            "h": "2677",
                            "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Rear_3.4_611ef627-0fbe-488c-ac45-1b2d57c0fe95_4000x2677_crop_center_sys20200427151838id_.jpg",
                            "w": "4000"
                        },
                        "id": "20200427151838",
                        "title": "TITLE",
                        "text": "Text",
                        "bgColor": "black"
             
                    },
        
                    {
        
                        "image": {
                            "h": "2677",
                            "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Rear_3.4_611ef627-0fbe-488c-ac45-1b2d57c0fe95_4000x2677_crop_center_sys20200427151838id_.jpg",
                            "w": "4000"
                        },
                        "id": "20200427151838",
                        "title": "TITLE",
                        "text": "Text",
                        "bgColor": "black"
             
                    },
                    {
        
                        "image": {
                            "h": "2667",
                            "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Right_Side_f43ee0b8-5243-4bf6-b855-5955347d8250_4000x2677_crop_center_sys20200427150116id_.jpg",
                            "w": "4000"
                        },
                        "id": "20200427150116",
                        "title": "TITLE",
                        "text": "Text",
                        "bgColor": "black"
        
                    }
        
                ]


            }




        ]


    },








    //##########################################################
    //##########################################################
    //##########################################################

    // ImageWrapText TM:


    {
        "styl": {
            "call": "indent",
            "class": "IndentRow",
            "marginTop": "25px",
            "marginBottom": "25px",
            "paddingTop": "0px",
            "paddingBottom": "0px",
            "backgroundColor":"transparent",
            "textColor":"black"
        },
        "call": "TxtComponent",
        "info": {
            "call": "flex",
            "gridType": "ImageWrapText",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "default",
            "modCall": "fill_div",
            "thumb": {
                "call": "default",
                "diff": "0"
            },
            "cellBorder": {
                "boolean": true,
                "thickness": 1,
                "color": "gray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            },


            "compStyl": {
                "heading": {
                  "fontFamily": "Arial, Helvetica, sans-serif",
                  "fontSize": "32px",
                  "fontWeight": "550",
                  "textAlign": "left",
                  "textDecoration": "none",
                  "color": "#00cc66"
                },
                "pgraph": {
                  "fontFamily": "Arial, Helvetica, sans-serif",
                  "fontSize": "16px",
                  "fontWeight": "500",
                  "textAlign": "left",
                  "textDecoration": "none",
                  "color": "aqua"
                },
                "button": {
                  "mainWidth": "100%",
                  "position": "center",
                  "backgroundColorOver": "#fc0",
                  "backgroundColorOut": "#07a2e3",
                  "borderRadius": "20px",
                  "borderColorOver": "black",
                  "borderColorOut": "white",
                  "textDecoration": "none",
                  "textColorOver": "black",
                  "textColorOut": "white",
                  "fontSize": "16px",
                  "fontWeight": "550"
                },
                "link": {
                  "mainWidth": "100%",
                  "position": "center",
                  "textDecoration": "none",
                  "textColorOver": "orangered",
                  "textColorOut": "#07a2e3",
                  "fontSize": "16px",
                  "fontWeight": "550"
                }
              }



        },
        "items": [
            {  
            "image": {

                "align":"left",
                "maxWidth":"400",
                "maxHeight":"400",
                "relief": "20",
         
                "h": "360",
                "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/8773addfile_sys20200406242958id_.jpg",
                "w": "640"
     
            }, 
            "heading": "Default Scene Info",
            "pgraph": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
            }
        ]

    },




    //##########################################################
    //##########################################################
    //##########################################################

    // ImageWrapText TM:


    {
        "styl": {
            "call": "indent",
            "class": "IndentRow",
            "marginTop": "25px",
            "marginBottom": "25px",
            "paddingTop": "0px",
            "paddingBottom": "0px",
            "backgroundColor":"transparent",
            "textColor":"black"
        },
        "call": "TxtComponent",
        "info": {
            "call": "flex",
            "gridType": "ImageWrapText",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "default",
            "modCall": "fill_div",
            "thumb": {
                "call": "default",
                "diff": "0"
            },
            "cellBorder": {
                "boolean": true,
                "thickness": 1,
                "color": "gray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            },


            "compStyl": {
                "heading": {
                  "fontFamily": "Arial, Helvetica, sans-serif",
                  "fontSize": "32px",
                  "fontWeight": "550",
                  "textAlign": "left",
                  "textDecoration": "none",
                  "color": "#00cc66"
                },
                "pgraph": {
                  "fontFamily": "Arial, Helvetica, sans-serif",
                  "fontSize": "16px",
                  "fontWeight": "500",
                  "textAlign": "left",
                  "textDecoration": "none",
                  "color": "aqua"
                },
                "button": {
                  "mainWidth": "100%",
                  "position": "center",
                  "backgroundColorOver": "#fc0",
                  "backgroundColorOut": "#07a2e3",
                  "borderRadius": "20px",
                  "borderColorOver": "black",
                  "borderColorOut": "white",
                  "textDecoration": "none",
                  "textColorOver": "black",
                  "textColorOut": "white",
                  "fontSize": "16px",
                  "fontWeight": "550"
                },
                "link": {
                  "mainWidth": "100%",
                  "position": "center",
                  "textDecoration": "none",
                  "textColorOver": "orangered",
                  "textColorOut": "#07a2e3",
                  "fontSize": "16px",
                  "fontWeight": "550"
                }
              }

        },
        "items": [
            {  
            "image": {

                "align":"right",
                "maxWidth":"full",
                "maxHeight":"full",
                "relief": "25",
         

                // "h": "360",
                // "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/8773addfile_sys20200406242958id_.jpg",
                // "w": "640"

                "h": "640",
                "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/2139addfile_sys20200406239837id_.jpg",
                "w": "427"
     
            }, 
            "heading": "Default Scene Info",
            "pgraph": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
            }
        ]

    },







    //##########################################################
    //##########################################################
    //##########################################################

    // Video Gallery TM:


    {
        "styl": {
            "call": "indent",
            "class": "IndentRow",
            "marginTop": "25px",
            "marginBottom": "25px",
            "paddingTop": "0px",
            "paddingBottom": "0px",
            "backgroundColor":"transparent",
            "textColor":"black"
        },
        "call": "VidGrid",
        "info": {
            "call": "vid",
            "gridType": "ImageOnly",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "container",
            "modCall": "fill_div",
            "thumb": {
            "call": "default",
            "diff": "10"
            },
            "cellBorder": {
            "boolean": false,
            "thickness": 1,
            "color": "gray"
            },
            "gutter": {
            "boolean": "true",
            "size": "4"
            },
            "thresh": {
            "refWidth": false,
            "across1": "501",
            "across2": "801",
            "across3": "1051"
            },
            "mask": {
            "boolean": true,
            "message": {
                "boolean": true,
                "backgroundColor": "#4CAF50",
                "textColor": "white"
            }
            }
        },
        "items": [
            {
            "image": {
                "h": "90",
                "src": "https://img.youtube.com/vi/bcGebz7QGHU/0.jpg",
                "w": "120"
            },
            "video": {
                "site": "youtube",
                "id": "bcGebz7QGHU",
                "type": "external",
                "src": ""
            }
            },
            {
            "image": {
                "h": "90",
                "src": "https://img.youtube.com/vi/bcGebz7QGHU/0.jpg",
                "w": "120"
            },
            "video": {
                "site": "youtube",
                "id": "bcGebz7QGHU",
                "type": "external",
                "src": ""
            }
            },
            {
            "image": {
                "h": "90",
                "src": "https://img.youtube.com/vi/bcGebz7QGHU/0.jpg",
                "w": "120"
            },
            "video": {
                "site": "youtube",
                "id": "bcGebz7QGHU",
                "type": "external",
                "src": ""
            }
            }
        ]
    },






    //##########################################################
    //##########################################################
    //##########################################################

    // Image Gallery (Fixed) TM:


    {
        "styl": {
          "call": "full",
          "class": "FullRow",
          "marginTop": "0px",
          "marginBottom": "50px",
          "paddingTop": "0px",
          "paddingBottom": "0px",
          "backgroundColor":"transparent",
          "textColor":"black"
        },
        "call": "FGrid",
        "info": {
          "call": "fixed",
          "gridType": "ImageOnly",
          "gridBgColor": "transparent",
          "gridCall": "grid_cell",
          "cellCall": "container",
          "modCall": "fill_div",
          "thumb": {
            "call": "default",
            "diff": "0"
          },
          "cellBorder": {
            "boolean": false,
            "thickness": 0,
            "color": "lightgray"
          },
          "gutter": {
            "boolean": "0",
            "size": "0"
          },
          "thresh": {
            "refWidth": false,
            "across1": "501",
            "across2": "801",
            "across3": "1051"
          },
          "mask": {
            "boolean": true,
            "message": {
              "boolean": true,
              "backgroundColor": "#4CAF50",
              "textColor": "white"
            }
          }
        },
        "items": [
          {
            "id": "20200406240087",
            "title": "TITLE",
            "text": "Text",
            "image": {
              "h": "426",
              "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/6433addfile_sys20200406240087id_.jpg",
              "w": "640"
            }
          },
          {
            "id": "20200406234970",
            "title": "TITLE",
            "text": "Text",
            "image": {
              "h": "426",
              "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/8169addfile_sys20200406234970id_.jpg",
              "w": "640"
            }
          },
          {
            "id": "20200407011445",
            "title": "TITLE",
            "text": "Text",
            "image": {
              "h": "2160",
              "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/ferrari-monza_sys20200407011445id_.jpg",
              "w": "3840"
            }
          },
          {
            "id": "20200427148217",
            "title": "TITLE",
            "text": "Text",
            "image": {
              "h": "1109",
              "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/180957-car-monza-sp1-1_sys20200427148217id_.jpg",
              "w": "1582"
            }
          },
          {
            "id": "20200406242021",
            "title": "TITLE",
            "text": "Text",
            "image": {
              "h": "640",
              "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/2495addfile_sys20200406242021id_.jpg",
              "w": "426"
            }
          },
          {
            "id": "20200427151306",
            "title": "TITLE",
            "text": "Text",
            "image": {
              "h": "850",
              "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Front_3.4_edit_93741fd4-6b93-4c41-a419-ddb66797622c_2000x850_crop_center_sys20200427151306id_.jpg",
              "w": "2000"
            }
          },
          {
            "id": "20200427151838",
            "title": "TITLE",
            "text": "Text",
            "image": {
              "h": "2677",
              "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Rear_3.4_611ef627-0fbe-488c-ac45-1b2d57c0fe95_4000x2677_crop_center_sys20200427151838id_.jpg",
              "w": "4000"
            }
          },
          {
            "id": "20200427150116",
            "title": "TITLE",
            "text": "Text",
            "image": {
              "h": "2667",
              "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Right_Side_f43ee0b8-5243-4bf6-b855-5955347d8250_4000x2677_crop_center_sys20200427150116id_.jpg",
              "w": "4000"
            }
          },
          {
            "id": "20200427147996",
            "title": "TITLE",
            "text": "Text",
            "image": {
              "h": "720",
              "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/maxresdefault_sys20200427147996id_.jpg",
              "w": "1280"
            }
          }
        ]
    },






    //##########################################################
    //##########################################################
    //##########################################################

    // Image Gallery (Flex) TM:


    {
        "styl": {
            "call": "full",
            "class": "FullRow",
            "marginTop": "0px",
            "marginBottom": "50px",
            "paddingTop": "0px",
            "paddingBottom": "0px",
            "backgroundColor":"transparent",
            "textColor":"black"
        },
        "call": "FGrid",
        "info": {
        "call": "flex",
        "gridType": "ImageOnly",
        "gridBgColor": "transparent",
        "gridCall": "grid_column_cell",
        "cellCall": "container",
        "modCall": "fill_div",
        "thumb": {
            "call": "flex_height",
            "diff": "0"
        },
        "cellBorder": {
            "boolean": false,
            "thickness": 0,
            "color": "lightgray"
        },
        "gutter": {
            "boolean": "0",
            "size": "0"
        },
        "thresh": {
            "refWidth": false,
            "across1": "501",
            "across2": "801",
            "across3": "1051"
        },
        "mask": {
            "boolean": true,
            "message": {
            "boolean": true,
            "backgroundColor": "#4CAF50",
            "textColor": "white"
            }
        }
        },
        "items": [
        {
            "id": "20200406240087",
            "title": "TITLE",
            "text": "Text",
            "image": {
            "h": "426",
            "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/6433addfile_sys20200406240087id_.jpg",
            "w": "640"
            }
        },
        {
            "id": "20200406234970",
            "title": "TITLE",
            "text": "Text",
            "image": {
            "h": "426",
            "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/8169addfile_sys20200406234970id_.jpg",
            "w": "640"
            }
        },
        {
            "id": "20200407011445",
            "title": "TITLE",
            "text": "Text",
            "image": {
            "h": "2160",
            "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/ferrari-monza_sys20200407011445id_.jpg",
            "w": "3840"
            }
        },
        {
            "id": "20200427148217",
            "title": "TITLE",
            "text": "Text",
            "image": {
            "h": "1109",
            "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/180957-car-monza-sp1-1_sys20200427148217id_.jpg",
            "w": "1582"
            }
        },
        {
            "id": "20200406242021",
            "title": "TITLE",
            "text": "Text",
            "image": {
            "h": "640",
            "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/yellow/2495addfile_sys20200406242021id_.jpg",
            "w": "426"
            }
        },
        {
            "id": "20200427151306",
            "title": "TITLE",
            "text": "Text",
            "image": {
            "h": "850",
            "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Front_3.4_edit_93741fd4-6b93-4c41-a419-ddb66797622c_2000x850_crop_center_sys20200427151306id_.jpg",
            "w": "2000"
            }
        },
        {
            "id": "20200427151838",
            "title": "TITLE",
            "text": "Text",
            "image": {
            "h": "2677",
            "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Rear_3.4_611ef627-0fbe-488c-ac45-1b2d57c0fe95_4000x2677_crop_center_sys20200427151838id_.jpg",
            "w": "4000"
            }
        },
        {
            "id": "20200427150116",
            "title": "TITLE",
            "text": "Text",
            "image": {
            "h": "2667",
            "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Right_Side_f43ee0b8-5243-4bf6-b855-5955347d8250_4000x2677_crop_center_sys20200427150116id_.jpg",
            "w": "4000"
            }
        },
        {
            "id": "20200427147996",
            "title": "TITLE",
            "text": "Text",
            "image": {
            "h": "720",
            "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/maxresdefault_sys20200427147996id_.jpg",
            "w": "1280"
            }
        }
        ]
    },














    
    //##########################################################
    //##########################################################
    //##########################################################

    // Navigator TM:


    {
        "styl": {
            "call": "full",
            "class": "FullRow",
            "marginTop": "0px",
            "marginBottom": "0px",
            "paddingTop": "0px",
            "paddingBottom": "0px",
            "backgroundColor":"transparent",
            "textColor":"black"
        },
        "call": "Navigator",
        "info": {
            "call": "default",//switchRoute, default
            "gridType": "Bars",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "default",
            "modCall": "fill_div",
            "thumb": {
                "call": "default",
                "diff": "0"
            },
            "cellBorder": {
                "boolean": true,
                "thickness": 1,
                "color": "gray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            }
        },
        "items": [


            {

                "to"    :"",     
                "name"  :"Home",
                "descr" :"Sample tile or bar link goes to Home"
    
            },
    
            {
    
                "to"    :"SinglePage",     
                "name"  :"Single Page",
                "descr" :"This is a single page resource"
    
            },
    
            {
    
                "to"    :"MultiPage",     
                "name"  :"Multi Page",
                "descr" :"This is a multi page resource"
    
            },
    
    
            {
                "to"    :"FormSample",    
                "name"  :"Sample Form",
                "descr" :'This is a form resource'
    
            },


            {

                "to"    :"AuthArea",     
                "name"  :"Auth Area",
                "descr" :"Template for authenticating every page within this section of the application"
    
            }
    

   
        ]

    }





];


export default SceneInfo;






/*

{
    "call": "collage",
    "gridType": "Fixed",
    "gridBgColor": "transparent",
    "gridCall": "grid_cell",
    "cellCall": "container",
    "modCall": "fill_div",
    "cells": {
      "call": "default",
      "total": 1,
      "across": 1
    },
    "thumb": {
      "call": "default",
      "diff": "0",
      "bgColor": "black"
    },
    "cellBorder": {
      "boolean": false,
      "thickness": 0,
      "color": "lightgray"
    },
    "gutter": {
      "boolean": "0",
      "size": "0"
    },
    "thresh": {
      "refWidth": false,
      "across1": "501",
      "across2": "801",
      "across3": "1051"
    },
    "mask": {
      "boolean": false,
      "message": {
        "boolean": false,
        "backgroundColor": "#4CAF50",
        "textColor": "white"
      }
    },
    "opacity": 1,
    "webkitFilter": "grayscale(0%)",
    "filter": "grayscale(0%)",
    "view": "fullScreen",
    "viewHeight": "613px",
    "thumbHInt": 723
}


*/


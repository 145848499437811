export const EYE_OPENED = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};

	let iconColor = props.iconColor || "rgb(0,0,0)";


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

			<path 
				fill={iconColor}
				fillRule="evenodd"
				clipRule="evenodd"
                d="M46.163,250C53.536,214.58,121.214,112.085,250,112.085 S446.464,214.58,453.837,250.001C446.464,285.422,378.786,387.916,250,387.916S53.536,285.42,46.163,250L46.163,250z M250,156.274 c51.622,0,93.726,42.104,93.726,93.726c0,51.623-42.104,93.727-93.726,93.727c-51.623,0-93.726-42.104-93.726-93.727 C156.274,198.378,198.377,156.274,250,156.274L250,156.274z M250,194.113c30.782,0,55.888,25.105,55.888,55.887 c0,30.783-25.105,55.889-55.888,55.889S194.112,280.783,194.112,250C194.112,219.219,219.218,194.113,250,194.113L250,194.113z"
            />

			
		</svg>
		</div>

	);//return







}
import React, { 

    useState, 
    useEffect, 
    //useRef,
    //useMemo

} from 'react';

// import { 
//     useSelector, 
//     useDispatch 
// } 
// from 'react-redux';
// import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



import { 
    
    SVG_HEADER_LOGO as TPC_SVG_HEADER_LOGO,
    SVG_WIDESCREEN_LOGO as TPC_SVG_WIDESCREEN_LOGO,
	SVG_FOOTER_LOGO as TPC_SVG_FOOTER_LOGO,
	SVG_MENU_LOGO as TPC_SVG_MENU_LOGO,
	SVG_MENU_BULLET as TPC_SVG_MENU_BULLET,

	SVG_PRICE_TAG as TPC_SVG_PRICE_TAG,
	SVG_BREAKEVEN_TAG as TPC_SVG_BREAKEVEN_TAG,
	SVG_PROFIT_PIE as TPC_SVG_PROFIT_PIE,

	SVG_COGS as TPC_SVG_COGS,
	SVG_OVERHEAD as TPC_SVG_OVERHEAD,
	SVG_EMPLOYEE as TPC_SVG_EMPLOYEE,
	SVG_MATERIALS as TPC_SVG_MATERIALS,

} from './svgs/TPC';



const SVGlist = (props) => {

	let list = {

		PDM:{

			"SVG_HEADER_LOGO"		:false,
			"SVG_WIDESCREEN_LOGO"	:false,
			"SVG_FOOTER_LOGO"		:false,
			"SVG_MENU_LOGO"			:false,
			"SVG_MENU_BULLET"		:false
		},

		TPC:{

			"SVG_HEADER_LOGO"		:TPC_SVG_HEADER_LOGO,
			"SVG_WIDESCREEN_LOGO"	:TPC_SVG_WIDESCREEN_LOGO,
			"SVG_FOOTER_LOGO"		:TPC_SVG_FOOTER_LOGO,
			"SVG_MENU_LOGO"			:TPC_SVG_MENU_LOGO,
			"SVG_MENU_BULLET"		:TPC_SVG_MENU_BULLET,

			"SVG_PRICE_TAG"			:TPC_SVG_PRICE_TAG,
			"SVG_BREAKEVEN_TAG"	    :TPC_SVG_BREAKEVEN_TAG,
			"SVG_PROFIT_PIE"		:TPC_SVG_PROFIT_PIE,
			
			"SVG_COGS"		        :TPC_SVG_COGS,
			"SVG_OVERHEAD"	        :TPC_SVG_OVERHEAD,
			"SVG_EMPLOYEE"		    :TPC_SVG_EMPLOYEE,
			"SVG_MATERIALS"		    :TPC_SVG_MATERIALS

		},

	};
	return list;

}



// export const SVGsubstitutes = (props) => {

// 	let svgListKey = "TPC";

// 	let list = SVGlist({});
// 	let listObj = {};
// 	if(Object.prototype.toString.call(list) === '[object Object]')
// 	{
// 		listObj = list[svgListKey];
// 	}
// 	return listObj;

// 	// return(<div>
// 	// </div>);//return
// }

export const SubstituteSVG = (props) => {


	const [isLoaded, setIsLoaded] = useState(false);
	const [svgListKey] = useState("TPC");
    const [svgCall, setSvgCall] = useState(false);
    const [svgWidth, setSvgWidth] = useState(false);
    const [svgHeight, setSvgHeight] = useState(false);
    const [svgStyle, setSvgStyle] = useState(false);

    const [thmbWidth, setThmbWidth] = useState(false);
    const [thmbHeight, setThmbHeight] = useState(false);

	const [svgPercent, setSvgPercent] = useState(false);



    useEffect(() => {


		if(!isLoaded && !svgCall)
		{
			setIsLoaded(true);

			let headerLogo = svgListKey+"_SVG_HEADER_LOGO";
			let widescreenLogo = svgListKey+"_SVG_WIDESCREEN_LOGO";
			let footerLogo = svgListKey+"_SVG_FOOTER_LOGO";
			let menuLogo = svgListKey+"_SVG_MENU_LOGO";
			let menuBullet = svgListKey+"_SVG_MENU_BULLET";


			let svgPriceTag = svgListKey+"_SVG_PRICE_TAG";
			let svgBreakevenTag = svgListKey+"_SVG_BREAKEVEN_TAG";
			let svgProfitPie = svgListKey+"_SVG_PROFIT_PIE";
			let svgCogs = svgListKey+"_SVG_COGS";
			let svgOverhead = svgListKey+"_SVG_OVERHEAD";
			let svgEmployee = svgListKey+"_SVG_EMPLOYEE";
			let svgMaterials = svgListKey+"_SVG_MATERIALS";

		
			var imgSrc;
			imgSrc = props.imgSrc;

			//alert(imgSrc);
			let regxHeaderLogo = new RegExp(headerLogo,"g");
			let regxWidescreenLogo = new RegExp(widescreenLogo,"g");
			let regxFooterLogo = new RegExp(footerLogo,"g");
			let regxMenuLogo = new RegExp(menuLogo,"g");
			let regxMenuBullet = new RegExp(menuBullet,"g");

			let regxSvgPriceTag = new RegExp(svgPriceTag,"g");
			let regxSvgBreakevenTag = new RegExp(svgBreakevenTag,"g");
			let regxSvgProfitPie = new RegExp(svgProfitPie,"g");
			let regxSvgCogs = new RegExp(svgCogs,"g");
			let regxSvgOverhead = new RegExp(svgOverhead,"g");
			let regxSvgEmployee = new RegExp(svgEmployee,"g");
			let regxSvgMaterials = new RegExp(svgMaterials,"g");


			let regxWidth = new RegExp("_width-","gi");
			let regxHeight = new RegExp("_height-","gi");

			let regxStyle = new RegExp("_style-","gi");
			let regxBackgroundColor = new RegExp("_backgroundColor-","gi");
			let regxAlign = new RegExp("_align-","gi");
			let regxSpecials = new RegExp("_specials-","gi");



			var thmb_width,thmb_height,thmb_decimal,thmb_percent,thmb_left;

			let svg_width;
			let svg_height;
			let svg_style,svg_margin,svg_bgColor,svg_align,svg_specials;

		
			let list = SVGlist({});
			let listObj = {listKey:svgListKey};
			if(Object.prototype.toString.call(list) === '[object Object]')
			{
				listObj = list[svgListKey];
			}
			if(Object.keys(listObj).length > 0)
			{


				//alert(imgSrc)

				// -- call -- //

				if(imgSrc.match(regxHeaderLogo))
				{
					setSvgCall(headerLogo);
				}	
				else
				if(imgSrc.match(regxWidescreenLogo))
				{
					
					setSvgCall(widescreenLogo);
				}
				else
				if(imgSrc.match(regxFooterLogo))
				{
			
					setSvgCall(footerLogo);
				}
				else
				if(imgSrc.match(regxMenuLogo))
				{
				
					setSvgCall(menuLogo);
				}
				else
				if(imgSrc.match(regxMenuBullet))
				{
					setSvgCall(menuBullet);
				}



				// -- calc icons -- //

				else
				if(imgSrc.match(regxSvgPriceTag))
				{
					setSvgCall(svgPriceTag);
				}	
				else
				if(imgSrc.match(regxSvgBreakevenTag))
				{
					
					setSvgCall(svgBreakevenTag);
				}
				else
				if(imgSrc.match(regxSvgProfitPie))
				{
			
					setSvgCall(svgProfitPie);
				}
				else
				if(imgSrc.match(regxSvgCogs))
				{
				
					setSvgCall(svgCogs);
				}
				else
				if(imgSrc.match(regxSvgOverhead))
				{
					setSvgCall(svgOverhead);


					// -- direct - styling -- //

					if(props.svgWidth)
					{
						setSvgWidth(props.svgWidth);
					}
					if(props.svgHeight)
					{
						setSvgHeight(props.svgHeight);
					}
					if(props.svgStyle)
					{
						setSvgStyle(props.svgStyle);
					}


				}

				else
				if(imgSrc.match(regxSvgEmployee))
				{
				
					setSvgCall(svgEmployee);
				}
				else
				if(imgSrc.match(regxSvgMaterials))
				{
					setSvgCall(svgMaterials);
				}



				// -- default -- //

				else
				{
					setSvgCall(svgProfitPie);
				}




				// -- direct - styling -- //

				// if(props.svgWidth)
				// {
				// 	setSvgWidth(props.svgWidth);
				// }
				// if(props.svgHeight)
				// {
				// 	setSvgHeight(props.svgHeight);
				// }
				// if(props.svgStyle)
				// {
				// 	setSvgStyle(props.svgStyle);
				// }






				// -- styling -- //


				//width
				if(imgSrc.match(regxWidth))
				{
					svg_width = imgSrc.split(regxWidth)[1];
					svg_width = svg_width.split(regxHeight)[0];
					if(!svg_width.match(/px/gi)) svg_width = svg_width + "px";

					setSvgWidth(svg_width);
				}

				//height
				if(imgSrc.match(regxHeight))
				{
					svg_height = imgSrc.split(regxHeight)[1];
					svg_height = svg_height.split(regxStyle)[0];
					if(!svg_height.match(/px/gi)) svg_height = svg_height + "px";

					setSvgHeight(svg_height);	
				}

				//style
				if(imgSrc.match(regxStyle))
				{

					//---------------------------
					//margin

					svg_width = imgSrc.split(regxWidth)[1];
					svg_width = svg_width.split(regxHeight)[0];
					if(svg_width.match(/px/gi)) svg_width = svg_width.replace(/px/gi,"");

					svg_margin = "0 0 0 calc(50% - "+(svg_width/2)+"px)";



					//---------------------------
					//style

					svg_style = imgSrc.split(regxStyle)[1];


					//--------------------
					//backgroundColor

					svg_bgColor = 'transparent';
					if(imgSrc.match(regxBackgroundColor))
					{
						svg_bgColor = svg_style.split(regxBackgroundColor)[1];
						svg_bgColor = svg_bgColor.split("_")[0];
						//svg_bgColor = svg_bgColor.split("-")[1];
						if(!isNaN(svg_bgColor))
						{
							if(!svg_bgColor.match(/#/g))
							{
								svg_bgColor = "#"+svg_bgColor;
							}
						}
				
					}


					//---------------------
					//align

					svg_align = false;
					if(imgSrc.match(regxAlign))
					{
						svg_align = svg_style.split(regxAlign)[1];
						svg_align = svg_align.split("_")[0];
					}
					switch(svg_align)
					{
					case'left':

						svg_margin = "0 0 0 0";
					break;
					case'right':

						svg_margin = "0 calc(100% - "+ (svg_width + 15) +"px) 0 0";
					break;
					default:
					}



					//---------------------
					//specials

					svg_specials = false;
					if(imgSrc.match(regxSpecials))
					{
						svg_specials = svg_style.split(regxSpecials)[1];
						svg_specials = svg_specials.split("_");

					}

					console.log(""
					
						+"\n"+svg_margin
						+"\n"+svg_bgColor
						+"\n"+svg_align
						+"\n"+JSON.stringify(svg_specials,null,2)
					)

					setSvgStyle({

						backgroundColor:svg_bgColor,
						margin:svg_margin,
						align:svg_align,
						specials:svg_specials
					})
				}



			}



			if(props.cells && Object.prototype.toString.call(props.cells) === '[object Array]')
			{
				// console.log("utils/components/Content - ImageTextBox - Container Dimensions"
		
				// 	+"\n"+props.cells[props.cellNum].offsetWidth
				// 	+"\n"+props.cells[props.cellNum].offsetHeight
					
				// );

				thmb_width = props.cells[props.cellNum].offsetWidth;
				thmb_height = props.cells[props.cellNum].offsetHeight;

				if(thmb_width > thmb_height)
				{
					thmb_decimal = (parseFloat(thmb_height) / parseFloat(thmb_width));
					thmb_percent = thmb_decimal * 100;
					thmb_percent = thmb_percent.toFixed(1);
				}
				else
				if(thmb_height > thmb_width)
				{
					thmb_decimal = (parseFloat(thmb_width) / parseFloat(thmb_height));
					thmb_percent = thmb_decimal * 100;
					thmb_percent = thmb_percent.toFixed(1);
					
				}
				else
				{
					thmb_decimal = 1;
					thmb_percent = 100;
				}




				//thmb_decimal = thmb_decimal.toFixed(1);
				thmb_decimal = parseFloat(thmb_decimal)/2;

				thmb_left = (parseInt(thmb_width) * parseFloat(thmb_decimal))/1.75;

				console.log("THMB LEFT: "+thmb_left+"\nTHMB DECIMAL: "+thmb_decimal);

			
				setSvgPercent(thmb_percent);
				setSvgStyle({
					backgroundColor:"transparent",
					margin:"0 0 0 "+thmb_left+"px"
				});
				setThmbWidth(thmb_width);
				setThmbHeight(thmb_height);


			}


					
		}

    },
    [
  
        props,
        //zoneObj,
        isLoaded,
		svgListKey,
		svgCall
  
    ])






	if(!isLoaded)
	{
		return(<div>
		</div>);//return

	}else{


		return(<div>

			{

				svgListKey === 'PDM'
				?

					<TPC_SVG_HEADER_LOGO />

				:
				svgListKey === 'TPC'
				?


					svgCall.match(/_SVG_HEADER_LOGO/g)
					?
		
						<TPC_SVG_HEADER_LOGO 
						
							svgWidth = {svgWidth}
							svgHeight = {svgHeight}
							svgStyle = {svgStyle}	
							
						/>
		
					:
					svgCall.match(/_SVG_WIDESCREEN_LOGO/g)
					?
		
						<TPC_SVG_WIDESCREEN_LOGO 

							svgWidth = {svgWidth}
							svgHeight = {svgHeight}
							svgStyle = {svgStyle}	

						/>
		
					:
					svgCall.match(/_SVG_FOOTER_LOGO/g)
					?
		
		
						<TPC_SVG_FOOTER_LOGO 
					
							svgWidth = {svgWidth}
							svgHeight = {svgHeight}
							svgStyle = {svgStyle}	
						
						/>
		

					:
					svgCall.match(/_SVG_MENU_LOGO/g)
					?
		
						<TPC_SVG_MENU_LOGO
						
							svgWidth = {svgWidth}
							svgHeight = {svgHeight}
							svgStyle = {svgStyle}	
						
						/>
					
					:
					svgCall.match(/_SVG_MENU_BULLET/g)
					?
		
		
						<TPC_SVG_MENU_BULLET 
						
							svgWidth = {svgWidth}
							svgHeight = {svgHeight}
							svgStyle = {svgStyle}
								
						/>
				


					:




					// ==================================
					// CALC - ICONS
					// ==================================



					svgCall.match(/_SVG_PRICE_TAG/g)
					?
		
						<TPC_SVG_PRICE_TAG 

							// svgWidth = {svgWidth}
							// svgHeight = {svgHeight}
							// svgStyle = {svgStyle}

							svgWidth = {svgPercent ? svgPercent + "%" : svgWidth}
							svgHeight = {svgPercent ? svgPercent + "%" : svgHeight}
							svgStyle = {svgStyle}	

		
						/>
		
					:
					svgCall.match(/_SVG_BREAKEVEN_TAG/g)
					?
		
						<TPC_SVG_BREAKEVEN_TAG


							// svgWidth = {svgWidth}
							// svgHeight = {svgHeight}
							// svgStyle = {svgStyle}
		
							svgWidth = {svgPercent ? svgPercent + "%" : svgWidth}
							svgHeight = {svgPercent ? svgPercent + "%" : svgHeight}
							svgStyle = {svgStyle}	

						/>
		
					:
					svgCall.match(/_SVG_PROFIT_PIE/g)
					?
		
		
						<TPC_SVG_PROFIT_PIE

							// svgWidth = {svgWidth}
							// svgHeight = {svgHeight}
							// svgStyle = {svgStyle}
					
							svgWidth = {svgPercent ? svgPercent + "%" : svgWidth}
							svgHeight = {svgPercent ? svgPercent + "%" : svgHeight}
							svgStyle = {svgStyle}	

	
						/>
		

					:
					svgCall.match(/_SVG_COGS/g)
					?
		
						<TPC_SVG_COGS

							// svgWidth = {svgWidth}
							// svgHeight = {svgHeight}
							// svgStyle = {svgStyle}
						
							svgWidth = {svgPercent ? svgPercent + "%" : svgWidth}
							svgHeight = {svgPercent ? svgPercent + "%" : svgHeight}
							svgStyle = {svgStyle}	

						/>
					

					:
					svgCall.match(/_SVG_OVERHEAD/g)
					?
		
						<TPC_SVG_OVERHEAD

							// svgWidth = {svgWidth}
							// svgHeight = {svgHeight}
							// svgStyle = {svgStyle}

							// svgWidth = {svgPercent ? svgPercent + "%" : svgWidth}
							// svgHeight = {svgPercent ? svgPercent + "%" : svgHeight}
							// svgStyle = {svgStyle}
							
							
							svgWidth = {thmbWidth}
							svgHeight = {thmbHeight}
							svgStyle = {svgStyle}

							
						/>
		
					:
					svgCall.match(/_SVG_EMPLOYEE/g)
					?
		
						<TPC_SVG_EMPLOYEE

							// svgWidth = {svgWidth}
							// svgHeight = {svgHeight}
							// svgStyle = {svgStyle}

							svgWidth = {svgPercent ? svgPercent + "%" : svgWidth}
							svgHeight = {svgPercent ? svgPercent + "%" : svgHeight}
							svgStyle = {svgStyle}	

						/>
		
					:
					svgCall.match(/_SVG_MATERIALS/g)
					?
		
		
						<TPC_SVG_MATERIALS
					
							// svgWidth = {svgWidth}
							// svgHeight = {svgHeight}
							// svgStyle = {svgStyle}


							svgWidth = {svgPercent ? svgPercent + "%" : svgWidth}
							svgHeight = {svgPercent ? svgPercent + "%" : svgHeight}
							svgStyle = {svgStyle}	
						
						/>
		
	

					
					:null


				:null


			}




		</div>);//return
	}




}

const Handler = (props) => {


    //var newArr;
    //var newChoice;
    //var object;

    // console.log(JSON.stringify(props,null,2));
    //alert("Prime Handler - "+props.call);

    
    var regx;
    var hashbang;

    var currentZone = props.pathname;
    //currentZone = currentZone.replace(/\//gi,"-");
    currentZone = currentZone.replace(/ /gi,"-");

    if(currentZone === '') currentZone = 'Home';


    var currentFlyr;

    var scrollbox;
    var scrollpos;

    var key;
    


    switch(props.call)
    {
    case'blueprint':

        props.handleState({call:'handleBlueprint',value:props.data});
    break;
    case'url':

        props.handleState({call:'handleUrl',value:props.data});
    break;
    case'dispatch':


        //type:hndl.action,//UPDATE_ZONE or DELETE_ZONE
        //item:{
            //key     :hndl.key,
            //value   :hndl.value
        //}


        // console.log("PRIME HANDLER - dispatch key: "+currentZone+" "+props.data.key);
        // console.log("PRIME HANDLER - dispatch value: "+JSON.stringify(props.data.value,null,2));
     
        // alert("PRIME HANDLER - dispatch - console check");
        

        key = currentZone+"_"+props.data.key;
        if(!props.usePrefix)
        {
            key = props.data.key;
        }
        props.handleState({call:'handleDispatch',value:{

            action      :props.data.action,
            key         :key,
            value       :props.data.value

        }});


    break;
    case'removeHash':


        props.handleState({call:'hashData',value:false});

    break;
    case'showMenu':


        //alert('showMenu');

        props.handleState({call:'showMenu',value:true});
        props.handleState({call:'menuData',value:[]});

        // hashbang = "#!";
        // regx = new RegExp("#!","gi");
        // if(!props.location.hash.match(regx)
        // )
        // {
        //     //props.history.push(hashbang);
        //     props.handleRoute({call:"push",value:hashbang});
        // }else{
        //     props.handleRoute({call:"push",value:false});
        // }



        
        // props.handleState({call:'menuData',value: {

        //     isError     : props.isError,
        //     errorType   : props.errorType,
        //     isTimed     : props.isTimed,
        //     items       : props.items

        // }});
     


        //console.log("LOCATION: "+JSON.stringify(props.location,null,2));
        //console.log("HISTORY: "+JSON.stringify(history,null,2));


        /*
        if(props.location.hash !== "#!") 
        {
            //props.history.push("#!");
            props.handleRoute({call:"push",value:"#!"});

        }
        */

    break;
    case'showConfirm':

        //alert(Object.prototype.toString.call(props));
        //alert(JSON.stringify(props,null,2));

        hashbang = "#!";
        if(props.data.hashbang 
        && props.data.hashbang !== undefined)
            hashbang = props.data.hashbang;

        props.handleState({call:'hashData',value:hashbang});
        props.handleState({call:'showConfirm',value:true});
        props.handleState({call:'confirmData',value:props.data});

        //console.log("LOCATION: "+JSON.stringify(props.location,null,2));
        //console.log("HISTORY: "+JSON.stringify(history,null,2));

       regx = new RegExp("#!","gi");
       if(!props.location.hash.match(regx)
       )
       {
           //props.history.push(hashbang);
           props.handleRoute({call:"push",value:hashbang});
       }
       


    break;
    case'runConfirm':


        //alert("runConfirm");
        //alert(Object.prototype.toString.call(props));
        //alert(JSON.stringify(props,null,2));


        props.handleState({call:'hashData',value:false});
        props.handleState({call:'showConfirm',value:false});
        props.handleState({call:'confirmData',value:false});

        
        switch(props.data.submitCall)
        {
        case'account':

            props.handleState({call:'handleDispatch',value:{

                action  :"UPDATE_ZONE",
                key     :currentZone + "_runAccount",
                value   :true

            }});
        break;
        case'calculate':

            props.handleState({call:'handleDispatch',value:{

                action  :"UPDATE_ZONE",
                key     :currentZone + "_runCalculate",
                value   :true

            }});
        break;
        default:
        }
        

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentZone + "_postTo",
            value   :props.data.postTo

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentZone + "_call",
            value   :props.data.call

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentZone + "_currentKey",
            value   :props.data.keyname

        }});


    break;
    case'completeConfirm':


        //alert("completeConfirm");


        // -- kill all zone stuff -- //

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentZone + "_runAccount",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentZone + "_runCalculate",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"DELETE_ZONE",
            key     :currentZone + "_postTo",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"DELETE_ZONE",
            key     :currentZone + "_call",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"DELETE_ZONE",
            key     :currentZone + "_currentKey",
            value   :false

        }});




        // -- set complete message -- //

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentZone + "_runMessngr",
            value   :true

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentZone + "_messngrData",
            value   :        {

                isError         : false,
                errorType       : '',
                isTimed         : true,
                timedDuration   : 1200,
                items           : [
        
                    {
                        "call":"heading",
                        "text":"Message" 
                    },
                    {
                        "call":"span",
                        //"text":"Update was Successfull!!" 
                        "text":props.message
                    }
            
                ]
    
            }


        }});


  

        // -- update zone -- //

        if(props.reset)
        {
            props.handleState({call:'handleDispatch',value:{

                action  :"UPDATE_ZONE",
                key     :props.reset.key,
                value   :props.reset.value

            }});

        }




        if(document.getElementById('scrollbox')
        && document.getElementById('scrollbox') !== undefined
        )
        {
            scrollbox = document.getElementById('scrollbox');
            //scrollpos = scrollbox.scrollHeight;
            scrollpos = 0;
            scrollbox.scrollTop = scrollpos;

        }


        
    break;
    case'showLoader':


        //props.handleState({call:'hashData',value:"#!"});
        props.handleState({call:'showLoader',value:true});
        props.handleState({call:'loaderData',value: {

            isError     : props.isError,
            errorType   : props.errorType,
            isTimed     : props.isTimed,
            items       : props.items

        }});
     
        //console.log("LOCATION: "+JSON.stringify(props.location,null,2));
        //console.log("HISTORY: "+JSON.stringify(history,null,2));


        /*
        if(props.location.hash !== "#!") 
        {
            //props.history.push("#!");
            props.handleRoute({call:"push",value:"#!"});

        }
        */

    break;
    case'showLogin':


        //props.handleState({call:'hashData',value:"#!"});
        props.handleState({call:'showLogin',value:true});
        props.handleState({call:'loginData',value: {

            isError     : props.isError,
            errorType   : props.errorType,
            isTimed     : props.isTimed,
            items       : props.items

        }});
     
        //console.log("LOCATION: "+JSON.stringify(props.location,null,2));
        //console.log("HISTORY: "+JSON.stringify(history,null,2));


        /*
        if(props.location.hash !== "#!") 
        {
            //props.history.push("#!");
            props.handleRoute({call:"push",value:"#!"});

        }
        */

    break;
    case'showMessngr':



        //alert(Object.prototype.toString.call(props));
        //alert(JSON.stringify(props,null,2));


        //props.handleState({call:'hashData',value:"#!"});
        props.handleState({call:'showMessngr',value:true});
        props.handleState({call:'messngrData',value: {

            isError         : props.isError,
            errorType       : props.errorType,
            isTimed         : props.isTimed,
            timedDuration   : props.timedDuration,
            items           : props.items

        }});
     
        //console.log("LOCATION: "+JSON.stringify(props.location,null,2));
        //console.log("HISTORY: "+JSON.stringify(history,null,2));


        /*
        if(props.location.hash !== "#!") 
        {
            //props.history.push("#!");
            props.handleRoute({call:"push",value:"#!"});

        }
        */


    break;
    case'hideMessngr':

        //alert('hideMessngr');
        //alert(JSON.stringify(props,null,2));

        props.handleState({call:'showMessngr',value:false});
        props.handleState({call:'messngrData',value:false});


        // -- kill messngr -- //
        
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentZone + "_runMessngr",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"DELETE_ZONE",
            key     :currentZone + "_messngrData",
            value   :false

        }});



        // -- remove zone stuff -- //





        // -- kill all flyr stuff -- //

        currentFlyr = props.zoneObj.currentFlyr;

        if(props.zoneObj[currentFlyr + "_runComplete"])
        {

            props.handleState({call:'handleDispatch',value:{

                action  :"DELETE_ZONE",
                key     :currentFlyr + "_postTo",
                value   :false

            }});
            props.handleState({call:'handleDispatch',value:{

                action  :"DELETE_ZONE",
                key     :currentFlyr + "_call",
                value   :false

            }});
            props.handleState({call:'handleDispatch',value:{

                action  :"DELETE_ZONE",
                key     :currentFlyr + "_currentKey",
                value   :false

            }});
            props.handleState({call:'handleDispatch',value:{

                action  :"UPDATE_ZONE",
                key     :currentFlyr + "_runComplete",
                value   :false

            }});

            props.handleState({call:'hashData',value:false}); 

            props.handleState({call:'showFlyr',value:false});
            props.handleState({call:'flyrData',value:false});


        }else{

            props.handleState({call:'handleDispatch',value:{

                action  :"UPDATE_ZONE",
                key     :currentFlyr + "_runComplete",
                value   :false
    
            }});

        }


    break;
    case'showReadr':


        alert("showReadr");


        //alert(Object.prototype.toString.call(props));
        //alert(JSON.stringify(props,null,2));

        props.handleState({call:'hashData',value:"#!"});
        props.handleState({call:'showReadr',value:true});
        props.handleState({call:'readrData',value: {

            isTimed         : props.isTimed,
            timedDuration   : props.timedDuration,
            data            : props.data

        }});
     
        //console.log("LOCATION: "+JSON.stringify(props.location,null,2));
        //console.log("HISTORY: "+JSON.stringify(history,null,2));

        /*
        if(props.location.hash !== "#!") 
        {
            //props.history.push("#!");
            props.handleRoute({call:"push",value:"#!"});

        }
        */


        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentZone + "_runAccount",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentZone + "_runCalculate",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"DELETE_ZONE",
            key     :currentZone + "_postTo",
            value   :props.data.postTo

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"DELETE_ZONE",
            key     :currentZone + "_call",
            value   :props.data.call

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"DELETE_ZONE",
            key     :currentZone + "_currentKey",
            value   :props.data.keyname

        }});




    break;
    case'showFlyr':


        hashbang = "#!";
        if(props.data.flyr.hashbang 
        && props.data.flyr.hashbang !== undefined) 
            hashbang = props.data.flyr.hashbang;


        // console.log("PROPS: "+JSON.stringify(props,null,2));
        // //console.log("HASHBANG: "+hashbang);
        // alert('Prime Handler showFlyr');


        props.handleState({call:'hashData',value:hashbang});
        //props.handleState({call:'hashData',value:"#!"});
        props.handleState({call:'showFlyr',value:true});
        props.handleState({call:'flyrData',value:props});

        /*
        props.handleState({call:'flyrData',value:{

            gridType    :props.gridType,
            items       :props.data,
            position    :props.position,
            id          :props.id

        }});
        */

     
        //console.log("LOCATION: "+JSON.stringify(props.location,null,2));
        //console.log("HISTORY: "+JSON.stringify(history,null,2));


        /*
        if(props.location.hash !== "#!") 
        {
            //props.history.push("#!");
            props.handleRoute({call:"push",value:"#!"});

        }
        */

        regx = new RegExp("#!","gi");
        if(!props.location.hash.match(regx)
        )
        {
            //props.history.push(hashbang);
            props.handleRoute({call:"push",value:hashbang});
        }
        

    break;
    case'showFGridFlyr':


        props.handleState({call:'hashData',value:"#!"});
        props.handleState({call:'showFGridFlyr',value:true});
        props.handleState({call:'fgridFlyrData',value:{

            gridType    :props.gridType,
            items       :props.data,
            position    :props.position,
            id          :props.id

        }});
     
        //console.log("LOCATION: "+JSON.stringify(props.location,null,2));
        //console.log("HISTORY: "+JSON.stringify(history,null,2));


        if(props.location.hash !== "#!") 
        {
            //props.history.push("#!");
            props.handleRoute({call:"push",value:"#!"});

        }

    break;
    case'showVidFlyr':


        props.handleState({call:'hashData',value:"#!"});
        props.handleState({call:'showVidFlyr',value:true});
        props.handleState({call:'vidFlyrData',value:{

            gridType    :props.gridType,
            items       :props.data,
            position    :props.position,
            id          :props.id

        }});
     
        //console.log("LOCATION: "+JSON.stringify(props.location,null,2));
        //console.log("HISTORY: "+JSON.stringify(history,null,2));


        if(props.location.hash !== "#!") 
        {
            //props.history.push("#!");
            props.handleRoute({call:"push",value:"#!"});

        }

    break;
    default:


        // remove all flyrs and reset
        
        //scrollbox = document.getElementById('scrollbox');
        //scrollpos = scrollbox.scrollTop;

        props.handleState({call:'hashData',value:false}); 

        props.handleState({call:'showConfirm',value:false});
        props.handleState({call:'confirmData',value:false});
        
        props.handleState({call:'showLoader',value:false});
        props.handleState({call:'loaderData',value:false});

        props.handleState({call:'showLogin',value:false});
        props.handleState({call:'loginData',value:false});

        props.handleState({call:'showMenu',value:false});
        props.handleState({call:'menuData',value:false});

        props.handleState({call:'showMessngr',value:false});
        props.handleState({call:'messngrData',value:false});

        props.handleState({call:'showReadr',value:false});
        props.handleState({call:'readrData',value:false});
    
        props.handleState({call:'showFlyr',value:false});
        props.handleState({call:'flyrData',value:false});
     
        props.handleState({call:'showFGridFlyr',value:false});
        props.handleState({call:'fgridFlyrData',value:false});
     
        props.handleState({call:'showVidFlyr',value:false});
        props.handleState({call:'vidFLyrData',value:false});




        // -- kill messngr -- //

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentZone + "_runMessngr",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"DELETE_ZONE",
            key     :currentZone + "_messngrData",
            value   :false

        }});




        switch(props.action)
        {
        case'reload':

            //window.location.reload();//regular dom 
            props.handleRoute({call:"reload",value:""});
        break;
        case'redirect':

            //history.replace("/");
            props.handleRoute({call:"redirect",value:"/"});
        break;
        default:


            /*
            if(props.location.hash === "#!")
            {
                //history.goBack(); 
                props.handleRoute({call:"goBack",value:""});
            }
            */

            regx = new RegExp("#!","gi");
            if(props.location.hash.match(regx))
            {

                //history.goBack(); 
                props.handleRoute({call:"goBack",value:""});
            }
            
        break;
        }//==

        //scrollbox.scrollTop = scrollpos;

    break;
    }//==


}


export default Handler;


export const SVG_WIDESCREEN_LOGO = (props) => {


	let svgWidth;
	let svgHeight;
	let svgStyle;
	let svgViewBox;
	let svgPreserveAspectRatio;

	svgWidth = props.svgWidth || "600";
	svgHeight = props.svgHeight || "200";
	svgViewBox = props.svgViewBox || "0 0 600 200";
	svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	svgStyle = props.svgStyle || {
		background:"transparent",
		margin:"0 0 0 0"
	};

	if(props.svgWidth)
	{
		svgWidth = props.svgWidth;
		if(!props.svgWidth.match(/px/)) svgWidth = props.svgWidth + "px";
	}
	if(props.svgHeight)
	{
		svgHeight = props.svgHeight;
		if(!props.svgHeight.match(/px/)) svgHeight = props.svgHeight + "px";
	}
	// if(props.svgStyle)
	// {
	// }






	// let backgroundColor = props.backgroundColor || "rgb(30,35,45)";
	// let buttonColor = props.buttonColor || "rgb(255,255,255)";
	// let pieceColor = props.pieceColor || "rgb(0,255,0)";
	// let pieColor = props.pieColor || "rgb(30,35,45)";


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"

			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>


		<rect 
			fill="#141820" 
			width={svgWidth} 
			height={svgHeight}
		/>


		<path 
			fillRule="evenodd" 
			clipRule="evenodd" 
			fill="#00cc66" 
			d="M157.285,41.462c5.975-2.987,12.941-4.981,19.906-4.981 c6.968,0,13.936,1.994,19.907,4.981L177.191,80.3L157.285,41.462z"
		/>


		<path 
			fillRule="evenodd" 
			clipRule="evenodd" 
			fill="#ffffff" 
			d="M140.322,154.25h-13.555v3.173h10.203v3.533h-10.203v3.181h13.798v3.543h-18.334v-16.97h18.09V154.25z
			M104.879,167.68h-4.524v-16.97h4.524v6.713h10.322v-6.713h4.53v16.97h-4.53v-6.724h-10.322V167.68L104.879,167.68z M90.149,167.68
		   h-4.804v-13.755h4.804V167.68L90.149,167.68z M76.816,152.46v-3.916h21.857v3.916H76.816L76.816,152.46z M270.114,167.68h-4.812
		   v-13.059h-8.269v-3.925h21.354v3.925h-8.273V167.68L270.114,167.68z M255.074,167.68h-4.812v-16.97h4.812V167.68L255.074,167.68z
			M245.212,157.423v3.533h-11.017v6.724h-4.613v-16.97h18.716v4.009h-14.103v2.704H245.212L245.212,157.423z M214.128,163.802h3.436
		   c1.921,0,3.188-0.234,3.809-0.722c0.632-0.491,0.948-1.387,0.948-2.7v-2.363c0-1.327-0.315-2.223-0.948-2.709
		   c-0.622-0.485-1.888-0.729-3.809-0.729h-2.885l0,0h-10c0.063-0.312,0.137-0.6,0.229-0.853c0.284-0.825,0.754-1.473,1.398-1.953
		   c0.564-0.429,1.315-0.727,2.251-0.907c0.934-0.176,2.351-0.27,4.255-0.27h6.04c1.909,0,3.334,0.094,4.282,0.27
		   c0.943,0.181,1.694,0.479,2.247,0.907c0.626,0.476,1.084,1.111,1.371,1.938c0.287,0.812,0.438,1.867,0.438,3.138v4.686
		   c0,1.303-0.151,2.353-0.438,3.15c-0.287,0.81-0.74,1.451-1.371,1.926c-0.555,0.43-1.306,0.729-2.234,0.903
		   c-0.92,0.181-2.35,0.273-4.295,0.273h-6.016c-1.962,0-3.397-0.093-4.313-0.273c-0.902-0.174-1.642-0.474-2.218-0.903
		   c-0.645-0.475-1.116-1.106-1.398-1.875c-0.288-0.79-0.431-1.805-0.431-3.093v-4.598c0-0.34,0.005-0.677,0.029-0.986h5.202
		   c-0.234,0.509-0.352,1.181-0.352,2.046v2.322c0,1.289,0.318,2.167,0.955,2.654C210.948,163.558,212.216,163.802,214.128,163.802
		   L214.128,163.802z M195.81,150.709c2.635,0,4.421,0.393,5.377,1.201c0.945,0.787,1.426,2.239,1.426,4.351
		   c0,1.47-0.22,2.567-0.634,3.277c-0.429,0.713-1.137,1.191-2.132,1.438c0.88,0.213,1.537,0.587,1.978,1.129
		   c0.433,0.534,0.654,1.24,0.654,2.097v3.479h-5.073v-2.585c0-0.682-0.149-1.132-0.46-1.383c-0.309-0.245-0.921-0.367-1.825-0.367
		   h-9.145v4.335h-4.602v-11.583h4.602v2.986h10.04c0.679,0,1.159-0.169,1.423-0.522c0.273-0.344,0.407-0.973,0.407-1.871
		   c0-0.717-0.14-1.242-0.436-1.57c-0.286-0.322-0.755-0.496-1.394-0.496h-7.734l0,0h-6.908v-3.914H195.81L195.81,150.709z
			M161.763,167.68h-4.809v-13.284h4.809v3.027h10.426c2.151,0,2.515-0.775,2.515-2.123c0-1.425,0,1.055,0-0.379
		   c0-1.349-0.363-2.131-2.515-2.131h-15.234v-4.246h15.916c4.913,0,7.008,0.765,7.008,6.376c0,1.434,0-1.046,0,0.379
		   c0,5.608-2.095,6.38-7.008,6.38h-11.107V167.68L161.763,167.68z M516.391,150.709c2.623,0,4.416,0.393,5.372,1.201
		   c0.941,0.787,1.421,2.239,1.421,4.351c0,1.47-0.209,2.567-0.635,3.277c-0.415,0.713-1.137,1.191-2.128,1.438
		   c0.88,0.213,1.532,0.587,1.974,1.129c0.438,0.534,0.663,1.24,0.663,2.097v3.479h-5.085v-2.585c0-0.682-0.147-1.132-0.457-1.383
		   c-0.307-0.245-0.919-0.367-1.818-0.367h-9.146v4.335h-4.607v-11.583h4.607v2.986h10.036c0.678,0,1.155-0.169,1.43-0.522
		   c0.261-0.344,0.407-0.973,0.407-1.871c0-0.717-0.146-1.242-0.438-1.57c-0.294-0.322-0.761-0.496-1.399-0.496h-7.738l0,0h-6.905
		   v-3.914H516.391L516.391,150.709z M484.855,167.786c-1.96,0-3.401-0.093-4.311-0.273c-0.911-0.174-1.648-0.474-2.219-0.898
		   c-0.646-0.479-1.117-1.111-1.406-1.88c-0.274-0.79-0.43-1.805-0.43-3.093v-4.598c0-0.34,0.012-0.677,0.032-0.986h5.199
		   c-0.232,0.509-0.354,1.181-0.354,2.046v2.322c0,1.289,0.321,2.167,0.954,2.654c0.643,0.478,1.917,0.722,3.818,0.722h3.438
		   c1.912,0,3.192-0.234,3.812-0.722c0.629-0.491,0.945-1.387,0.945-2.7v-2.363c0-1.327-0.316-2.223-0.945-2.709
		   c-0.619-0.485-1.899-0.729-3.812-0.729h-2.895l0,0h-9.992c0.061-0.312,0.151-0.6,0.228-0.853c0.289-0.825,0.761-1.473,1.406-1.953
		   c0.554-0.429,1.308-0.727,2.245-0.907c0.938-0.176,2.358-0.27,4.253-0.27h6.045c1.91,0,3.338,0.094,4.286,0.27
		   c0.939,0.181,1.688,0.479,2.242,0.907c0.624,0.476,1.087,1.111,1.376,1.938c0.289,0.812,0.432,1.867,0.432,3.138v4.686
		   c0,1.303-0.143,2.353-0.432,3.15c-0.289,0.81-0.749,1.451-1.376,1.926c-0.56,0.43-1.309,0.729-2.233,0.903
		   c-0.918,0.181-2.355,0.273-4.295,0.273H484.855L484.855,167.786z M474.795,150.696v3.925h-8.273v13.059h-4.813v-13.059h-8.275
		   v-3.925H474.795L474.795,150.696z M441.147,150.696l-8.983,16.983h5.464l6.688-13.057h0.404l4.542,9.117h-7.989l-2.051,3.939
		   h17.653l-8.953-16.983H441.147L441.147,150.696z M411.922,167.68v-16.97h4.801v13.031h13.874v3.939
		   C424.365,167.68,418.136,167.68,411.922,167.68L411.922,167.68z M400.881,163.74c1.448,0,2.407-0.195,2.901-0.605
		   c0.487-0.407,0.727-1.16,0.727-2.257v-10.182h4.8v11.544c0,1.97-0.525,3.376-1.593,4.206c-1.063,0.82-2.905,1.233-5.529,1.233
		   c-2.246,0-4.49,0-6.741,0c-2.612,0-4.457-0.413-5.522-1.233c-1.064-0.83-1.591-2.236-1.591-4.206v-11.544h4.796v10.182
		   c0,1.097,0.247,1.85,0.731,2.257c0.491,0.41,1.457,0.605,2.896,0.605C398.136,163.74,399.507,163.74,400.881,163.74L400.881,163.74
		   z M369.617,161.63c0,1.347,0.35,2.11,2.508,2.11h13.248v3.939h-13.982c-4.911,0-7-0.46-7-6.05c0-1.604,0-3.275,0-4.872
		   c0-5.597,2.089-6.062,7-6.062h13.982v3.937h-13.248c-2.156,0-2.508,0.771-2.508,2.125
		   C369.617,158.354,369.617,160.025,369.617,161.63L369.617,161.63z M343.589,167.68v-16.97h4.802v13.031h13.875v3.939
		   C356.038,167.68,349.808,167.68,343.589,167.68L343.589,167.68z M326.233,150.696l-8.984,16.983h5.466l6.685-13.057h0.405
		   l4.542,9.117h-7.991l-2.047,3.939h17.646l-8.942-16.983H326.233L326.233,150.696z M298.046,161.432
		   c0,1.346,0.354,2.119,2.513,2.119h15.106v4.129h-15.928c-4.92,0-7.018-0.646-7.018-6.248c0-1.615,0-5.044,0-6.652
		   c0-5.612,2.098-6.235,7.018-6.235h15.928v4.108H300.56c-2.159,0-2.513,0.782-2.513,2.127
		   C298.046,156.388,298.046,159.816,298.046,161.432L298.046,161.432z M177.191,134.077c-23.888,0-43.796-19.918-43.796-43.822
		   c0-16.924,9.955-31.867,23.89-38.835l19.906,38.835l0,0l19.907-38.835c13.94,6.968,23.89,21.911,23.89,38.835
		   C220.988,114.159,201.082,134.077,177.191,134.077L177.191,134.077z M280.82,117.718V76.75h-14.802v40.968H280.82L280.82,117.718z
			M238.14,71.824h70.558V58.7H238.14V71.824L238.14,71.824z M332.166,117.718V99.224h34.192c15.131,0,21.563-2.371,21.563-19.68
		   c0-4.391,0,3.227,0-1.162c0-17.32-6.433-19.683-21.563-19.683h-48.995v13.131h46.896c6.632,0,7.732,2.385,7.732,6.552
		   c0,4.388,0-3.229,0,1.162c0,4.17-1.101,6.549-7.732,6.549h-32.094v-7.331h-14.803v38.956H332.166L332.166,117.718z M412.356,98.461
		   c0-4.974,0-15.564,0-20.534c0-4.152,1.101-6.539,7.731-6.539h46.517V58.7h-49.038c-15.14,0-21.601,1.936-21.601,19.227
		   c0,4.97,0,15.561,0,20.534c0,17.283,6.461,19.257,21.601,19.257h49.038v-12.722h-46.517
		   C413.457,104.996,412.356,102.622,412.356,98.461L412.356,98.461z M524.99,32.215h-2.404H209.624v2.414h312.962v99.115H209.624
		   v2.413h312.962h2.404H525V32.215H524.99z M144.953,34.628v-2.414h-67.54H75v2.414v99.115v2.413h2.414h67.54v-2.413h-67.54V34.628
		   H144.953z"
			
		/>



		</svg>
		</div>

	);//return
}
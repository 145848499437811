import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';




import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,



} from "react-router-dom";

// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import { createPortal } from 'react-dom';
import usePortal from 'utils/hooks/Portal';


//=====================================================


// -- components -- //
import { 
    SubstituteSVG
} from 'utils/components/Specials/SubstituteSVG';


//import CreateMarkup from 'utils/components/Universal/CreateMarkup';
import CreateLink from 'utils/components/Universal/CreateLink';

// import Navigator from 'utils/components/Content/Navigator';
// import SideNavInfo from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';



// -- library -- //

import { 
    LibArray, 
    LibElem 
} from 'library';



import {

    Alfa,
    Base,

    //Flank,
    MenuBase,
    Menu, 
    MenuControls,
    MenuTitle, 
    MenuButton,
    MenuBlank


} from './Styled';


//import { Lorem } from 'app-components/Prime/Data';

import Data from './Data';


const Index = (props) => {



    const portalTarget = usePortal("stage");


    //props.isError
    //props.isTimed
    //props.items



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();


    //console.log("Location: "+JSON.stringify(location,null,2));

    // location: {
    //     "pathname": "/Text1",
    //     "search": "",
    //     "hash": "#!",
    //     "key": "90mmxu"
    // }



    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);




    // -- window dims -- //
    //const { width, height } = useWindowDimensions();

    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
  
    let currentZone = pathname;
    currentZone = currentZone.replace(/\//gi,"-");
    currentZone = currentZone.replace(/ /gi,"-");
    if(currentZone === '') currentZone = 'Home';

    let zoneKey = currentZone + "_fetch";




    // -- ref -- //

    const refBase = useRef(null)
    //const tabRef = useRef(null)
    const refMenuBase = useRef(null);
    const refMenu = useRef(null);
    // const flankRef = useRef(null) 
    // const controlsRef = useRef(null)   



    // -- state -- //
    const [highZ, setHighZ] = useState(0)

    useEffect(() => {
        
        //setFlyrHeight(flyrRef.current.offsetHeight);
        if(highZ === 0)
        {
            var z = LibElem.HighestZ();
            setHighZ(z)

        }

    }, [highZ])




    // -- state -- //
    // const [flyrHeight, setFlyrHeight] = useState(0)
    // const [flankLeftWidth, setFlankLeftWidth] = useState("0px")
    // const [flankRightWidth, setFlankRightWidth] = useState("0px")
    // const [buttonStyle, setButtonStyle] = useState({});
    // useEffect(() => {

        
    //     //setFlyrHeight(flyrRef.current.offsetHeight);
    //     setButtonStyle({

    //         bgColor     :"#0291cd",
    //         txtColor    :"#FFF",
    //         brColor     :"#0291cd"

    //     });

    // }, [])







    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [menuData, setMenuData] = useState([]);
    const [menuGrfx, setMenuGrfx] = useState({

        logo:{

            src:'NOIMAGE',
            w:'260',
            h:'40'
        },
        bullet:{

            src:'NOIMAGE',
            w:'40',
            h:'40'
        }

    });
    const [menuStyl, setMenuStyl] = useState({

        border:"1px solid #C00",
        buttonBackgroundColorOver:false,
        buttonTextColorOver:false

    });

    const [menuHeight, setMenuHeight] = useState(false);


    useEffect(() => {


        // if(props.databind.settings 
        // && props.databind.settings !== undefined)
        // {
        //     if(props.databind.settings.headerNav
        //     && props.databind.settings.headerNav !== undefined)
        //     {
    
        //         var across = props.databind.settings.headerNav.length;
    
        //         if(across > 6) across = 6;
        //         setNumAcross(across);

        //     } 
        

        // } 


        // if(zoneObj.settings 
        // && zoneObj.settings !== undefined)
        // {
        //     if(zoneObj.settings.headerNav
        //     && zoneObj.settings.headerNav !== undefined)
        //     {

        //         var across = zoneObj.settings.headerNav.length;
    
        //         if(across > 6) across = 6;
        //         setNumAcross(across);

        //     } 
        
        // } 





        if(!isLoaded)
        {

            setIsLoaded(true);


   


            //"https://powerdigitalmedia-net.s3.amazonaws.com/" +  
            

            // console.log("siteObj: "+JSON.stringify(zoneObj.settings.siteObj,null,2));
            // alert("zoneObj.settings.siteObj: "+JSON.stringify(Object.keys(zoneObj.settings.siteObj),null,2));
            // "base_domain": "theprofitcalculator.com",
            // "database_name": "theprofitcalculator-com",
            // "main_bucket": "theprofitcalculator-com",
            // "alt_bucket": "theprofitcalculator-com",
            // "bucket": "theprofitcalculator-com",
            // "bucket_path": "//theprofitcalculator-com.s3.amazonaws.com/",


            var bucket_path = '';
            var imgSrc;


            //---------------------------------------
            // setMenuData
            //---------------------------------------

            if(zoneObj.settings 
            && zoneObj.settings !== undefined)
            {

                if(zoneObj.settings.siteObj 
                && zoneObj.settings.siteObj !== undefined)
                {
                    bucket_path = zoneObj.settings.siteObj.bucket_path;
                }


                var menuLogo = {
                    src:menuGrfx.logo.src,
                    w:menuGrfx.logo.w,
                    h:menuGrfx.logo.h
                };
                var menuBullet = {
                    src:menuGrfx.bullet.src,
                    w:menuGrfx.bullet.w,
                    h:menuGrfx.bullet.h 
                };


                // menuLogo
                if(zoneObj.settings.graphic.logo)
                {

                    var menuLogoWidth;
                    var menuLogoHeight;
                    menuLogoWidth = zoneObj.settings.graphic.logo.image.w;
                    menuLogoHeight = zoneObj.settings.graphic.logo.image.h;
                    if(menuLogoWidth > 260) menuLogoWidth = menuGrfx.logo.w;
                    if(menuLogoHeight > 40) menuLogoHeight = menuGrfx.logo.h;


                    imgSrc = bucket_path + zoneObj.settings.graphic.logo.image.src;

                    menuLogo = {
                        src:imgSrc,
                        w:menuLogoWidth,
                        h:menuLogoHeight
                    };
                }


                //menuBullet
                if(zoneObj.settings.graphic.icon)
                {
                    imgSrc = bucket_path + zoneObj.settings.graphic.icon.image.src;

                    menuBullet = {
                        src:imgSrc,
                        w:menuGrfx.bullet.w,
                        h:menuGrfx.bullet.h 
                    };
                }

                // alert("menuLogo: "+JSON.stringify(menuLogo,null,2));
                // alert("menuBullet: "+JSON.stringify(menuBullet,null,2));

                setMenuGrfx({

                    logo:menuLogo,
                    bullet:menuBullet

                });

                setMenuStyl({

                    border:zoneObj.settings.appearance.menu.menuBorder,
                    buttonBackgroundColorOver:zoneObj.settings.appearance.menu.menuButtonBackgroundColorOver,
                    buttonTextColorOver:zoneObj.settings.appearance.menu.menuButtonTextColorOver

                });





                if(zoneObj.settings.menu
                && zoneObj.settings.menu !== undefined)
                {

                    // var across = zoneObj.settings.menuNav.length;
        
                    // if(across > 6) across = 6;
                    // //setNumAcross(across);

                    //console.log("MENU NAV: "+JSON.stringify(zoneObj.settings.menuNav,null,2))


                    var menuNav = zoneObj.settings.menu;
                    var categories = [];
                    var captured = [];
                    var array = [];
                    var object;
                    var cat;

                    for(object of menuNav)
                    {
                        categories.push(object.category);
                    }
                    for(cat of categories)
                    {
                        if(!LibArray.InArray(cat,captured))
                        {

                            //title 
                            captured.push(cat);
                            if(cat.toLowerCase() !== 'main')
                            {
                                array.push({

                                    call:'title',
                                    data:{
                                        name:cat
                                    }

                                });

                            }

                            //buttons
                            for(object of menuNav)
                            {
                                if(object.category === cat)
                                {
                                    array.push({

                                        call:'button',
                                        data:{
                                            name:object.name,
                                            to:object.to,
                                            descr:object.descr
                                        }

                                    });
                                }
                            }
                        }
                    }

                    //---------------------------------
                    // create hash links 
                    //---------------------------------

                    console.log("zone fetch: "+JSON.stringify(zoneObj[zoneKey],null,2))

                    //console.log("DYNAMIC BUTTONS: "+JSON.stringify(array,null,2));


                    var i;
                    for(i=0; i < 5; i++)
                    {
                        array.push({

                            call:'blank',
                            data:{
                                name:"",
                                to:"",
                                descr:""
                            }

                        });
                    
                    }//for


                    setMenuData(array);

                }else{

                    setMenuData(Data);
                }
            

            } 
            else
            {

                setMenuData(Data);

            }



        }



        if(!menuHeight)
        {
            if(refBase.current)
            {
                if(refMenuBase.current)
                {
                    if(refMenuBase.current.offsetHeight < refBase.current.scrollHeight)
                    {
                        //console.log(refMenuBase.current.offsetHeight+"\n"+refBase.current.scrollHeight);
                        setMenuHeight(refBase.current.scrollHeight+"px");

                    }
                }
            }
        }






    },[
        
        props,
        zoneObj,
        zoneKey,
        isLoaded,
        menuGrfx,
        menuStyl,

        refBase,
        refMenuBase,
        refMenu,
        menuHeight
    ])

    




    






    // -- handle -- //

    // const handleOverOut = (hndl) => {



    //     /*

    //     //console.log(v);
    //     setButtonStyle({

    //         bgColor     :v.bgColor,
    //         txtColor    :v.txtColor,
    //         brColor     :v.brColor

    //     });

    //     */


    //     var button = document.getElementById(hndl.id);
    //     button.style.backgroundColor = hndl.bgColor;
    //     button.style.color = hndl.txtColor;
    //     button.style.border = "1px solid "+hndl.borderColor;


    // }


    // const handleConfirm = (hndl) => {

    //     switch(hndl.call)
    //     {
    //     case'yes':

    //         props.handle({

    //             call:'runConfirm',
    //             data:hndl.data

    //         });

    //     break;
    //     default:

    //         props.handle({

    //             call:'redirect'

    //         });
    
    //     }


    // }






    const handle = (hndl) => {


        switch(hndl.call)
        {
        case'url':


            props.handle({

                call:'default',
                data:false

            });

            //alert(JSON.stringify(hndl.data,null,2))

        

            // check url

            // var href = window.location.href;                //returns the href (URL) of the current page
            // var host = window.location.host;                //returns the domain with port number
            // var hostname = window.location.hostname;        //returns the domain name of the web host
            // var pathname = window.location.pathname;        //returns the path and filename of the current page
            // var protocol = window.location.protocol;        //returns the web protocol used (http: or https:)
            // var assign = window.location.assign;            //loads a new document

            var externalUrl = false;
            var subfolderUrl = false;
            var url;
            url = hndl.data;

            var internalSite = "site.site/";

            var subfolder = "site.subfolder";

            var regxInternalSite = new RegExp(internalSite,"gi");
            var regxSubfolder = new RegExp(subfolder,"gi");
            var regxHost = new RegExp(window.location.host,"gi");


            if(url.match(regxSubfolder))
            {
                url = url.split(".subfolder/")[1];
                url = "//"+window.location.host + "/" +url;

                subfolderUrl = true;
            }
            else
            if(url.match(regxInternalSite))
            {
                url = url.split(".site/")[1];
            }
            else
            if(!url.match(regxHost) && url.match(/http/g))
            {
                externalUrl = true;
            }
            //alert(externalUrl+" "+url);



            if(externalUrl)
            {
                props.handle({

                    call:'url',
                    data:{

                        action:'target',
                        url:url

                    }

                });

            }
            else
            if(subfolderUrl)
            {
                props.handle({

                    call:'url',
                    data:{

                        action:'href',
                        url:url

                    }

                });

            }

            else
            {

                // alert(pathname+" "+url);
                if(url.charAt(0) === '/') url = url.substring(1,url.length);
        
                url = "/" + url;
                props.handle({

                    call:'url',
                    data:{

                        action:'push',
                        url:url

                    }

                });

            }




        break;
        default://close

            props.handle({

                call:'default',
                data:false

            });

        }


    }








    if(!isLoaded)
    {
        return createPortal(<div>
        </div>, portalTarget);

    }else{


        return createPortal(<div>



            <Alfa
            zIndex={highZ+1}
            ></Alfa>




            {/* <Base zIndex={highZ+2}
            backgroundColor={"#00cc66"}
            //height={menuHeight}
            
            onClick={(v) => handle({

                call:"close"

            })}
            >
            </Base> */}


            <Base
            ref={refBase}
            zIndex={highZ+3}
            >


                <MenuBase zIndex={"1"}
                ref={refMenuBase}
                //backgroundColor={"#00cc66"}
                height={menuHeight}

                
                onClick={(v) => handle({

                    call:"close"

                })}
                >
                </MenuBase>



                <Menu zIndex={"2"}

                    id="flyr"
                    ref={refMenu}  

                    //backgroundColor={'#1a1926'}
                    //width={flyrW}
                    //height={flyrH}
                    //margin={flyrMart+" 0 0 "+flyrMarl}
                    //top={flyrMart}
                    //left={flyrMarl}

                    height={menuHeight}

                    border={menuStyl.border}

                >



                    {/* {HEADER} */}


                    <MenuControls
                    //ref={setTileRef}         
                    key={"controls"}
                    id={"menuControls"}
                    >

                        {/* <div 
                        className="Name"
                        >
                            {"PowerDigitalMedia"}
                    
                        </div> */}


                        {
                        menuGrfx.logo.src.match(/_SVG_/g)
                        ?

                            <div className="Logo">

                                <SubstituteSVG 

                                    imgSrc={menuGrfx.logo.src}

                                />

                            </div>
                        
                        :

                            <div className="Img">

                                <img 
                                style={{
                                    
                                    flexShrink: 0,

                                    width:"auto",

                                    //minWidth:box.image.maxWidth,
                                    //minHeight: "100%",

                                    maxWidth:"100%",
                                    maxHeight:"40px",

                                    marginTop:"0px",
                                    marginLeft:"0px"

                                }}

                                    src={menuGrfx.logo.src} 
                                    width={menuGrfx.logo.w} 
                                    height={menuGrfx.logo.h} 
                                    alt="noimg"

                                />

                            </div>

                        }


                    
                        <div 
                        className="XButton"
                        onClick={(v) => handle({

                            call:'close'

                        })}
                        >

                            {"X"}
            
                        </div>
                    
                
                    </ MenuControls>





                    {/* {ITEMS} */}


                    {
                    menuData.map((item, i) => (

                        item.call === 'title'
                        ?
                    
                            <MenuTitle
                            //ref={setTileRef}         
                            key={"menuTitle-"+i}
                            id={"menuTitle-"+i}
                            >


                                {
                                menuGrfx.bullet.src.match(/_SVG_/g)
                                ?

                                    <div className="Bullet">

                                        <SubstituteSVG 

                                            imgSrc={menuGrfx.bullet.src}

                                        />

                                    </div>
                                
                                :

                                    <div className="Img">

                                        <img 
                                            src={menuGrfx.bullet.src} 
                                            width={menuGrfx.bullet.w} 
                                            height={menuGrfx.bullet.h} 
                                            alt="noimg"
                                        />

                                    </div>


                                }


                                <div className="Text">

                                    {item.data.name}
                                    {/* {keyName+ " " +item.name} */}

                                </div>
                            
                            </ MenuTitle>


                        :
                        item.call === 'button'
                        ?



                            // <MenuButton
                            // //ref={setTileRef}         
                            // key={"menuButton-"+i}
                            // id={"menuButton-"+i}

                            // //backgroundColor={item.backgroundColor}
                            // //float={"left"}
                            // onClick={(v) => handle({
                            //     call:'url',
                            //     data:item.data
                            
                            // })}
                            // >

                            //     <div className="Text">

                            //         {item.data.name}
                            //         {/* {keyName+ " " +item.name} */}

                            //     </div>
                            
                            // </ MenuButton>
                            





                            <div key={i}>
                            {
                            item.data.to.match(/site.site/gi)
                            ?

                                <MenuButton
                                id={"menuButton-"+i}
                                onClick={(v) => handle({

                                    call      :"url",
                                    data      :item.data.to

                                })}


                                hoverBackgroundColor={menuStyl.buttonBackgroundColorOver}
                                hoverTextColor={menuStyl.buttonTextColorOver}
                                >

                                    {item.data.name}


                                </ MenuButton>




                            ://ANCHOR
                            item.data.to.match(/http/gi) || item.data.to.match(/\/\//gi)
                            ?



                                <a 
                                href={CreateLink({

                                    name:item.data.name,
                                    value:item.data.to

                                })}
                                target="_blank"
                                rel="noreferrer"
                                >

                                    <MenuButton
                                    id={"menuButton-"+i}

                                    hoverBackgroundColor={menuStyl.buttonBackgroundColorOver}
                                    hoverTextColor={menuStyl.buttonTextColorOver}
                                    >
                                        {item.data.name}

                                    </ MenuButton>

                                </a>


                        
                            :

                                <MenuButton
                                id={"menuButton-"+i}
                                onClick={(v) => handle({

                                    call      :"url",
                                    data      :item.data.to

                                })}

                                hoverBackgroundColor={menuStyl.buttonBackgroundColorOver}
                                hoverTextColor={menuStyl.buttonTextColorOver}
                                >

                                    {item.data.name}


                                </ MenuButton>



                            }
                            </div>


                        ://blank


                            <div key={i}>
                            {
                            <MenuBlank
                            id={"menuButton-"+i}
                            >
                            </ MenuBlank>
                            }
                            </div>


                    ))
                    }





                    {/* {FOOTER} */}

                    
                    {/* <MenuControls
                    //ref={setTileRef}         
                    key={"controls"}
                    id={"menuControls"}
                    >


                        {
                        menuGrfx.logo.src.match(/_SVG_/g)
                        ?

                            <div className="Logo">

                                <SubstituteSVG 

                                    imgSrc={menuGrfx.logo.src}

                                />

                            </div>
                        
                        :

                            <div className="Img">

                                <img 
                                style={{
                                    
                                    flexShrink: 0,

                                    width:"auto",

                                    //minWidth:box.image.maxWidth,
                                    //minHeight: "100%",

                                    maxWidth:"100%",
                                    maxHeight:"40px",

                                    marginTop:"0px",
                                    marginLeft:"0px"

                                }}

                                    src={menuGrfx.logo.src} 
                                    width={menuGrfx.logo.w} 
                                    height={menuGrfx.logo.h} 
                                    alt="noimg"

                                />

                            </div>

                        }


                    
                        <div 
                        className="XButton"
                        onClick={(v) => handle({

                            call:'close'

                        })}
                        >

                            {"X"}
            
                        </div>
                    
                
                    </ MenuControls>  */}




                </Menu>


            </Base>


        </div>, portalTarget);

    }






}

export default Index;



import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';

import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';

import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";


// import { LibForm, LibString } from 'library';


import ApiResources from 'utils/components/Pages/ApiResources';


//------------------------
//Axios Stuff
//------------------------

import axios from "axios";

const queryString = params =>
    Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join("&")

const createUrl = (url, queryOptions) => {

    if(Object.keys(queryOptions).length > 0)
    {
        url = url + "?" + queryString(queryOptions);
    }
    return url

}

const createPostParams = (bodyOptions) => {

    var ret;
    var params = "";
    var count = 0;
    for(var keyname in bodyOptions)
    {
        if(count === 0)
        {
            params += keyname+"="+bodyOptions[keyname];
        } else {
            params += "&"+keyname+"="+bodyOptions[keyname];
        }
        count++;
    }

    ret = params;
    if(ret === "") ret = false;
    return ret;

}




export const Fetch = (props) => {


    // alert('_Connect / AuthAccount / Fetch');

    var apiDomain;
    var hostname;
    //var hostname = window.location.hostname;//returns the domain name of the web host
    var protocol = window.location.protocol;//returns the web protocol used (http: or https:)


    if(window.location.hostname.match(/192.168/gi))
    {
        hostname = ApiResources.network['3001'].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }
    else
    if(window.location.hostname.match(/localhost/gi))
    {
        hostname = ApiResources.local['3001'].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }else{
        hostname = ApiResources.aws['x'].vpc;
        apiDomain = "https://" + hostname + "/";
    }//=



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    const hashbang = location.hash;



    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
	//console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));



    // -- state -- //
    const [inProcess, setInProcess] = useState(false)

    useEffect(() => {


        const runAxios = (eff) => {


            let url = eff.resrc;
            let options = eff.options;


            //let result;

            let axiosParams;
            if(options.method.match(/GET/gi))
            {
                axiosParams = {
                    method: "GET",
                    url: createUrl(url, options.query),
                }
                
            }
            else
            if(options.method.match(/POST/gi))
            {

                axiosParams = {

                    method: "POST",
                    url: createUrl(url, options.query),
                    data: createPostParams(options.body),
                    headers: {

                        //'Content-Type': "application/json",
                        'Content-Type'  : 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                        //'Authorization' : 'Bearer '+localStorage.accessToken
                    },
                    // responseType:'json'
                }

            }

            // Content-Type
            //     'application/x-www-form-urlencoded' 
            //     'application/json' 
            
            // responseType 
            //     'arraybuffer', 
            //     'blob', 
            //     'document', 
            //     'json', 
            //     'text', 
            //     'stream'


            axios(axiosParams)
            .then(response => {

                // console.log("response: "+JSON.stringify(response,null,2));
                // console.log("response.data: "+response.data);
                // alert(response.data);

                return response.data;

            })
            .then(data =>  {

                // console.log("Object prototype: "+Object.prototype.toString.call(data));
                // console.log("data.success: "+data.success);
                // console.log("data.result: "+data.result);
                // console.log("data.result: "+JSON.stringify(data.result,null,2));

                // alert('Zone/_Connect/AuthAccount/Fetch - success');

                // if(props.key.match(/MSZ/gi))
                // {

                //     console.log("MSZ RESULT: "+JSON.stringify(result,null,2));
                //     //alert('success');


                // }

                /*
                result={
                    "success": true,
                    "result": {
                        "tokenError": {
                            "name": "TokenExpiredError",
                            "message": "jwt expired",
                            "expiredAt": "2020-12-27T18:13:18.000Z"
                        },
                        "authorized": false,
                        "user": false
                    }
                }

                */
 
                //alert(data.success);
            
                if(data.success)
                {

                    //alert('complete');
                    props.handle({

                        call    :"TrackFetchComplete",
                        message :"SUCCESS: Process was successfull!!",
                        success :data.success,
                        result  :data.result
                
                    });
                

                }else{

                    //alert("Account axios error - 1.2");
                    props.handle({

                        call    :"TrackFetchError",
                        message :"SUCCESS: No Data",
                        success :false,
                        result  :false
                
                    });

                }


            })
            .catch(error =>  {


                console.log("FAILED");
                console.log(error);


                //alert("Account error - 1.1");
                props.handle({

                    call       :"TrackFetchError",
                    type       :'process',
                    errors     :[{

                        "key"       :"0-process",
                        "section"   :"process",
                        "message"   :error.message
    
                    }]
        
                });


            });




        };//runAxios
        
        //#############################################################################
        //#############################################################################

        /**
         * 
         * 
         * To block multiple hits to the server 
         * 
         * 1) Set response to "processing" or "not null" once fetch begins - this prevents a function rerun
         * 2) Keep loading set to true until fetch is complete
         * 3) On page component check that loading is "false" before calling dispatch
         * 
         * 
         * NOTE: There are two version async and non async - no differcene I see between the two
         * use the non async version
         * 
         * 
         */





        if(!inProcess)
        {

            setInProcess(true);


            var isOkay;
            // var okar = [];  
            // var ear = [];

            // var frm;
            // var label;
            // var field;
            // var array;
            // //var object;
            // var err;

            // //var indx;
            // var i;
            // var j;

            // var newVal;


            //console.log(JSON.stringify(formArr,null,2));
            //alert('submit check');

            isOkay = zoneObj.track ? false : true;

            if(zoneObj.track && zoneObj.track !== undefined)
            {
                props.handle({

                    call    :"TrackExists"
                
                });
        
            }
            else
            if(isOkay)
            {
 
                var _VARS;
                var testOutput = false;
                if(testOutput)
                {

                  
                    // var keyname;
                    // var html = '';
                    // var str;

                    // var items = props.data;

                    // for(keyname in items)
                    // {
                    //     html += "<h2>"+keyname+"</h2>";
        
                    //     str = items[keyname];
                    //     str = JSON.stringify(str,null,2);
                    //     str = str.replace(/\n/gi,"<br/>&nbsp;");
        
                    //     html += str;

                    // }//==


                    // props.handle({

                    //     call:"showReadr",
                    //     data :{

                    //         data:{
                    //             name:"Readr",
                    //             value:html
                    //         },
                    //         flyr:{
                    //             type:'print',
                    //             styl:{
                    //                 maxWidth:750
                    //             }
                    //         }

                    //     }
        
                    // });


                    alert('testing output');



                }
                else
                {


                    _VARS = JSON.stringify({});

                    // var site;
                    // if(apiDomain.match(/localhost/))
                    // {
                    //     site = apiDomain;
                    // }else{
                    //     site = window.location.hostname;
                    // }


                    // hostname = ApiResources.aws['x'].vpc;
                    // apiDomain = "https://" + hostname + "/";
                    //var resrc = apiDomain + "fetch-siteplan";
                    //var resrc = apiDomain + "fetch-blueprint";


                    var resrc = apiDomain + "track-fetch";
                    //if(!props.postTo || props.postTo !== undefined) resrc = props.postTo;
                    //alert("RESRC: "+resrc);

                    var site = 'theprofitcalculator.com';
                    var email = false;
        
                    if(window.location.hostname)
                    {
                        site = window.location.hostname;
                    }
                    if(localStorage.accessEmail && localStorage.accessEmail !== undefined)
                    {
                        email = localStorage.accessEmail;
                    }
        
        
                    // var href = window.location.href;                //returns the href (URL) of the current page
                    // var host = window.location.host;                //returns the domain with port number
                    // var hostname = window.location.hostname;        //returns the domain name of the web host
                    // var pathname = window.location.pathname;        //returns the path and filename of the current page
                    // var protocol = window.location.protocol;        //returns the web protocol used (http: or https:)
                    // var assign = window.location.assign;            //loads a new document
                    
                    // alert(""
                    // +"\nlocation: "+JSON.stringify(location,null,2)
                    // +"\nhostname: "+window.location.hostname
                    // +"\nsite: "+site
                    // +"\nemail: "+email
                    // +"\naccessEmail: "+localStorage.accessEmail
                    // +"\naccessToken: "+localStorage.accessToken 
                    // +"\nrefreshToken: " +localStorage.refreshToken
                    // );
        
                
                    runAxios({
        
                        resrc:resrc,
                        options:{
        
                            method:"POST",
                            body:{

                                site    :site,
                                email   :email,
                                tree    :"app",
                                route   :'app',
                                vars    :_VARS

                            },
                            query:{}
        
                        }
        
                            
                    });


                }// -- run

                    
            }//==


        }
        else
        {

            console.log("Axios - Track - Fetch -  Processing...");


        }//-- inProcess


   
    }, [

        hashbang,
        inProcess,
        zoneObj,
        props,
        apiDomain
 
    ])


    return <div></div>


}




import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';



//---------------------------
//utils/hooks
//---------------------------

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
import useDeviceDetect from 'utils/hooks/DeviceDetect';


//---------------------------
//utils/components
//---------------------------

import {
    Cleaner as XssCleaner
} from 'utils/components/Xss';







//import '../../StyleSheets/dynamic-form.css';
import 'app-style/dynamic-form/style.css';


import Exceptions from '../Exceptions';
import Restrictions from '../Restrictions';




//https://stackoverflow.com/questions/21733847/react-jsx-selecting-selected-on-selected-select-option


export const FFlabelfield = (props) => {



    //-------------------------------
    // hooks
    //-------------------------------

    // -- window dims -- //
    const { 
        width,
        // height 
    } = useWindowDimensions();

    // -- Device Detect -- //
    const DeviceDetection = useDeviceDetect();
    //console.log("FFcostfield - DeviceDetection " + JSON.stringify(DeviceDetection));
    let isMobile = DeviceDetection.isMobile;





    //==========================================
    // state/effect - fieldVal
    //==========================================

    const [threshold] = useState(550);

    const [fieldVal, setFieldVal] = useState('');
    const [fieldStyl, setFieldStyl] = useState({});
    const [passwordFieldType, setPasswordFieldType] = useState('password');

    useEffect(() => {


        var call,value;

        var fontStyle = 'normal';
        var fontColor = "#000";

        let data = props.data;

        for(var keyname in data)
        {
            switch(keyname)
            {
            case'label':
            break;
            case'field':

                call = data[keyname].id;
                value = data[keyname].text;
                value = XssCleaner({call:call,value:value});
                setFieldVal(value);

                if(data[keyname].fieldtype === 'select')
                {
                    if(data[keyname].hint === data[keyname].text 
                    || data[keyname].text === ''
                    )
                    {
                        fontStyle = 'italic';
                        fontColor = '#777';
                    }

                }//==

            break;
            default:
            break;
            }//==

        }


        setFieldStyl({
            "bgColor":"transparent",
            "fontStyle":fontStyle,
            "fontColor":fontColor
        });


        var problemArr = props.problemArr;
        for(var i=0; i < problemArr.length; i++)
        {
            if(props.ij === problemArr[i]['key'])
            {
                setFieldStyl({
                    "bgColor":"lightyellow",
                    "fontStyle":fontStyle,
                    "fontColor":fontColor
                });
                break;
            }
    
        }//== i




        if(props.viewPasswords !== undefined)
        {
            if(props.viewPasswords && passwordFieldType === 'password')
            {
                setPasswordFieldType('text');
            }
            else
            if(!props.viewPasswords && passwordFieldType === 'text')
            {
                setPasswordFieldType('password');
            }

        }



        //setThreshold(550);



 
     },[
         props,
         passwordFieldType
    ])
 
 




    //==========================================
    // state/effect - hasException
    //==========================================

    const [hasException, setHasException] = useState(false);

    useEffect(() => {

        //let temps = [];
        let exceptArr = Exceptions.mobileInputMode.labelfields;
        let exceptObj;
        let found = false;
        let data = props.data;
        for(var keyname in data)
        {
            switch(keyname)
            {
            case'label':
            break;
            case'field':

                //temps.push(data[keyname].id);

                for(exceptObj of exceptArr)
                {
                    if(exceptObj.id === data[keyname].id)
                    {
                        found = {

                            inputMode:exceptObj.inputMode

                        }//found
                        break;

                    }//if

                }//for 


            break;
            default:
            break;
            }//==

        }// loop

        if(found) setHasException(found);


        //alert("found: "+JSON.stringify(found,null,2));

        //alert("TEMPS: "+JSON.stringify(temps,null,2));




 
     },[
         props
    ])
 













    //<input name="firstName" onChange={e => setFirstName(e.target.value)} />
     

      

    // -- handle -- //

    function handleFocus(target) {

        target.select();


        // if(isMobile)
        // {
        //     if(document.getElementById("flyrBottomBumper"))
        //     {
        //         var elem = document.getElementById("flyrBottomBumper");
        //         elem.style.height = "0px";
        //         console.log("elem.id: "+elem.id);

        //     }

        // }

     
    }


    
    function handleBlur(e) {


        console.log("TARGET VALUE: ", e.target.value);
        console.log("TARGET TYPE: ", e.target.type);
        console.log("TARGET HINT: ", props.data.field.hint);
        console.log("TARGET ID: ", e.target.id);

        var inp = document.getElementById(e.target.id);
        //alert(inp.value);
        inp.blur();


        var newVal;
        newVal = fieldVal;
        if(e.target.value !== '')
        {
            newVal = e.target.value;
        }
 

    
        switch(e.fieldtype)
        {
        case'price':


            if(isNaN(newVal) || newVal === '') newVal = 0;
            newVal = parseFloat(newVal);
            newVal = newVal.toFixed(2);

            newVal = XssCleaner({call:"price",value:newVal});
  
            setFieldVal(newVal);

            props.handle({

                call        :'capture',
                fieldCall   :"labelfield",
                key         :props.ij,
                id          :props.data.id,
                data        :props.data,
                newValue    :newVal
            
            });

        break;
        case'percent':

            if(isNaN(newVal) || newVal === '') newVal = 0;
            newVal = parseFloat(newVal);
            newVal = newVal.toFixed(1);

            newVal = XssCleaner({call:"percent",value:newVal});

            setFieldVal(newVal);

            props.handle({

                call        :'capture',
                fieldCall   :"labelfield",
                key         :props.ij,
                id          :props.data.id,
                data        :props.data,
                newValue    :newVal
            
            });

        break;
        default:
        }


    }
    



    function handleChange(e) {

        console.log("TARGET VALUE: ", e.target.value);
        //console.log("TARGET TYPE: ", e.target.type);
        //console.log("TARGET HINT: ", props.data.field.hint);
        console.log("IJ: "+props.ij+" \nINDX: "+e.indx);


        // var inp = document.getElementById(e.target.id);
        // alert(inp.value);


        // alert(e.target.value+"=="+fieldVal);

        var newStyl;
        var newVal;
        var newArr;
        var i;
    

        /*
        if(e.target.type === 'select')
        {

            newStyl = fieldStyl;

            if(e.target.value !== props.data.field.hint)
            {
                newStyl['fontStyle'] = 'normal';
                newStyl['fontColor'] = '#000';
            }else{
                newStyl['fontStyle'] = 'italic';
                newStyl['fontColor'] = '#777';
            }

            setFieldStyl(newStyl);

        }

        setFieldVal(e.target.value);

        */





        /*
        if(props.problemArr.length > 0)
        {
            props.handle({

                call        :'clear',
                fieldCall   :"labelfield",
                key         :props.ij,
                id          :props.data.id,
                data        :props.data,
                newValue    :newVal
            
            });

        }
        */


        if(props.problemArr.length > 0)
        {
            if(props.ij !== undefined)
            {
                newArr = [];
                for(i=0; i < props.problemArr.length; i++)
                {

                    //console.log(props.problemArr[i]['key'] +" "+ e.indx);
                    if(props.problemArr[i]['key'] !== props.ij)
                    {
                        newArr.push(props.problemArr[i]);
                    }
                }

                console.log(JSON.stringify(newArr,null,2));
                //alert('new')
                props.handle({

                    call:"resetProblemArr",
                    errors:newArr

                });

            }
        }
        


        newVal = fieldVal;
        if(e.target.value !== '')
        {
            newVal = e.target.value;
        }else{
            newVal = '';
        }


        //alert(props.data.field.id);
        var object;
        for(object of Restrictions)
        {
            if(object.id === props.data.field.id)
            {
                if(object.restrict === 'numberOnly')
                {
                    if(isNaN(newVal))
                    {
                        newVal = 0;

                    }
                }
                break;
            }
        }

        
        switch(e.fieldtype)
        {
        case'select':

            newStyl = fieldStyl;

            if(e.target.value !== props.data.field.hint)
            {
                newStyl['fontStyle'] = 'normal';
                newStyl['fontColor'] = '#000';
            }else{
                newStyl['fontStyle'] = 'italic';
                newStyl['fontColor'] = '#777';
            }

            setFieldStyl(newStyl);

        break;
        case'price':

            if(isNaN(newVal) || newVal === '') newVal = 0;
        break;
        case'percent':

            if(isNaN(newVal) || newVal === '') newVal = 0;
        break;
        default:
        }


        newVal = XssCleaner({call:e.fieldtype,value:newVal});

        setFieldVal(newVal);

        props.handle({

            call        :'capture',
            fieldCall   :"labelfield",
            key         :props.ij,
            id          :props.data.field.id,
            data        :props.data,
            newValue    :newVal
        
        })


    }




    // function noChange(e) {

    //     console.log("noChange");

    // }




    // -- display -- //

    return (


        <div className="frm">


            {
            //------------------------------------
            //HIDDEN
            //------------------------------------

            props.data.field.fieldtype === "hidden" 
            ? 


                <div 
                key={props.ij}
                >

                    <input
                        type="hidden"
                        id={props.data.field.id}
                        value={fieldVal}          
                        placeholder={props.data.hint}
                    />

                </div>



            : null


            //-------------------------------------
            }




            {
            //------------------------------------
            //TEXT FIELD
            //------------------------------------

            props.data.field.fieldtype === "text" 
            ? 


                <div 
                key={props.ij}
                className="labelfield"
                style={{
                    backgroundColor:'transparent'
                }}
                >

                    <div 
                    className="label"
                    style={

                        width < threshold 
                        ?

                            {
                                width:"100%",
                                margin:"0 0 0 0"
                            }

                        :null
                    }
                    >

                        {props.data.label.text}

                    </div>




                    <div 
                    className="field-wrap"
                    style={

                        width < threshold 
                        ?

                            {
                                width:"100%",
                                margin:"0 0 0 0"
                            }

                        :null
                    }
                    >

                        <input
                            type="text"
                            inputMode="text"
                            // type="number" pattern="[0-9]*"
                            // inputMode="numeric"

                            className="input-tag"
                            style={{
                                backgroundColor:fieldStyl.bgColor,
                                fontStyle:fieldStyl.fontStyle,
                                color:fieldStyl.fontColor
                            }}
            
                            id={props.data.field.id}
                            value={fieldVal}
                            placeholder={props.data.field.hint}
                            tabIndex={props.data.field.tabIndex}
        
                            //onFocus={handleFocus}

                            /*
                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => target.select(), 0);
                            }}
                            */

                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}
                            

                            // onBlur={event => {

                            //     handleBlur({

                            //         target      :event.target,
                            //         fieldtype   :props.data.field.fieldtype

                            //     })

                            // }}  


                            
    
                            onChange={event => {

                                handleChange({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype

                                })

                            }}

                            onKeyPress = {event => {
                                // event properties must be copied to use async
                                setTimeout(() => props.handleMovement({

                                    event       :event,
                                    section     :"labelfields",
                                    index       :props.ij
                                
        
                                }), 0);
                            }}



                        />

                
                    </div>

                </div>



            : null


            //-------------------------------------
            }





            {
            //------------------------------------
            //TEXTAREA
            //------------------------------------

            props.data.field.fieldtype === "textarea" 
            ? 

                <div 
                key={props.ij}
                className="labelfield"
                style={{
                    backgroundColor:'transparent'
                }}

                >



                    <div 
                    className="label-ta"
                    style={

                        width < threshold 
                        ?

                            {
                                width:"100%",
                                margin:"0 0 0 0"
                            }

                        :null
                    }
                    >

                        {props.data.label.text}

                    </div>



                    <div 
                    className="field-wrap"
                    style={

                        width < threshold 
                        ?

                            {
                                width:"100%",
                                margin:"0 0 0 0"
                            }

                        :null
                    }
                    >

                        <textarea
                        className="ta-tag"
                        style={{
                            backgroundColor:fieldStyl.bgColor,
                            fontStyle:fieldStyl.fontStyle,
                            color:fieldStyl.fontColor

                        }}

                        id={props.data.field.id}
                        placeholder={props.data.hint}
                        tabIndex={props.data.field.tabIndex}


                        value={fieldVal}


                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}
                            

                            /*
                            onBlur={event => {

                                handleBlur({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype

                                })

                            }} 
                            */ 
    
                            onChange={event => {

                                handleChange({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype

                                })

                            }}

                            onKeyPress = {event => {
                                // event properties must be copied to use async
                                setTimeout(() => props.handleMovement({

                                    event       :event,
                                    section     :"labelfields",
                                    index       :props.ij
    
        
                                }), 0);
                            }}



                        >
                        </textarea>

                    </div>
                    
                </div>
            
            : null


            //-------------------------------------
            }







            {
            //------------------------------------
            //SELECT
            //------------------------------------

            props.data.field.fieldtype === "select" 
            ? 

                <div 
                key={props.ij}
                className="labelfield"
                style={{
                    backgroundColor:'transparent'
                }}

                >



                    <div 
                    className="label"
                    style={

                        width < threshold 
                        ?

                            {
                                width:"100%",
                                margin:"0 0 0 0"
                            }

                        :null
                    }
                    >

                        {props.data.label.text}

                    </div>




                    <div 
                    className="field-wrap"
                    style={

                        width < threshold 
                        ?

                            {
                                width:"100%",
                                margin:"0 0 0 0"
                            }

                        :null
                    }
                    >

                        <select

                        className="sel-tag"
                        style={{
                            backgroundColor:fieldStyl.bgColor,
                            fontStyle:fieldStyl.fontStyle,
                            color:fieldStyl.fontColor
                        }}

                        id={props.data.field.id}
                        placeholder={props.data.field.hint}
                        tabIndex={props.data.field.tabIndex}

                        value={fieldVal}
                        onChange={event => {

                            handleChange({

                                target      :event.target,
                                fieldtype   :props.data.field.fieldtype

                            })

                        }}
                        
                
                        /*
                        onFocus = {event => {
                            // event properties must be copied to use async
                            const target = event.target;
                            setTimeout(() => handleFocus(target), 0);
                        }}
                        
                        onBlur={event => {

                            handleBlur({

                                target      :event.target,
                                fieldtype   :props.data.field.fieldtype

                            })

                        }}
                        */


                        onKeyPress = {event => {
                            // event properties must be copied to use async
                            setTimeout(() => props.handleMovement({

                                event       :event,
                                section     :"labelfields",
                                index       :props.ij

    
                            }), 0);
                        }}

                        
        
                        >

                                {
                                //====================================			
                                //first option
                                //====================================
                                }

                                <option 
                                className="opt"       
                                key={props.data.field.hint} 
                                value={props.data.field.hint}
                                >

                                    {props.data.field.hint}

                                </option>
                            

                
                                {
                                //====================================			
                                //options
                                //====================================


                                props.data.field.array.map((option) => (

                                    <option 
                                    className="opt"                     
                                    key={option} 
                                    value={option}

                                    >

                                        {option}

                                    </option>
                                ))

                                }


                        </select>


                    </div>

                </div>
            
            : null


            //-------------------------------------
            }









            {
            //------------------------------------
            //PRICE FIELD
            //------------------------------------

            props.data.field.fieldtype === "price" 
            ? 

                isMobile
                ?

                    <div 
                    key={props.ij}
                    className="labelfield"
                    style={{
                        backgroundColor:'transparent'
                    }}
                    >

                        <div 
                        className="label"
                        style={

                            width < threshold
                            ?
    
                                {
                                    width:"100%",
                                    margin:"0 0 0 0"
                                }
    
                            :null
                        }
                        >

                            {props.data.label.text}

                        </div>




                        <div 
                        className="field-wrap"
                        style={

                            width < threshold 
                            ?
    
                                {
                                    width:"100%",
                                    margin:"0 0 0 0"
                                }
    
                            :null
                        }
                        >


                            <div
                            className="symbol"
                            >
                                $
                            </div>


                            <input
                            
                                // type="text"
                                // inputMode="text"
                                type="number" pattern="[0-9]*"
                                inputMode="numeric"

                                className="symbol-input-tag"
                                style={{
                                    backgroundColor:fieldStyl.bgColor,
                                    fontStyle:fieldStyl.fontStyle,
                                    color:fieldStyl.fontColor
                                }}
                
                                id={props.data.field.id}
                                value={fieldVal}
                                placeholder={props.data.field.hint}
                                tabIndex={props.data.field.tabIndex}

            
                                //onFocus={handleFocus}

                                /*
                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => target.select(), 0);
                                }}
                                */

                                onFocus={event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocus(target), 0);
                                }}
                                onBlur={event => {

                                    handleBlur({

                                        target      :event.target,
                                        fieldtype   :props.data.field.fieldtype

                                    })

                                }}  
        
                                onChange={event => {

                                    handleChange({

                                        target      :event.target,
                                        fieldtype   :props.data.field.fieldtype

                                    })

                                }}

                                onKeyPress = {event => {
                                    // event properties must be copied to use async
                                    setTimeout(() => props.handleMovement({

                                        event       :event,
                                        section     :"labelfields",
                                        index       :props.ij
        
            
                                    }), 0);
                                }}




                            />

                    
                        </div>

                    </div>



                : //NOT MOBILE




                    <div 
                    key={props.ij}
                    className="labelfield"
                    style={{
                        backgroundColor:'transparent'
                    }}
                    >

                        <div 
                        className="label"
                        style={

                            width < threshold 
                            ?
    
                                {
                                    width:"100%",
                                    margin:"0 0 0 0"
                                }
    
                            :null
                        }
                        >

                            {props.data.label.text}

                        </div>




                        <div 
                        className="field-wrap"
                        style={

                            width < threshold 
                            ?
    
                                {
                                    width:"100%",
                                    margin:"0 0 0 0"
                                }
    
                            :null
                        }
                        >


                            <div
                            className="symbol"
                            >
                                $
                            </div>


                            <input
                            
                                type="text"
                                inputMode="text"

                                className="symbol-input-tag"
                                style={{
                                    backgroundColor:fieldStyl.bgColor,
                                    fontStyle:fieldStyl.fontStyle,
                                    color:fieldStyl.fontColor
                                }}
                
                                id={props.data.field.id}
                                value={fieldVal}
                                placeholder={props.data.field.hint}
                                tabIndex={props.data.field.tabIndex}

            
                                //onFocus={handleFocus}

                                /*
                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => target.select(), 0);
                                }}
                                */

                                onFocus={event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocus(target), 0);
                                }}
                                onBlur={event => {

                                    handleBlur({

                                        target      :event.target,
                                        fieldtype   :props.data.field.fieldtype

                                    })

                                }}  
        
                                onChange={event => {

                                    handleChange({

                                        target      :event.target,
                                        fieldtype   :props.data.field.fieldtype

                                    })

                                }}

                                onKeyPress = {event => {
                                    // event properties must be copied to use async
                                    setTimeout(() => props.handleMovement({

                                        event       :event,
                                        section     :"labelfields",
                                        index       :props.ij
        
            
                                    }), 0);
                                }}




                            />

                    
                        </div>

                    </div>





            : null


            //-------------------------------------
            }







            {
            //------------------------------------
            //PERCENT FIELD
            //------------------------------------

            props.data.field.fieldtype === "percent" 
            ? 


                isMobile
                ?

                    <div 
                    key={props.ij}
                    className="labelfield"
                    style={{
                        backgroundColor:'transparent'
                    }}
                    >

                        <div 
                        className="label"
                        style={

                            width < threshold 
                            ?
    
                                {
                                    width:"100%",
                                    margin:"0 0 0 0"
                                }
    
                            :null
                        }
                        >

                            {props.data.label.text}

                        </div>




                        <div 
                        className="field-wrap"
                        style={

                            width < threshold 
                            ?
    
                                {
                                    width:"100%",
                                    margin:"0 0 0 0"
                                }
    
                            :null
                        }
                        >


                            <div
                            className="symbol"
                            >
                                %
                            </div>




                            <input

                                // type="text"
                                // inputMode="text"

                                type="number" pattern="[0-9]*"
                                inputMode="numeric"

                                className="symbol-input-tag"
                                style={{
                                    backgroundColor:fieldStyl.bgColor,
                                    fontStyle:fieldStyl.fontStyle,
                                    color:fieldStyl.fontColor
                                }}
                
                                id={props.data.field.id}
                                value={fieldVal}
                                placeholder={props.data.field.hint}
                                tabIndex={props.data.field.tabIndex}

            
                                //onFocus={handleFocus}

                                /*
                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => target.select(), 0);
                                }}
                                */

                                onFocus={event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocus(target), 0);
                                }}
            
                                onBlur={event => {

                                    handleBlur({

                                        target      :event.target,
                                        fieldtype   :props.data.field.fieldtype

                                    })

                                }}

                                onChange={event => {

                                    handleChange({

                                        target      :event.target,
                                        fieldtype   :props.data.field.fieldtype

                                    })

                                }}

                                onKeyPress = {event => {
                                    // event properties must be copied to use async
                                    setTimeout(() => props.handleMovement({

                                        event       :event,
                                        section     :"labelfields",
                                        index       :props.ij
        
            
                                    }), 0);
                                }}




                            />

                    
                        </div>

                    </div>




                : // NOT MOBILE


                    <div 
                    key={props.ij}
                    className="labelfield"
                    style={{
                        backgroundColor:'transparent'
                    }}
                    >

                        <div 
                        className="label"
                        style={

                            width < threshold 
                            ?
    
                                {
                                    width:"100%",
                                    margin:"0 0 0 0"
                                }
    
                            :null
                        }
                        >

                            {props.data.label.text}

                        </div>




                        <div 
                        className="field-wrap"
                        style={

                            width < threshold 
                            ?
    
                                {
                                    width:"100%",
                                    margin:"0 0 0 0"
                                }
    
                            :null
                        }
                        >


                            <div
                            className="symbol"
                            >
                                %
                            </div>




                            <input

                                type="text"
                                inputMode="text"

                                // type="number" pattern="[0-9]*"
                                // inputMode="numeric"

                                className="symbol-input-tag"
                                style={{
                                    backgroundColor:fieldStyl.bgColor,
                                    fontStyle:fieldStyl.fontStyle,
                                    color:fieldStyl.fontColor
                                }}

                                id={props.data.field.id}
                                value={fieldVal}
                                placeholder={props.data.field.hint}
                                tabIndex={props.data.field.tabIndex}


                                //onFocus={handleFocus}

                                /*
                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => target.select(), 0);
                                }}
                                */

                                onFocus={event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocus(target), 0);
                                }}

                                onBlur={event => {

                                    handleBlur({

                                        target      :event.target,
                                        fieldtype   :props.data.field.fieldtype

                                    })

                                }}

                                onChange={event => {

                                    handleChange({

                                        target      :event.target,
                                        fieldtype   :props.data.field.fieldtype

                                    })

                                }}

                                onKeyPress = {event => {
                                    // event properties must be copied to use async
                                    setTimeout(() => props.handleMovement({

                                        event       :event,
                                        section     :"labelfields",
                                        index       :props.ij


                                    }), 0);
                                }}




                            />


                        </div>

                    </div>





            : null


            //-------------------------------------
            }






            {
            //------------------------------------
            //STANDARD FIELD
            //------------------------------------

            props.data.field.fieldtype === "standard" 
            ? 




                isMobile && hasException
                ?


    
                    <div 
                    key={props.ij}
                    className="labelfield"
                    style={{
                        backgroundColor:'transparent'
                    }}
                    >

                        <div 
                        className="label"
                        style={

                            width < threshold 
                            ?
    
                                {
                                    width:"100%",
                                    margin:"0 0 0 0"
                                }
    
                            :null
                        }
                        >

                            {props.data.label.text}

                        </div>


                        <div 
                        className="field-wrap"
                        style={

                            width < threshold 
                            ?
    
                                {
                                    width:"100%",
                                    margin:"0 0 0 0"
                                }
    
                            :null
                        }
                        >

                            {
                            hasException.inputMode === 'numeric'
                            ?

                                <input

                                    // type="text"
                                    // inputMode="text"

                                    type="number" pattern="[0-9]*"
                                    inputMode="numeric"

                                    className="symbol-input-tag"
                                    style={{
                                        backgroundColor:fieldStyl.bgColor,
                                        fontStyle:fieldStyl.fontStyle,
                                        color:fieldStyl.fontColor
                                    }}
                    
                                    id={props.data.field.id}
                                    value={fieldVal}
                                    placeholder={props.data.field.hint}
                                    tabIndex={props.data.field.tabIndex}

                
                                    //onFocus={handleFocus}

                                    /*
                                    onFocus = {event => {
                                        // event properties must be copied to use async
                                        const target = event.target;
                                        setTimeout(() => target.select(), 0);
                                    }}
                                    */

                                    onFocus={event => {
                                        // event properties must be copied to use async
                                        const target = event.target;
                                        setTimeout(() => handleFocus(target), 0);
                                    }}
                    
                                    // onBlur={event => {

                                    //     handleBlur({

                                    //         target      :event.target,
                                    //         fieldtype   :props.data.field.fieldtype

                                    //     })

                                    // }}  

                                    //onChange={noChange}
                                    
                                    onChange={event => {
                    
                                        handleChange({

                                            target      :event.target,
                                            fieldtype   :props.data.field.fieldtype
            
                                        });
                                    }}

                                    onKeyPress = {event => {
                                        // event properties must be copied to use async
                                        setTimeout(() => props.handleMovement({

                                            event       :event,
                                            section     :"labelfields",
                                            index       :props.ij
            
                
                                        }), 0);
                                    }}


                                />



                            :


                                <input

                                    type="text"
                                    inputMode="text"

                                    className="symbol-input-tag"
                                    style={{
                                        backgroundColor:fieldStyl.bgColor,
                                        fontStyle:fieldStyl.fontStyle,
                                        color:fieldStyl.fontColor
                                    }}
                    
                                    id={props.data.field.id}
                                    value={fieldVal}
                                    placeholder={props.data.field.hint}
                                    tabIndex={props.data.field.tabIndex}

                
                                    //onFocus={handleFocus}

                                    /*
                                    onFocus = {event => {
                                        // event properties must be copied to use async
                                        const target = event.target;
                                        setTimeout(() => target.select(), 0);
                                    }}
                                    */

                                    onFocus={event => {
                                        // event properties must be copied to use async
                                        const target = event.target;
                                        setTimeout(() => handleFocus(target), 0);
                                    }}
                                    
                                    // onBlur={event => {

                                    //     handleBlur({

                                    //         target      :event.target,
                                    //         fieldtype   :props.data.field.fieldtype

                                    //     })

                                    // }}  

                                    //onChange={noChange}
                                    
                                    onChange={event => {
                    
                                        handleChange({

                                            target      :event.target,
                                            fieldtype   :props.data.field.fieldtype
            
                                        });
                                    }}

                                    onKeyPress = {event => {
                                        // event properties must be copied to use async
                                        setTimeout(() => props.handleMovement({

                                            event       :event,
                                            section     :"labelfields",
                                            index       :props.ij
            
                
                                        }), 0);
                                    }}


                                />


                            }



                    
                        </div>

                    </div>



                    


                : //NOT EXCEPTION




                    <div 
                    key={props.ij}
                    className="labelfield"
                    style={{
                        backgroundColor:'transparent'
                    }}
                    >

                        <div 
                        className="label"
                        style={

                            width < threshold 
                            ?
    
                                {
                                    width:"100%",
                                    margin:"0 0 0 0"
                                }
    
                            :null
                        }
                        >

                            {props.data.label.text}

                        </div>




                        <div 
                        className="field-wrap"
                        style={

                            width < threshold 
                            ?
    
                                {
                                    width:"100%",
                                    margin:"0 0 0 0"
                                }
    
                            :null
                        }
                        >

                            <input

                                type="text"
                                inputMode="text"

                                className="symbol-input-tag"
                                style={{
                                    backgroundColor:fieldStyl.bgColor,
                                    fontStyle:fieldStyl.fontStyle,
                                    color:fieldStyl.fontColor
                                }}
                
                                id={props.data.field.id}
                                value={fieldVal}
                                placeholder={props.data.field.hint}
                                tabIndex={props.data.field.tabIndex}

            
                                //onFocus={handleFocus}

                                /*
                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => target.select(), 0);
                                }}
                                */

                                onFocus={event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocus(target), 0);
                                }}
                                
                                
                                // onBlur={event => {

                                //     handleBlur({

                                //         target      :event.target,
                                //         fieldtype   :props.data.field.fieldtype

                                //     })

                                // }}  

                                //onChange={noChange}
                                
                                onChange={event => {
                
                                    handleChange({

                                        target      :event.target,
                                        fieldtype   :props.data.field.fieldtype
        
                                    });
                                }}

                                onKeyPress = {event => {
                                    // event properties must be copied to use async
                                    setTimeout(() => props.handleMovement({

                                        event       :event,
                                        section     :"labelfields",
                                        index       :props.ij
        
            
                                    }), 0);
                                }}




                            />

                    
                        </div>

                    </div>





            : null


            //-------------------------------------
            }



            {
            //------------------------------------
            //PASSWORD FIELD
            //------------------------------------

            props.data.field.fieldtype === "password" 
            ? 


                <div 
                key={props.ij}
                className="labelfield"
                style={{
                    backgroundColor:'transparent'
                }}
                >

                    <div 
                    className="label"
                    style={

                        width < threshold 
                        ?

                            {
                                width:"100%",
                                margin:"0 0 0 0"
                            }

                        :null
                    }
                    >

                        {props.data.label.text}

                    </div>




                    <div 
                    className="field-wrap"
                    style={

                        width < threshold
                        ?

                            {
                                width:"100%",
                                margin:"0 0 0 0"
                            }

                        :null
                    }
                    >

                        <input
                            type={passwordFieldType}
                            inputMode="text"
                            className="symbol-input-tag"
                            style={{
                                backgroundColor:fieldStyl.bgColor,
                                fontStyle:fieldStyl.fontStyle,
                                color:fieldStyl.fontColor
                            }}
            
                            id={props.data.field.id}
                            value={fieldVal}
                            placeholder={props.data.field.hint}
                            tabIndex={props.data.field.tabIndex}

        
                            //onFocus={handleFocus}

                            /*
                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => target.select(), 0);
                            }}
                            */

                            onFocus={event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}
                            
                            /*
                            onBlur={event => {

                                handleBlur({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype

                                })

                            }}  
                            */
                            onChange={event => {
            
                                handleChange({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype
    

                                });
                            }}

                            onKeyPress = {event => {
                                // event properties must be copied to use async
                                setTimeout(() => props.handleMovement({

                                    event       :event,
                                    section     :"labelfields",
                                    index       :props.ij
    
        
                                }), 0);
                            }}



                        />

                
                    </div>

                </div>



            : null


            //-------------------------------------
            }


        </div>



    )


}


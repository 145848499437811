
import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';

import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';

import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";




import { 
    LibCheck,
    // LibForm, 
    // LibString 
} from 'library';

//import BuildItems from './BuildItems';

import ApiResources from 'utils/components/Pages/ApiResources';


//------------------------
//Axios Stuff
//------------------------

import axios from "axios";

const queryString = params =>
    Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join("&")

const createUrl = (url, queryOptions) => {

    if(Object.keys(queryOptions).length > 0)
    {
        url = url + "?" + queryString(queryOptions);
    }
    return url

}

const createPostParams = (bodyOptions) => {

    var ret;
    var params = "";
    var count = 0;
    for(var keyname in bodyOptions)
    {
        if(count === 0)
        {
            params += keyname+"="+bodyOptions[keyname];
        } else {
            params += "&"+keyname+"="+bodyOptions[keyname];
        }
        count++;
    }

    ret = params;
    if(ret === "") ret = false;
    return ret;

}



const Index = (props) => {


    //-------------------------------
    // add scripts
    //-------------------------------

    // -- add scripts -- //

    /*

    useAddScript({

        to:'body',
        async:false,
        url: 'https://apis.google.com/js/client.js?onload=googleApiClientReady'

    });
    */



    //-------------------------------
    // window dims
    //-------------------------------

    // const { 
    //     width, 
    //     height 
    // } = useWindowDimensions();

    // -- screen orientation -- //
    //const screenOrientation = useScreenOrientation()



    //-------------------------------
    // router
    //-------------------------------

    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    const hashbang = location.hash;



    //console.log("Location: "+JSON.stringify(location,null,2));

    // location: {
    //     "pathname": "/Text1",
    //     "search": "",
    //     "hash": "#!",
    //     "key": "90mmxu"
    // }




    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);



    //-------------------------------
    // redux
    //-------------------------------

    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));

    let currentZone = pathname;
    //currentZone = currentZone.replace(/\//gi,"-");
    currentZone = currentZone.replace(/ /gi,"-");
    if(currentZone === '') currentZone = 'Home';

    //let zoneKey = currentZone + "_fetch";
    
    // -- Fetch -- // 
    // let fetchd = Fetch({

    //     key      : currentZone+"_fetch",
    //     route    : pathname

    // }); 




    //-------------------------------
    // api
    //-------------------------------


    var apiDomain;
    var hostname;
    //var hostname = window.location.hostname;//returns the domain name of the web host
    var protocol = window.location.protocol;//returns the web protocol used (http: or https:)


    if(window.location.hostname.match(/192.168/gi))
    {
        hostname = ApiResources.network['3001'].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }
    else
    if(window.location.hostname.match(/localhost/gi))
    {
        hostname = ApiResources.local['3001'].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }else{
        hostname = ApiResources.aws['x'].vpc;
        apiDomain = "https://" + hostname + "/";
    }//=



    var postTo = apiDomain + "read-site-blueprint";

    //console.log("RESRC: "+resrc);

    // var route = pathname;
    // if(pathname === '') route = 'home';



    //fetchdOptions-start
	const [fetchdOptions] = useState({
		method:"POST",
		body:{
			site:"theprofitcalculator.com",
			tree:"blueprint-site-SiteVersion0",
			route:pathname,
			action:"readAll"

		},
		query:{}
	});
	//fetchdOptions-end








    // -- state -- //
    const [inProcess, setInProcess] = useState(false)

    useEffect(() => {


        const runAxios = (eff) => {


            let url = eff.resrc;
            let options = eff.options;
 
    

            //let result;

            let axiosParams;
            if(options.method.match(/GET/gi))
            {
                axiosParams = {
                    method: "GET",
                    url: createUrl(url, options.query),
                }
                
            }
            else
            if(options.method.match(/POST/gi))
            {

                axiosParams = {

                    method: "POST",
                    url: createUrl(url, options.query),
                    data: createPostParams(options.body),
                    headers: {

                        //'Content-Type': "application/json",
                        'Content-Type'  : 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                        //'Authorization' : 'Bearer '+localStorage.accessToken
                    },
                    // responseType:'json'
                }

            }

            // Content-Type
            //     'application/x-www-form-urlencoded' 
            //     'application/json' 
            
            // responseType 
            //     'arraybuffer', 
            //     'blob', 
            //     'document', 
            //     'json', 
            //     'text', 
            //     'stream'


            axios(axiosParams)
            .then(response => {

                // console.log("response: "+JSON.stringify(response,null,2));
                // console.log("response.data: "+response.data);
                // alert(response.data);

                return response.data;

            })
            .then(data =>  {

                console.log("Object prototype: "+Object.prototype.toString.call(data));
                console.log("data.success: "+data.success);
                console.log("data.result: "+data.result);
                console.log("data.result: "+JSON.stringify(data.result,null,2));



                // alert('Dashboard/Account - success');

                // if(props.key.match(/MSZ/gi))
                // {

                //     console.log("MSZ RESULT: "+JSON.stringify(result,null,2));
                //     //alert('success');


                // }

                /*
                result={
                    "success": true,
                    "result": {
                        "tokenError": {
                            "name": "TokenExpiredError",
                            "message": "jwt expired",
                            "expiredAt": "2020-12-27T18:13:18.000Z"
                        },
                        "authorized": false,
                        "user": false
                    }
                }

                */
 
                //alert(data.success);
            
                if(data.success)
                {
                    if(reset)
                    {
                        reset.value = data.result.account_items;
                    }

                    //reset = false;
                    //alert("reset: "+reset);


                    switch(props.call)
                    {
                    case'delete':

                        // props.handle({

                        //     call    :"completeConfirm",
                        //     //message :"SUCCESS: Process was successfull!!"
                        //     message :data.success.message,
                        //     reset   :reset
                    
                        // });

                    break;
                    default:

                        //alert('complete');
                        // props.handle({

                        //     call    :"complete",
                        //     //message :"SUCCESS: Process was successfull!!"
                        //     message :data.success.message,
                        //     reset   :reset
                    
                        // });



                        var currentRoute,currentKey;
                        currentRoute = options.body.route;
                        if(currentRoute === '') currentRoute = "Home";

                        currentKey = currentRoute+"_fetch";
                        console.log(currentKey);

        
               
                        if(LibCheck.IsIterable(data.result))
                        {

                            console.log("----IsIterable----");

                            //console.log(currentKey+"\n"+currentRoute+"\n"+eff.zoneObj[currentRoute+"_fetch"]);


                            // {
                            //     "error": false,
                            //     "result": {
                            //       "currentScene": {
                            //         "sceneRoute": "Home",
                            //         "sceneName": "Home",
                            //         "name": "Home_sys20211028060401id_.json",
                            //         "reckey": "rec-blog-entry-20211028060401",
                            //         "compName": "Home",
                            //         "recid": "20211028060401",
                            //         "routeName": "Home",
                            //         "sceneCompName": "Home"
                            //       },
                            //       "sceneName": "Home",
                            //       "sceneInfo": [
                            //         {
                            //           "styl": {
                            //             "call": "full",
                            //             "class": "FullRow",
                            //             "marginTop": "0px",
                            //             "marginBottom": "0px"
                            //           },
                            //           "call": "Navigator",
                            //           "info": {
                            //             "call": "default",
                            //             "gridType": "Tiles",
                            //             "gridBgColor": "transparent",
                            //             "gridCall": "grid_cell",
                            //             "cellCall": "default",
                            //             "modCall": "fill_div",
                            //             "thumb": {
                            //               "call": "default",
                            //               "diff": "0"
                            //             },
                            //             "cellBorder": {
                            //               "boolean": true,
                            //               "thickness": 1,
                            //               "color": "gray"
                            //             },
                            //             "gutter": {
                            //               "boolean": "0",
                            //               "size": "0"
                            //             },
                            //             "thresh": {
                            //               "refWidth": false,
                            //               "across1": "501",
                            //               "across2": "801",
                            //               "across3": "1051"
                            //             }
                            //           },
                            //           "items": [
                            //             {
                            //               "name": "Cars",
                            //               "to": "Cars",
                            //               "descr": "This link will go to Cars",
                            //               "category": "main"
                            //             },
                            //             {
                            //               "name": "McLaren-Honda Mp4/4",
                            //               "to": "McLarenHonda",
                            //               "descr": "McLaren-Honda Mp4/4",
                            //               "category": "Honda Racing"
                            //             },
                            //             {
                            //               "name": "Williams-Honda FW11/B",
                            //               "to": "WilliamsHonda",
                            //               "descr": "Williams-Honda FW11/B",
                            //               "category": "Honda Racing"
                            //             },
                            //             {
                            //               "name": "Spoon Sports AP1",
                            //               "to": "SpoonSportsAP1",
                            //               "descr": "Spoon Sports AP1",
                            //               "category": "Honda Racing"
                            //             },
                            //             {
                            //               "name": "S2000 CR",
                            //               "to": "S2000CR",
                            //               "descr": "S2000 CR",
                            //               "category": "Honda OEM"
                            //             },
                            //             {
                            //               "name": "S2000 AP1 / AP2",
                            //               "to": "S2000",
                            //               "descr": "S2000 AP1 / AP2",
                            //               "category": "Honda OEM"
                            //             },
                            //             {
                            //               "name": "Contact Form",
                            //               "to": "ContactForm",
                            //               "descr": "This link will go to ContactForm",
                            //               "category": "main"
                            //             },
                            //             {
                            //               "name": "Page 1",
                            //               "to": "Page1",
                            //               "descr": "This link will go to Page 1",
                            //               "category": "main"
                            //             },
                            //             {
                            //               "name": "Page 2",
                            //               "to": "Page2",
                            //               "descr": "This link will go to Page 2",
                            //               "category": "main"
                            //             },
                            //             {
                            //               "name": "Page 3",
                            //               "to": "Page3",
                            //               "descr": "This link will go to Page 3",
                            //               "category": "main"
                            //             }
                            //           ],
                            //           "recid": "20211028062323",
                            //           "reckey": "rec-blogsegment-entry-20211028062323",
                            //           "name": "Navigator--Home-----Alpha_sys20211028062323id_.json"
                            //         }
                            //       ]
                            //     }
                            //   }
                          





                            // var object;
                            // var key,value;
                            // for(object of data.result)
                            // {
                            //     key = object.result.currentScene.sceneRoute+'_fetch';
                            //     value = object.result;

                            //     if(key !== currentKey)
                            //     {
                            //         props.handle({

                            //             call:'dispatch',
                            //             data:{

                            //                 action      :'UPDATE_ZONE',
                            //                 key         :key,
                            //                 value       :value

                            //             },
                            //             usePrefix:false // will add currentZone to the zoneObj

                            //         });

                            //     }//if

                            // }//for 


    
                        }//if

                        props.handle({

                            call:'FetchAll',
                            data:data.result
                            
                        });


                    }



                }else{

                    //alert("Account axios error - 1.2");
                    props.handle({

                        call    :"error",
                        message :"SUCCESS: No Data",
                        reset   :false
                
                    });

                }


            })
            .catch(error =>  {


                console.log("FAILED");
                console.log(error);


                //alert("Account error - 1.1");
                props.handle({

                    call       :"error",
                    type       :'process',
                    errors     :[{

                        "key"       :"0-process",
                        "section"   :"process",
                        "message"   :error.message
    
                    }]
        
                });


            });




        };//runAxios
        
        //#############################################################################
        //#############################################################################

        /**
         * 
         * 
         * To block multiple hits to the server 
         * 
         * 1) Set response to "processing" or "not null" once fetch begins - this prevents a function rerun
         * 2) Keep loading set to true until fetch is complete
         * 3) On page component check that loading is "false" before calling dispatch
         * 
         * 
         * NOTE: There are two version async and non async - no differcene I see between the two
         * use the non async version
         * 
         * 
         */





        if(!inProcess)
        {

            setInProcess(true);


            var isOkay = false;
            //var okar = [];  
            // var ear = [];

            // var frm;
            // var label;
            // var field;
            // var array;
            // //var object;
            // var err;

            // //var indx;
            // var i;
            // var j;

            // var newVal;


            //console.log(JSON.stringify(formArr,null,2));
            //alert('submit check');


            props.handle({call:"clear"});
            isOkay = true;
            if(isOkay)
            {

                // var currentRoute;
                // currentRoute = fetchdOptions.body.route;
                // if(currentRoute === '') currentRoute = "Home";
    
                // alert(currentRoute+"\n"+JSON.stringify(zoneObj[currentRoute+"_fetch"],null,2));
    
                var reset; 
                //var editName;
    
                reset = false;
                //editName = 'FetchAll';
            
                //var view = JSON.stringify(okar,null,2);
                //console.log("VIEW: "+view);
 
                var testOutput = false;
                if(testOutput)
                {

                    console.log("POST TO: "+postTo);


                    // props.handle({

                    //     call:'FetchAll',
                    //     data:fetchdOptions,
                        
                    // });

                    // props.handle({

                    //     call    :"error",
                    //     message :"SUCCESS: No Data",
                    //     reset   :false
                
                    // });

                    // props.handle({

                    //     call        :"output",
                    //     message     :"SUCCESS: Run Output Here",
                    //     data        :{
        
                    //         call    :"print",
                    //         data    :[]
                    //     }
        
                    // });




                    var html,str;
                    html += "<h2>FetchAll</h2>";
            
                        str = JSON.stringify(fetchdOptions,null,2);
    
                    html += str;

        
                    props.handle({

                        call:"showReadr",
                        data :{

                            data:{
                                name:"Readr",
                                value:html
                            },
                            flyr:{
                                type:'print',
                                styl:{
                                    maxWidth:750
                                }
                            }

                        }
        
                    });



                    // props.handle({

                    //     call:'FetchAll',
                    //     data:fetchdOptions,
                        
                    // });





                }
                else
                {


                    // hostname = ApiResources.aws['x'].vpc;
                    // apiDomain = "https://" + hostname + "/";
                    //var resrc = apiDomain + "fetch-siteplan";
                    //var resrc = apiDomain + "fetch-blueprint";

                    var resrc = apiDomain + "form-submit";
                    if(!postTo || postTo !== undefined) resrc = postTo;
                    //alert("RESRC: "+resrc);


                    //console.log("RESRC: "+resrc);
                
                
                    // var route = pathname;
                    // if(pathname === '') route = 'home';
                
            
                    // var site = 'theprofitcalculator.com';
                    // var email = false;
        
                    // if(window.location.hostname)
                    // {
                    //     site = window.location.hostname;
                    // }
                    // if(localStorage.accessEmail && localStorage.accessEmail !== undefined)
                    // {
                    //     email = localStorage.accessEmail;
                    // }
        
        
                    // var href = window.location.href;                //returns the href (URL) of the current page
                    // var host = window.location.host;                //returns the domain with port number
                    // var hostname = window.location.hostname;        //returns the domain name of the web host
                    // var pathname = window.location.pathname;        //returns the path and filename of the current page
                    // var protocol = window.location.protocol;        //returns the web protocol used (http: or https:)
                    // var assign = window.location.assign;            //loads a new document
                    
                    // alert(""
                    // +"\nlocation: "+JSON.stringify(location,null,2)
                    // +"\nhostname: "+window.location.hostname
                    // +"\nsite: "+site
                    // +"\nemail: "+email
                    // +"\naccessEmail: "+localStorage.accessEmail
                    // +"\naccessToken: "+localStorage.accessToken 
                    // +"\nrefreshToken: " +localStorage.refreshToken
                    // );
        
                
                    runAxios({
        
                        resrc:resrc,
                        options:{
        
                            method:fetchdOptions.method,
                            body:fetchdOptions.body,
                            query:fetchdOptions.query
        
                        }
        
                            
                    });


                }// -- run

                    
            }//==


        }
        else
        {

            console.log("FetchAll - Axios Processing...");


        }//-- inProcess


   
    }, [

        hashbang,
        inProcess,
        zoneObj,
        props,
        apiDomain,
        postTo,
        fetchdOptions
 
    ])


    return <div></div>


};

export default Index;




